import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TeamService } from './team.service';
import { TeamPermissionType } from './models/teamPermissionType.enum';

@Injectable()
export class TeamPermissionGuardService implements CanActivate {
  constructor(private teamService: TeamService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(obs => {
      var projectId = route.parent.params.id;
      var permissionId = route.data['permissionId'] as string;
      var permissionGroup = route.data['permissionGroup'] as string;
      this.teamService.GetPermission(projectId, permissionGroup, permissionId).subscribe(result => {
        if (result === TeamPermissionType.None) {
          this.router.navigate(['/user', 'project', projectId, 'unauthorized']);
          obs.next(false);
        } else if (result === TeamPermissionType.ReadOnly) {
          this.teamService.setReadOnlyProp(true);
          obs.next(true);
        } else {
          obs.next(true);
        }
      });
    });
  }
}
