import { Component, OnInit, Input } from '@angular/core';
import { UserSharedService } from '../../services/user.shared.sevice';
import { UserProfile } from '../../models/userProfile';

@Component({
  selector: 'user-info',
  templateUrl: './user.info.component.html'
})
export class UserInfoComponent implements OnInit {
  @Input() userId: string;
  @Input() showFirstName: boolean = true;
  @Input() showLastName: boolean = true;
  profile: UserProfile;
  constructor(private userSharedService: UserSharedService) {}

  ngOnInit(): void {
    this.userSharedService.GetProfile(this.userId).subscribe(profile => {
      this.profile = profile;
    });
  }
}
