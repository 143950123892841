import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Permission } from '../models/permission';
import { ProjectSet } from '../models/sets/projectSetts';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class UACProjectService {
  constructor(private client: HttpClient) {}

  public GetUserPermissions(delegateUserId: string, projectId: string): Observable<ProjectSet> {
    return this.client.get<ProjectSet>(`/uac/project/GetUserPermissions/${delegateUserId}/${projectId}`);
  }

  public AddUserPermissions(delegateUserId: string, projectId: string, permission: Permission): Observable<Permission> {
    return this.client.put<Permission>(`/uac/project/AddUserPermissions/${delegateUserId}/${projectId}`, permission);
  }

  public DeleteUserPermission(delegateUserId: string, projectId: string, permissionId: string): Observable<boolean> {
    return this.client.delete<boolean>(`/uac/project/DeleteUserPermission/${projectId}/${delegateUserId}/${permissionId}`);
  }
}
