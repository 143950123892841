import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'project-settings-sidenavigation',
  templateUrl: './project.settings.sidenavigation.component.html'
})
export class ProjectSettingsSideNavigationComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
