import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { ImageInfo } from '../models/imageinfo';
import { ImageDataInfo } from '../models/imagedata';
import { PhotoViews } from '../models/photoview.enum';

@Component({
  selector: 'civcast-picture',
  templateUrl: './picture.component.html',
  styleUrls: ['./picture.css']
})
export class CivCastPictureComponent implements OnInit {
  @Input() image: ImageInfo;
  @Input() photoView: PhotoViews = PhotoViews.Full;
  @Input() imageSize: ImageSizes;
  @Output() removeImageEvent = new EventEmitter<ImageInfo>();
  @Output() editImageEvent = new EventEmitter<ImageInfo>();
  @Output() viewImageEvent = new EventEmitter<ImageInfo>();

  shownImageData: ImageDataInfo;

  constructor() {}

  ngOnInit(): void {
    if (this.imageSize == ImageSizes.Thumbnail) {
      this.shownImageData = this.image.ThumbnailImageUrl;
    } else if (this.imageSize == ImageSizes.Large) {
      this.shownImageData = this.image.LargeImageUrl;
    } else {
      this.shownImageData = this.image.ThumbnailImageUrl;
    }
  }

  showImage(image: ImageInfo) {
    image.ShowOverlay = true;
  }

  hideImage(image: ImageInfo) {
    image.ShowOverlay = false;
  }

  removeImage(image: ImageInfo) {
    this.removeImageEvent.next(image);
  }

  markForDeletion(image: ImageInfo, isMarked: boolean) {
    this.image.MarkForDeletion = isMarked;
    this.removeImageEvent.next(image);
  }

  editImage(image: ImageInfo) {
    this.editImageEvent.next(image);
  }

  viewImage(image: ImageInfo) {
    this.viewImageEvent.next(image);
  }
}

export enum ImageSizes {
  Thumbnail,
  Large
}
