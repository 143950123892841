import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Permission } from '../../models/permission';
import { UACUserService } from '../../services/uac.user.service';

@Component({
  selector: 'user-permissions',
  templateUrl: './user.permissions.component.html',
  styleUrls: []
})
export class UserPermissionsComponent implements OnInit {
  constructor(private uacUserService: UACUserService) {}

  @Input() permissions: Array<Permission>;
  @Output() removePermission = new EventEmitter<Permission>();

  ngOnInit(): void {
    this.uacUserService.getPermissionsRefresh().subscribe(permissions => {
      this.permissions = permissions;
    });
  }

  removeAPermission(perm: Permission) {
    this.removePermission.next(perm);
  }
}
