import { CostCode } from './costcode';

export class CostCodeWizard {
  constructor() {
    this.SheetInfo = new CostCodeSheetData();
    this.NewCostCodes = new Array<CostCode>();
    this.MissingCostCodes = new Array<CostCode>();
  }

  ProjectId: string;
  FileLocation: string;
  CurrentStep: CostCodeEnum;
  IsCustom: boolean;
  SheetInfo: CostCodeSheetData;
  NewCostCodes: Array<CostCode>;
  MissingCostCodes: Array<CostCode>;
}

export class CostCodeSheetData {
  constructor() {
    this.Code = new ColumnHeading();
    this.Description = new ColumnHeading();
    this.Hours = new ColumnHeading();
    this.Units = new ColumnHeading();
    this.UM = new ColumnHeading();

    this.Sheets = new Array<string>();
  }

  Code: ColumnHeading;
  Description: ColumnHeading;
  Hours: ColumnHeading;
  Units: ColumnHeading;
  UM: ColumnHeading;
  SelectedSheetName: string;
  SampleCostCode: CostCode;
  Sheets: Array<string>;
  HasHeader: boolean;
}

export class ColumnHeading {
  Name: string;
  Cell: string;
}

export enum CostCodeEnum {
  Import,
  CustomImport,
  GetSampleData,
  Save
}
