import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { AccessControlService } from './services/access.control.service';
import { AccessLevel } from './models/accessLevel';

@Injectable()
export class ProjectPermissionResolve implements Resolve<AccessLevel> {
  constructor(private accessControlService: AccessControlService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Observable<AccessLevel>(obs => {
      var projectId = route.parent.params.id;
      var permissionId = route.data['permissionId'] as string;
      var permissionGroup = route.data['permissionGroup'] as string;
      this.accessControlService.GetProjectAccessLevel(projectId, permissionId, permissionGroup).subscribe(result => {
        obs.next(result);
        obs.complete();
      });
    });
  }
}
