import * as Core from '../../../core';

export class Employee extends Core.BaseEntity {
  public _t: string[] = ['BaseEntity', 'Employee'];
  CustomId: string;
  ForemanId: string;
  FirstName: string;
  LastName: string;
  Classification: string;
  IsActive: boolean;
}
