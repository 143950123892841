import { Approver } from './approver';

export class ApprovalHistory {
  User: Approver;
  ApprovedDate: Date;
  HistoryType: ApprovalHistoryType;
}

export enum ApprovalHistoryType {
  Submitted,
  Approved,
  Edited,
  Final,
  Unapproved,
  Unlock
}
