import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { ComponentView } from '../../models/IComponent';

@Component({
  selector: 'modal-dailylog',
  templateUrl: './dailylog.modal.component.html'
})
export class DailyLogModalComponent {
  @Input() projectId: string;
  @Input() permission: AccessLevel;
  @Input() dailyLogId: string;
  @Input() month: number;
  @Input() day: number;
  @Input() year: number;
  @Input() userId: string;
  @Input() dateTimeInfo: Date;
  @Input() view: ComponentView = ComponentView.Single;

  constructor(private activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.dismiss();
  }
}
