import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalService } from '../../../../services/global.service';
import * as ProjectApi from '../../models';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import { UserProjectService } from '../../user.project.service';
import { SettingsService } from './settings.service';
import { IComponent } from '../../models/IComponent';
import { ValidationResult } from '../../models/validationResult';
import { Observable } from 'rxjs/Observable';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { AlertType } from '../../../custom.controls/growl/alert-type.enum';
import { ComponentIcons } from '../../../../shared/component.icons';

@Component({
  selector: 'project-settings',
  templateUrl: './project.settings.component.html',
  styleUrls: ['../project.component.css']
})
export class ProjectSettingsComponent implements OnInit, IComponent {
  allComponents: ProjectApi.Component[] = [];
  // dynamicComponents: ProjectApi.Component[] = [];
  // currentComponent: ProjectApi.Component;
  // @ViewChild('components', { read: ViewContainerRef }) target: ViewContainerRef;
  projectId: string;
  project: ProjectApi.Project;
  @Input() componentType: string;
  icons: typeof ComponentIcons = ComponentIcons;

  constructor(
    private userProjectService: UserProjectService,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private growlService: GrowlService,
    private settingsService: SettingsService,
    private userSharedService: UserSharedService
  ) {}

  ngOnInit(): void {
    this.route.parent.parent.params.subscribe(params => {
      this.projectId = params['id'];
      this.route.data.subscribe(params => {
        if (this.settingsService.componentType.toLowerCase() === 'global') {
          this.getGlobalComponents();
        } else if (this.settingsService.componentType.toLowerCase() === 'project') {
          this.getProjectComponents();
        }
      });
    });
  }

  setActive(c: ProjectApi.Component) {
    c['IsActive'] = !c['IsActive'];
    // this.target.clear();
  }
  initializeComponents() {}

  addAll() {
    for (const c of this.allComponents) {
      if (c.AllowDeletion) {
        c['IsActive'] = true;
      }
    }
  }

  removeAll() {
    for (const c of this.allComponents) {
      if (c.AllowDeletion) {
        c['IsActive'] = false;
      }
    }
  }

  saveComponents() {
    if (this.settingsService.componentType.toLowerCase() === 'global') {
      this.saveGlobalComponents();
    } else if (this.settingsService.componentType.toLowerCase() === 'project') {
      this.saveProjectComponents();
    }
  }

  getProjectComponents() {
    this.allComponents = [];
    this.userProjectService.GetProjectComponentInfo(this.projectId).subscribe(pComps => {
      this.globalService.GetStaticData('projectcomponents').subscribe(globalComps => {
        for (const pComponent of pComps) {
          const hasComponent = globalComps.filter(item => item.ComponentIdentifier.indexOf(pComponent.ComponentIdentifier) !== -1);

          if (hasComponent.length > 0) {
            pComponent['IsActive'] = true;

            if (hasComponent[0].Components) {
              for (const gInner of hasComponent[0].Components) {
                const hasInner = pComponent.Components.filter(
                  x => x.ComponentIdentifier.indexOf(hasComponent[0].ComponentIdentifier) !== -1
                );

                if (hasInner.length > 0) {
                  hasInner['IsActive'] = true;
                } else {
                  gInner['IsActive'] = false;
                  pComponent.Components.push(gInner);
                }
              }
            }
          } else {
            pComponent['IsActive'] = false;
          }
          this.allComponents.push(pComponent);
        }

        for (const component of globalComps) {
          const c = pComps.filter(item => item.ComponentIdentifier.indexOf(component.ComponentIdentifier) !== -1);
          if (c.length <= 0) {
            component['IsActive'] = false;

            if (component.Components) {
              for (const innerC of component.Components) {
                innerC['IsActive'] = false;
              }
            }

            this.allComponents.push(component);
          }
        }
      });
    });
  }

  getGlobalComponents() {
    this.globalService.GetStaticData('ProjectComponents').subscribe(
      projectGlobalComponents => {
        this.userSharedService.GetComponents().subscribe(components => {
          for (const uComponent of components) {
            const hasComponent = projectGlobalComponents.filter(
              item => item.ComponentIdentifier.indexOf(uComponent.ComponentIdentifier) !== -1
            );

            if (hasComponent.length > 0) {
              uComponent['IsActive'] = true;
            } else {
              uComponent['IsActive'] = false;
            }
            this.allComponents.push(uComponent);
          }

          for (const component of projectGlobalComponents) {
            const c = this.allComponents.filter(item => item.ComponentIdentifier.indexOf(component.ComponentIdentifier) !== -1);
            if (c.length <= 0) {
              component['IsActive'] = false;
              this.allComponents.push(component);
            }
          }
        });
      },
      err => {}
    );
  }

  saveProjectComponents() {
    const checkedComponents = [];

    const components = Object.assign({}, this.allComponents);

    for (const component in components) {
      if (components[component]['IsActive']) {
        const c = components[component];
        c['_t'] = ['BaseEntity', 'Component'];
        c['Id'] = component;

        checkedComponents.push(c);
      }
    }

    this.userProjectService.ReplaceProjectComponents(this.projectId, checkedComponents).subscribe(reponse => {
      this.growlService.addAlert('Project Tools have been saved.', AlertType.SUCCESS);
    });
  }

  saveGlobalComponents() {
    const checkedComponents: ProjectApi.Component[] = [];

    const components = Object.assign({}, this.allComponents);

    for (const component in components) {
      if (components[component]['IsActive']) {
        const c = components[component];
        c['_t'] = ['BaseEntity', 'Component'];
        c['Id'] = component;

        checkedComponents.push(c);
      }
    }

    this.userSharedService.SaveComponents(checkedComponents).subscribe(success => {
      if (success) {
        this.growlService.addAlert('Tools have been saved.', AlertType.SUCCESS);
      } else {
        this.growlService.addAlert('The was an error saving the tools.', AlertType.DANGER);
      }
    });
  }

  Save(): Observable<boolean> {
    return Observable.of(true);
  }
  Validate(): Observable<ValidationResult> {
    const valResult = new ValidationResult();
    valResult.IsValid = true;
    return Observable.of(valResult);
  }

  Clear(): Observable<boolean> {
    return Observable.of(true);
  }

  Remove(): Observable<boolean> {
    return Observable.of(true);
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
