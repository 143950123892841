import { Component } from '../models/component';

export class DailyProjectComponent extends Component {
  constructor() {
    super();
  }

  ProjectId: string;
  Year: number;
  Day: number;
  Month: number;
  DateTimeInfo: Date;
}
