import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from './settings.service';
import { UserProjectService } from '../../user.project.service';
import { ComponentFactory } from '../../models/componentFactory';
import { UserSharedService } from '../../../user/services/user.shared.sevice';

@Component({
  selector: 'settings-parent',
  templateUrl: './settings.parent.component.html',
  styleUrls: ['../project.component.css']
})
export class SettingsParentComponent implements OnInit {
  @ViewChild('component', { static: false }) target: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private userProjectService: UserProjectService,
    private userSharedService: UserSharedService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.settingsService.parentName = params.parent;

      if (this.settingsService.componentType.toLowerCase() === 'project') {
        if (this.settingsService.parentName === 'costcodes') {
          var factory = new ComponentFactory(this.target, this.componentFactoryResolver);
          factory.InitializeCostCodeSettings();
        } else {
          this.userProjectService.GetProjectSection(this.settingsService.pId, this.settingsService.parentName).subscribe(comp => {
            if (comp) {
              var factory = new ComponentFactory(this.target, this.componentFactoryResolver);
              factory.InitializeComponent(comp, this.settingsService.componentType);
            }
          });
        }
      } else {
        this.userSharedService.GetComponent(this.settingsService.parentName).subscribe(comp => {
          if (comp) {
            var factory = new ComponentFactory(this.target, this.componentFactoryResolver);
            factory.InitializeComponent(comp, this.settingsService.componentType);
          }
        });
      }
    });
  }
}
