import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { TeamService } from './team.service';
import { Observable } from 'rxjs/Rx';
import { TeamPermissionType } from './models/teamPermissionType.enum';

@Injectable()
export class TeamPermissionResolve implements Resolve<TeamPermissionType> {
  constructor(private teamService: TeamService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Observable<TeamPermissionType>(obs => {
      var projectId = route.parent.params.id;
      var permissionId = route.data['permissionId'] as string;
      var permissionGroup = route.data['permissionGroup'] as string;
      this.teamService.GetPermission(projectId, permissionGroup, permissionId).subscribe(result => {
        obs.next(result);
        obs.complete();
      });
    });

    //var projectId = route.parent.params.id;
    //var permissionId = route.data["permissionId"] as string;

    //return this.teamService.GetPermission(projectId, permissionId).subscribe(result => {
    //    return true;
    //});
  }
}
