import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserProjectService } from '../user.project.service';
import { ModalSaveProject } from './modal.saveproject.component';
import { DatePipe } from '@angular/common';
import { InfoService } from './info/info.service';
import { ValueTypes } from '../../civcast.form/models/valueTypes.enum';
import { TeamService } from './team/team.service';
import { AccessControlService } from '../../user.permissions/services/access.control.service';
import { GrowlService } from '../../custom.controls/growl/growl.service';
import { AlertType } from '../../custom.controls/growl/alert-type.enum';
import * as ProjectApi from '../../user.project/models';
import 'rxjs/Rx';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { OrderByPipe } from 'ngx-pipes';

@Component({
  selector: 'project-list',
  templateUrl: './projectlist.component.html',
  styleUrls: ['./projectlist.component.css'],
  providers: [OrderByPipe]
})
export class ProjectListComponent implements OnInit {
  projects: Array<ProjectApi.Project> = new Array();
  recentProjects: Array<ProjectApi.RecentProject> = new Array();
  nFilterProjects: Array<ProjectApi.Project> = new Array();
  //nFilterRecentProjects: ProjectApi.RecentProject[];
  filter: string = 'list';

  search = new FormControl();
  hasProjects: boolean = false;
  public loading: boolean = false;

  constructor(
    private router: Router,
    private userProjectService: UserProjectService,
    private infoService: InfoService,
    private accessControlService: AccessControlService,
    private growlService: GrowlService,
    private teamService: TeamService,
    private modalService: NgbModal,
    private orderyBy: OrderByPipe
  ) {}

  ngOnInit() {
    this.loadProjects();
  }

  loadProjects() {
    this.loading = true;
    Observable.forkJoin(this.userProjectService.GetProjects(), this.userProjectService.GetRecentProjects()).subscribe(
      info => {
        var projects = info[0];
        if (projects.length > 0) {
          this.hasProjects = true;
        }

        this.projects = projects;
        this.nFilterProjects = Object.assign([], projects);

        this.recentProjects = info[1];

        this.loading = false;

        this.search.valueChanges.debounceTime(400).subscribe(value => {
          this.userProjectService.SearchProjects(value).subscribe(projects => {
            this.projects = projects;
            this.nFilterProjects = Object.assign([], projects);
          });
        });
      },
      error => {
        this.loading = false;
      }
    );
  }

  getRecentMenu(project: ProjectApi.RecentProject) {
    this.userProjectService.GetProjectComponentInfo(project.Project.Id).subscribe(components => {
      project.Project.Components = components;
    });
  }

  getProjectMenu(project: ProjectApi.Project) {
    this.userProjectService.GetProjectComponentInfo(project.Id).subscribe(components => {
      project.Components = components;
    });
  }

  isTeamMember(project: ProjectApi.Project) {
    this.teamService.IsTeamMember(project.Id).subscribe(isTeam => {
      console.log('Is Team Member: ' + isTeam);
    });
  }

  highlightRecentProject(project: ProjectApi.RecentProject) {
    var isHighlighted = !project.Project.IsHighlighted;
    this.userProjectService.HighlightProject(project.ProjectId, isHighlighted).subscribe(x => {
      project.Project.IsHighlighted = isHighlighted;

      for (let p of this.projects) {
        if (p.Id == project.ProjectId) {
          p.IsHighlighted = isHighlighted;
        }
      }
    });
  }

  highlightProject(project: ProjectApi.Project) {
    var isHighlighted = !project.IsHighlighted;
    this.userProjectService.HighlightProject(project.Id, isHighlighted).subscribe(x => {
      project.IsHighlighted = isHighlighted;

      for (let p of this.recentProjects) {
        if (p.ProjectId == project.Id) {
          p.Project.IsHighlighted = isHighlighted;
        }
      }
    });
  }

  gatherDetailInfo(project: ProjectApi.Project) {
    var permissionId = 'project::info';
    var permissionGroup = 'project';

    this.accessControlService.GetProjectAccessLevel(project.Id, permissionId, permissionGroup).subscribe(result => {
      if (result.AccessLevelId.toLowerCase() === 'fullaccess' || result.AccessLevelId.toLowerCase() === 'readonly') {
        this.infoService.GetInfoComponent(project.Id).subscribe(props => {
          if (props != null) {
            if (props.Properties != null) {
              project['Properties'] = props.Properties;

              var dateItems = props.Properties.filter(x => x.ValueType == ValueTypes.Date);
              var datePipe = new DatePipe('en');
              for (let d of dateItems) {
                d.Value = datePipe.transform(d.Value, 'MM/dd/yyyy') as String;
              }
            }
          }
        });
      }
    });
  }

  goToProject(project: ProjectApi.Project) {
    this.router.navigate(['/project', project.Id]);
  }

  addProject() {
    var permissionId = 'project::addproject';
    var permissionGroup = 'global';
    this.accessControlService.GetAccessLevel(permissionId, permissionGroup).subscribe(result => {
      if (result.AccessLevelId.toLowerCase() === 'fullaccess') {
        const modalRef = this.modalService.open(ModalSaveProject, { size: 'lg' });
        modalRef.componentInstance.project = new ProjectApi.Project();
        modalRef.result.then(result => {
          var project = result as ProjectApi.Project;

          if (!this.projects) {
            this.projects = new Array<ProjectApi.Project>();
          }

          if (!this.nFilterProjects) {
            this.nFilterProjects = new Array<ProjectApi.Project>();
          }

          this.projects.push(project);
          this.nFilterProjects.push(Object.assign({}, project));

          this.hasProjects = true;
        });
      } else {
        this.growlService.addAlert('You do not have access to add projects', AlertType.WARNING);
      }
    });
  }

  setFilter(f: string) {
    this.filter = f;
  }
  setOrder(prop) {
    this.projects = this.orderyBy.transform(this.projects, prop);
  }
}
