import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from "rxjs/Rx";
import { AccessControlService } from './services/access.control.service';
import { AccessLevel } from './models/accessLevel';

@Injectable()
export class PermissionResolve implements Resolve<AccessLevel> {

    constructor(private accessControlService: AccessControlService, private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) { }

    resolve(route: ActivatedRouteSnapshot) {
        return new Observable<AccessLevel>(obs => {
            var permissionId = route.data["permissionId"] as string;
            var permissionGroup = route.data["permissionGroup"] as string;
            this.accessControlService.GetAccessLevel(permissionId, permissionGroup).subscribe(result => {
                obs.next(result);
                obs.complete();
            });
        });
    }
}