import { ImageDataInfo } from './imagedata';

export class ImageInfo {
  constructor(file: File = null) {
    this.oFile = file;
  }

  ImageId: string;
  oFile: File;
  ThumbnailImageUrl: ImageDataInfo = new ImageDataInfo();
  LargeImageUrl: ImageDataInfo = new ImageDataInfo();
  IsProcessing: boolean = false;
  ShowOverlay: boolean = false;
  UploadDateTime: Date = null;
  MarkForDeletion: boolean = false;
  Description: string = null;
  Title: string = null;
}
