import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';
import { CivCastPicaService } from '../pica/pica.service';
import { CivCastPicaResizeOptionsInterface } from '../pica/pica.resize.option.interface';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CivCastImageService } from '../image.service';
import { Subject, Observable, Subscription } from 'rxjs';
import { ImageInfo } from '../models/imageinfo';
import { MaxFileError } from './maxfile.error';

@Component({
  selector: 'civcast-aws-uploader',
  templateUrl: './civcast.aws.uploader.component.html'
})
export class CivCastAWSUploader implements OnDestroy {
  public filesList: Array<File> = new Array<File>();
  private unsubscribe: Subject<void> = new Subject();
  private subscription: Subscription;
  @Output() selectedFiles = new EventEmitter<FileList>();
  @Output() uploadError = new EventEmitter<Error>();
  @Output() finishUploadButtonEvent = new EventEmitter();
  @Input() allowDragDrop: boolean = false;
  @Input() maxFiles: number = 0;

  constructor() {}

  onDrop(event: DragEvent) {
    this.preventEvent(event);

    if (!event.dataTransfer || !event.dataTransfer.files) {
      return;
    }

    this.runFiles(event.dataTransfer.files);
  }

  onDragEnter(event: Event) {
    this.preventEvent(event);
  }

  onDragOver(event: any) {
    this.preventEvent(event);
  }

  runFiles(files: FileList) {
    this.selectedFiles.next(files);
  }

  onChange(event: EventTarget) {
    let eventObj: MSInputMethodContext = <MSInputMethodContext>event;
    let target: HTMLInputElement = <HTMLInputElement>eventObj.target;
    let files: FileList = target.files;

    if (this.maxFiles > 0) {
      if (files.length > this.maxFiles) {
        this.uploadError.next(new MaxFileError('Too many files. You are allowed to upload ' + this.maxFiles + ' at a time'));
        return;
      }
    }

    this.runFiles(files);

    target.value = '';
    this.executeFinishButton();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  executeFinishButton() {
    this.finishUploadButtonEvent.next();
  }

  showOverlay(imageInfo: ImageInfo) {
    imageInfo.ShowOverlay = true;
  }

  hideOverlay(imageInfo: ImageInfo) {
    imageInfo.ShowOverlay = false;
  }

  private preventEvent(event: any): void {
    event.stopPropagation();
    event.preventDefault();
  }
}

//
//Here is how I personally did it.

//I had an api I can call that gives me back basic infos :
//{
//    "token":"cfc2b831-...",
//        "url":"https://test-26.s3-eu-west-1.amazonaws.com/",
//            "formData":[
//                { "bucket": "test-26" },
//                { "key": "upload/images/11a59aa4..." },
//                { "Content-Type": "image/" },
//                { "X-Amz-Algorithm": "AWS4-HMAC-SHA256" },
//                { "X-Amz-Credential": "AKKWEN2A/20171129/eu-west-1/s3/aws4_request" },
//                { "X-Amz-Date": "20171129T091121Z" },
//                { "policy": "eyJleH..." },
//                { "X-Amz-Signature": "3afbb6d..." }
//            ]
//}
//Then this is my function to send the file on fileDrop:

//onFileDrop(fileArr) {
//    const file = fileArr[0];
//    this.http.post('api/image', { imageType: 'Photo' }).subscribe((r: any) => {
//        const xhr: XMLHttpRequest = new XMLHttpRequest();
//        const formData = new FormData();
//        const url = r.url;
//        r.formData.forEach(ent => {
//            Object.entries(ent).forEach(([k, v]) => {
//                formData.append(k, v);
//            });
//        });
//        formData.append('file', file);
//        xhr.open('POST', url, true);
//        xhr.send(formData);
//    });
//}
