import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserProjectService } from '../user.project.service';
import * as ProjectApi from '../models';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'project-sidenav-component',
  templateUrl: './project.sidenav.component.html',
  styleUrls: ['./project.component.css']
})
export class ProjectSidenavComponent implements OnInit {
  components: ProjectApi.Component[] = [];
  projectId: string;
  constructor(
    private userProjectService: UserProjectService,
    private client: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.projectId = this.route.snapshot.params.id;
    this.client.get('/api/global/GetStaticData/ProjectComponents').subscribe(
      projectGlobalComponents => {
        const globalComponents = projectGlobalComponents as ProjectApi.Component[];

        this.userProjectService.GetProjectComponentInfo(this.projectId).subscribe(components => {
          for (const uComponent of components) {
            const hasComponent = globalComponents.filter(item => item.ComponentIdentifier.indexOf(uComponent.ComponentIdentifier) !== -1);

            if (hasComponent.length > 0) {
              uComponent['IsChecked'] = true;
            } else {
              uComponent['IsChecked'] = false;
            }
            this.components.push(uComponent);
          }

          for (const component of globalComponents) {
            const c = this.components.filter(item => item.ComponentIdentifier.indexOf(component.ComponentIdentifier) !== -1);
            if (c.length <= 0) {
              component['IsChecked'] = false;
              this.components.push(component);
            }
          }
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  saveComponents() {
    const checkedComponents = [];

    const components = Object.assign({}, this.components);

    for (const component in components) {
      if (components[component]['IsChecked']) {
        const c = components[component];
        c['_t'] = ['BaseEntity', 'Component'];
        c['Id'] = component;
        // delete c["IsChecked"];

        checkedComponents.push(c);
      }
    }

    this.userProjectService.ReplaceProjectComponents(this.projectId, checkedComponents).subscribe(reponse => {});
  }
}
