import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TeamService } from './team.service';

@Injectable()
export class TeamGuardService implements CanActivate {
  constructor(private teamService: TeamService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(obs => {
      var projectId = route.params.id;

      if (!projectId) {
        projectId = route.parent.params.id;
      }

      this.teamService.IsTeamMember(projectId).subscribe(isLoggedIn => {
        if (!isLoggedIn) {
          this.router.navigate(['/user', 'team-unauthorized']);
          obs.next(false);
        } else {
          obs.next(true);
        }
      });
    });
  }
}
