import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ProjectActivityService } from './project.activity.service';
import { ProjectActivity } from './projectActivity';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'project-activity',
  templateUrl: './project.activity.component.html'
})
export class ProjectActivityComponent implements OnInit {
  activity: ProjectActivity[];
  page: number = 1;
  totalItems: number;
  search = new FormControl();
  orderBy: string;
  projectId: string;
  isReversed: boolean = true;

  constructor(private route: ActivatedRoute, private router: Router, private projectActivityService: ProjectActivityService) {}

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.projectId = params['id'];
      //this.projectActivityService.GetProjectActivity(projectId).subscribe(activity => {
      //    this.activity = activity;
      //});

      this.getPagedActivity();

      this.search.valueChanges.debounceTime(800).subscribe(value => {
        this.getPagedActivity();
      });
    });
  }

  getPagedActivity() {
    this.projectActivityService
      .GetProjectActivityPaged(this.projectId, this.page - 1, this.orderBy, this.isReversed, this.search.value)
      .subscribe(result => {
        this.activity = result.Items;
        this.totalItems = result.Info.TotalItems;
      });
  }

  changePage() {
    this.getPagedActivity();
  }
}
