import { Injectable, OnDestroy } from '@angular/core';
import { ImageInfo } from './models/imageinfo';
import { CivCastPicaService } from './pica/pica.service';
import { Observable } from 'rxjs/Observable';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { CivCastPicaResizeOptionsInterface } from './pica/pica.resize.option.interface';
import { CivCastPicaExifService } from './pica/pica.exif.service';
import { of } from 'rxjs/observable/of';
import { concatMap } from 'rxjs/operator/concatMap';
import { retry } from 'rxjs/operators/retry';

@Injectable()
export class CivCastImageService implements OnDestroy {
  //public imageList: Array<ImageInfo> = new Array<ImageInfo>();
  private options: CivCastPicaResizeOptionsInterface;
  private unsubscribe: Subject<void> = new Subject();
  public addPhoto = new Subject<ImageInfo>();
  public addPhotoHandler$ = this.addPhoto.asObservable();
  public removePhoto = new Subject<ImageInfo>();
  public removePhotoHandler$ = this.removePhoto.asObservable();

  public removePhotoById = new Subject<string>();
  public removePhotoByIdHandler$ = this.removePhotoById.asObservable();

  public clearPhotos = new Subject<Array<ImageInfo>>();
  public clearPhotosHandler = this.clearPhotos.asObservable();

  public photoUpdate = new Subject<ImageInfo>();
  public photoUpdateHandler = this.photoUpdate.asObservable();

  public photoMarkDeletion = new Subject<ImageInfo>();
  public photoMarkDeletionHandler = this.photoMarkDeletion.asObservable();

  constructor(private picaService: CivCastPicaService, private sanitizer: DomSanitizer, private civCastExifSerivce: CivCastPicaExifService) {
    this.options = {
      features: ['js', 'ww'],
      aspectRatio: {
        forceMinDimensions: false,
        keepAspectRatio: true
      }
    };
  }

  private imageExtensions: string[] = ['image/jpeg', 'image/png', 'image/png', 'image/tiff'];

  public isImage(file: File): boolean {
    //const fileExtension: string = file.name.toLowerCase().substr(file.name.lastIndexOf('.') + 1);
    return this.imageExtensions.indexOf(file.type) !== -1;
  }

  public getPhotoMetaData(image: HTMLImageElement): Observable<any> {
    var obs = new Subject<any>();

    this.civCastExifSerivce.getAllTags(image).subscribe(
      result => {
        obs.next(result);
        obs.complete();
      },
      err => {
        obs.error(err);
      }
    );

    return obs.asObservable();
  }

  public processImages(imageList: Array<ImageInfo>) {
    for (let image of imageList) {
      this.processImage(image).subscribe(
        result => {
          image = result;
          image.IsProcessing = false;
        },
        err => {
          image.IsProcessing = false;
        }
      );
    }
  }

  public addImages(files: FileList) {
    var fList = new Array<ImageInfo>();
    for (var x = 0; x < files.length; x++) {
      var file = files.item(x);

      if (!this.isImage(file)) {
        console.log('File is not a supported image: ' + file.type);
      } else {
        var info: ImageInfo = null;
        info = new ImageInfo(file);
        fList.push(info);
      }
    }

    var source = Observable.range(0, fList.length);

    var v = source
      .concatMap((item: number) => {
        return this.processImage(fList[item]);
      })
      .subscribe(image => {
        this.addImage(image);
      });
  }

  public addImage(image: ImageInfo) {
    this.addPhoto.next(image);
  }

  public addImageFile(file: File): Observable<ImageInfo> {
    var obs = new Subject<ImageInfo>();

    var info: ImageInfo = null;
    info = new ImageInfo(file);

    this.processImage(info).subscribe(
      result => {
        this.addImage(result);

        obs.next(info);
        obs.complete();
      },
      err => {
        obs.error(err);
      }
    );

    return obs.asObservable();
  }

  public removeImage(image: ImageInfo) {
    this.removePhoto.next(image);
  }

  public removeImageById(imageId: string) {
    this.removePhotoById.next(imageId);
  }

  public markPhotoForDeletion(image: ImageInfo) {
    this.photoMarkDeletion.next(image);
  }

  public clearAllPhotos(images: Array<ImageInfo>) {
    var locList = Object.assign([], images);

    for (let image of locList) {
      if (image.UploadDateTime) {
        image.MarkForDeletion = true;
        this.markPhotoForDeletion(image);
      } else {
        image.MarkForDeletion = false;
        this.removeImage(image);
      }
    }
  }

  processImage(info: ImageInfo): Observable<ImageInfo> {
    info.IsProcessing = true;
    let imageInfoProcess = new Subject<ImageInfo>();

    var img = new Image();

    img.onload = () => {
      this.civCastExifSerivce.getExifOrientedImagePromise(img).subscribe(oImage => {
        var finalSafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(oImage.src);
        info.ThumbnailImageUrl.Url = oImage.src;
        info.ThumbnailImageUrl.SafeUrl = finalSafeUrl;
        info.ThumbnailImageUrl.Height = oImage.height;
        info.ThumbnailImageUrl.Width = oImage.width;

        info.LargeImageUrl.Url = oImage.src;
        info.LargeImageUrl.SafeUrl = finalSafeUrl;
        info.LargeImageUrl.Height = oImage.height;
        info.LargeImageUrl.Width = oImage.width;

        info.IsProcessing = false;

        imageInfoProcess.next(info);
        imageInfoProcess.complete();
      });
    };

    var url = URL.createObjectURL(info.oFile);
    img.src = url;

    return imageInfoProcess.asObservable();
  }

  getThumbnail(info: ImageInfo, height: number = 260, width: number = 480): Observable<File> {
    var obs = new Subject<File>();
    this.picaService.resizeImage(info.oFile, width, height, true, this.options).subscribe(
      result => {
        obs.next(result);
        obs.complete();
      },
      error => {
        obs.error(error);
      }
    );

    return obs.asObservable();
  }

  getResizeImage(info: ImageInfo, height: number = 1080, width: number = 1920, options: CivCastPicaResizeOptionsInterface): Observable<File> {
    var obs = new Subject<File>();
    this.picaService.resizeImage(info.oFile, width, height, true, options).subscribe(
      result => {
        obs.next(result);
        obs.complete();
      },
      error => {
        obs.error(error);
      }
    );

    return obs.asObservable();
  }

  getCompressedImage(info: ImageInfo, size: number = 0.5): Observable<File> {
    var obs = new Subject<File>();
    this.picaService.compressImage(info.oFile, size, true).subscribe(
      result => {
        obs.next(result);
        obs.complete();
      },
      error => {
        obs.error(error);
      }
    );

    return obs.asObservable();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
