import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Auth } from 'aws-amplify';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      Auth.currentAuthenticatedUser()
        .then(result => {
          if (!result) {
            this.router.navigate(['/unauthorized']);
            obs.next(false);
          } else {
            obs.next(true);
          }
          //this.authService.activateSignIn(result);
        })
        .catch(() => {});
    });
  }
}
