import { Component, OnInit } from '@angular/core';
import { UserProjectService } from '../../user.project/user.project.service';
import { ApprovalService } from './approval.service';
import { Approval } from './models/approval';
import { UserSharedService } from '../services/user.shared.sevice';
import { ApprovalLevel } from './models/approvalLevel';
import { ApprovalInfo } from './models/approvalInfo';
import { AccessLevel } from '../../user.permissions/models/accessLevel';
import { TimeCardService } from '../../user.project.timecard/timecard.service';
import { FormControl } from '@angular/forms';
import { DailyLogService } from '../../user.project/components/dailylog/dailylog.service';
import { NgbDateStruct, NgbModal, NgbDate, NgbCalendar, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { SelectedData } from '../../core/SelectedData';
import { ConfirmService } from '../../custom.controls/confirm/confirm.service';
import { GrowlService } from '../../custom.controls/growl/growl.service';
import { AlertType } from '../../custom.controls/growl/alert-type.enum';
import { Observable } from 'rxjs/Observable';
import { Approver } from './models/approver';
import { DailyLogComponent } from '../../user.project/components/dailylog/dailylogcomponent';
import { DailyLogModalComponent } from '../../user.project/components/dailylog/dailylog.modal.component';
import { orderBy } from 'lodash';

@Component({
  selector: 'user-approvals',
  templateUrl: './approval.view.component.html',
  styleUrls: ['./approval.view.css']
})
export class UserApprovalViewComponent implements OnInit {
  private Approvals: Array<ApprovalInfo> = new Array<ApprovalInfo>();
  private ApprovalLevels: Array<ApprovalLevel> = new Array<ApprovalLevel>();
  isExpandAll = false;
  nsAccessLevel: AccessLevel;
  mode = 1;
  currentFilter = 'Unapproved';
  currentType = 'unapproved';
  search = new FormControl();
  sort: any = null;
  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  noData: Array<any> = [];
  projectSelection: Array<SelectedData> = new Array<SelectedData>();
  selectedProjects: Array<SelectedData> = new Array<SelectedData>();
  isPayroll = false;
  isApproving = false;
  currentUserLevel = -1;
  currentSelectedLevel = 1;
  public s2ProjectOptions: any = {};
  projectsLoaded = false;
  fiilterLoaded = false;
  sortDataItems: Array<any> = new Array<any>();
  notSubmitted: Array<DailyLogComponent> = new Array<DailyLogComponent>();
  projectFilterType = 'TeamProjects';
  dateFilterType = 'SingleDay';
  hoveredDate: NgbDate;
  fromDate: NgbDate | null;
  toDate: NgbDate | null;
  dateFilterInfo: DateFilterInfo;

  constructor(
    private approvalService: ApprovalService,
    private projectService: UserProjectService,
    private userService: UserSharedService,
    private timeCardService: TimeCardService,
    private growlService: GrowlService,
    private dailyLogService: DailyLogService,
    private confirmService: ConfirmService,
    private modal: NgbModal,
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter
  ) {
    this.nsAccessLevel = new AccessLevel();
    this.nsAccessLevel.AccessLevelId = 'readonly';
    this.dateFilterInfo = new DateFilterInfo();

    // this.dateFilterInfo.dateRangeStart = calendar.getToday();
    // this.dateFilterInfo.dateRangeEnd = calendar.getNext(calendar.getToday(), 'd', 10);

    this.s2ProjectOptions = {
      singleSelection: false,
      idField: 'ItemId',
      textField: 'ItemText',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 0,
      allowSearchFilter: true
    };

    this.sortDataItems = [
      {
        Name: 'Date',
        Sort: 0
      },
      {
        Name: 'Foreman',
        Sort: 0
      },
      {
        Name: 'ProjectId',
        Sort: 0
      },
      {
        Name: 'Project',
        Sort: 0
      }
    ];

    this.sort = this.sortDataItems[0];

    const filter = {
      Projects: [],
      StartDate: null,
      EndDate: null
    };

    this.search.valueChanges.debounceTime(500).subscribe(value => {
      this.filter(this.currentType, this.currentUserLevel);
    });

    this.approvalService.IsPayroll().subscribe(result => {
      this.isPayroll = result;
    });
  }

  clearDateFilterRange() {
    this.dateFilterInfo.dateRangeStart = null;
    this.dateFilterInfo.dateRangeEnd = null;

    this.filter(this.currentType, this.currentUserLevel);
  }
  ngOnInit(): void {
    this.getProjects();
  }

  /**Start Date Range Functions */

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
  }
  onDateSelection(date: NgbDate) {
    this.dateFilterInfo.singleDate = null;
    if (!this.dateFilterInfo.dateRangeStart && !this.dateFilterInfo.dateRangeEnd) {
      this.dateFilterInfo.dateRangeStart = date;
    } else if (this.dateFilterInfo.dateRangeStart && !this.dateFilterInfo.dateRangeEnd && date.after(this.dateFilterInfo.dateRangeStart)) {
      this.dateFilterInfo.dateRangeEnd = date;
    } else {
      this.dateFilterInfo.dateRangeEnd = null;
      this.dateFilterInfo.dateRangeStart = date;
    }

    if (this.dateFilterInfo.dateRangeStart && this.dateFilterInfo.dateRangeEnd) {
      this.filter(this.currentType, this.currentUserLevel);
    }
  }

  onSingleDateSelection(date: NgbDate) {
    this.dateFilterInfo.dateRangeEnd = null;
    this.dateFilterInfo.dateRangeStart = null;
    this.dateFilterInfo.singleDate = date;

    this.filter(this.currentType, this.currentUserLevel);
  }

  clearDateFilterSingle() {
    this.dateFilterInfo.singleDate = null;
    this.filter(this.currentType, this.currentUserLevel);
  }
  isHovered(date: NgbDate) {
    return (
      this.dateFilterInfo.dateRangeStart &&
      !this.dateFilterInfo.dateRangeEnd &&
      this.hoveredDate &&
      date.after(this.dateFilterInfo.dateRangeStart) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return date.after(this.dateFilterInfo.dateRangeStart) && date.before(this.dateFilterInfo.dateRangeEnd);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.dateFilterInfo.dateRangeStart) ||
      date.equals(this.dateFilterInfo.dateRangeEnd) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  setProjectFilter(filterType) {
    this.projectFilterType = filterType;
    this.getProjects();
  }

  /**End Date Range Functions */
  getProjects() {
    this.projectsLoaded = false;
    if (this.projectFilterType === 'TeamProjects') {
      this.projectService.GetTeamProjects().subscribe(
        result => {
          this.projectsLoaded = true;
          this.setupProjectsFilterList(this.sortFilterList(result));

          this.approvalService.GetUserLevel().subscribe(result => {
            this.currentUserLevel = result;
            this.approvalService.GetUserApprovalLevels().subscribe(result => {
              this.ApprovalLevels = result;
              this.filter(this.currentType, this.currentUserLevel);
            });
          });
        },
        err => {
          this.projectsLoaded = true;
        }
      );
    } else if (this.projectFilterType === 'AllProjects') {
      this.projectService.GetAllProjects().subscribe(
        result => {
          this.projectsLoaded = true;
          this.setupProjectsFilterList(this.sortFilterList(result));
          this.filter(this.currentType, this.currentUserLevel);
        },
        err => {
          this.projectsLoaded = true;
        }
      );
    } else {
      this.projectsLoaded = true;
    }
  }

  setupProjectsFilterList(projects) {
    this.projectSelection = new Array<SelectedData>();
    for (const p of projects) {
      const project: SelectedData = {
        ItemId: p.Id,
        ItemText: p.InternalId
      };

      this.projectSelection.push(project);
    }

    this.selectedProjects = new Array<SelectedData>();

    for (const p of projects) {
      const project: SelectedData = {
        ItemId: p.Id,
        ItemText: p.InternalId
      };

      this.selectedProjects.push(project);
    }
  }

  sortFilterList(data: any) {
    return orderBy([...data], 'InternalId', ['asc']);
  }

  setDateFilter(filter) {
    this.dateFilterType = filter;
  }

  onItemDeSelect(item: any) {
    this.filter(this.currentType, this.currentUserLevel);
  }

  onItemSelect(item: any) {
    this.filter(this.currentType, this.currentUserLevel);
  }
  onSelectAll(items: any) {
    this.getProjects();
    // this.filter(this.currentType, this.currentUserLevel);
  }

  onItemDeselectAll(items: any) {
    this.selectedProjects = [];
    this.filter(this.currentType, this.currentUserLevel);
  }

  expandAll() {
    this.isExpandAll = !this.isExpandAll;
    for (const item of this.Approvals) {
      item.ShowTimeCard = this.isExpandAll;
    }
  }

  sortData(sort: any) {
    this.sort = sort;
    this.filter(this.currentType, this.currentUserLevel);

    if (this.sort.Sort === 0) {
      this.sort.Sort = 1;
    } else if (this.sort.Sort === 1) {
      this.sort.Sort = 0;
    }
  }

  getFilter(lvl: number) {
    return {
      Sort: this.sort,
      Search: this.search.value,
      Level: lvl,
      DateInfo: this.dateFilterInfo,
      Projects: this.selectedProjects
    };
  }

  selectAll() {
    for (const item of this.Approvals) {
      if (item.Approval.FinalApprovalDate === null) {
        item.IsSelected = true;
      }
    }
  }

  usSelectAll() {
    for (const item of this.Approvals) {
      if (item.Approval.FinalApprovalDate === null) {
        item.IsSelected = false;
      }
    }
  }

  showDailyLog(dailyLog: DailyLogComponent) {
    const aLevel = new AccessLevel();
    aLevel.AccessLevelId = 'readonly';

    const ref = this.modal.open(DailyLogModalComponent, { size: 'lg' });
    ref.componentInstance.projectId = dailyLog.ProjectId;
    ref.componentInstance.dailyLogId = dailyLog.Id;
    ref.componentInstance.permission = aLevel;
    ref.componentInstance.userId = dailyLog.UserId;
    ref.componentInstance.dateTimeInfo = new Date(dailyLog.DateTimeInfo);
  }

  filter(filter: string, level: number) {
    this.fiilterLoaded = false;
    if (level === undefined || level === null) {
      level = this.currentUserLevel;
    }

    const mainFilter = this.getFilter(level);
    this.currentSelectedLevel = level;

    this.currentType = filter;
    if (this.currentType === 'unapproved') {
      if (this.currentSelectedLevel >= 999) {
        this.currentFilter = 'Unapproved for Payroll';
      } else {
        this.currentFilter = 'Unapproved at L' + this.currentSelectedLevel;
      }

      this.approvalService.GetAllUnApprovals(mainFilter).subscribe(
        result => {
          this.Approvals = result;
          this.fiilterLoaded = true;
        },
        err => {
          this.fiilterLoaded = true;
        }
      );
    } else if (this.currentType === 'approved') {
      if (this.currentSelectedLevel >= 999) {
        this.currentFilter = 'Approved for Payroll';
        this.approvalService.GetApprovedForPayroll(mainFilter).subscribe(result => {
          this.Approvals = result;
          this.fiilterLoaded = true;
        });
      } else {
        this.currentFilter = `Approved at L${this.currentSelectedLevel}`;
        this.approvalService.GetAllApproved(mainFilter).subscribe(
          result => {
            this.Approvals = result;
            this.fiilterLoaded = true;
          },
          err => {
            this.fiilterLoaded = true;
          }
        );
      }
    } else if (this.currentType === 'all') {
      this.currentFilter = 'All Timesheets';

      this.approvalService.GetApprovals(mainFilter).subscribe(
        result => {
          this.Approvals = result;
          this.fiilterLoaded = true;
        },
        err => {
          this.fiilterLoaded = true;
        }
      );
    } else if (this.currentType === 'notsubmitted') {
      // this.approvals = [];
      // this.noData = [];
      this.currentFilter = 'Not Submitted';
      this.dailyLogService.GetNotSubmitted(mainFilter).subscribe(
        result => {
          this.nsAccessLevel = new AccessLevel();
          this.nsAccessLevel.AccessLevelId = 'readonly';
          for (const comp of result) {
            comp.DateTimeInfo = new Date(comp.DateTimeInfo);
          }

          this.notSubmitted = result;
          this.fiilterLoaded = true;
        },
        err => {
          this.fiilterLoaded = true;
        }
      );
    } else if (this.currentType === 'nodata') {
      this.Approvals = [];
      // this.notSubmitted = [];
      this.currentFilter = 'No Data';

      this.fiilterLoaded = true;
      if (this.startDate && this.endDate) {
        this.fiilterLoaded = false;
        this.dailyLogService.GetAllDailyLogNoData(this.selectedProjects, this.startDate, this.endDate).subscribe(
          result => {
            for (let item of result) {
              item = new Date(item);
            }
            this.noData = result;
            this.fiilterLoaded = true;
          },
          err => {
            this.fiilterLoaded = true;
          }
        );
      }
    }
  }

  selectApproval(approval: ApprovalInfo) {
    if (approval.Approval.FinalApprovalDate === null) {
      approval.IsSelected = !approval.IsSelected;
    }
  }

  approveSelected() {
    this.isApproving = true;
    const approvals = Array<Observable<Approver>>();

    for (const a of this.Approvals) {
      if (a.IsSelected) {
        approvals.push(this.approvalService.Approve(a.ProjectId, a.Approval.Id));
      }
    }

    if (approvals.length > 0) {
      Observable.forkJoin(approvals).subscribe(result => {
        this.isApproving = false;
        this.filter(this.currentType, this.currentUserLevel);
      });
    } else {
      this.isApproving = false;
    }
  }

  approve(approval: Approval) {
    this.confirmService.open('Are you ready to approve this time card?').result.then(
      item => {
        this.approvalService.Approve(approval.ProjectId, approval.Id).subscribe(result => {
          approval.CurrentApprover = result;
        });
      },
      err => {
        this.growlService.addAlert(err._body, AlertType.DANGER);
      }
    );
  }

  removeApproval(approval) {
    this.approvalService.GetApproval(approval.ProjectId, approval.DailyLogId).subscribe(result => {
      let idx = -1;
      for (const item of this.Approvals) {
        if (item.Approval.Id === approval.Id) {
          idx++;
          break;
        }
      }

      if (idx !== -1) {
        this.Approvals.splice(idx, 1);
      }
    });
  }

  updateApproval(approval) {}

  unapprove(approval: Approval) {
    this.confirmService.open('Do you want to unpprove this time card?').result.then(item => {
      this.approvalService.Unapprove(approval.ProjectId, approval.Id).subscribe(
        result => {},
        err => {
          this.growlService.addAlert(err._body, AlertType.DANGER);
        }
      );
    });
  }

  unlock(approval: Approval) {
    this.confirmService.open('Do you want to unlock this time card?').result.then(item => {
      this.approvalService.Unlock(approval.ProjectId, approval.Id).subscribe(
        result => {
          approval.FinalApprovalDate = null;
        },
        err => {
          this.growlService.addAlert(err._body, AlertType.DANGER);
        }
      );
    });
  }
}

export class DateFilterInfo {
  dateRangeStart: NgbDate;
  dateRangeEnd: NgbDate;
  singleDate: NgbDate;
}
