import { Observable } from 'rxjs';
import { ComponentView } from './IComponent';

export abstract class DataBoundComponent<T> {
  public abstract DataBindByProjectInfo(): Observable<T>;
  public abstract DataBindByUserInfo(): Observable<T>;
  error: DataComponentError;

  constructor() {
    this.error = new DataComponentError();
  }
  InitializeData(view: DataComponentView): Observable<T> {
    if (view === DataComponentView.Project) {
      return this.DataBindByProjectInfo();
    } else if (view === DataComponentView.User) {
      return this.DataBindByUserInfo();
    } else {
      this.error.hasError = true;
      this.error.messages.push('View not Supported: ' + view);
      throw new Error('View not found: ' + view);
    }
  }

  /*
        @deprecated
        This initializes data based on old view type.
    */
  InitializeDataCView(view: ComponentView) {
    if (view === ComponentView.Single) {
      return this.DataBindByUserInfo();
    } else if (view === ComponentView.Normal) {
      return this.DataBindByProjectInfo();
    } else if (view === ComponentView.FinalApprover) {
      return this.DataBindByUserInfo();
    } else {
      this.error.hasError = true;
      this.error.messages.push('View not Supported: ' + view);
      throw new Error('View not supported: ' + view);
    }
  }
}

export enum DataComponentView {
  Project,
  User
}

export class DataComponentError {
  hasError: boolean = false;
  messages: Array<string> = [];
}
