import { Component, OnInit, Input, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as UserApi from '../../models';
import { EquipmentService } from '../../services/equipment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EquipmentModal } from './equipment.modal';
import { EquipmentImportModal } from './equipment.import.modal';
import { FormControl } from '@angular/forms';
import 'rxjs/Rx';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'equipment-equipment',
  templateUrl: './equipment.component.html'
})
export class EquipmentComponent implements OnInit {
  equipment: UserApi.EquipmentItem[] = [];
  templateUrl: string;
  search = new FormControl();
  nEquipment: UserApi.EquipmentItem[] = [];
  @Input() permissionType: AccessLevel;

  constructor(private route: ActivatedRoute, private modalService: NgbModal, private equipmentService: EquipmentService) {}

  ngOnInit() {
    this.route.data.subscribe(params => {
      this.permissionType = params.permissionType;

      this.templateUrl = environment.api + '/user/equipment/GetEquipmentTemplate';

      this.equipmentService.GetEquipment().subscribe(equipment => {
        this.equipment = equipment;
        this.nEquipment = equipment;
      });

      this.search.valueChanges.debounceTime(400).subscribe(value => {
        if (value === '') {
          this.equipment = this.nEquipment;
        } else {
          this.equipment = this.nEquipment.filter(
            item =>
              item.EquipmentId.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
              item.Make.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
              item.Model.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
              item.Notes.toString().toLowerCase().indexOf(value.toLowerCase()) > -1
          );
        }
      });
    });
  }

  importEquipment() {
    const modalRef = this.modalService.open(EquipmentImportModal, { size: 'lg' });

    modalRef.result.then(items => {
      this.equipment = this.equipment.concat(items);
    });
  }

  addEquipmentItem() {
    const modalRef = this.modalService.open(EquipmentModal, { size: 'lg' });
    modalRef.componentInstance.equipmentItem = new UserApi.EquipmentItem();

    modalRef.result.then(item => {
      this.equipment.push(item);
    });
  }

  editItem(idx, item) {
    const modalRef = this.modalService.open(EquipmentModal, { size: 'lg' });
    modalRef.componentInstance.equipmentItem = Object.assign({}, item);

    modalRef.result.then(item => {
      this.equipment.splice(idx, 1, item);
    });
  }

  deleteItem(idx, item) {
    this.equipmentService.RemoveEquipmentItem(item).subscribe(response => {
      if (response) {
        this.equipment.splice(idx, 1);
      }
    });
  }
}
