import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Delegate } from '../models/delegate';
import { Observable } from 'rxjs/Observable';
import { UserSharedService } from '../services/user.shared.sevice';

@Injectable()
export class DelegateResolve implements Resolve<Delegate> {
  constructor(private userSharedService: UserSharedService) {}

  resolve(route: ActivatedRouteSnapshot) {
    return new Observable<Delegate>(obs => {
      var delegateId = route.params.delegateId;
      this.userSharedService.GetDelegate(delegateId).subscribe(result => {
        obs.next(result);
        obs.complete();
      });
    });
  }
}
