import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { UserProjectService } from '../../user.project.service';
import { ReportsService } from './report.service';
import { TeamService } from '../team/team.service';
import { TeamMember } from '../team/models/TeamMember';

@Component({
  selector: 'reports-component',
  templateUrl: './report.component.html'
})
export class ReportsComponent implements OnInit {
  projectId: string;
  templateUrl: string;
  @Input() permissionType: AccessLevel;
  teamMembers: Array<TeamMember> = [];

  constructor(private userProjectService: UserProjectService) {
    this.permissionType = new AccessLevel();
    this.permissionType.AccessLevelId = 'fullaccess';
  }

  ngOnInit() {
    if (this.userProjectService.project) {
      this.projectId = this.userProjectService.project.Id;
    } else {
      this.userProjectService.mainProject$.subscribe(x => {
        this.projectId = x.Id;
      });
    }
  }
}
