import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GrowlService } from './growl/growl.service';
import { GrowlComponent } from './growl/growl.component';
import { ClassificationPipe } from './pipes/classification.pipe';
import { ConfirmService } from './confirm/confirm.service';
import { ConfirmModalComponent } from './confirm/confirm.modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { CivCastNumericalInput } from "./Input/Numerical/numerical.input.component";
import { OrderPipe } from './pipes/orderBy.pipe';

@NgModule({
  imports: [CommonModule, NgbModule],
  declarations: [
    GrowlComponent,
    ConfirmModalComponent,
    ClassificationPipe,
    OrderPipe
    // CivCastNumericalInput
  ],
  providers: [GrowlService, ConfirmService],
  exports: [
    GrowlComponent,
    ClassificationPipe,
    // CivCastNumericalInput,
    OrderPipe
  ],
  entryComponents: [ConfirmModalComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CivCastCommonControlsModule {}
