import { Component, OnInit, Input } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AccessLevel } from '../../../../user.permissions/models/accessLevel';
import { AccessControlService } from '../../../../user.permissions/services/access.control.service';
import { GlobalReportsService } from '../user.report.service';

@Component({
  selector: 'user-timecard-report',
  templateUrl: './user.timecard.report.component.html'
})
export class UserTimeCardReportComponent implements OnInit {
  @Input() permissionType: AccessLevel;

  startDate: NgbDateStruct;
  endDate: NgbDateStruct;
  isAllowed: boolean = false;
  isRunning: boolean = false;

  constructor(private accessControlService: AccessControlService, private globalReportsService: GlobalReportsService) {}

  ngOnInit(): void {
    var permissionId = 'global::report::timecard';
    var permissionGroup = 'global';
    this.accessControlService.GetAccessLevel(permissionId, permissionGroup).subscribe(result => {
      if (result.AccessLevelId.toLowerCase() === 'fullaccess') {
        this.isAllowed = true;
      }
    });

    this.globalReportsService.GetWeekRange().subscribe(result => {
      this.startDate = {
        day: result.StartDate.Day,
        month: result.StartDate.Month,
        year: result.StartDate.Year
      };

      this.endDate = {
        day: result.EndDate.Day,
        month: result.EndDate.Month,
        year: result.EndDate.Year
      };
    });
  }

  runTimeCardReport() {
    this.isRunning = true;
    this.globalReportsService.RunTimeCardReport(this.startDate, this.endDate).subscribe(
      result => {
        this.isRunning = false;
      },
      err => {
        this.isRunning = false;
      }
    );
  }
}
