import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import * as UserApi from '../models';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class EmployeeService {
  constructor(private client: HttpClient) {}

  public SaveEmployeeImportInfo(empInfo: any) {
    return this.client.post<boolean>('/user/employee/SaveEmployeeImportInfo', empInfo);
  }

  public GetEmployees(status: string): Observable<UserApi.Employee[]> {
    return this.client.get<UserApi.Employee[]>(`/user/employee/GetEmployees/${status}`);
  }

  public GetForemen(status: string): Observable<UserApi.Foreman[]> {
    return this.client.get<UserApi.Foreman[]>(`/user/employee/GetForemen/${status}`);
  }

  public GetForemenWithEmployees(status: string): Observable<UserApi.Foreman[]> {
    return this.client.get<UserApi.Foreman[]>(`/user/employee/GetForemenWithEmployees/${status}`);
  }

  public SaveEmployee(item: UserApi.Employee): Observable<UserApi.Employee> {
    return this.client.post<UserApi.Employee>('/user/employee/SaveEmployee', item);
  }

  public AddEmployee(item: UserApi.Employee): Observable<UserApi.Employee> {
    return this.client.post<UserApi.Employee>('/user/employee/AddEmployee', item);
  }

  public SaveForeman(item: UserApi.Foreman): Observable<UserApi.Foreman> {
    return this.client.post<UserApi.Foreman>('/user/employee/SaveForeman', item);
  }

  public SaveEmployeeSettings(isEnabled: boolean, item): Observable<boolean> {
    return this.client.post<boolean>(`/user/employee/SaveEmployeeSettings/${isEnabled}`, item);
  }

  public EmployeeActivation(employeeId: string, isEnabled: boolean): Observable<boolean> {
    return this.client.get<boolean>(`/user/employee/EmployeeActivation/${employeeId}/${isEnabled}`);
  }

  public ForemanActivation(employeeId: string, isEnabled: boolean): Observable<boolean> {
    return this.client.get<boolean>(`/user/employee/ForemanActivation/${employeeId}/${isEnabled}`);
  }

  public GetStepInfo(step, requestInfo): Observable<any> {
    return this.client.post<any>(`/user/employee/EmployeeImportCustom/${step}`, requestInfo);
  }

  public ChangeForeman(oldForemanId: string, newForemanId: string): Observable<boolean> {
    return this.client.get<boolean>(`/user/employee/ChangeForeman/${oldForemanId}/${newForemanId}`);
  }
}
