//Modules
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InlineEditorModule } from '@qontu/ngx-inline-editor';
import { NgPipesModule } from 'ngx-pipes';
import { UserPermissionsModule } from '../user.permissions/user.permissions.module';
import { DndModule } from 'ng2-dnd';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { Select2Module } from 'ng2-select2';
import { ToastrModule } from 'ngx-toastr';
//Components
import { TimeCardComponentView } from './timecard.component';
import { TimeCardGraphComponent } from './timecard.graph.component';

//services
import { TimeCardService } from './timecard.service';
import { HttpClientModule } from '@angular/common/http';
import { TimeReasonsSettingsComponent } from './time-reasons-settings/time-reasons-settings.component';
//Modals

//pipes

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    InlineEditorModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgbModule,
    DndModule.forRoot(),
    UserPermissionsModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(0, 79, 150, 0.8)'
    }),
    Select2Module,
    ToastrModule.forRoot()
  ],
  declarations: [TimeCardComponentView, TimeCardGraphComponent, TimeReasonsSettingsComponent],
  exports: [TimeCardComponentView, TimeCardGraphComponent]
})
export class UserTimeCardModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UserTimeCardModule,
      providers: [TimeCardService]
    };
  }
}
