import { Component, OnInit, Input, ViewContainerRef, ViewChild, ComponentFactoryResolver, Output, EventEmitter } from '@angular/core';

import { AccessLevel } from '../../user.permissions/models/accessLevel';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalHistoryModalComponent } from './approvalhistory.modal.component';
import { DailyLogComponent } from '../../user.project/components/dailylog/dailylogcomponent';
import { Approval } from './models/approval';
import { DailyLogService } from '../../user.project/components/dailylog/dailylog.service';
import { ConfirmService } from '../../custom.controls/confirm/confirm.service';
import { ApprovalService } from './approval.service';
import { GrowlService } from '../../custom.controls/growl/growl.service';
import { AlertType } from '../../custom.controls/growl/alert-type.enum';
import { ComponentView } from '../../user.project/models/IComponent';
import { ApprovalHistoryType } from './models/approvalHistory';
import { DailyLogModalComponent } from '../../user.project/components/dailylog/dailylog.modal.component';
import { TimeCardComponentView, TimeCardLayout } from '../../user.project.timecard/timecard.component';
import { TimeCardService } from '../../user.project.timecard/timecard.service';
import { ProjectDailyLogComponent } from '../../user.project/components/dailylog/project.dailylog.component';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'approval',
  templateUrl: './approval.component.html',
  entryComponents: [ProjectDailyLogComponent]
})
export class ApprovalComponent implements OnInit {
  @Input() approval: Approval;
  @Input() view: string;
  @Input() userLevel: number = -1;
  @Output() update = new EventEmitter<Approval>();
  @Output() remove = new EventEmitter<Approval>();

  @ViewChild('timeCard', { static: false, read: ViewContainerRef }) target: ViewContainerRef;

  permissionType: AccessLevel;
  dailyLogComponent: DailyLogComponent;
  dailyLogTimeStamp: Date;
  mode: EditMode = EditMode.NonEdit;
  timeCardComp: TimeCardComponentView;
  projectDailyLogComponent: ProjectDailyLogComponent;
  isRunning: boolean = false;

  constructor(
    private dailyLogService: DailyLogService,
    private confirmService: ConfirmService,
    private approvalService: ApprovalService,
    private timeCardSerivce: TimeCardService,
    private toastrService: ToastrService,
    private modal: NgbModal,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit() {
    this.permissionType = new AccessLevel();
    this.permissionType.AccessLevelId = 'readonly';
    this.isRunning = true;

    this.dailyLogService.GetDailyLogById(this.approval.ProjectId, this.approval.DailyLogId).subscribe(
      result => {
        this.dailyLogComponent = result;
        this.dailyLogTimeStamp = new Date(this.dailyLogComponent.Year, this.dailyLogComponent.Month - 1, this.dailyLogComponent.Day);

        if (!this.dailyLogComponent.NoWork) {
          this.loadTimeCard(ComponentView.Single);
        }

        this.isRunning = false;
      },
      err => {
        this.isRunning = false;
      }
    );
  }

  edit() {
    this.approvalService.GetApprovalTimeCardEdit(this.approval.ProjectId, this.approval.Id).subscribe(
      x => {
        if (!this.dailyLogComponent.NoWork) {
          this.target.clear();
          this.permissionType.AccessLevelId = 'fullaccess';
          if (this.approval.FinalApprovalDate) {
            this.loadDailyLogComponent(ComponentView.FinalApprover);
          } else {
            this.loadDailyLogComponent(ComponentView.Single);
          }

          this.mode = EditMode.Edit;
        }
      },
      err => {
        console.log(err);
        this.toastrService.error(err.error);
      }
    );
  }

  cancel() {
    this.mode = EditMode.NonEdit;
    this.permissionType.AccessLevelId = 'readonly';
    this.loadTimeCard(ComponentView.Single);

    //this.ngOnInit();
    // this.permissionType.AccessLevelId = "readonly";
    // //this.loadTimeCard();
  }

  loadTimeCard(view: ComponentView) {
    if (this.target) {
      this.target.clear();
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TimeCardComponentView);
    this.timeCardComp = <TimeCardComponentView>this.target.createComponent(componentFactory).instance;
    this.timeCardComp.dateInfo = this.dailyLogTimeStamp;
    this.timeCardComp.permissionType = this.permissionType;
    this.timeCardComp.view = view;
    this.timeCardComp.userId = this.dailyLogComponent.UserId;
    this.timeCardComp.year = this.dailyLogComponent.Year;
    this.timeCardComp.month = this.dailyLogComponent.Month;
    this.timeCardComp.day = this.dailyLogComponent.Day;
    this.timeCardComp.layoutType = TimeCardLayout.None;
    this.timeCardComp.projectId = this.approval.ProjectId;
  }

  loadDailyLogComponent(view: ComponentView) {
    if (this.target) {
      this.target.clear();
    }

    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectDailyLogComponent);
    this.projectDailyLogComponent = <ProjectDailyLogComponent>this.target.createComponent(componentFactory).instance;
    this.projectDailyLogComponent.dailyLogId = this.dailyLogComponent.Id;
    this.projectDailyLogComponent.projectId = this.dailyLogComponent.ProjectId;
    this.projectDailyLogComponent.view = view;
    this.projectDailyLogComponent.permissionType = this.permissionType;
    this.projectDailyLogComponent.userId = this.dailyLogComponent.UserId;
  }
  saveDailyLog() {
    this.isRunning = true;
    this.projectDailyLogComponent.validateComponents().subscribe(result => {
      if (result) {
        this.projectDailyLogComponent.save().subscribe(
          result => {
            this.approvalService.AddApprovalHistory(this.approval.ProjectId, this.approval.Id, ApprovalHistoryType.Edited).subscribe(
              result => {
                this.approval.History.push(result);
                this.isRunning = false;
                this.cancel();
              },
              err => {
                this.isRunning = false;
                this.cancel();
              }
            );
          },
          err => {
            this.isRunning = false;
            this.cancel();
          }
        );
      } else {
        this.isRunning = false;
        this.toastrService.error('Could not save daily log.');
      }
    });
  }

  saveTimeCard() {
    this.timeCardSerivce.SaveTimeCardComponentEdit(this.approval.ProjectId, this.timeCardComp.timeCardComponent).subscribe(
      result => {
        this.approvalService.AddApprovalHistory(this.approval.ProjectId, this.approval.Id, ApprovalHistoryType.Edited).subscribe(result => {
          this.approval.History.push(result);
          this.cancel();
        });
      },
      err => {
        this.cancel();
      }
    );
    //this.timeCardComp.Save().subscribe(result => {
    //    this.approvalService.AddApprovalHistory(this.approval.ProjectId, this.approval.Id, ApprovalHistoryType.Edited).subscribe(result => {
    //        this.approval.History.push(result);
    //        this.cancel();
    //    });
    //});
  }

  updateComp(approval: Approval) {
    this.update.next(approval);
  }

  removeComp(approval: Approval) {
    this.remove.next(approval);
  }

  showHistory() {
    var ref = this.modal.open(ApprovalHistoryModalComponent, { size: 'lg' });
    ref.componentInstance.history = this.approval.History;
  }

  showDailyLog() {
    var aLevel = new AccessLevel();
    aLevel.AccessLevelId = 'readonly';

    var ref = this.modal.open(DailyLogModalComponent, { size: 'lg' });
    ref.componentInstance.projectId = this.dailyLogComponent.ProjectId;
    ref.componentInstance.dailyLogId = this.dailyLogComponent.Id;
    ref.componentInstance.permission = aLevel;
    ref.componentInstance.userId = this.dailyLogComponent.UserId;
    ref.componentInstance.year = this.dailyLogComponent.Year;
    ref.componentInstance.month = this.dailyLogComponent.Month;
    ref.componentInstance.day = this.dailyLogComponent.Day;
    ref.componentInstance.dateTimeInfo = new Date(this.dailyLogComponent.Year, this.dailyLogComponent.Month - 1, this.dailyLogComponent.Day);
  }

  approve() {
    this.confirmService.open('Are you ready to approve this time card?').result.then(item => {
      this.approvalService.Approve(this.approval.ProjectId, this.approval.Id).subscribe(
        result => {
          this.approval.CurrentApprover = result;
          this.removeComp(this.approval);
        },
        err => {
          this.toastrService.error(err.error);
        }
      );
    });
  }

  unapprove() {
    var test = '';
    this.confirmService.open('Do you want to unpprove this time card?').result.then(item => {
      var test2 = '';
      this.approvalService.Unapprove(this.approval.ProjectId, this.approval.Id).subscribe(
        result => {
          this.approval.CurrentApprover = null;
          this.removeComp(this.approval);
        },
        err => {
          this.toastrService.error(err.error);
        }
      );
    });
  }

  unlock() {
    this.confirmService.open('Do you want to unlock this time card?').result.then(item => {
      this.approvalService.Unlock(this.approval.ProjectId, this.approval.Id).subscribe(
        result => {
          this.approval.FinalApprovalDate = null;
          this.updateComp(this.approval);
        },
        err => {
          this.toastrService.error(err.error);
        }
      );
    });
  }
}

export enum EditMode {
  Edit,
  NonEdit
}
