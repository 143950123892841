import { Component, OnInit, Input } from '@angular/core';
import { CivCastImageService } from '../../../custom.controls/image/image.service';
import { ImageInfo } from '../../../custom.controls/image/models/imageinfo';
import { ToastrService, Toast, ActiveToast } from 'ngx-toastr';
import { ComponentView, IComponent } from '../../models/IComponent';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { Observable } from 'rxjs/Observable';
import { ValidationResult } from '../../models/validationResult';
import { Subject, observable } from 'rxjs';
import { PhotosService } from './photos.service';
import { PhotosComponent } from './models/photos.component';
import { ManagedUpload } from 'aws-sdk/clients/s3';
import { Photo } from './models/photo';
import { AWSPhotoStorage } from './models/aws.photo.storage';
import { PhotoViews } from '../../../custom.controls/image/models/photoview.enum';
import { CivCastPicaResizeOptionsInterface } from '../../../custom.controls/image/pica/pica.resize.option.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { DataBoundComponent } from '../../models/DataboundComponent';

@Component({
  selector: 'project-photos',
  templateUrl: './photos.component.html'
})
export class ProjectPhotosComponent extends DataBoundComponent<PhotosComponent> implements OnInit, IComponent {
  @Input() month: number;
  @Input() day: number;
  @Input() year: number;
  @Input() projectId: string = null;
  @Input() dateInfo: Date;
  @Input() permissionType: AccessLevel;
  @Input() view: ComponentView = ComponentView.Normal;
  @Input() userId: string = null;
  private pComponent: PhotosComponent;
  public imageList: Array<ImageInfo> = new Array<ImageInfo>();
  public markedForDeletion: Array<ImageInfo> = new Array<ImageInfo>();

  mainView: string = 'gallery';
  loading: boolean = false;
  isUploading: boolean = false;
  private cancelSub: Subject<void> = new Subject();
  activeToast: ActiveToast<Toast>;
  pView: PhotoViews = PhotoViews.Full;
  resizeOptions: CivCastPicaResizeOptionsInterface;

  constructor(
    private civcastImageService: CivCastImageService,
    private photosService: PhotosService,
    private sanitizer: DomSanitizer,
    private toastr: ToastrService
  ) {
    super();

    this.pComponent = new PhotosComponent();

    this.resizeOptions = {
      aspectRatio: {
        forceMinDimensions: false,
        keepAspectRatio: true
      }
    };

    this.civcastImageService.photoMarkDeletion.subscribe(result => {
      if (result.MarkForDeletion) {
        this.markedForDeletion.push(result);
      } else {
        for (var x = 0; x < this.markedForDeletion.length; x++) {
          var mark = this.markedForDeletion[x];

          if (mark.ImageId === result.ImageId) {
            this.markedForDeletion.splice(x, 1);
            break;
          }
        }
      }
    });

    this.civcastImageService.removePhoto.subscribe(result => {
      this.removeFromImagesById(result.ImageId);
    });

    this.civcastImageService.photoUpdateHandler.subscribe(result => {
      for (var x = 0; x < this.imageList.length; x++) {
        var image = this.imageList[x];
        if (result.ImageId === image.ImageId) {
          image.Description = result.Description;
          image.Title = result.Title;
        }
      }
    });
  }

  public DataBindByProjectInfo(): Observable<PhotosComponent> {
    return this.photosService.GetComponent(this.projectId, this.year, this.month, this.day);
  }
  public DataBindByUserInfo(): Observable<PhotosComponent> {
    return this.photosService.GetComponentByUser(this.projectId, this.userId, this.year, this.month, this.day);
  }

  ngOnInit(): void {
    this.loading = true;

    if (this.permissionType.AccessLevelId.toLowerCase() != 'fullaccess') {
      this.pView = PhotoViews.ViewOnly;
    }

    this.initialize().subscribe(
      result => {
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }

  initialize(): Observable<boolean> {
    var obs = new Subject<boolean>();
    var subscriptions = new Array<any>();

    this.InitializeDataCView(this.view).subscribe(
      result => {
        this.pComponent = result;
        this.gatherPhotosFromComp(this.pComponent);
        obs.next(true);
        obs.complete();
      },
      err => {
        obs.error(err);
      }
    );

    return obs.asObservable();
  }

  gatherPhotosFromComp = function (photoComp: PhotosComponent) {
    for (let p of photoComp.Photos) {
      this.convertAndAddPhoto(p);
    }
  };

  convertAndAddPhoto = function (photo: Photo) {
    try {
      if (photo.ThumbnailStorage) {
        var info: ImageInfo = new ImageInfo();
        var awsThumbnailStorage = photo.ThumbnailStorage as AWSPhotoStorage;
        var awsLargeStorage = photo.LargeStorage as AWSPhotoStorage;

        info.ThumbnailImageUrl.Url = awsThumbnailStorage.Location;
        info.ThumbnailImageUrl.SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(awsThumbnailStorage.Location);

        info.LargeImageUrl.Url = awsLargeStorage.Location;
        info.LargeImageUrl.SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(awsLargeStorage.Location);

        info.ImageId = photo.PhotoId;
        info.Title = photo.Title;
        info.Description = photo.Description;
        info.UploadDateTime = photo.UploadDate;

        this.civcastImageService.addImage(info);
        this.imageList.push(info);
      } else {
        console.log('Storage type not supported');
      }
    } catch (e) {
      this.toastr.error('Error getting photo ' + photo.PhotoId);
    }
  };

  setView(view: string) {
    this.mainView = view;
  }

  cancelUpload() {
    this.cancelSub.next();
    this.cancelSub.complete();

    this.cancelSub = new Subject();
  }

  GetNonUploadedFiles(): number {
    var count: number = 0;
    for (let file of this.imageList) {
      if (!file.UploadDateTime) {
        count++;
      }
    }

    return count;
  }

  setSelectedFiles(files: FileList) {
    var list = new Array<File>();
    for (var x = 0; x < files.length; x++) {
      var file = files.item(x);
      if (this.civcastImageService.isImage(file)) {
        list.push(file);
      } else {
        this.toastr.error('The file named ' + file.name + ' is not allowed.');
      }
    }

    var source = Observable.range(0, list.length);

    var v = source
      .concatMap((item: number) => {
        return this.civcastImageService.addImageFile(list[item]);
      })
      .subscribe(
        image => {
          if (!image) {
            this.toastr.error('Issue uploading image ' + image.oFile.name);
          } else {
            if (!image.ImageId) {
              image.ImageId = (new Date().getTime() + Math.floor(Math.random() * (999999 - 100 + 1)) + 100).toString();
            }
            this.imageList.push(image);
          }
        },
        err => {
          this.toastr.error('Error adding uploaded files');
        },
        () => {
          this.GetNonUploadedFiles();
        }
      );

    //this.civcastImageService.addImages(files);
  }

  photoUploadProgress(progress: ManagedUpload.Progress, image: ImageInfo, toast: Promise<Toast>) {
    this.activeToast.portal.instance.title = 'File Upload: ' + image.oFile.name;
    var x = parseInt(((progress.loaded * 100) / progress.total).toString());
    this.activeToast.portal.instance.message = 'Uploaded: ' + x + '%';
  }

  uploadFile(image: File, key: string): Observable<ManagedUpload.SendData> {
    var obs = new Subject<ManagedUpload.SendData>();

    this.photosService
      .UploadPhoto(image, key, (progress: ManagedUpload.Progress) => {
        this.activeToast.portal.instance.title = 'File Upload: ' + image.name;
        var x = parseInt(((progress.loaded * 100) / progress.total).toString());
        this.activeToast.portal.instance.message = 'Uploaded: ' + x + '%';
      })
      .subscribe(
        result => {
          obs.next(result);
          obs.complete();
        },
        err => {
          obs.error(err);
        }
      );

    return obs.asObservable();
  }

  processFileAndUpload(image: ImageInfo, componentId: string): Observable<[ImageInfo, any, any]> {
    var obs = new Subject<[ImageInfo, any, any]>();

    try {
      var thumbResult: any = null;
      var largeResult: any = null;

      var source = Observable.of(image);

      source
        .flatMap(source => {
          this.activeToast.portal.instance.title = 'Creating Thumbnail: ' + source.oFile.name;
          this.activeToast.portal.instance.message = 'Processing....';
          return this.civcastImageService.getThumbnail(source);
        })
        .flatMap(result => {
          var thumbPhotoKey = this.projectId + '/' + componentId + '/thumbnail/' + image.oFile.name;
          return this.uploadFile(result, thumbPhotoKey);
        })
        .flatMap(result => {
          thumbResult = result;
          this.activeToast.portal.instance.title = 'Compressing File: ' + image.oFile.name;
          this.activeToast.portal.instance.message = 'Processing....';
          let width: number = image.LargeImageUrl.Width;
          var height: number = image.LargeImageUrl.Height;

          if (image.LargeImageUrl.Width > 1920) {
            width = 1920;
            height = 1080;
          }

          return this.civcastImageService.getResizeImage(image, height, width, this.resizeOptions);
        })
        .flatMap(result => {
          var largePhotoKey = this.projectId + '/' + componentId + '/large/' + image.oFile.name;
          return this.uploadFile(result, largePhotoKey);
        })
        .subscribe(
          result => {
            largeResult = result;
            obs.next([image, thumbResult, largeResult]);
            obs.complete();
          },
          err => {
            obs.error(err);
          },
          () => {}
        );
    } catch (e) {
      obs.error(e);
    }

    return obs.asObservable();
  }

  deletePhotoById(photoId: string) {
    for (var x = 0; x < this.pComponent.Photos.length; x++) {
      var photo = this.pComponent.Photos[x];

      if (photo.PhotoId === photoId) {
        this.pComponent.Photos.splice(x, 1);
        break;
      }
    }
  }

  removeFromImagesById(imageId: string) {
    for (var x = 0; x < this.imageList.length; x++) {
      var image = this.imageList[x];

      if (image.ImageId === imageId) {
        this.imageList.splice(x, 1);
        break;
      }
    }
  }

  deleteImages(compId: string): Observable<boolean> {
    if (this.markedForDeletion.length > 0) {
      var obs = new Subject<boolean>();
      var aObs = new Array<Observable<string>>();

      var source = Observable.range(0, this.markedForDeletion.length);

      source
        .concatMap((idx: number) => {
          return this.photosService.DeletePhoto(this.projectId, compId, this.markedForDeletion[idx].ImageId);
        })
        .subscribe(
          result => {
            this.removeFromImagesById(result);
            this.deletePhotoById(result);
            this.civcastImageService.removeImageById(result);
          },
          err => {
            this.toastr.error('Error deleting photos.');
          },
          () => {
            this.markedForDeletion = new Array<ImageInfo>();
            obs.next(true);
            obs.complete();
          }
        );

      return obs.asObservable();
    } else {
      return Observable.of(true);
    }
  }

  removeImage(image: ImageInfo) {
    var idx = this.imageList.indexOf(image);
    this.imageList.splice(idx, 1);
  }

  removeAllImages() {
    for (var x of this.imageList) {
      this.removeFromImagesById(x.ImageId);
    }
  }

  clearAllImages() {
    this.imageList = new Array<ImageInfo>();
  }

  processNewImages(compId: string): Observable<boolean> {
    var imagesNotUploaded = this.imageList.filter(x => x.UploadDateTime == null);

    if (imagesNotUploaded.length > 0) {
      var obs = new Subject<boolean>();

      this.isUploading = true;
      var message = 'Begining to upload images';
      this.activeToast = this.toastr.info(message, 'File Upload', {
        tapToDismiss: true,
        positionClass: 'toast-bottom-center',
        enableHtml: true,
        closeButton: false
      });

      var finalPhotoList = new Array<Photo>();

      var source = Observable.range(0, imagesNotUploaded.length);

      var v = source
        .concatMap((item: number) => {
          return this.processFileAndUpload(imagesNotUploaded[item], compId);
        })
        .takeUntil(this.cancelSub)
        .subscribe(
          sendData => {
            var photo = new Photo();
            var thumbNailStorage = new AWSPhotoStorage();
            var largeStorage = new AWSPhotoStorage();

            thumbNailStorage.Bucket = sendData[1].bucket;
            // thumbNailStorage.ETag = sendData[1].ETag;
            thumbNailStorage.Key = sendData[1].key;
            thumbNailStorage.Location = sendData[1].location;

            largeStorage.Bucket = sendData[2].bucket;
            // largeStorage.ETag = sendData[2].ETag;
            largeStorage.Key = sendData[2].key;
            largeStorage.Location = sendData[2].location;

            photo.Height = sendData[0].LargeImageUrl.Height;
            photo.Width = sendData[0].LargeImageUrl.Width;
            photo.ThumbnailStorage = thumbNailStorage;
            photo.LargeStorage = largeStorage;
            photo.Title = sendData[0].Title;
            photo.Description = sendData[0].Description;
            photo.FileName = sendData[0].oFile.name;
            photo.PhotoType = sendData[0].oFile.type;
            photo.PhotoSize = sendData[0].oFile.size;

            finalPhotoList.push(photo);

            this.removeImage(sendData[0]);
            this.civcastImageService.removeImage(sendData[0]);

            //this.convertAndAddPhoto(photo);
          },
          (error: Error) => {
            if (this.activeToast) {
              this.activeToast.toastRef.close();
            }

            this.toastr.error('There was an error uploading the files. ' + error.message);
            obs.error(error);
          },
          () => {
            var source = Observable.range(0, finalPhotoList.length);

            source
              .concatMap((idx: number) => {
                return this.photosService.AddPhoto(this.projectId, compId, finalPhotoList[idx]);
              })
              .takeUntil(this.cancelSub)
              .subscribe(
                (photo: Photo) => {
                  photo.ThumbnailStorage['_t'] = ['PhotoStorage', 'AWSStorage'];
                  photo.LargeStorage['_t'] = ['PhotoStorage', 'AWSStorage'];
                  this.pComponent.Photos.push(photo);
                  this.convertAndAddPhoto(photo);

                  obs.next(true);
                  obs.complete();
                },
                err => {
                  this.toastr.error('There was an error uploading the files.');
                  obs.error(err);
                },
                () => {
                  if (this.activeToast) {
                    this.activeToast.toastRef.close();
                  }

                  this.toastr.info('Images have been uploaded');
                  this.isUploading = false;
                }
              );
          }
        );

      return obs.asObservable();
    } else {
      this.isUploading = false;
      return Observable.of(true);
    }
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.loading = true;

      if (this.view === ComponentView.FinalApprover) {
        Observable.timer(100).subscribe(
          result => {
            obs.next(true);
            obs.complete();
            this.loading = false;
          },
          err => {
            obs.error(err);
            this.loading = false;
          }
        );
      } else {
        for (let p of this.pComponent.Photos) {
          p.ThumbnailStorage['_t'] = ['PhotoStorage', 'AWSStorage'];
          p.LargeStorage['_t'] = ['PhotoStorage', 'AWSStorage'];
        }

        for (let item of this.imageList.filter(z => z.UploadDateTime !== null)) {
          var photo = this.pComponent.Photos.filter(x => x.PhotoId == item.ImageId);

          if (photo.length > 0) {
            photo[0].Title = item.Title;
            photo[0].Description = item.Description;
          }
        }

        this.photosService.SaveComponent(this.pComponent, this.projectId).subscribe(
          photoCompResult => {
            this.pComponent.Id = photoCompResult.Id;

            Observable.forkJoin(this.deleteImages(photoCompResult.Id), this.processNewImages(photoCompResult.Id))
              .takeUntil(this.cancelSub)
              .subscribe(
                result => {
                  obs.next(true);
                  obs.complete();
                },
                err => {
                  obs.error(err);
                },
                () => {
                  if (this.activeToast) {
                    this.activeToast.toastRef.close();
                  }
                  this.loading = false;
                }
              );
          },
          err => {
            if (this.activeToast) {
              this.activeToast.toastRef.close();
            }

            this.toastr.error('There was an eror saving the photos component');

            this.loading = false;
            this.isUploading = false;

            obs.error(err);
          }
        );
      }
    });
  }

  Validate(): Observable<ValidationResult> {
    var valResult = new ValidationResult();
    valResult.IsValid = true;

    return Observable.of(valResult);
  }

  Clear(): Observable<boolean> {
    this.setView('Normal');
    this.civcastImageService.clearAllPhotos(this.imageList);
    return Observable.of(true);
  }

  Remove(): Observable<boolean> {
    var obs = new Subject<boolean>();
    this.loading = true;

    this.photosService.DeleteComponent(this.projectId, this.year, this.month, this.day).subscribe(
      result => {
        this.clearAllImages();
        obs.next(true);
        obs.complete();
      },
      err => {
        this.loading = false;
        obs.error(err);
      },
      () => {
        this.loading = false;
      }
    );

    return obs.asObservable();
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
