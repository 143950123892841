import { SiteCondition } from './sitecondition';
import { DailyProjectComponent } from '../../daily.project.component';

export class SiteConditionComponent extends DailyProjectComponent {
  constructor() {
    super();
  }
  ConditionId: number;
  CreateDate: Date = null;
  Conditions: SiteCondition[] = [];
}
