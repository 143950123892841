import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as ProjectApi from '../models';

import { UserProjectService } from '../user.project.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'modal-saveproject',
  templateUrl: './modal.saveproject.component.html'
})
export class ModalSaveProject {
  closeResult: string;
  @Input() project: ProjectApi.Project;
  warningMessage: string;

  constructor(public activeModal: NgbActiveModal, private userProjectService: UserProjectService, private toastr: ToastrService) {}

  saveProject() {
    this.userProjectService.CreateProject(this.project).subscribe(
      project => {
        this.activeModal.close(project);
      },
      err => {
        if (err.error === 'Project already exists') {
          this.warningMessage = err.error;
        } else {
          this.toastr.error(err.error);
        }
      }
    );
  }

  close() {
    this.activeModal.dismiss();
  }
}
