import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'project-new',
  templateUrl: './project.new.component.html'
})
export class ProjectNewComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
