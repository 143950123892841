import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'files-component',
  templateUrl: './files.component.html'
})
export class FilesComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
