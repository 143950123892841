import { ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import * as ProjectApi from './';
import { DailyLogSettingsComponent } from '../components/dailylog/dailylog.settings.component';
import { ComponentIdentifier } from './componentIdentifier';
import { WeatherSettingsComponent } from '../components/weather/weather.settings.component';
import { SiteConditionsSettingsComponent } from '../components/siteconditions/site.conditions.settings.component';
import { ProjectInfoSettingsComponent } from '../components/info/project.info.settings.component';
import { InfoComponent } from '../components/info/models/infoComponent';
import { ProjectCostCodesComponent } from '../components/costcodes/project.costcodes.component';
import { ProjectSettingsComponent } from '../components/settings/project.settings.component';

export class ComponentFactory {
  target: ViewContainerRef;
  cResolver: ComponentFactoryResolver;

  constructor(target: ViewContainerRef, componentFactoryResolver: ComponentFactoryResolver) {
    this.target = target;
    this.cResolver = componentFactoryResolver;
  }

  InitializeComponent(comp: ProjectApi.Component, type: string) {
    switch (comp.ComponentIdentifier.toUpperCase()) {
      case ComponentIdentifier.DAILY_LOG.toUpperCase():
        this.InitializeDailyLogSettings(comp, type);
        break;
      case ComponentIdentifier.WEATHER.toUpperCase():
        this.InitializeWeatherSettings(comp, type);
        break;
      case ComponentIdentifier.SITE_CONDITIONS.toUpperCase():
        this.InitializeSiteConditionSettings(comp, type);
        break;
      case ComponentIdentifier.INFO.toUpperCase():
        this.InitializeInfoSettings(null, comp as InfoComponent, type);
        break;
      case ComponentIdentifier.COSTCODES.toUpperCase():
        this.InitializeCostCodeSettings();
        break;
      case ComponentIdentifier.MENU.toUpperCase():
        this.InitializeMenuSettings(comp as InfoComponent, type);
        break;
      default:
        break;
    }
    //if (comp.ComponentIdentifier.toUpperCase() === ComponentIdentifier.DAILY_LOG.toUpperCase()) {

    //}
  }

  InitializeInfoSettings(project: ProjectApi.Project, comp: InfoComponent, type: string) {
    const componentFactory = this.cResolver.resolveComponentFactory(ProjectInfoSettingsComponent);
    var infoSettings = <ProjectInfoSettingsComponent>this.target.createComponent(componentFactory).instance;
    infoSettings.mainComponent = comp;
    infoSettings.componentType = type;
  }

  InitializeDailyLogSettings(comp: ProjectApi.Component, type: string) {
    const componentFactory = this.cResolver.resolveComponentFactory(DailyLogSettingsComponent);
    var dailyLogSettings = <DailyLogSettingsComponent>this.target.createComponent(componentFactory).instance;
    dailyLogSettings.mainComponent = comp;
    dailyLogSettings.componentType = type;
  }

  InitializeWeatherSettings(comp: ProjectApi.Component, type: string) {
    const componentFactory = this.cResolver.resolveComponentFactory(WeatherSettingsComponent);
    var dailyLogSettings = <WeatherSettingsComponent>this.target.createComponent(componentFactory).instance;
    dailyLogSettings.mainComponent = comp;
    dailyLogSettings.componentType = type;
  }

  InitializeSiteConditionSettings(comp: ProjectApi.Component, type: string) {
    const componentFactory = this.cResolver.resolveComponentFactory(SiteConditionsSettingsComponent);
    var siteConditionSettings = <SiteConditionsSettingsComponent>this.target.createComponent(componentFactory).instance;
    siteConditionSettings.mainComponent = comp;
    siteConditionSettings.componentType = type;
  }

  InitializeCostCodeSettings() {
    const componentFactory = this.cResolver.resolveComponentFactory(ProjectCostCodesComponent);
    var siteConditionSettings = <ProjectCostCodesComponent>this.target.createComponent(componentFactory).instance;
  }

  InitializeMenuSettings(comp: ProjectApi.Component, type: string) {
    const componentFactory = this.cResolver.resolveComponentFactory(ProjectSettingsComponent);
    var siteConditionSettings = <ProjectSettingsComponent>this.target.createComponent(componentFactory).instance;
  }
}
