import { Component, Input, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileItem } from 'ng2-file-upload';
import { CostCodesComponent } from './models/costcodescomponent';
import { CostCodeWizard, CostCodeEnum } from './models/costCodeWizard';
import { CostCodeService } from './costcode.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'modal-costcodesimport',
  templateUrl: './project.costcodes.import.modal.html'
})
export class ModalCostCodesImport implements OnInit {
  closeResult: string;
  costCodesSection: CostCodesComponent;
  error: string;
  isError = false;
  public letterArray: Array<string>;
  costCodeWizard: CostCodeWizard;

  public uploader: FileUploader;
  @Input() projectId: string;
  constructor(public activeModal: NgbActiveModal, private authService: AuthService, private costCodeService: CostCodeService) {
    this.costCodeWizard = new CostCodeWizard();
    this.costCodeWizard.CurrentStep = CostCodeEnum.Import;

    this.createLetterArray();
  }

  ngOnInit() {
    const URL = environment.api + `/component/costcode/CostCodeDocImport/${this.projectId}`;
    this.authService.getAccessToken().subscribe(result => {
      this.uploader = new FileUploader({ url: URL, authToken: `Bearer ${result}` });
      this.uploader.options.autoUpload = true;
      this.uploader.onBeforeUploadItem = (item: FileItem) => {
        console.log(item);
        item.headers.push(`Bearer ${result}`);
      };

      this.uploader.onCompleteItem = (item: FileItem, response: any, status: number) => {
        this.costCodeWizard = JSON.parse(response) as CostCodeWizard;
      };

      this.uploader.onErrorItem = (item: FileItem, response: string, status: number) => {
        this.uploader.isUploading = false;
        this.isError = true;
        this.error = 'There was an issue uploading the report. Please make sure you use the template provided';
        // this.growlService.addAlert("There was an issue uploading the report. Please make sure you use the template provided", AlertType.DANGER);
      };
    });
  }

  createLetterArray() {
    this.letterArray = new Array<string>();

    this.letterArray.push('A');
    this.letterArray.push('B');
    this.letterArray.push('C');
    this.letterArray.push('D');
    this.letterArray.push('E');
    this.letterArray.push('F');
    this.letterArray.push('G');
    this.letterArray.push('H');
    this.letterArray.push('I');
    this.letterArray.push('J');
    this.letterArray.push('K');
    this.letterArray.push('L');
    this.letterArray.push('M');
    this.letterArray.push('N');
    this.letterArray.push('O');
    this.letterArray.push('P');
    this.letterArray.push('Q');
    this.letterArray.push('R');
    this.letterArray.push('S');
    this.letterArray.push('T');
    this.letterArray.push('U');
    this.letterArray.push('V');
    this.letterArray.push('W');
    this.letterArray.push('X');
    this.letterArray.push('Y');
    this.letterArray.push('Z');
  }

  goToNextStep() {
    this.costCodeService.ImportCostCodeWizard(this.projectId, this.costCodeWizard).subscribe(
      wizard => {
        this.costCodeWizard = wizard;
      },
      err => {
        this.isError = true;
        this.error = 'There was an issue.';
      }
    );
  }

  goToPreviousStep() {
    this.costCodeWizard.CurrentStep -= 1;
    // this.costCodeService.ImportCostCodeWizard(this.projectId, this.costCodeWizard).subscribe(wizard => {
    //    this.costCodeWizard = wizard;
    // }, err => {
    //    this.isError = true;
    //    this.error = "There was an issue.";
    // });
  }

  SaveCostCodes() {
    this.costCodeWizard.CurrentStep = CostCodeEnum.Save;
    this.costCodeService.ImportCostCodeWizard(this.projectId, this.costCodeWizard).subscribe(
      comp => {
        this.activeModal.close(comp);
      },
      err => {
        this.isError = true;
        this.error = 'There was an issue.';
      }
    );
  }

  onBeforeUploadItem(item: FileItem) {}

  close() {
    this.activeModal.dismiss();
  }
}
