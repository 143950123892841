import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from './settings.service';

@Component({
  selector: 'Settings-local',
  templateUrl: './settings.local.component.html'
})
export class SettingsLocalComponent implements OnInit {
  public PageTitle = '';
  public PageInstructions = '';

  constructor(private route: ActivatedRoute, private settingsService: SettingsService) {}

  ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.PageTitle = this.route.snapshot.data.PageTitle;
      this.PageInstructions = this.route.snapshot.data.PageInstructions;
      if (this.route.snapshot.data.ComponentType) {
        this.settingsService.componentType = this.route.snapshot.data.ComponentType;
      } else {
        this.settingsService.componentType = this.route.parent.snapshot.data.ComponentType;
      }

      var projectId = null;
      if (params.id) {
        projectId = params.id;
      }

      if (projectId) {
        this.settingsService.pId = projectId;
      }
    });
  }
}
