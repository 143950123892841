import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeCard } from './models/timecard';
import { TimeCardService, TimeInfo } from './timecard.service';
import { TimeCardEmployee } from './models/timecardemployee';
import { TimeDescription } from './models/timeDescription';
import { TimeCardLayout } from './timecard.component';
import { AccessLevel } from '../user.permissions/models/accessLevel';
import { CostCode } from '../user.project/components/costcodes/models/costcode';
import { Employee } from '../user/models/index';
import { SelectedData } from '../core/SelectedData';
import { ComponentView } from '../user.project/models/IComponent';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'timecard-graph',
  templateUrl: './timecard.graph.component.html'
})
export class TimeCardGraphComponent implements OnInit {
  @Input() timeCard: TimeCard = null;
  @Input() permissionType: AccessLevel;
  @Input() projectCostCodes: Array<CostCode>;
  @Input() allEmployees: Array<Employee>;
  @Input() s2ProjectCostCodes: Array<SelectedData>;
  @Input() s2Employees: Array<SelectedData>;
  @Input() timeDescriptions: Array<string>;
  @Input() view: ComponentView = ComponentView.Normal;
  @Input() layoutType: TimeCardLayout = TimeCardLayout.Card;

  selectedCostCode: CostCode;
  selectedEmployee: Employee;

  employeeFullName: string;

  public s2OptionsEmployee: any = {};
  public s2OptionsCostCodes: any = {};
  selectedCostCodes: SelectedData[] = [];
  selectedEmployees: SelectedData[] = [];

  timeInfo: Array<TimeInfo> = [];

  public loading = false;

  constructor(private toastr: ToastrService, private timeCardService: TimeCardService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    this.s2OptionsEmployee = {
      singleSelection: false,
      idField: 'ItemId',
      textField: 'ItemText',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.s2OptionsCostCodes = {
      singleSelection: false,
      idField: 'ItemId',
      textField: 'ItemText',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.timeInfo = this.timeCardService.GetTimeInfo();

    for (let emp of this.timeCard.TimeCardEmployees) {
      if (emp.TimeDescriptionId === '' || emp.TimeDescriptionId === null) {
        emp.TimeDescriptionId = this.timeDescriptions[0];
      }
    }
  }

  setEmployeeFullNameTip(employee) {
    this.employeeFullName = employee.Employee.FirstName + ' ' + employee.Employee.LastName;
  }

  Initialize() {}

  checkTimeDescValue(tcEmployee: TimeCardEmployee) {
    if (tcEmployee.TotalHours > 0) {
      tcEmployee.TimeDescriptionId = 'Work Day';
    }
  }

  changeCostCode(newC, idx: number) {
    var sp = newC.target.value;
    var costCode = this.projectCostCodes.filter(x => x.Phase === newC.target.value);
    if (costCode) {
      if (costCode.length > 0) {
        var cc = Object.assign({}, costCode[0]);

        for (let emp of this.timeCard.TimeCardEmployees) {
          var n = Object.assign({}, costCode[0]);
          var old = Object.assign({}, emp.CostCodes[idx]);
          n.UserValue = old.UserValue;
          emp.CostCodes.splice(idx, 1, n);
          //emp.CostCodes[idx].UserValue = old.UserValue;
        }

        this.timeCard.CostCodes.splice(idx, 1, cc);
      }
    }
  }

  addCostCode() {
    for (let c of this.selectedCostCodes) {
      var costCode = this.projectCostCodes.filter(x => x.Phase === c.ItemId);

      if (costCode.length > 0) {
        var hasCostCode = this.timeCard.CostCodes.filter(x => x.Phase === c.ItemId);

        if (hasCostCode.length <= 0) {
          this.timeCard.CostCodes.push(Object.assign({}, costCode[0]));

          for (var x = 0; x < this.timeCard.TimeCardEmployees.length; x++) {
            costCode[0].UserValue = null;
            this.timeCard.TimeCardEmployees[x].CostCodes.push(Object.assign({}, costCode[0]));
          }
        } else {
          this.toastr.error('Cost code ' + costCode[0].Phase + ' already exists.');
        }
      }
    }

    this.selectedCostCodes = [];
  }

  deleteEmployee(idx: number) {
    this.timeCard.TimeCardEmployees.splice(idx, 1);
  }

  deleteCostCode(idx: number) {
    this.timeCard.CostCodes.splice(idx, 1);

    for (let emp of this.timeCard.TimeCardEmployees) {
      emp.CostCodes.splice(idx, 1);
      this.calculateTotalTime(emp);
    }

    this.toastr.error('Cost Code deleted.');
  }

  addEmployee() {
    for (let c of this.selectedEmployees) {
      var employee = this.allEmployees.filter(x => x.CustomId === c.ItemId);

      if (employee.length > 0) {
        var hasEmployee = this.timeCard.TimeCardEmployees.filter(x => x.Employee.CustomId === employee[0].CustomId);
        if (hasEmployee.length <= 0) {
          var timeCardEmployee = new TimeCardEmployee();
          timeCardEmployee.Employee = Object.assign({}, employee[0]);

          for (let cCode of this.timeCard.CostCodes) {
            cCode.UserValue = null;
            timeCardEmployee.CostCodes.push(Object.assign({}, cCode));
          }

          if (timeCardEmployee.TimeDescriptionId === undefined) {
            timeCardEmployee['TimeDescriptionId'] = null;
          }

          if (timeCardEmployee.TimeDescriptionId === '' || timeCardEmployee.TimeDescriptionId === null) {
            timeCardEmployee.TimeDescriptionId = this.timeDescriptions[0];
          }

          this.timeCard.TimeCardEmployees.push(timeCardEmployee);
        } else {
          this.toastr.error('Employee already exists in the time card');
        }
      }
    }

    this.selectedEmployees = [];
  }

  //changed(data: { value: string[] }) {
  //    this.selectedCostCodes = data.value;
  //}

  //changedEmployee(data: { value: string[] }) {
  //    this.selectedEmployees = data.value;
  //}

  calculateTotalTime(tcEmployee: TimeCardEmployee) {
    tcEmployee.TotalHours = 0;
    for (var x = 0; x < tcEmployee.CostCodes.length; x++) {
      tcEmployee.TotalHours += tcEmployee.CostCodes[x].UserValue;
    }
  }
}
