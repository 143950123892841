import { Injectable, ɵConsole } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { CostCode } from './models/costcode';
import { CostCodesComponent } from './models/costcodescomponent';
import { CostCodeWizard } from './models/costCodeWizard';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable()
export class CostCodeService {
  constructor(private client: HttpClient) {}

  public ImportCostCodeWizard(projectId: string, costCodeWizard: CostCodeWizard): Observable<CostCodeWizard> {
    return this.client.post<CostCodeWizard>(`/component/costcode/CostCodeImport/${projectId}`, costCodeWizard);
  }

  public AddCostCode(projectId: string, costCode: CostCode): Observable<boolean> {
    return this.client.post<boolean>(`/component/costcode/AddCostCode/${projectId}`, costCode);
  }

  public SaveCostCode(projectId: string, costCode: CostCode): Observable<boolean> {
    return this.client.post<boolean>(`/component/costcode/SaveCostCode/${projectId}`, costCode);
  }

  public GetCostCodeComponent(projectId: string): Observable<CostCodesComponent> {
    return this.client.get<CostCodesComponent>(`/component/costcode/GetCostCodeComponent/${projectId}`);
  }

  public DeleteCostCode(projectId: string, costCodeId: string): Observable<boolean> {
    return this.client.delete<boolean>(`/component/costcode/DeleteCostCode/${projectId}/${costCodeId}`);
  }
}
