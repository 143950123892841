import { Injectable } from '@angular/core';
import { Permission } from '../models/permission';
import { UserSet } from '../models/sets/userSet';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable()
export class UACUserService {
  constructor(private client: HttpClient) {}

  permissionRefresh = new Subject<Array<Permission>>();

  getPermissionsRefresh(): Observable<Array<Permission>> {
    return this.permissionRefresh.asObservable();
  }

  setPermissionRefresh(permnissions: Array<Permission>) {
    this.permissionRefresh.next(permnissions);
  }

  public GetUserPermissions(delegateUserId: string): Observable<UserSet> {
    return this.client.get<UserSet>(`/uac/user/GetUserPermissions/${delegateUserId}`);
  }

  public AddUserPermissions(delegateUserId: string, permission: Permission): Observable<Permission> {
    return this.client.put<Permission>(`/uac/user/AddUserPermissions/${delegateUserId}`, permission);
  }

  public DeleteUserPermission(delegateUserId: string, permissionId: string): Observable<boolean> {
    return this.client.delete<boolean>(`/uac/user/DeleteUserPermission/${delegateUserId}/${permissionId}`);
  }
}
