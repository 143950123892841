import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoItem } from '../../models/InfoItem';
import { FormOption } from '../../models/formOption';
import { ValueTypes } from '../../models/valueTypes.enum';

@Component({
  selector: 'modal-property-edit',
  templateUrl: './property.edit.modal.html'
})
export class ModalPropertyEdit {
  closeResult: string;
  @Input() infoItem: InfoItem;
  @Input() mode: string;
  formOptions: FormOption[] = [];
  constructor(public activeModal: NgbActiveModal) {
    this.formOptions.push(new FormOption('Text', ValueTypes.String));
    this.formOptions.push(new FormOption('Date', ValueTypes.Date));
    this.formOptions.push(new FormOption('Number', ValueTypes.Decimal));
    this.formOptions.push(new FormOption('Check', ValueTypes.Boolean));
    this.formOptions.push(new FormOption('Currency', ValueTypes.Currency));
  }

  save() {
    this.activeModal.close(this.infoItem);
  }

  close() {
    this.activeModal.dismiss('close');
  }

  delete() {
    this.activeModal.dismiss('delete');
  }
}
