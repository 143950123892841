import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import * as AWS from 'aws-sdk';
import { PutObjectRequest, ManagedUpload, ClientConfiguration } from 'aws-sdk/clients/s3';
import { Subject } from 'rxjs';
import { BaseComponentService } from '../base.component.service';
import { PhotosComponent } from './models/photos.component';
import { Photo } from './models/photo';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Auth, Storage as AmpStorage } from 'aws-amplify';
import { pseudoRandomBytes } from 'crypto';

@Injectable()
export class PhotosService extends BaseComponentService<PhotosComponent> {
  constructor(private client: HttpClient) {
    super('photos', client);
  }

  private imageTypes: string[] = ['image/jpeg', 'image/png', 'image/png', 'image/tiff'];

  public GetAllComps(projectId: string): Observable<PhotosComponent[]> {
    return this.client.get<PhotosComponent[]>(`/component/photos/GetAllProjectComps/${projectId}`);
  }

  public AddPhoto(projectId: string, componentId: string, photo: Photo): Observable<Photo> {
    return this.client.put<Photo>(`/component/photos/AddPhoto/${projectId}/${componentId}`, photo);
  }

  public DeletePhoto(projectId: string, componentId: string, photoId: string): Observable<string> {
    return this.client.delete<string>(`/component/photos/DeletePhoto/${projectId}/${componentId}/${photoId}`);
  }

  getContentType(fileName: string): string {
    const ext = fileName.split('.').pop();
    let type: string = null;
    switch (ext) {
      case 'jpg':
        type = 'image/jpeg';
        break;
      case 'png':
        type = 'image/png';
        break;
      case 'tiff':
        type = 'image/tiff';
        break;
      default:
        type = 'image/jpeg';
        break;
    }

    return type;
  }

  // public UploadPhoto(image: File, key: string, progress: (progress: ManagedUpload.Progress) => void):
  // Observable<ManagedUpload.SendData> {
  //     var obs = new Subject<ManagedUpload.SendData>();
  //     var client: ClientConfiguration = {
  //         accessKeyId: environment.AWSPhotos.AccessKey,
  //         secretAccessKey: environment.AWSPhotos.SecretKey,
  //         region: environment.AWSPhotos.Region
  //     };

  //     var s3 = new AWS.S3(client);

  //     Storage.put(key, image, {
  //         ContentType: cType
  //     }).then(result => {
  //         obs.next(result);
  //     });

  //     var putobj: PutObjectRequest = {
  //         Bucket: environment.AWSPhotos.Bucket,
  //         Key: key,
  //         Body: image,
  //         ACL: 'public-read'
  //     };

  //     var cType = this.getContentType(image.name);
  //     if (cType) {
  //         putobj.ContentType = cType;
  //     }

  //     var mUpload = s3.upload(putobj, (err) => {
  //         obs.error(err);
  //     }).on('httpUploadProgress', progress);

  //     mUpload.promise().then((result) => {
  //         //image.UploadDateTime = new Date();
  //         obs.next(result);
  //         obs.complete();
  //     });

  //     return obs.asObservable();
  // }

  public UploadPhoto(image: File, key: string, p: (progress: ManagedUpload.Progress) => void): Observable<any> {
    const obs = new Subject<any>();
    // var client: ClientConfiguration = {
    //     accessKeyId: environment.AWSPhotos.AccessKey,
    //     secretAccessKey: environment.AWSPhotos.SecretKey,
    //     region: environment.AWSPhotos.Region
    // };

    // var s3 = new AWS.S3(client);
    const cType = this.getContentType(image.name);

    AmpStorage.configure({
      bucket: environment.AWSPhotos.Bucket
    });

    AmpStorage.put(key, image, {
      contentType: cType,
      level: 'public',
      progressCallback(p) {}
    })
      .then(result => {
        try {
          const r = {
            bucket: environment.AWSPhotos.Bucket,
            key: `public/${(result as any).key}`,
            location: `${environment.AWSPhotos.Location}/public/${key}`
          };
          obs.next(r);
          obs.complete();
        } catch (e) {
          obs.error(e);
        }
      })
      .catch(err => {
        obs.error(err);
      });

    // var putobj: PutObjectRequest = {
    //     Bucket: environment.AWSPhotos.Bucket,
    //     Key: key,
    //     Body: image,
    //     ACL: 'public-read'
    // };

    // if (cType) {
    //     putobj.ContentType = cType;
    // }

    // var mUpload = s3.upload(putobj, (err) => {
    //     obs.error(err);
    // }).on('httpUploadProgress', progress);

    // mUpload.promise().then((result) => {
    //     //image.UploadDateTime = new Date();
    //     obs.next(result);
    //     obs.complete();
    // });

    return obs.asObservable();
  }
}
