import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'project-settings-theme',
  templateUrl: './project.settings.theme.component.html'
})
export class ProjectSettingsThemeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
