import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import 'rxjs/add/operator/mergeMap';
import { Injectable } from '@angular/core';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.auth.getAccessToken().mergeMap((result: string) => {
      if (result) {
        const authReq = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + result) });
        return next.handle(authReq);
      }

      return next.handle(req);
    });
  }
}
