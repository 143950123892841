export const environment = {
  production: false,
  AWSPhotos: {
    Bucket: 'cm-development',
    Location: 'https://cm-development.s3.amazonaws.com'
  },
  // tslint:disable-next-line: max-line-length
  // AWSPhotos: { AccessKey: "AKIAIUW6KHELJHWLMRQQ", SecretKey: "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", Region: "us-east-2", Bucket: "civ-cm-photos-test" },
  api: 'https://stagecmapi.civcast.com',
  BaseSiteUrl: 'https://stagecm.civcast.com',
  id_server: 'https://id-dev.civcast.com/',
  auth: {
    userPoolWebClientId: '4ok3108r9ef79dfrd6mstovfv',
    userPoolId: 'us-east-1_RsKBbRZVs',
    oauth: {
      domain: 'login-dev.civcast.com',
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: 'https://stagecm.civcast.com',
      redirectSignOut: 'https://stagecm.civcast.com',
      responseType: 'code'
    },
    identityPoolId: 'us-east-1:34c8a4a9-faf7-41e6-87f9-238e65c8aaed',
    region: 'us-east-1'
  }
};
