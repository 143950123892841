export class User {
  _id: string;
  userId: string;
  profile: any;
  data: any;

  // constructor(){}
  // constructor(userData: any) {
  //     if (userData !== null) {
  //         this.profile.initialize(userData);
  //     }
  // }
}
