import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import * as ProjectApi from '../../models';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'modal-sidenav',
  templateUrl: './project.sidenav.modal.html'
})
export class ModalProjectSideNav implements OnInit {
  components: ProjectApi.Component[] = [];

  constructor(public activeModal: NgbActiveModal, private userSharedService: UserSharedService, private client: HttpClient) {}

  ngOnInit(): void {
    this.client.get('/api/global/GetStaticData/ProjectComponents').subscribe(
      projectGlobalComponents => {
        const globalComponents = projectGlobalComponents as ProjectApi.Component[];

        this.userSharedService.GetComponents().subscribe(components => {
          for (const uComponent of components) {
            const hasComponent = globalComponents.filter(item => item.ComponentIdentifier.indexOf(uComponent.ComponentIdentifier) !== -1);

            if (hasComponent.length > 0) {
              uComponent['IsChecked'] = true;
            } else {
              uComponent['IsChecked'] = false;
            }
            this.components.push(uComponent);
          }

          for (const component of globalComponents) {
            const c = this.components.filter(item => item.ComponentIdentifier.indexOf(component.ComponentIdentifier) !== -1);
            if (c.length <= 0) {
              component['IsChecked'] = false;
              this.components.push(component);
            }
          }
        });
      },
      err => {
        console.log(err);
      }
    );
  }

  close() {
    this.activeModal.dismiss();
  }

  saveSideNav() {
    const checkedComponents: ProjectApi.Component[] = [];

    const components = Object.assign({}, this.components);

    for (const component in components) {
      if (components[component]['IsChecked']) {
        const c = components[component];
        c['_t'] = ['BaseEntity', 'Component'];
        c['Id'] = component;
        delete c['IsChecked'];

        checkedComponents.push(c);
      }
    }

    this.userSharedService.SaveComponents(checkedComponents).subscribe(success => {
      this.activeModal.dismiss();
    });
  }
}
