import { Observable } from 'rxjs/Observable';
import { ValidationResult } from './validationResult';
import { Bool } from 'aws-sdk/clients/inspector';

export interface IComponent {
  Save(): Observable<boolean>;
  Validate(): Observable<ValidationResult>;
  Clear(): Observable<boolean>;
  Remove(): Observable<boolean>;
  Submit(): Observable<boolean>;
  UnSubmit(): Observable<boolean>;
}

export enum ComponentView {
  Normal,
  Single,
  FinalApprover
}
