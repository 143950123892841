import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import { TeamMember } from './models/TeamMember';
import { TeamComponent } from './models/TeamComponent';
import { TeamPermissionType } from './models/teamPermissionType.enum';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Auth } from 'aws-amplify';

@Injectable()
export class TeamService {
  readOnlyProp = new Subject<boolean>();

  constructor(private client: HttpClient) {}

  getReadOnlyProp(): Observable<boolean> {
    return this.readOnlyProp.asObservable();
  }

  setReadOnlyProp(isReadOnly: boolean) {
    this.readOnlyProp.next(isReadOnly);
  }

  public AddTeamMember(projectId: string, members: TeamMember[]): Observable<TeamMember> {
    return this.client.put<TeamMember>(`/Component/Team/AddTeamMember/${projectId}`, members);
  }

  public SaveTeamMember(projectId: string, member: TeamMember): Observable<TeamMember> {
    return this.client.put<TeamMember>(`/Component/Team/SaveTeamMember/${projectId}`, member);
  }

  public SetTeamMemberDeleted(projectId: string, memberId: string, isDeleted: boolean): Observable<boolean> {
    return this.client.put<boolean>(`/Component/Team/SetTeamMemberDeleted/${projectId}/${memberId}/${isDeleted}`, '');
  }

  public GetTeamComponent(projectId: string): Observable<TeamComponent> {
    return this.client.get<TeamComponent>(`/Component/Team/${projectId}`);
  }

  public GetTeamMemberSettings(): Observable<TeamComponent> {
    return this.client.get<TeamComponent>('/Component/Team/GetTeamMemberSettings');
  }

  public RemoveTeamMember(teamMemberId: string): Observable<boolean> {
    return this.client.delete<boolean>(`/Component/Team/RemoveTeamMember/${teamMemberId}`);
  }

  public ActivateTeamSettingsMember(teamMemberId: string, isDeleted: boolean): Observable<boolean> {
    return this.client.put<boolean>(`/Component/Team/ActivateTeamSettingsMember/${teamMemberId}/${isDeleted}`, '');
  }

  public IsTeamMember(projectId: string): Observable<boolean> {
    return this.client.get<boolean>(`/component/Team/IsTeamMember/${projectId}`);
  }

  /*
        @deprecated
    */
  public GetPermission(projectId: string, permissionGroup: string, permissionId: string): Observable<TeamPermissionType> {
    return this.client.get<TeamPermissionType>(`/component/Team/GetPermission/${projectId}/${permissionGroup}/${permissionId}`);
  }
}
