export enum ComponentIdentifier {
  TIMECARD = 'TimeCard',
  TEAM = 'Team',
  ACTIVITY = 'Activity',
  DAILY_LOG = 'DailyLog',
  WEATHER = 'Weather',
  NOTES = 'Notes',
  COSTCODES = 'CostCodes',
  SITE_CONDITIONS = 'SiteConditions',
  INFO = 'Info',
  MENU = 'Menu',
  PHOTOS = 'Photos'
}
