import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup } from '@angular/forms';
import { CostCode } from './models/costcode';

@Component({
  selector: 'modal-costcodesadd',
  templateUrl: './project.costcodes.add.modal.html'
})
export class ModalAddCostCode {
  closeResult: string;
  @Input() costCode: CostCode;
  @Input() formType: string;
  complexForm: FormGroup;

  constructor(public activeModal: NgbActiveModal) {
    //this.complexForm = fb.group({
    //});
  }

  addCostCode() {
    this.activeModal.close(this.costCode);
  }

  close() {
    this.activeModal.dismiss();
  }
}
