import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import * as ProjectApi from '../../models';
import { UserProjectService } from '../../user.project.service';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { SiteConditionsService } from './site.conditions.service';
import { SettingsService } from '../settings/settings.service';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import { SiteConditionComponent } from './models/siteconditioncomponent';
import { SiteCondition } from './models/sitecondition';
import { IComponent } from '../../models/IComponent';
import { ValidationResult } from '../../models/validationResult';

@Component({
  selector: 'siteconditions-settings',
  templateUrl: './site.conditions.settings.component.html',
  styleUrls: ['../project.component.css']
})
export class SiteConditionsSettingsComponent implements OnInit, IComponent {
  siteConditionComponent: SiteConditionComponent = null;
  conditionName: string;

  @Input() mainComponent: ProjectApi.Component;
  @Input() componentType: string;

  constructor(
    private growl: GrowlService,
    private route: ActivatedRoute,
    private projectService: UserProjectService,
    private userSharedService: UserSharedService,
    private settingsService: SettingsService,
    private siteConditionService: SiteConditionsService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.settingsService.parentName = params.parent;

      if (this.settingsService.componentType === 'global') {
        this.userSharedService.GetComponent('dailylog').subscribe(result => {
          var weatherComp = result.Components.filter(x => x.ComponentIdentifier.toLowerCase() === 'siteconditions');

          if (weatherComp.length > 0) {
            this.siteConditionComponent = weatherComp[0] as SiteConditionComponent;
          }
        });
      } else if (this.settingsService.componentType === 'project') {
        this.projectService.GetProjectSection(this.settingsService.pId, 'dailylog').subscribe(result => {
          var weatherComp = result.Components.filter(x => x.ComponentIdentifier.toLowerCase() === 'siteconditions');

          if (weatherComp.length > 0) {
            this.siteConditionComponent = weatherComp[0] as SiteConditionComponent;
          }
        });
      }
    });
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.siteConditionComponent['_t'] = ['BaseEntity', 'Component', 'ProjectComponent', 'SiteConditionComponent'];

      if (this.settingsService.componentType.toLowerCase() === 'project') {
        this.projectService.SaveSub1Component(this.settingsService.pId, 'dailylog', 'siteconditions', this.siteConditionComponent).subscribe(comp => {
          //this.growl.addAlert("Site conditions have been saved", AlertType.SUCCESS);
          obs.next(true);
          obs.complete();
        });
      } else {
        this.userSharedService.SaveSub1Component('dailylog', 'siteconditions', this.siteConditionComponent).subscribe(comp => {
          // this.growl.addAlert("Site conditions have been saved", AlertType.SUCCESS);
          obs.next(true);
          obs.complete();
        });
      }
    });
  }

  remove(idx: number, c: SiteCondition) {
    this.siteConditionComponent.Conditions.splice(idx, 1);
    this.Save().subscribe(x => {});
  }

  addCondition() {
    var sky: SiteCondition = new SiteCondition();
    sky.Description = this.conditionName;
    sky.Name = this.conditionName;

    this.siteConditionComponent.Conditions === undefined ? (this.siteConditionComponent['Conditions'] = []) : null;
    this.siteConditionComponent.Conditions === null ? (this.siteConditionComponent.Conditions = []) : this.siteConditionComponent.Conditions;

    sky.SiteConditionId = this.siteConditionComponent.Conditions.length + 1;

    this.siteConditionComponent.Conditions.push(sky);

    this.Save().subscribe(x => {});

    this.conditionName = '';
  }

  Clear(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      throw new Error('Method not implemented.');
    });
  }
  Remove(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      throw new Error('Method not implemented.');
    });
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var vResult = new ValidationResult();
      vResult.IsValid = true;
      obs.next(vResult);
      obs.complete();
    });
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
