import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavService } from '../../../../services/nav.service';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { ModalCostCodesImport } from './project.costcodes.import.modal';
import { ModalAddCostCode } from './project.costcodes.add.modal';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { IComponent } from '../../models/IComponent';
import { ValidationResult } from '../../models/validationResult';
import { SettingsService } from '../settings/settings.service';
import { CostCodesComponent } from './models/costcodescomponent';
import { CostCode } from './models/costcode';
import { CostCodeService } from './costcode.service';
import 'rxjs/Rx';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { environment } from 'src/environments/environment';
import { OrderByPipe } from 'ngx-pipes';

@Component({
  selector: 'project-cost-codes',
  templateUrl: './project.costcodes.component.html',
  providers: [NavService, OrderByPipe],
  styleUrls: ['../project.component.css', './project.costcodes.component.css']
})
export class ProjectCostCodesComponent implements OnInit, IComponent {
  projectId: string;
  costCodesComponent: CostCodesComponent;
  hoursTotal = 0;
  search = new FormControl();
  templateUrl: string;
  nCostCodes: CostCode[];
  @Input() permissionType: AccessLevel;

  constructor(
    private route: ActivatedRoute,
    private costCodeService: CostCodeService,
    private settingsService: SettingsService,
    private modalService: NgbModal,
    private orderBy: OrderByPipe
  ) {
    this.templateUrl = environment.api + '/api/project/GetCostCodesTemplate';
  }

  ngOnInit() {
    this.route.data.subscribe(params => {
      this.permissionType = params.permissionType;
    });

    // var ac = new AccessLevel();
    // ac.AccessLevelId = "fullaccess";
    // ac.Name = "Full Access";
    // this.permissionType = ac;

    this.projectId = this.settingsService.pId;

    this.costCodeService.GetCostCodeComponent(this.projectId).subscribe(response => {
      this.costCodesComponent = response;
      this.nCostCodes = this.costCodesComponent.CostCodes;

      this.calculate();
    });

    this.search.valueChanges.debounceTime(400).subscribe(value => {
      if (value === '') {
        this.costCodesComponent.CostCodes = this.nCostCodes;
      } else {
        this.costCodesComponent.CostCodes = this.nCostCodes.filter(item => {
          if (item.Description !== null && item.Description.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            return true;
          } else if (item.Phase !== null && item.Phase.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            return true;
          } else if (item.Hours != null && item.Hours.toString().indexOf(value) > -1) {
            return true;
          } else if (item.Phase != null && item.Phase.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            return true;
          } else if (item.UnitOfMeasure != null && item.UnitOfMeasure.toLowerCase().indexOf(value.toLowerCase()) > -1) {
            return true;
          } else if (item.Units != null && item.Units.toString().toLowerCase().indexOf(value) > -1) {
            return true;
          }

          return false;
        });
      }

      this.calculate();
    });
  }

  setOrder(costCodes: CostCode[], prop: string): CostCode[] {
    return this.orderBy.transform(costCodes, prop);
  }

  importCostCodes() {
    const modalRef = this.modalService.open(ModalCostCodesImport, { size: 'lg' });
    modalRef.componentInstance.projectId = this.projectId;
    modalRef.result.then(result => {
      this.costCodesComponent = result;
      this.nCostCodes = result.CostCodes;
      this.calculate();
    });
  }

  addCostCode() {
    const modalRef = this.modalService.open(ModalAddCostCode, { size: 'lg' });
    modalRef.componentInstance.costCode = new CostCode();
    modalRef.componentInstance.formType = 'Add';
    modalRef.result.then(result => {
      this.costCodeService.AddCostCode(this.projectId, result).subscribe(b => {
        this.costCodesComponent.CostCodes.push(result);
        this.calculate();
      });
    });
  }

  editCostCode(idx, costCode: CostCode) {
    const modalRef = this.modalService.open(ModalAddCostCode, { size: 'lg' });
    modalRef.componentInstance.costCode = Object.assign({}, costCode);
    modalRef.componentInstance.formType = 'Edit';
    modalRef.result.then(result => {
      this.costCodeService.SaveCostCode(this.projectId, result).subscribe(b => {
        this.costCodesComponent.CostCodes.splice(idx, 1, result);
        this.calculate();
      });
    });
  }

  deleteCostCode(idx, costCode: CostCode) {
    this.costCodeService.DeleteCostCode(this.projectId, costCode.CostCodeId).subscribe(b => {
      this.costCodesComponent.CostCodes.splice(idx, 1);
      this.calculate();
    });
  }

  calculate() {
    this.hoursTotal = 0;
    for (const costCode of this.costCodesComponent.CostCodes) {
      this.hoursTotal += costCode.Hours;
    }
  }

  Save(): Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  Validate(): Observable<ValidationResult> {
    const valResult = new ValidationResult();
    valResult.IsValid = true;
    return Observable.of(valResult);
  }

  Clear(): Observable<boolean> {
    return Observable.of(true);
  }
  Remove(): Observable<boolean> {
    return Observable.of(true);
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
