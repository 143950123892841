import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import * as UserApi from '../../../user/models';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddDelegate } from './modal.adddelegate';
import { UserSharedService } from '../../services/user.shared.sevice';
import { DelegateService } from '../../services/delegate.service';
import { UserService } from '../../services/user.service';
import { Delegate } from '../../models/delegate';

@Component({
  selector: 'project-settings-delegates',
  templateUrl: './project.settings.delegates.component.html',
  styleUrls: ['./project.settings.delegates.component.css']
})
export class ProjectSettingsDelegatesComponent implements OnInit {
  user: UserApi.CMUser;
  aUser: UserApi.User;
  obSetting: string = 'LastName';
  isReversed: boolean = false;

  cUsers: Array<Delegate> = [];

  search = new FormControl();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private delegeService: DelegateService,
    private userService: UserService,
    private userSharedService: UserSharedService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.userSharedService.GetDelegates().subscribe(user => {
      this.user = user;
      this.cUsers = Object.assign([], user.DelegatedUsers);
    });

    this.userService.getUser().subscribe(user => {
      this.aUser = user;
    });

    this.search.valueChanges.debounceTime(400).subscribe(value => {
      this.user.DelegatedUsers = this.cUsers.filter(
        x => x.FirstName.toLowerCase().indexOf(value.toLowerCase()) > -1 || x.LastName.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
    });
  }

  open() {
    const modalRef = this.modalService.open(ModalAddDelegate, { size: 'lg' });
    modalRef.componentInstance.currentDelegates = this.user.DelegatedUsers;
    modalRef.result.then(result => {
      this.user.DelegatedUsers = this.user.DelegatedUsers.concat(result);
    });
  }

  openDelegateSettings(idx, delegate: UserApi.Delegate) {
    this.router.navigate(['/user', delegate.Id, 'delegate']);
  }

  accessDelegate(delegate: UserApi.Delegate) {
    this.delegeService.AccessDelegate(delegate).subscribe(cmUser => {
      this.user.CurrentDelegation = delegate;
      this.userService.cmUser.next(cmUser);
      //this.router.navigate(['/user','project-list']);
    });
  }

  editPermissions(delegate: UserApi.Delegate) {
    this.router.navigate(['/user', 'permission', 'manageuser', delegate.Id]);
  }

  removeCurrentDelegate() {
    this.delegeService.RemoveCurrentDelegate().subscribe(response => {
      this.user.CurrentDelegation = null;
      this.userService.cmUser.next(null);
    });
  }

  removeDelegate(idx, delegate) {
    this.delegeService.RemoveDelegate(delegate).subscribe(response => {
      this.user.DelegatedUsers.splice(idx, 1);
    });
  }

  sort(name: string, isReversed: boolean) {
    this.obSetting = name;
    this.isReversed = isReversed;
  }
}
