export enum ComponentIcons {
  info = 'fas fa-info-circle fa-lg',
  team = 'fas fa-users fa-lg',
  'daily-log' = 'fas fa-sun fa-lg',
  history = 'fas fa-history fa-lg',
  settings = 'fas fa-cog fa-lg',
  reports = 'fas fa-file-alt fa-lg',
  photos = 'fas fa-image fa-lg',
  punchlist = 'fas fa-list fa-lg',
  files = 'fas fa-file fa-lg'
}

