import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject } from 'rxjs/Rx';
import { UserProjectService } from '../../user.project.service';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { SiteConditionComponent } from './models/siteconditioncomponent';
import { SiteConditionsService } from './site.conditions.service';
import { ComponentIdentifier } from '../../models/componentIdentifier';
import { IComponent, ComponentView } from '../../models/IComponent';
import { ValidationResult } from '../../models/validationResult';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { DataBoundComponent } from '../../models/DataboundComponent';

@Component({
  selector: 'siteconditions',
  templateUrl: './site.conditions.component.html',
  styleUrls: ['../project.component.css']
})
export class ProjectSiteConditionsComponent extends DataBoundComponent<SiteConditionComponent> implements OnInit, IComponent {
  siteConditionComponent: SiteConditionComponent = new SiteConditionComponent();

  @Input() month: number;
  @Input() day: number;
  @Input() year: number;
  @Input() projectId: string = null;
  @Input() dateInfo: Date;
  @Input() permissionType: AccessLevel;
  @Input() view: ComponentView = ComponentView.Normal;
  @Input() userId: string = null;
  public loading = false;

  constructor(
    private growl: GrowlService,
    private route: ActivatedRoute,
    private siteConditionService: SiteConditionsService,
    private userProjectService: UserProjectService,
    private router: Router
  ) {
    super();
  }

  ngOnInit(): void {
    this.loading = true;
    if (this.projectId === null) {
      this.route.parent.params.subscribe(params => {
        this.projectId = params.id;
        this.Initialize();
      });
    } else {
      this.Initialize();
    }
  }

  public DataBindByProjectInfo(): Observable<SiteConditionComponent> {
    return this.siteConditionService.GetSiteConditionComponent(this.projectId, this.year, this.month, this.day);
  }
  public DataBindByUserInfo(): Observable<SiteConditionComponent> {
    return this.siteConditionService.GetSiteConditionComponentByUser(this.projectId, this.userId, this.year, this.month, this.day);
  }
  Initialize() {
    this.InitializeDataCView(this.view).subscribe(result => {
      this.siteConditionComponent = result;

      if (this.siteConditionComponent.CreateDate === null) {
        this.userProjectService.GetProjectSection(this.projectId, ComponentIdentifier.DAILY_LOG).subscribe(comp => {
          var siteCondition = comp as SiteConditionComponent;

          if (siteCondition) {
            var siteConComp = siteCondition.Components.filter(
              x => x.ComponentIdentifier.toLowerCase().indexOf(ComponentIdentifier.SITE_CONDITIONS.toLowerCase()) !== -1
            );

            if (siteConComp.length > 0) {
              var wComp = siteConComp[0] as SiteConditionComponent;
              this.siteConditionComponent.Conditions = Object.assign([], wComp.Conditions);
            }
          }

          this.loading = false;
        });
      } else {
        this.loading = false;
      }
    });
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.loading = true;

      if (this.view === ComponentView.FinalApprover) {
        Observable.timer(100).subscribe(
          result => {
            obs.complete();
            obs.next(true);
            this.loading = false;
          },
          err => {
            obs.error(err);
            this.loading = false;
          }
        );
      } else {
        this.siteConditionService.SaveTimeCardComponent(this.projectId, this.siteConditionComponent).subscribe(
          comp => {
            this.siteConditionComponent = comp;
            this.loading = false;
            obs.next(true);
            obs.complete();
          },
          err => {
            this.loading = false;
            obs.next(false);
            obs.error(err);
          }
        );
      }
    });
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var vResult = new ValidationResult();

      if (this.error.hasError) {
        vResult.IsValid = false;
        obs.error(vResult);
      } else {
        vResult.IsValid = true;
        obs.next(vResult);
        obs.complete();
      }
    });
  }

  Clear(): Observable<boolean> {
    this.loading = true;
    return new Observable<boolean>(obs => {
      this.siteConditionComponent.ConditionId = null;
      this.loading = false;
      obs.next(true);
      obs.complete();
    });
  }

  Remove(): Observable<boolean> {
    this.loading = true;
    return new Observable<boolean>(obs => {
      this.siteConditionService.DeleteSiteConditionComponent(this.projectId, this.year, this.month, this.day).subscribe(
        result => {
          this.Clear().subscribe(result => {
            this.loading = false;
            obs.next(true);
          });
        },
        err => {
          this.loading = false;
          obs.next(false);
          obs.error(err);
        }
      );
    });
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
