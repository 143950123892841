import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { InfoComponent } from './models/infoComponent';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class InfoService {
  constructor(private client: HttpClient) {}

  public GetInfoComponent(projectId: string): Observable<InfoComponent> {
    return this.client.get<InfoComponent>(`/component/info/GetInfoComponent/${projectId}`);
  }

  public SaveInfoComponent(projectId: string, tc: InfoComponent): Observable<InfoComponent> {
    return this.client.post<InfoComponent>(`/component/weather/SaveInfoComponent/${projectId}`, tc);
  }
}
