import { Component, Input, OnInit, Injectable, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileItem } from 'ng2-file-upload';
import * as UserApi from '../../../user/models';
import { EmployeeService } from '../../services/employee.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'modal-employee-import',
  templateUrl: './employee.import.modal.html'
})
export class EmployeeImportModal implements OnInit {
  public uploader: FileUploader;
  public responseInfo = {
    IsPartial: false,
    Rule: 0,
    SaveSettings: true
  };

  public maxStep = 5;
  public minStep = 1;
  public currentStep = 1;
  public settingOption = true;
  public letterArray: string[] = [];
  isError = false;
  error = '';
  updatingEmployees = false;

  public employeeInfo: any;

  @Input() Delegates: UserApi.Delegate[] = [];

  constructor(private activeModal: NgbActiveModal, private authService: AuthService, private employeeService: EmployeeService) {
    this.createLetterArray();
  }

  createLetterArray() {
    this.letterArray = [];

    this.letterArray.push('A');
    this.letterArray.push('B');
    this.letterArray.push('C');
    this.letterArray.push('D');
    this.letterArray.push('E');
    this.letterArray.push('F');
    this.letterArray.push('G');
    this.letterArray.push('H');
    this.letterArray.push('I');
    this.letterArray.push('J');
    this.letterArray.push('K');
    this.letterArray.push('L');
    this.letterArray.push('M');
    this.letterArray.push('N');
    this.letterArray.push('O');
    this.letterArray.push('P');
    this.letterArray.push('Q');
    this.letterArray.push('R');
    this.letterArray.push('S');
    this.letterArray.push('T');
    this.letterArray.push('U');
    this.letterArray.push('V');
    this.letterArray.push('W');
    this.letterArray.push('X');
    this.letterArray.push('Y');
    this.letterArray.push('Z');
  }

  ngOnInit(): void {
    const URL = environment.api + `/user/employee/EmployeeImport/${this.responseInfo.Rule}`;

    this.authService.getAccessToken().subscribe(result => {
      this.uploader = new FileUploader({ url: URL, authToken: 'Bearer ' + result });
      this.uploader.options.autoUpload = true;
      this.uploader.onBeforeUploadItem = (item: FileItem) => {
        item.url = environment.api + `/user/employee/EmployeeImport/${this.responseInfo.Rule}`;
        item.headers.push('Bearer ' + result);
      };

      this.uploader.onCompleteItem = (item: FileItem, response: any, status: number) => {
        const info = JSON.parse(response);
        this.responseInfo = info;
        if (!info.IsPartial) {
          this.responseInfo = info;
          this.currentStep = 3;
          this.goToNextStep();
          info.IsPartial = true;
        }

        this.uploader.isUploading = false;
      };

      this.uploader.onErrorItem = (item: FileItem, response: string, status: number) => {
        this.uploader.isUploading = false;
        this.isError = true;
        this.error = 'There was an issue uploading the report.';
        // this.growlService.addAlert("There was an issue uploading the report. Please make sure you use the template provided", AlertType.DANGER);
      };
    });
  }

  goToNextStep() {
    if (this.currentStep == this.maxStep) {
    } else {
      this.currentStep++;
    }

    if (this.currentStep == 2) {
      this.employeeService.GetStepInfo(2, this.responseInfo).subscribe(info => {
        this.responseInfo = info;
      });
    } else if (this.currentStep == 4) {
      this.employeeService.GetStepInfo('gatherdata', this.responseInfo).subscribe(info => {
        this.responseInfo = info;

        if (info.ImportValidation) {
          if (info.ImportValidation._employeeValidation && info.ImportValidation._newEmployees) {
            if (info.ImportValidation._employeeValidation.length === 0 && info.ImportValidation._newEmployees.length > 0) {
              this.finalizeEmployees();
            }
          }
        }
      });
    }
  }

  fixData(employeeInfo) {
    if (employeeInfo['IsVerify']) {
      employeeInfo.IsVerify = !employeeInfo.IsVerify;
    } else {
      employeeInfo['IsVerify'] = true;
    }
  }

  goToPreviousStep() {
    if (this.currentStep == this.minStep) {
    } else {
      this.currentStep--;
    }

    if (this.currentStep == 2) {
      this.employeeService.GetStepInfo(2, this.responseInfo).subscribe(info => {
        this.responseInfo = info;
      });
    }
  }

  // saveForeman(foreman: UserApi.Foreman) {
  //    foreman["_t"] = ["BaseEntity", "Foreman"];

  //    for (var x = 0; x < this.Delegates.length; x++) {
  //        var delegate = this.Delegates[x];
  //        if (delegate.Id === foreman.DelegateUserId) {
  //            foreman.FirstName = delegate.FirstName;
  //            foreman.LastName = delegate.LastName;
  //            break;
  //        }
  //    }
  // }

  finalizeEmployees() {
    this.updatingEmployees = true;
    if (this.responseInfo.SaveSettings) {
      this.employeeService.SaveEmployeeSettings(true, this.responseInfo).subscribe(
        e => {
          this.employeeService.SaveEmployeeImportInfo(this.responseInfo).subscribe(
            result => {
              this.updatingEmployees = false;
              this.activeModal.close('Refresh');
            },
            err => {
              this.updatingEmployees = false;
            }
          );
        },
        err => {
          this.updatingEmployees = false;
        }
      );
    } else {
      this.employeeService.SaveEmployeeImportInfo(this.responseInfo).subscribe(
        result => {
          this.updatingEmployees = false;
          this.activeModal.close('Refresh');
        },
        err => {
          this.updatingEmployees = false;
        }
      );
    }
  }

  // saveImport() {
  //    if (this.responseInfo.SaveSettings) {
  //        this.employeeService.SaveEmployeeSettings(true, this.responseInfo).subscribe(e => {
  //            this.employeeService.GetStepInfo("save", this.responseInfo).subscribe(info => {
  //                this.responseInfo = info;

  //                if (this.responseInfo.NewForeman.length <= 0) {
  //                    this.activeModal.close("Refresh");
  //                } else {
  //                    this.currentStep = 5;
  //                }
  //            });
  //        });
  //    } else {
  //        this.employeeService.GetStepInfo("save", this.responseInfo).subscribe(info => {
  //            this.responseInfo = info;

  //            if (this.responseInfo.NewForeman.length <= 0) {
  //                this.activeModal.close("Refresh");
  //            } else {
  //                this.currentStep = 5;
  //            }
  //        });
  //    }

  // }

  close() {
    this.activeModal.dismiss();
  }
}
