import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

import { ApprovalHistory, ApprovalHistoryType } from './models/approvalHistory';
import { GrowlService } from '../../custom.controls/growl/growl.service';
import { Approval } from './models/approval';
import { AlertType } from '../../custom.controls/growl/alert-type.enum';
import { Approver } from './models/approver';
import { ApprovalLevel } from './models/approvalLevel';
import { Subject } from 'rxjs/Subject';
import { ApprovalInfo } from './models/approvalInfo';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable()
export class ApprovalService {
  constructor(private http: HttpClient, private growlService: GrowlService) {}

  public GetUserApprovalLevels(): Observable<ApprovalLevel[]> {
    return this.http.get<ApprovalLevel[]>('/component/approval/GetUserApprovalLevels');
  }

  public AddApproval(projectId: string, dailyLogId: string, dailyLogDate: Date): Observable<Approval> {
    return this.http.post<Approval>(`/component/approval/AddApproval/${projectId}/${dailyLogId}`, dailyLogDate);
  }

  public AddApprovalHistory(projectId: string, approvalId: string, historyType: ApprovalHistoryType): Observable<ApprovalHistory> {
    return this.http.put<ApprovalHistory>(`/component/approval/AddHistory/${projectId}/${approvalId}/${historyType}`, '');
  }

  public GetApprovalTimeCardEdit(projectId: string, approvalId: string): Observable<boolean> {
    return this.http.get<boolean>(`/component/approval/GetApprovalTimeCardEdit/${projectId}/${approvalId}`);
  }

  public Unlock(projectId: string, approvalId: string): Observable<boolean> {
    return this.http.put<boolean>(`/component/approval/Unlock/${projectId}/${approvalId}`, '');
  }

  public Unapprove(projectId: string, approvalId: string): Observable<Approval> {
    return this.http.put<Approval>(`/component/approval/Unapprove/${projectId}/${approvalId}`, '');
  }

  public RemoveApproval(projectId: string, dailyLogId: string): Observable<boolean> {
    return this.http.delete<boolean>(`/component/approval/RemoveApproval/${projectId}/${dailyLogId}`);
  }

  public GetUnApproved(projectId: string, filter: any): Observable<Approval[]> {
    const searchObject = {
      o: filter
    };
    return this.http.post<Approval[]>(`/component/approval/GetUnApproved/${projectId}`, searchObject);
  }

  public GetApproval(projectId: string, dailyLogId: string): Observable<Approval> {
    return this.http.get<Approval>(`/component/approval/GetApproval/${projectId}/${dailyLogId}`);
  }

  public GetApproved(projectId: string, filter: any): Observable<Approval[]> {
    const searchObject = {
      o: filter
    };
    return this.http.post<Approval[]>(`/component/approval/GetApproved/${projectId}`, searchObject);
  }

  public GetAll(projectId: string, filter: any): Observable<Approval[]> {
    const searchObject = {
      o: filter
    };
    return this.http.post<Approval[]>(`/component/approval/GetAll/${projectId}`, searchObject);
  }

  public Approve(projectId: string, approvalId: string): Observable<Approver> {
    return this.http.put<Approver>(`/component/approval/Approve/${projectId}/${approvalId}`, '');
  }

  public GetApprovals(filter: any): Observable<ApprovalInfo[]> {
    return this.http.post<ApprovalInfo[]>('/component/approval/GetApprovals', filter);
  }

  public GetApprovedForPayroll(filter: any): Observable<ApprovalInfo[]> {
    return this.http.post<ApprovalInfo[]>('/component/approval/ApprovedForPayroll', filter);
  }

  public GetUserLevel(): Observable<number> {
    return this.http.get<number>('/component/approval/GetUserLevel');
  }

  public GetAllUnApprovals(filter: any): Observable<ApprovalInfo[]> {
    return this.http.post<ApprovalInfo[]>('/component/approval/GetAllUnApprovals', filter);
  }

  public GetAllApproved(filter: any): Observable<ApprovalInfo[]> {
    return this.http.post<ApprovalInfo[]>('/component/approval/GetAllApproved', filter);
  }

  public IsPayroll(): Observable<boolean> {
    return this.http.get<boolean>('/component/approval/IsPayroll');
  }

  // public SaveWeatherComponent(projectId: string, tc: WeatherComponent) {
  //    return new Observable<WeatherComponent>(obs => {
  // tslint:disable-next-line: max-line-length
  //        this.authService.GetData(Authentication.HttpMethods.POST, "/component/weather/SaveWeatherComponent/" + projectId, tc).subscribe(response => {
  //            obs.next(response.json() as WeatherComponent);
  //            obs.complete();
  //        }, err => {

  //        });
  //    });
  // }
}
