//Global
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CurrencyMaskModule } from 'ng2-currency-mask';

//Modules
import { DndModule } from 'ng2-dnd';
import { InlineEditorModule } from '@qontu/ngx-inline-editor';
import { CivCastCommonControlsModule } from '../custom.controls/custom.controls.module';

//Components
import { StringElement } from './components/elements/string.element';
import { DateElement } from './components/elements/date.element';
import { NumberElement } from './components/elements/number.element';
import { BooleanElement } from './components/elements/boolean.element';
import { ParentElement } from './components/elements/parent.element';
import { ModalPropertyAdd } from './components/modals/property.add.modal';
import { ModalPropertyEdit } from './components/modals/property.edit.modal';
import { CurrencyElement } from './components/elements/currency.element';
import { FormService } from './civcast.form.service';
import { ElementEditable } from './components/elements/element.editable.component';

//pipes

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InlineEditorModule,
    ReactiveFormsModule,
    NgbModule,
    DndModule.forRoot(),
    CivCastCommonControlsModule,
    CurrencyMaskModule
  ],
  declarations: [
    StringElement,
    DateElement,
    NumberElement,
    BooleanElement,
    ParentElement,
    CurrencyElement,
    ElementEditable,
    ModalPropertyAdd,
    ModalPropertyEdit
  ],
  entryComponents: [
    StringElement,
    DateElement,
    NumberElement,
    BooleanElement,
    ParentElement,
    CurrencyElement,
    ElementEditable,
    ModalPropertyAdd,
    ModalPropertyEdit
  ],
  exports: [ParentElement, ElementEditable]
})
export class CivCastFormModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CivCastFormModule,
      providers: [FormService]
    };
  }
}
