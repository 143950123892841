//modules
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DndModule } from 'ng2-dnd';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PopoverModule } from 'ngx-popover';
import { NgPipesModule } from 'ngx-pipes';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { UserTimeCardModule } from '../user.project.timecard/user.timecard.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { FileUploadModule } from 'ng2-file-upload';

//directives

//components
import { ProjectSettingsDelegatesComponent } from './components/delegation/project.settings.delegates.component';
import { ActivityHistoryComponent } from './components/history/activity.history.component';
import { UserClassficationsComponent } from './components/delegation/user.classifications.component';
import { EquipmentComponent } from './components/Equipment/equipment.component';
import { EmployeeComponent } from './components/employee/employee.component';
import { SettingsComponent } from './settings/settings.component';
import { UserApprovalViewComponent } from './approval/approval.view.component';
import { CivCastCommonControlsModule } from '../custom.controls/custom.controls.module';
import { UserPermissionsModule } from '../user.permissions/user.permissions.module';
import { DelegateResolve } from './resolves/delegate.resolve';
import { UserViewComponent } from './components/userview.component';
import { UserChangeDelegationComponent } from './components/user.changedelegation';
import { DelegateSettingsComponent } from './components/delegation/delegate.settings.component';
import { UserProfileComponent } from './components/profile/user.profile.component';
import { UserReportsComponent } from './components/reports/user.reports.component';
import { UserInfoComponent } from './components/profile/user.info.component';
import { ApprovalComponent } from './approval/approval.component';
import { ApprovalHistoryModalComponent } from './approval/approvalhistory.modal.component';
import { UserTimeCardReportComponent } from './components/reports/timecard/user.timecard.report.component';

//modals
import { ModalAddDelegate } from './components/delegation/modal.adddelegate';
import { ModalDelegateSettings } from './components/delegation/modal.delegate.settings';
import { ModalClassification } from './components/delegation/modal.classification';
import { EquipmentModal } from './components/Equipment/equipment.modal';
import { EmployeeImportModal } from './components/employee/employee.import.modal';
import { EquipmentImportModal } from './components/Equipment/equipment.import.modal';
import { EmployeeAddModal } from './components/employee/employee.add.modal';
import { ForemanAddModal } from './components/employee/foreman.add.modal';
import { ForemanDeactivateModal } from './components/employee/foreman.deactivate.modal';

//Services
import { UserSharedService } from './services/user.shared.sevice';
import { DelegateService } from './services/delegate.service';
import { EquipmentService } from './services/equipment.service';
import { EmployeeService } from './services/employee.service';
import { UserBaseComponent } from './user.base.component';
import { UserNavMenuComponent } from './navmenu/navmenu.component';
import { GlobalReportsService } from './components/reports/user.report.service';
import { HttpClientModule } from '@angular/common/http';
import { LoadingComponent } from 'src/app/components/loading/loading.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgPipesModule,
    ReactiveFormsModule,
    NgbModule,
    HttpClientModule,
    DndModule.forRoot(),
    UserTimeCardModule.forRoot(),
    PopoverModule,
    RouterModule,
    FileUploadModule,
    NgMultiSelectDropDownModule.forRoot(),
    CivCastCommonControlsModule,
    UserPermissionsModule.forRoot(),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(0, 79, 150, 0.8)'
    })
  ],
  declarations: [
    ProjectSettingsDelegatesComponent,
    ActivityHistoryComponent,
    UserClassficationsComponent,
    EquipmentComponent,
    EmployeeComponent,
    ModalAddDelegate,
    ModalDelegateSettings,
    ModalClassification,
    EquipmentModal,
    EquipmentImportModal,
    EmployeeImportModal,
    EmployeeAddModal,
    ForemanAddModal,
    ForemanDeactivateModal,
    SettingsComponent,
    UserBaseComponent,
    UserNavMenuComponent,
    UserViewComponent,
    UserChangeDelegationComponent,
    DelegateSettingsComponent,
    UserProfileComponent,
    UserReportsComponent,
    UserInfoComponent,
    UserApprovalViewComponent,
    ApprovalComponent,
    ApprovalHistoryModalComponent,
    UserTimeCardReportComponent,
    LoadingComponent
  ],
  entryComponents: [
    ModalAddDelegate,
    ModalDelegateSettings,
    ModalClassification,
    EquipmentModal,
    EquipmentImportModal,
    EmployeeImportModal,
    EmployeeAddModal,
    ForemanAddModal,
    ForemanDeactivateModal,
    UserProfileComponent,
    LoadingComponent
  ],
  exports: [
    UserViewComponent,
    UserChangeDelegationComponent,
    UserNavMenuComponent,
    UserProfileComponent,
    UserInfoComponent,
    ApprovalComponent,
    ApprovalHistoryModalComponent
  ]
})
export class UserModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UserModule,
      providers: [UserSharedService, DelegateService, EquipmentService, EmployeeService, GlobalReportsService, DelegateResolve]
    };
  }
}
