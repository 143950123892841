import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-explan-paragraph',
  templateUrl: './explan-paragraph.component.html',
  styleUrls: ['./explan-paragraph.component.css']
})
export class ExplanParagraphComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
