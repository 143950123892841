//import { Permission } from './Permission';
export class Delegate {
  public Id: string;
  public Username: string;
  public FirstName: string;
  public LastName: string;
  public CompanyName: string;
  //public Permissions: Permission[] = [];
  public IsEnabled: boolean;
  public CreateDate: Date;
  public Title: string;
}
