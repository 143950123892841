import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { InfoItem } from './models/InfoItem';
import { ValueTypes } from './models/valueTypes.enum';

@Injectable()
export class FormService {
  constructor() {}

  setReadableName(item: InfoItem) {
    switch (item.ValueType) {
      case ValueTypes.String:
        item.TypeName = 'Text';
        break;
      case ValueTypes.Date:
        item.TypeName = 'Date';
        break;
      case ValueTypes.Decimal:
        item.TypeName = 'Number';
        break;
      case ValueTypes.Boolean:
        item.TypeName = 'Check';
        break;
      case ValueTypes.Currency:
        item.TypeName = 'Currency';
        break;
      default:
        item.TypeName = 'Unknown';
        break;
    }
  }
}
