import { Component, Input, OnInit, Injectable } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as UserApi from '../../models';
import { DelegateService } from '../../services/delegate.service';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'modal-classification',
  templateUrl: './modal.classification.html'
})
export class ModalClassification implements OnInit {
  closeResult: string;
  @Input() modalType: string;
  @Input() classification: UserApi.Classification;
  @Input() userClassifications: UserApi.Classification[] = [];
  // permissions: UserApi.Permission[];

  constructor(public activeModal: NgbActiveModal, private client: HttpClient, private delegateService: DelegateService) {}

  ngOnInit(): void {
    if (this.modalType === 'add') {
      this.client.get('/api/global/GetStaticData/Permissions').subscribe(
        response => {
          // this.classification.Permissions = response.json() as UserApi.Permission[];
        },
        err => {}
      );
    }
  }

  saveClassification() {
    if (this.modalType === 'add') {
      this.delegateService.AddClassification(this.classification).subscribe(c => {
        this.activeModal.close(c);
      });
    } else if (this.modalType === 'edit') {
      this.delegateService.SaveClassification(this.classification).subscribe(c => {
        this.activeModal.close(c);
      });
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}
