import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Policy } from '../models/policy';
import { Permission } from '../models/permission';
import { PolicySet } from '../models/sets/policySet';
import { Subject } from 'rxjs';
import { UserPermissionServiceSerializer } from '../models/userPermissionSerializer';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class UACPolicyService {
  serializer: UserPermissionServiceSerializer = new UserPermissionServiceSerializer();

  constructor(private client: HttpClient) {}

  userPolicies = new Subject<Policy[]>();

  getUsersPolicies(): Observable<Policy[]> {
    return this.userPolicies.asObservable();
  }

  setUserPolicies(policies: Policy[]) {
    this.userPolicies.next(policies);
  }

  public AddPolicy(delegateUserId: string, policy: Policy): Observable<Policy> {
    policy.Permissions.map(item => {
      return this.serializer.SerializePermission(item);
    });
    return this.client.put<Policy>(`/uac/policy/AddPolicy/${delegateUserId}`, policy);
  }

  public GetPolicies(delegateUserId: string): Observable<PolicySet> {
    return this.client.get<PolicySet>(`/uac/policy/GetPolicies/${delegateUserId}`);
  }

  public AddPermissionToPolicy(policyId: string, permission: Permission): Observable<Permission> {
    return this.client.put<Permission>(`/uac/policy/AddPermissionToPolicy/${policyId}`, permission);
  }

  public RemovePermissionFromPolicy(policyId: string, permissionId: string): Observable<boolean> {
    return this.client.put<boolean>(`/uac/policy/RemovePermissionFromPolicy/${policyId}/${permissionId}`, '');
  }

  public RemoveAllPolicy(policyId: string): Observable<boolean> {
    return this.client.delete<boolean>(`/uac/policy/RemoveAllPolicy/${policyId}`);
  }

  public RemovePolicy(delegateUserId: string, policyId: string): Observable<boolean> {
    return this.client.delete<boolean>(`/uac/policy/RemovePolicy/${delegateUserId}/${policyId}`);
  }
}
