import { Component, OnInit, Input } from '@angular/core';
import { UserSharedService } from '../services/user.shared.sevice';
import { UserProfile } from '../models/userProfile';

@Component({
  selector: 'user-view',
  templateUrl: './userview.component.html'
})
export class UserViewComponent implements OnInit {
  @Input() userId: string;
  @Input() view: string = 'basic';
  userProfile: UserProfile;
  constructor(private userSharedService: UserSharedService) {}

  ngOnInit(): void {
    this.userSharedService.GetProfile(this.userId).subscribe(result => {
      this.userProfile = result;
    });
  }
}
