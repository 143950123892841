import * as ProjectApi from '../../../models';
import { InfoItem } from '../../../../civcast.form/models/InfoItem';

export class InfoComponent extends ProjectApi.Component {
  _t: ['BaseEntity', 'Component', 'ProjectComponent', 'InfoComponent'];
  ProjectId: string;

  Properties: InfoItem[] = [];

  load(): void {}
}
