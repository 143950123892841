import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'project-plan-room',
  templateUrl: './project.planroom.component.html',
  styleUrls: ['../project.component.css']
})
export class ProjectPlanRoomComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
