export class Component {
  constructor() {}

  Id: string;
  ComponentIdentifier: string;
  UrlLink: string;
  AllowDeletion: boolean;
  HasSettings: boolean;
  Name: string;
  Components: Component[] = [];
  CreateDate: Date = null;
}
