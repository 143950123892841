import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Rx';
import { saveAs } from 'file-saver';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';

@Injectable()
export class GlobalReportsService {
  constructor(private authService: AuthService, private client: HttpClient) {}

  public RunTimeCardReport(startDate: NgbDateStruct, endDate: NgbDateStruct): Observable<boolean> {
    return new Observable<boolean>(obs => {
      const info = {
        StartDate: startDate,
        EndDate: endDate
      };

      this.authService.getAccessToken().subscribe(result => {
        const url = '/user/reports/GetTimeCardReport/';
        const res = this.client
          .post(url, info, {
            headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + result },
            responseType: 'blob'
          })
          .subscribe(result => {
            saveAs(result, 'TimeCardReport.xlsx');
            obs.next(true);
          });
      });

      // this.authService.DownloadFile( "/user/reports/GetTimeCardReport/", info).subscribe(response => {
      //     saveAs(response, "TimeCardReport.xlsx");
      //     obs.next(true);
      // }, err => {
      //     obs.error(err);
      // });
    });
  }

  public GetWeekRange(): Observable<any> {
    return new Observable<any>(obs => {
      this.client.get('/user/reports/GetWeekRange').subscribe(
        response => {
          obs.next(response);
        },
        err => {
          obs.error(err);
        }
      );
    });
  }
}
