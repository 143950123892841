import { Component, OnInit, Input } from '@angular/core';
import { UserSharedService } from '../services/user.shared.sevice';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'user-changedelegation',
  templateUrl: './user.changedelegation.html'
})
export class UserChangeDelegationComponent implements OnInit {
  constructor(private userSharedService: UserSharedService, private activatedRoute: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    this.router.navigate(['/user', 'project-list']);
  }
}
