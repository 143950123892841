import { ICustomSerializer } from '../../core/ICustomSerializer';

export class AccessLevel implements ICustomSerializer {
  _t: string[];
  AccessLevelId: string;
  Name: string;

  Serialize(): void {
    console.log('AccessLevelSerializer');
  }
}
