import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = new Subject<boolean>();
  isLoggedIn$ = this.isLoggedIn.asObservable();
  constructor(private client: HttpClient) {}

  public isUserLoggedIn(): Observable<boolean> {
    var obs = new Subject<boolean>();

    Auth.currentAuthenticatedUser()
      .then(result => {
        this.isLoggedIn.next(result);
        obs.next(result);
        //this.authService.activateSignIn(result);
      })
      .catch(() => {
        this.isLoggedIn.next(false);
        obs.error(false);
      });

    return obs.asObservable();
  }

  public getAccessToken(): Observable<string> {
    var obs = new Subject<string>();

    Auth.currentSession()
      .then(data => {
        var token = data.getAccessToken().getJwtToken();
        obs.next(token);
        obs.complete();
      })
      .catch(err => {
        obs.error(err);
      });

    return obs.asObservable();
  }

  public getIdentityToken(): Observable<string> {
    var obs = new Subject<string>();

    Auth.currentSession()
      .then(data => {
        var token = data.getIdToken().getJwtToken();
        obs.next(token);
        obs.complete();
      })
      .catch(err => {
        obs.error(err);
      });

    return obs.asObservable();
  }

  public DownloadFile(url: string, data: any): Observable<any> {
    var obs = new Subject<any>();
    this.getAccessToken().subscribe(result => {
      var res = this.client.post(url, data, {
        headers: { 'Content-Type': 'application/json', Authorization: 'Bearer ' + result },
        responseType: 'blob'
      });

      obs.next(res);
    });

    return obs.asObservable();
  }

  public logIn() {
    var userManagementUrl = encodeURI(environment.id_server + '/login?redirectUrl=' + environment.BaseSiteUrl + '/auth/validate');
    window.location.replace(userManagementUrl);
    //window.location.replace(this.getLoginUri(returnPageName));
  }

  public logOut() {
    var userManagementUrl = encodeURI(environment.id_server + '/logout?redirectUrl=' + environment.BaseSiteUrl + '/auth/callback/signout');
    window.location.replace(userManagementUrl);
  }
}
