import { Component, OnInit, Input } from '@angular/core';
import { UACPolicyService } from '../../services/uac.policy.service';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import { GlobalService } from '../../../../services/global.service';
import { Observable } from 'rxjs/Observable';
import { Policy } from '../../models/policy';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalAddPolicy } from './create.policy.modal';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'create-policy',
  templateUrl: './create.policy.component.html',
  styleUrls: []
})
export class CreatePolicyComponent implements OnInit {
  @Input() policies: Array<Policy> = [];
  cPolicies: Array<Policy> = [];
  searchPolicy = new FormControl();

  constructor(
    private router: Router,
    private userSharedService: UserSharedService,
    private uacPolicyService: UACPolicyService,
    private globalService: GlobalService,
    private modalService: NgbModal
  ) {}

  ngOnInit(): void {
    Observable.forkJoin(this.globalService.GetPolicies(), this.userSharedService.GetPolicies()).subscribe(info => {
      this.policies = info[0].concat(info[1]);
      this.cPolicies = Object.assign([], this.policies);
    });

    this.searchPolicy.valueChanges.debounceTime(400).subscribe(value => {
      var sValue = value as string;
      this.policies = this.cPolicies.filter(x => x.PolicyName.toLowerCase().indexOf(sValue.toLowerCase()) > -1);
    });
  }

  removePolicy(p: Policy) {
    this.userSharedService.RemovePolicy(p.PolicyId).subscribe(result => {
      this.uacPolicyService.RemoveAllPolicy(p.PolicyId).subscribe(result2 => {
        var idx = this.policies.indexOf(p);
        this.policies.splice(idx, 1);
      });
    });
  }

  editPolicy(p: Policy) {
    this.router.navigate(['/user', 'permission', 'policies', p.PolicyId]);
  }

  dragEnd() {
    for (var x = 0; x < this.policies.length; x++) {
      this.policies[x].Order = x;
    }

    this.userSharedService.ReOrderPolicies(this.policies).subscribe(x => {});
  }

  newPolicy(p: Policy) {
    var nPolicy: Policy = null;

    if (p) {
      nPolicy = Object.assign({}, p);
    } else {
      nPolicy = new Policy();
    }

    const modalRef = this.modalService.open(ModalAddPolicy, { size: 'lg' });
    modalRef.componentInstance.policy = nPolicy;
    modalRef.result.then(result => {
      this.userSharedService.AddNewPolicy(result).subscribe(fResult => {
        this.policies.push(fResult);
        this.router.navigate(['/user', 'permission', 'policies', fResult.PolicyId]);
      });
    });
  }
}
