import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CivCastPicaService } from './pica/pica.service';
import { CivCastPicaExifService } from './pica/pica.exif.service';
import { CivCastImageService } from './image.service';
import { CivCastAWSUploader } from './uploader/civcast.aws.uploader.component';
import { CommonModule } from '@angular/common';
import { CivCastCropperComponent } from './cropper/civcast.cropper.component';
import { CivCastGalleryComponent } from './gallery/civcast.gallery.component';
import { CivCastPictureComponent } from './picture/picture.component';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { CivCastImageViewerComponent } from './imageviewer/image.viewer.component';
import { ClickStopPropagationDirective } from '../directives/clickstoppropagation.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(0, 79, 150, 0.8)'
    })
  ],
  declarations: [
    CivCastAWSUploader,
    CivCastCropperComponent,
    CivCastGalleryComponent,
    CivCastPictureComponent,
    CivCastImageViewerComponent,
    ClickStopPropagationDirective
  ],
  exports: [CivCastAWSUploader, CivCastGalleryComponent, CivCastPictureComponent, CivCastImageViewerComponent],
  providers: [
    { provide: CivCastPicaService, useClass: CivCastPicaService },
    { provide: CivCastPicaExifService, useClass: CivCastPicaExifService },
    { provide: CivCastImageService, useClass: CivCastImageService }
  ]
})
export class CivCastImageModule {}
