import { Component, OnInit, Input } from '@angular/core';
import { InfoItem } from '../../models/InfoItem';

@Component({
  selector: 'currency-element',
  templateUrl: './currency.element.html'
})
export class CurrencyElement implements OnInit {
  @Input() infoItem: InfoItem;
  @Input() mode: string;
  ngOnInit(): void {}
}
