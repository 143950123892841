import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as UserApi from '../../../user/models';
import { TeamService } from './team.service';
import { TeamMember } from './models/TeamMember';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'modal-teammember-settings',
  templateUrl: './teammember.edit.modal.html'
})
export class TeamMemberEditModal implements OnInit {
  closeResult: string;
  @Input() TeamMember: TeamMember;
  @Input() userClassifications: UserApi.Classification[] = [];
  @Input() projectId: string;
  model = 1;

  constructor(public activeModal: NgbActiveModal, private client: HttpClient, private teamService: TeamService) {}

  ngOnInit(): void {
    this.client.get('/api/user/GetClassifications').subscribe(
      response => {
        this.userClassifications = response as UserApi.Classification[];
      },
      err => {
        console.log(err);
      }
    );

    // sync latest permissions
    // this.authService.GetData(Authentication.HttpMethods.GET, "/api/global/GetStaticData/Permissions").subscribe(response => {
    //    var permissions = response.json() as UserApi.Permission[];
    //    for (let p of permissions) {
    //        var hasPermission = this.TeamMember.DelegateUser.Permissions.filter(item => item.Name.indexOf(p.Name) !== -1);
    //        if (hasPermission.length <= 0) {
    //            this.TeamMember.DelegateUser.Permissions.push(p);
    //        }
    //    }
    // }, err => {
    // });
  }

  saveTeamMember() {
    this.teamService.SaveTeamMember(this.projectId, this.TeamMember).subscribe(nMember => {
      this.activeModal.close(nMember);
    });
  }

  classificationChange(classification: UserApi.Classification) {
    this.TeamMember.DelegateUser.Title = classification.Name;
    // this.TeamMember.DelegateUser.Permissions = classification.Permissions;
  }

  close() {
    this.activeModal.dismiss();
  }
}
