import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'DailyLog-Settings-Base-Component',
  templateUrl: './dailylog.settings.base.component.html'
})
export class DailyLogSettingsBaseComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
