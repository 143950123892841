import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'punchlist-component',
  templateUrl: './punchlist.component.html'
})
export class PunchListComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
