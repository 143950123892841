import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as UserApi from '../../../user/models';
import { EquipmentService } from '../../services/equipment.service';

@Component({
  selector: 'modal-equipment',
  templateUrl: './equipment.modal.html'
})
export class EquipmentModal implements OnInit {
  @Input() equipmentItem: UserApi.EquipmentItem;

  constructor(private activeModal: NgbActiveModal, private equipmentService: EquipmentService) {}

  ngOnInit(): void {}

  saveItem() {
    this.equipmentService.SaveEquipmentItem(this.equipmentItem).subscribe(item => {
      this.activeModal.close(item);
    });
  }

  close() {
    this.activeModal.dismiss();
  }
}
