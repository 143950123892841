import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { UserSharedService } from '../../services/user.shared.sevice';
import { UserProfile } from '../../models/userProfile';

@Component({
  selector: 'user-profile',
  templateUrl: './user.profile.component.html'
})
export class UserProfileComponent implements OnInit {
  @Input() userId: string;
  profile: UserProfile;
  constructor(private userSharedService: UserSharedService) {}

  ngOnInit(): void {
    this.userSharedService.GetProfile(this.userId).subscribe(profile => {
      this.profile = profile;
    });
  }
}
