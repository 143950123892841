import { Component, OnInit, Input } from '@angular/core';
import { GrowlService } from './growl.service';
import { Alert } from './alert';
import { AlertType } from './alert-type.enum';

@Component({
  selector: 'civcast-growl',
  template: `<div *ngFor="let alert of alerts">
    <div class="alert alert-{{ alert.type }}" [ngClass]="{ 'alert-dismissible': alert.dismissable }" role="alert">
      <button type="button" class="close" data-dismiss="alert" (click)="closeAlert(alert)" *ngIf="alert.dismissable">
        <span aria-hidden="true">&times;</span>
      </button>
      <span [innerHtml]="alert.message"></span>
    </div>
  </div>`,
  styleUrls: ['./growl.css']
})
export class GrowlComponent implements OnInit {
  @Input() alertCount: number;
  @Input() autoClose: number;

  public alerts: Alert[];

  constructor(private growlService: GrowlService) {}

  ngOnInit() {
    this.growlService.configure(this.alertCount, this.autoClose);
    this.growlService.alerts.subscribe((alerts: Alert[]) => {
      this.alerts = alerts;
    });
  }

  public closeAlert(alert: Alert): void {
    // service is automatically notified!
    if (alert.dismissable) {
      this.growlService.removeAlert(alert);
    }
  }
}
