import { Component } from '@angular/core';

@Component({
  selector: 'permission-user-base',
  templateUrl: './user.base.permission.component.html',
  styleUrls: []
})
export class UserBasePermissionComponent {
  constructor() {}
}
