import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { TimeCardComponent } from './models/timeCardComponent';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable()
export class TimeCardService {
  constructor(private client: HttpClient) {}

  timeInfo: Array<TimeInfo> = [];

  public GetTimeCardComponent(projectId: string, year: number, month: number, day: number): Observable<TimeCardComponent> {
    return this.client.get<TimeCardComponent>(`/component/timecard/GetTimeCardComponent/${projectId}/${year}/${month}/${day}`);
  }

  public GetRecentTimeCard(projectId: string, year: number, month: number, day: number): Observable<TimeCardComponent> {
    return this.client.get<TimeCardComponent>(`/component/timecard/GetRecentTimeCard/${projectId}/${year}/${month}/${day}`);
  }

  public GetTimeCardComponentByUser(
    projectId: string,
    userId: string,
    year: number,
    month: number,
    day: number
  ): Observable<TimeCardComponent> {
    const url = `/component/timecard/GetTimeCardComponentByUser/${projectId}/${userId}/${year}/${month}/${day}`;
    return this.client.get<TimeCardComponent>(url);
  }

  public SaveTimeCardComponent(projectId: string, tc: TimeCardComponent): Observable<TimeCardComponent> {
    return this.client.post<TimeCardComponent>(`/component/timecard/SaveTimeCardComponent/${projectId}`, tc);
  }

  public SaveTimeCardComponentEdit(projectId: string, tc: TimeCardComponent): Observable<TimeCardComponent> {
    return this.client.post<TimeCardComponent>(`/component/timecard/SaveTimeCardComponentEdit/${projectId}`, tc);
  }

  public DeleteTimeCard(projectId: string, year: number, month: number, day: number): Observable<boolean> {
    return this.client.delete<boolean>(`/component/timecard/DeleteTimeCard/${projectId}/${year}/${month}/${day}`);
  }

  public GetTimeInfo(): Array<TimeInfo> {
    if (this.timeInfo.length <= 0) {
      this.timeInfo = new Array<TimeInfo>();

      for (let x = 0; x <= 24; x++) {
        const d = new TimeInfo();
        d.Display = `${x}`;
        d.Value = x;

        this.timeInfo.push(d);

        if (x !== 24) {
          const d5 = new TimeInfo();
          d5.Display = `${x}.5`;
          d5.Value = x + 0.5;
          this.timeInfo.push(d5);
        }
      }
    }

    return this.timeInfo;
  }
}

export class TimeInfo {
  Display: string;
  Value: number;
}
