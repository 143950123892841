import { Component, OnInit, Input } from '@angular/core';
import { UserProjectService } from '../../user.project.service';
import * as ProjectApi from '../../../user.project/models';
import { InfoService } from './info.service';
import { InfoComponent } from './models/infoComponent';
import { InfoItem } from '../../../civcast.form/models/InfoItem';
import { ComponentIdentifier } from '../../models/componentIdentifier';
import { IComponent } from '../../models/IComponent';
import { Observable } from 'rxjs/Observable';
import { ValidationResult } from '../../models/validationResult';

@Component({
  selector: 'project-info',
  templateUrl: './project.info.component.html',
  styleUrls: ['../project.component.css']
})
export class ProjectInfoComponent implements OnInit, IComponent {
  @Input() project: ProjectApi.Project = null;
  @Input() infoComponent: InfoComponent = new InfoComponent();
  @Input() properties: InfoItem[] = [];
  cMode: string = 'info';
  public loading = false;

  constructor(private userProjectService: UserProjectService, private infoService: InfoService) {}

  ngOnInit(): void {
    this.loading = true;
    this.infoComponent.Properties = [];

    if (this.project != null) {
      this.userProjectService.GetProjectSection(this.project.Id, ComponentIdentifier.INFO).subscribe(comp => {
        this.infoComponent = comp;
        this.loading = false;
      });
    } else {
      if (this.userProjectService.project !== null && this.userProjectService.project !== undefined) {
        this.project = this.userProjectService.project;
        this.userProjectService.GetProjectSection(this.project.Id, ComponentIdentifier.INFO).subscribe(comp => {
          this.infoComponent = comp;
          this.loading = false;
        });
      } else {
        this.userProjectService.mainProject$.subscribe(project => {
          this.project = project;

          this.userProjectService.GetProjectSection(project.Id, ComponentIdentifier.INFO).subscribe(comp => {
            this.infoComponent = comp;
            this.loading = false;
          });
        });
      }
    }
  }

  SaveInfo() {
    this.Save().subscribe(result => {});
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      Observable.forkJoin(
        this.userProjectService.SaveProject(this.project),
        this.infoService.SaveInfoComponent(this.project.Id, this.infoComponent)
      ).subscribe(
        info => {
          obs.next(true);
          obs.complete();
        },
        err => {
          obs.next(false);
          obs.error(err);
        }
      );
    });
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var vResult = new ValidationResult();
      vResult.IsValid = true;
      obs.next(vResult);
      obs.complete();
    });
  }

  Clear(): Observable<boolean> {
    return Observable.of(true);
  }

  Remove(): Observable<boolean> {
    return Observable.of(true);
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
