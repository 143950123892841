import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

// Modules
import { AmplifyAngularModule, AmplifyService, AmplifyModules } from 'aws-amplify-angular';
import { CivCastCommonControlsModule } from './modules/custom.controls/custom.controls.module';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgProgressModule } from 'ngx-progressbar';
import { UserModule } from './modules/user/user.module';
import { UserProjectModule } from './modules/user.project/user.project.module';

// Interceptors
import { CustomHttpInterceptor } from './shared/custom-http.interceptor';
import { AuthInterceptor } from './shared/auth-interceptor';
import { AWSCurrentSessionInterceptor } from './shared/aws-current-session.interceptor';
import { ErrorHandlerInterceptor } from './shared/error-handler.interceptor';

// Services
import { UserService } from './modules/user/services/user.service';
import { GlobalService } from './services/global.service';
import { NavService } from './services';

// Components
import { AppComponent } from './app.component';
import { HomeFeatureComponent } from './components/home-feature/home-feature.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { HomeComponent } from './components/home/home.component';
import { FeaturesComponent } from './components/features/features.component';
import { NavMenuComponent } from './components/nav-menu/nav-menu.component';
import { UnauthorizedComponent } from './components/unauthorized/auth.unauthorized.component';
import { CheckLoginComponent } from './components/check-login/check-login.component';
import { SigninComponent } from './components/signin/signin.component';
import { SignoutComponent } from './components/signout/signout.component';
import { AuthValidationComponent } from './components/auth-validation/auth-validation.component';
import { AuthSignoutCallbackComponent } from './components/auth-signout-callback/auth-signout-callback.component';
import { LoadingComponent } from './components/loading/loading.component';
import { NavbarComponent } from './components/home/navbar/navbar.component';
import { AboveTheFoldComponent } from './components/home/above-the-fold/above-the-fold.component';
import { TheStakesComponent } from './components/home/the-stakes/the-stakes.component';
import { ValuePropositionComponent } from './components/home/value-proposition/value-proposition.component';
import { GuideComponent } from './components/home/guide/guide.component';
import { ThePlanComponent } from './components/home/the-plan/the-plan.component';
import { PricingComponent } from './components/home/pricing/pricing.component';
import { ExplanParagraphComponent } from './components/home/explan-paragraph/explan-paragraph.component';
import { VideoComponent } from './components/home/video/video.component';
import { FooterComponent } from './components/home/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeFeatureComponent,
    PageNotFoundComponent,
    HomeComponent,
    FeaturesComponent,
    NavMenuComponent,
    UnauthorizedComponent,
    CheckLoginComponent,
    SigninComponent,
    SigninComponent,
    SignoutComponent,
    AuthValidationComponent,
    AuthSignoutCallbackComponent,
    NavbarComponent,
    AboveTheFoldComponent,
    TheStakesComponent,
    ValuePropositionComponent,
    GuideComponent,
    ThePlanComponent,
    PricingComponent,
    ExplanParagraphComponent,
    VideoComponent,
    FooterComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AmplifyAngularModule,
    CommonModule,
    CivCastCommonControlsModule,
    BrowserAnimationsModule,
    NgProgressModule,
    UserModule.forRoot(),
    UserProjectModule.forRoot(),
    RouterModule.forRoot([
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: '',
        component: HomeComponent,
        children: [
          { path: 'home', component: HomeComponent },
          { path: 'product', component: FeaturesComponent }
        ]
      },

      {
        path: 'auth/validate',
        component: AuthValidationComponent
      },
      {
        path: 'auth/callback/signout',
        component: AuthSignoutCallbackComponent
      },
      {
        path: 'login',
        component: SigninComponent
      },
      {
        path: 'logout',
        component: SignoutComponent
      },
      {
        path: 'signin',
        component: SigninComponent
      },
      {
        path: 'signout',
        component: SignoutComponent
      },
      {
        path: 'checklogin',
        component: CheckLoginComponent
      },
      { path: 'unauthorized', component: UnauthorizedComponent },
      { path: '**', component: PageNotFoundComponent }
    ]),
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(0, 79, 150, 0.8)',
      fullScreenBackdrop: false
    })
  ],
  providers: [
    AmplifyService,
    UserService,
    GlobalService,
    NavService,
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AWSCurrentSessionInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
