import { Component, OnInit, Input, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import * as UserApi from '../../../user/models';
import { UserSharedService } from '../../services/user.shared.sevice';
import { DelegateService } from '../../services/delegate.service';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserProfileComponent } from '../profile/user.profile.component';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { AlertType } from '../../../custom.controls/growl/alert-type.enum';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'delegate-settings',
  templateUrl: './delegate.settings.component.html'
})
export class DelegateSettingsComponent implements OnInit {
  @Input() delegateUser: UserApi.Delegate = new UserApi.Delegate();
  @ViewChild('userProfile', { static: false }) target: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private userSharedService: UserSharedService,
    private growlService: GrowlService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private client: HttpClient
  ) {}

  ngOnInit() {
    this.route.params.subscribe(params => {
      let delegateId = null;
      delegateId = params.delegateId;

      this.getDelegate(params.delegateId);
    });
  }

  getDelegate(delegateId: string) {
    this.userSharedService.GetDelegate(delegateId).subscribe(delegate => {
      this.delegateUser = delegate;

      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserProfileComponent);
      const profile = this.target.createComponent(componentFactory).instance as UserProfileComponent;
      profile.userId = delegate.Id;
    });
  }

  saveDelegate() {
    this.client.post('/api/user/SaveDelegateUser', this.delegateUser).subscribe(
      response => {
        this.growlService.addAlert('Settings Saved.', AlertType.SUCCESS);
      },
      err => {
        this.growlService.addAlert('Error saving settings', AlertType.DANGER);
      }
    );
  }
}
