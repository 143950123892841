import { Component, OnInit, Input, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { UserSharedService } from '../../services/user.shared.sevice';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'user-reports',
  templateUrl: './user.reports.component.html'
})
export class UserReportsComponent implements OnInit {
  @ViewChild('reportComponents', { static: true }) target: ViewContainerRef;

  constructor(
    private userSharedService: UserSharedService,
    private activatedRoute: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private router: Router
  ) {}

  ngOnInit(): void {
    //const componentFactory = this.componentFactoryResolver.resolveComponentFactory(UserTimeCardReportComponent);
    //var timeCard = <UserTimeCardReportComponent>this.target.createComponent(componentFactory).instance;
  }
}
