import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Policy } from '../../models/policy';

@Component({
  selector: 'modal-create-policy',
  templateUrl: './create.policy.modal.html'
})
export class ModalAddPolicy implements OnInit {
  @Input() policy: Policy;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}

  add() {
    this.activeModal.close(this.policy);
  }

  close() {
    this.activeModal.dismiss();
  }
}
