import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'project-bid-items',
  templateUrl: './project.biditems.component.html',
  styleUrls: ['../project.component.css']
})
export class ProjectBidItemsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
