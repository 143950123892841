import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, UrlTree } from '@angular/router';
import * as UserApi from '../../../modules/user/models';
import { NavService } from '../../../services/nav.service';
import { DelegateService } from '../services/delegate.service';
import { UserService } from '../../../modules/user/services/user.service';
import { NgbPopover, NgbPopoverConfig } from '@ng-bootstrap/ng-bootstrap';
import { UserSharedService } from '../services/user.shared.sevice';
// import { IAppConfig, APP_CONFIG } from '../../../config/base.config';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
import { UserProjectService } from '../../user.project/user.project.service';
import { RecentProject } from '../../user.project/models';

@Component({
  selector: 'user-nav-menu',
  styleUrls: ['./navmenu.component.css'],
  templateUrl: './navmenu.component.html',
  providers: [NgbPopoverConfig]
})
export class UserNavMenuComponent implements OnInit {
  route: UrlTree;
  isLoggedIn: boolean;
  isDelegation: boolean;
  user: UserApi.User;
  cmUser: UserApi.CMUser;
  // private client: Client;
  firstName = 'Test First Name';
  showNav = false;
  showProjects = false;
  @ViewChild('p', { static: false }) public popover: NgbPopover;
  recentProjects: Subject<RecentProject[]> = new Subject<RecentProject[]>();
  recentProjects$ = this.recentProjects.asObservable();

  constructor(
    public navService: NavService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private delegateService: DelegateService,
    private userService: UserService,
    private userProjectService: UserProjectService,
    private userSharedService: UserSharedService,
    private ngPopUpconfig: NgbPopoverConfig
  ) {}

  ngOnInit() {
    this.router.events.subscribe(val => {
      if (this.router.url.startsWith('/home')) {
        this.showNav = false;
      } else {
        this.showNav = true;
      }
      if (this.router.url.startsWith('/user/project/')) {
        this.showProjects = true;
        this.navService.showNavIcon = true;
        this.navService.isNavOpenTest.next(this.navService.isNavOpen);
      } else {
        this.showProjects = false;
        this.navService.showNavIcon = false;
        this.navService.isNavOpenTest.next(this.navService.isNavOpen);
      }
    });

    this.ngPopUpconfig.placement = 'bottom';
    this.ngPopUpconfig.triggers = 'click';

    this.authService.isLoggedIn.subscribe(val => {
      this.isLoggedIn = val;

      this.userSharedService.GetMyDelegates().subscribe(cmUser => {
        // console.log("User Service Subscribed called");
        this.cmUser = cmUser;
        // console.log(cmUser);
        if (cmUser == null) {
          this.isDelegation = false;
        } else {
          this.isDelegation = cmUser.CurrentDelegation != null;
        }
      });
    });

    this.userService.getClaimsUser().subscribe(user => {
      this.user = user;
    });
  }

  loadProjects(event): void {
    if (event) {
      this.recentProjects$ = this.userProjectService.GetRecentProjects();
    }
  }

  goToAccountProfile(route) {
    window.location.replace(`${environment.id_server}/${route}?redirecturi=${encodeURIComponent(window.location.href)}`);
  }

  removeCurrentDelegate() {
    this.delegateService.RemoveCurrentDelegate().subscribe(response => {
      this.cmUser.CurrentDelegation = null;
      this.userService.cmUser.next(this.cmUser);
      this.router.navigate(['/user', 'change-delegation']);
    });
  }

  accessDelegate(delegate: UserApi.Delegate) {
    this.delegateService.AccessDelegate(delegate).subscribe(cmUser => {
      this.cmUser.CurrentDelegation = delegate;
      this.userService.cmUser.next(cmUser);
      this.router.navigate(['/user', 'change-delegation']);
      // this.router.navigate(['/user','project-list']);
    });
  }

  togglePopover() {
    // when this function runs for the first time, the popover contains the
    // old title ("Old Title"), after opening the popover the second time
    // the title is also updated ("New Title")

    this.firstName = 'Paul TESt Test';

    this.popover.isOpen() ? this.popover.close() : this.popover.open();
  }

  toggleNavState() {
    this.navService.isNavOpen = !this.navService.isNavOpen;
    this.navService.navOpenState = this.navService.isNavOpen ? 'close' : 'open';
    this.navService.isNavOpenTest.next(this.navService.isNavOpen);
  }

  login() {
    this.authService.logIn();
  }

  logOut() {
    this.authService.logOut();
  }
}
