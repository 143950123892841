import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { NotesComponent } from './models/notescomponent';
import { NotesService } from './notes.service';
import { Note } from './models/note';
import { IComponent, ComponentView } from '../../models/IComponent';
import { ValidationResult } from '../../models/validationResult';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { ToastrService } from 'ngx-toastr';
import { DataBoundComponent } from '../../models/DataboundComponent';

@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['../project.component.css']
})
export class ProjectNotesComponent extends DataBoundComponent<NotesComponent> implements OnInit, IComponent {
  notesComponent: NotesComponent;

  @Input() month: number;
  @Input() day: number;
  @Input() year: number;
  @Input() projectId: string = null;
  @Input() dateInfo: Date;
  @Input() permissionType: AccessLevel;
  @Input() view: ComponentView = ComponentView.Normal;
  @Input() userId: string = null;
  public loading = false;
  constructor(private route: ActivatedRoute, private notesService: NotesService, private toastrService: ToastrService) {
    super();
  }

  public DataBindByProjectInfo(): Observable<NotesComponent> {
    return this.notesService.GetNotesComponent(this.projectId, this.year, this.month, this.day);
  }
  public DataBindByUserInfo(): Observable<NotesComponent> {
    return this.notesService.GetNotesComponentByUser(this.projectId, this.userId, this.year, this.month, this.day);
  }

  ngOnInit(): void {
    this.loading = true;

    if (this.projectId === null) {
      this.route.parent.params.subscribe(params => {
        this.projectId = params['id'];
        this.Initialize();
      });
    } else {
      this.Initialize();
    }
  }

  Initialize() {
    this.InitializeDataCView(this.view).subscribe(
      result => {
        this.notesComponent = result;
        this.addNote();
        this.loading = false;
      },
      err => {
        this.loading = false;
      }
    );
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.loading = true;
      this.notesService.SaveNotesComponent(this.projectId, this.notesComponent).subscribe(
        comp => {
          this.notesComponent = comp;
          this.loading = false;
          obs.next(true);
          obs.complete();
        },
        err => {
          this.loading = false;
          obs.next(false);
          obs.error(err);
        }
      );
    });
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var vResult = new ValidationResult();
      if (this.error.hasError) {
        this.toastrService.error('Notes has an error that is unresolved Cannot not save at this time: ' + this.error.messages.join(','));
        obs.error();
        vResult.IsValid = false;
      } else {
        vResult.IsValid = true;
      }

      obs.next(vResult);
      obs.complete();
    });
  }

  Clear(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.notesComponent.Notes = [];
      this.addNote();
      obs.next(true);
      obs.complete();
    });
  }
  Remove(): Observable<boolean> {
    this.loading = true;
    return new Observable<boolean>(obs => {
      this.notesService.DeleteNoteComponent(this.projectId, this.year, this.month, this.day).subscribe(
        result => {
          this.Clear().subscribe(result => {
            this.loading = false;
            obs.next(true);
            obs.complete();
          });
        },
        err => {
          this.loading = false;
          obs.error(err);
          obs.complete();
        }
      );
    });
  }

  private addNote() {
    if (this.notesComponent.Notes.length <= 0) {
      var note = new Note();
      note.NoteId = 0;
      note.Text = '';
      this.notesComponent.Notes.push(note);
    }
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
