import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import * as ProjectApi from '../../../user.project/models';
import { UserProjectService } from '../../user.project.service';
import { GlobalService } from '../../../../services/global.service';
import { UserSharedService } from '../../../user/services/user.shared.sevice';

@Injectable()
export class SettingsService {
  pId: string;
  projectId = new Subject<string>();
  parentName: string;
  sub1: string;
  projectId$ = this.projectId.asObservable();
  componentType: string;

  constructor(private userSharedService: UserSharedService, private userProjectService: UserProjectService, private globalService: GlobalService) {}

  public SetProjectId(id: string) {
    this.pId = id;
    this.projectId.next(id);
  }

  public GetProjectComponents(): Observable<ProjectApi.Component[]> {
    return new Observable<ProjectApi.Component[]>(obs => {
      if (this.componentType.toLowerCase() === 'project') {
        this.userProjectService.GetProjectComponentInfo(this.pId).subscribe(pComps => {
          this.globalService.GetStaticData('projectcomponents').subscribe(globalComps => {
            var allComponents: ProjectApi.Component[] = [];
            for (let pComponent of pComps) {
              var hasComponent = globalComps.filter(item => item.ComponentIdentifier.indexOf(pComponent.ComponentIdentifier) !== -1);

              if (hasComponent.length > 0) {
                pComponent['IsActive'] = true;

                if (hasComponent[0].Components) {
                  for (let gInner of hasComponent[0].Components) {
                    var hasInner = pComponent.Components.filter(x => x.ComponentIdentifier.indexOf(hasComponent[0].ComponentIdentifier) !== -1);

                    if (hasInner.length > 0) {
                      hasInner['IsActive'] = true;
                    } else {
                      gInner['IsActive'] = false;
                      pComponent.Components.push(gInner);
                    }
                  }
                }
              } else {
                pComponent['IsActive'] = false;
              }
              allComponents.push(pComponent);
            }

            for (let component of globalComps) {
              var c = pComps.filter(item => item.ComponentIdentifier.indexOf(component.ComponentIdentifier) !== -1);
              if (c.length <= 0) {
                component['IsActive'] = false;

                if (component.Components) {
                  for (let innerC of component.Components) {
                    innerC['IsActive'] = false;
                  }
                }

                allComponents.push(component);
              }
            }

            obs.next(allComponents);
          });
        });
      } else {
        this.userSharedService.GetComponents().subscribe(pComps => {
          this.globalService.GetStaticData('projectcomponents').subscribe(globalComps => {
            var allComponents: ProjectApi.Component[] = [];
            for (let pComponent of pComps) {
              var hasComponent = globalComps.filter(item => item.ComponentIdentifier.indexOf(pComponent.ComponentIdentifier) !== -1);

              if (hasComponent.length > 0) {
                pComponent['IsActive'] = true;

                if (hasComponent[0].Components) {
                  for (let gInner of hasComponent[0].Components) {
                    var hasInner = pComponent.Components.filter(x => x.ComponentIdentifier.indexOf(hasComponent[0].ComponentIdentifier) !== -1);

                    if (hasInner.length > 0) {
                      hasInner['IsActive'] = true;
                    } else {
                      gInner['IsActive'] = false;
                      pComponent.Components.push(gInner);
                    }
                  }
                }
              } else {
                pComponent['IsActive'] = false;
              }
              allComponents.push(pComponent);
            }

            for (let component of globalComps) {
              var c = pComps.filter(item => item.ComponentIdentifier.indexOf(component.ComponentIdentifier) !== -1);
              if (c.length <= 0) {
                component['IsActive'] = false;

                if (component.Components) {
                  for (let innerC of component.Components) {
                    innerC['IsActive'] = false;
                  }
                }

                allComponents.push(component);
              }
            }

            obs.next(allComponents);
          });
        });
      }
    });
  }
}
