import { Component, OnInit, Input, ComponentFactoryResolver } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { TimeCardService } from './timecard.service';
import { TimeCardComponent } from './models/timeCardComponent';
import { TimeCardGraphComponent } from './timecard.graph.component';
import { TimeDescription } from './models/timeDescription';
import { IComponent, ComponentView } from '../user.project/models/IComponent';
import { AccessLevel } from '../user.permissions/models/accessLevel';
import { SelectedData } from '../core/SelectedData';
import { CostCode } from '../user.project/components/costcodes/models/costcode';
import { Employee } from '../user/models/index';
import { GrowlService } from '../custom.controls/growl/growl.service';
import { EmployeeService } from '../user/services/employee.service';
import { CostCodeService } from '../user.project/components/costcodes/costcode.service';
import { CostCodesComponent } from '../user.project/components/costcodes/models/costcodescomponent';
import { ValidationResult } from '../user.project/models/validationResult';
import { UserProjectService } from '../user.project/user.project.service';
import { ToastrService } from 'ngx-toastr';
import { DataComponentView, DataBoundComponent } from '../user.project/models/DataboundComponent';

@Component({
  selector: 'timecard',
  templateUrl: './timecard.component.html'
})
export class TimeCardComponentView extends DataBoundComponent<TimeCardComponent> implements OnInit, IComponent {
  @Input() projectId: string = null;
  @Input() dateInfo: Date;
  @Input() month: number;
  @Input() day: number;
  @Input() year: number;
  @Input() permissionType: AccessLevel;
  @Input() view: ComponentView = ComponentView.Normal;
  @Input() userId: string = null;
  @Input() layoutType: TimeCardLayout = TimeCardLayout.Card;
  @Input() s2ProjectCostCodes: Array<SelectedData>;
  @Input() s2Employees: Array<SelectedData>;
  timeCardComponent: TimeCardComponent;
  tcCardComponent: TimeCardGraphComponent = null;
  projectCostCodes: Array<CostCode>;
  allEmployees: Array<Employee>;
  timeDescriptions: Array<string>;
  selectedCostCode: CostCode;
  selectedEmployee: Employee;
  employeeFullName: string;
  public s2Options: Select2Options;
  selectedCostCodes: string[] = [];
  selectedEmployees: string[] = [];
  public loading = false;

  constructor(
    private growl: GrowlService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private timeCardService: TimeCardService,
    private costCodeService: CostCodeService,
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private projectService: UserProjectService,
    private toastrService: ToastrService,
    private router: Router
  ) {
    super();
  }

  public DataBindByProjectInfo(): Observable<TimeCardComponent> {
    return this.timeCardService.GetTimeCardComponent(this.projectId, this.year, this.month, this.day);
  }
  public DataBindByUserInfo(): Observable<TimeCardComponent> {
    return this.timeCardService.GetTimeCardComponentByUser(this.projectId, this.userId, this.year, this.month, this.day);
  }

  ngOnInit() {
    if (this.projectId === null) {
      this.route.parent.params.subscribe(params => {
        this.projectId = params['id'];
        this.loading = true;
        this.Initialize();
      });
    } else {
      this.Initialize();
    }
  }

  Initialize() {
    var subscriptions = new Array<any>();

    // if (this.view === ComponentView.Single) {
    //     subscriptions.push(this.timeCardService.GetTimeCardComponentByUser(this.projectId, this.userId, this.year, this.month, this.day));
    // } else if (this.view === ComponentView.Recent) {
    //     subscriptions.push(this.timeCardService.GetRecentTimeCard(this.projectId, this.year, this.month, this.day));
    // }else if (this.view == ComponentView.FinalApprover){
    //     subscriptions.push(this.timeCardService.GetTimeCardComponentByUser(this.projectId, this.userId, this.year, this.month, this.day));
    // }else {
    //     throw new Error("Component View is not supported: " + this.view)
    // }

    subscriptions.push(this.InitializeDataCView(this.view));
    subscriptions.push(this.costCodeService.GetCostCodeComponent(this.projectId));
    subscriptions.push(this.employeeService.GetEmployees('Active'));
    subscriptions.push(this.projectService.GetProjectSection(this.projectId, 'dailylog'));

    Observable.forkJoin(subscriptions).subscribe(
      info => {
        this.timeCardComponent = info[0] as TimeCardComponent;
        var costCodeComponent = info[1] as CostCodesComponent;
        this.projectCostCodes = costCodeComponent.CostCodes;
        this.s2ProjectCostCodes = new Array();

        for (let c of this.projectCostCodes) {
          var pData: SelectedData = {
            ItemId: c.Phase,
            ItemText: c.Phase + '-' + c.Description
          };

          this.s2ProjectCostCodes.push(pData);
        }

        this.allEmployees = info[2] as Array<Employee>;
        this.s2Employees = new Array();

        for (let c of this.allEmployees) {
          var pData: SelectedData = {
            ItemId: c.CustomId,
            ItemText: c.FirstName + ' ' + c.LastName + ' (' + c.CustomId + ')'
          };

          this.s2Employees.push(pData);
        }

        if (info[0].TimeReasons) {
          if (info[0].TimeReasons.length > 0) {
            this.timeDescriptions = info[0].TimeReasons;
            //this.timeCardComponent.TimeReasons = info[3];
          } else {
            var timeCardComp = info[3].Components.filter(x => x.ComponentIdentifier.toLowerCase() === 'timecard');

            if (timeCardComp.length > 0) {
              this.timeDescriptions = timeCardComp[0].TimeReasons;
              this.timeCardComponent.TimeReasons = timeCardComp[0].TimeReasons;
            }
          }
        } else {
          var timeCardComp = info[3].Components.filter(x => x.ComponentIdentifier.toLowerCase() === 'timecard');

          if (timeCardComp.length > 0) {
            if (timeCardComp[0].TimeReasons) {
              this.timeDescriptions = timeCardComp[0].TimeReasons;
              this.timeCardComponent.TimeReasons = timeCardComp[0].TimeReasons;
            } else {
              this.timeDescriptions = [];
            }
          }
        }

        if (!this.timeDescriptions) {
          this.timeDescriptions = this.getDefaultTimeDescriptions();
          this.timeCardComponent.TimeReasons = this.getDefaultTimeDescriptions();
        } else if (this.timeDescriptions.length <= 0) {
          this.timeDescriptions = this.getDefaultTimeDescriptions();
          this.timeCardComponent.TimeReasons = this.getDefaultTimeDescriptions();
        }

        if (this.timeCardComponent.TimeReasons.filter(x => x === 'Work Day').length <= 0) {
          this.timeCardComponent.TimeReasons.unshift('Work Day');
        }

        this.loading = false;
      },
      error => {
        this.loading = false;
      },
      function () {
        this.loading = false;
      }
    );
  }

  getDefaultTimeDescriptions(): Array<string> {
    return ['Work Day', 'Paid Time Off', 'No Show/No Call', 'Work on other crew', 'Sick', 'Vacation'];
  }

  importRecentTimeCard() {
    //this.view = ComponentView.Recent;

    this.timeCardService.GetRecentTimeCard(this.projectId, this.year, this.month, this.day).subscribe(tc => {
      if (tc != null) {
        tc.Day = this.day;
        tc.Month = this.month;
        tc.Year = this.year;
        tc.DateTimeInfo = new Date(this.year, this.month + 1, this.day);
        this.timeCardComponent = tc;
      }
    });

    //this.Initialize();
  }

  Clear(): Observable<boolean> {
    this.loading = true;
    return new Observable<boolean>(obs => {
      this.timeCardComponent.TimeCards[0].CostCodes = [];
      this.timeCardComponent.TimeCards[0].TimeCardEmployees = [];
      obs.next(true);
      obs.complete();
      this.loading = false;
    });
  }

  Remove(): Observable<boolean> {
    this.loading = true;
    return new Observable<boolean>(obs => {
      this.timeCardService.DeleteTimeCard(this.projectId, this.year, this.month, this.day).subscribe(
        result => {
          this.Clear().subscribe(result => {
            this.loading = false;
            obs.next(result);
            obs.complete();
          });
        },
        err => {
          obs.error(err);
          obs.complete();
          this.loading = false;
        }
      );
    });
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.loading = true;
      this.timeCardService.SaveTimeCardComponent(this.projectId, this.timeCardComponent).subscribe(
        tc => {
          this.timeCardComponent = tc;
          this.loading = false;
          obs.next(true);
          obs.complete();
        },
        err => {
          this.loading = false;
          obs.next(false);
          obs.error(err);
        }
      );
    });
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var validationResult = new ValidationResult();
      if (this.error.hasError) {
        this.toastrService.error('Time card has an error: ' + this.error.messages.join(','));
        validationResult.IsValid = false;
        return;
      }

      validationResult.IsValid = true;
      for (let tc of this.timeCardComponent.TimeCards) {
        if (validationResult.IsValid) {
          for (let employee of tc.TimeCardEmployees) {
            if (employee.TotalHours > 24) {
              validationResult.IsValid = false;
              validationResult.Message =
                'Employee ' + employee.Employee.FirstName + ' ' + employee.Employee.LastName + ' has over 24 hours in total time';
            }

            if (employee.TotalHours <= 0 && (employee.TimeDescriptionId === 'Work Day' || employee.TimeDescriptionId === 'WorkDay')) {
              validationResult.IsValid = false;
              validationResult.Message =
                'Employee ' +
                employee.Employee.FirstName +
                ' ' +
                employee.Employee.LastName +
                " has 0 hours and 'work day' selected. You must select another reason than 'work day' with 0 hours total.";
            }

            if (validationResult.IsValid) {
              for (let costCode of employee.CostCodes) {
                var hasCostCode = employee.CostCodes.filter(x => x.Phase === costCode.Phase);

                if (hasCostCode.length > 1) {
                  validationResult.IsValid = false;

                  validationResult.Message =
                    'Employee ' +
                    employee.Employee.FirstName +
                    ' ' +
                    employee.Employee.LastName +
                    ' has multiple of the same cost code named ' +
                    costCode.Description +
                    '. You must remove or replace one of the cost codes to submit.';

                  break;
                }

                if (employee.TotalHours <= 0) {
                  if (costCode.UserValue === null || costCode.UserValue === undefined) {
                    if (employee.TimeDescriptionId === 'WorkDay') {
                      validationResult.IsValid = false;

                      validationResult.Message =
                        'Employee ' +
                        employee.Employee.FirstName +
                        ' ' +
                        employee.Employee.LastName +
                        ' has a value for the cost code ' +
                        costCode.Description +
                        ' that is empty.  They must select another value than work day.';
                    }

                    break;
                  }
                }
              }
            } else {
              break;
            }
          }
        } else {
          break;
        }
      }

      obs.next(validationResult);
      obs.complete();
    });
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}

export enum TimeCardLayout {
  None,
  Card
}
