import { Component, OnInit, ViewContainerRef, ViewChild, AfterViewInit, ComponentFactoryResolver, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs/Rx';
import { NgbDateStruct, NgbDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DailyLogComponent } from './dailylogcomponent';
import { ProjectWeatherComponent } from '../weather/weather.component';
import { UserProjectService } from '../../user.project.service';
import { ComponentIdentifier } from '../../models/componentIdentifier';
import { ProjectSiteConditionsComponent } from '../siteconditions/site.conditions.component';
import { ProjectNotesComponent } from '../notes/notes.component';
import { DailyLogService } from './dailylog.service';
import { ConfirmService } from '../../../custom.controls/confirm/confirm.service';
import { IComponent, ComponentView } from '../../models/IComponent';
import { Subscription } from 'rxjs/Subscription';
import { ValidationResult } from '../../models/validationResult';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { ToastrService } from 'ngx-toastr';
import { ProjectPhotosComponent } from '../photos/photos.component';
import { Subject } from 'rxjs/Subject';
import { ApprovalService } from '../../../user/approval/approval.service';
import { Approval } from '../../../user/approval/models/approval';
import { TimeCardComponentView } from '../../../user.project.timecard/timecard.component';
import { observeOn } from 'rxjs/operators';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { AlertType } from '../../../custom.controls/growl/alert-type.enum';

const now = new Date();

@Component({
  selector: 'project-daily-log',
  templateUrl: './project.dailylog.component.html',
  styleUrls: ['../project.component.css']
})
export class ProjectDailyLogComponent implements OnInit, AfterViewInit {
  @ViewChild('projectComponents', { static: false, read: ViewContainerRef }) target: ViewContainerRef;

  @ViewChild('ngbDatepicker', { static: false }) dtPicker: NgbDatepicker;

  dynamicComponents: Array<IComponent> = new Array<IComponent>();

  @Input() projectId: string = null;
  @Input() permissionType: AccessLevel = null;
  @Input() dailyLogId: string = null;
  @Input() view: ComponentView = ComponentView.Normal;
  @Input() userId: string = null;

  originalPermission: AccessLevel = null;
  dailyLogComponent: DailyLogComponent;
  selectedDate: NgbDateStruct;
  routeSubscription: Subscription;
  routeDataSubscription: Subscription;
  approval: Approval;
  isPersisting = false;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private confirmService: ConfirmService,
    private route: ActivatedRoute,
    private projectService: UserProjectService,
    private dailyLogService: DailyLogService,
    private approvalService: ApprovalService,
    private toastr: ToastrService,
    private growlService: GrowlService
  ) {}

  ngOnInit() {
    if (this.view === ComponentView.Single || this.view === ComponentView.FinalApprover) {
      this.dailyLogService.GetDailyLogById(this.projectId, this.dailyLogId).subscribe(comp => {
        comp.DateTimeInfo = new Date(comp.DateTimeInfo);
        this.dailyLogComponent = comp;
        this.selectedDate = {
          year: comp.Year,
          month: comp.Month,
          day: comp.Day
        };
        this.getComponents(this.permissionType).subscribe(result => {});
      });
    } else {
      this.routeSubscription = this.route.params.subscribe(routeParams => {
        this.routeDataSubscription = this.route.data.subscribe(params => {
          this.originalPermission = params.permissionType;
          this.permissionType = params.permissionType;

          if (this.projectService.project === null || this.projectService.project === undefined) {
            this.route.parent.params.subscribe(params => {
              this.projectId = params.id;
            });
          } else {
            this.projectId = this.projectService.project.Id;
          }

          let nDate;

          if (routeParams.year != undefined) {
            nDate = new Date(routeParams.year, routeParams.month - 1, routeParams.day);
          } else {
            nDate = now;
          }

          this.selectedDate = {
            year: nDate.getFullYear(),
            month: nDate.getMonth() + 1,
            day: nDate.getDate()
          };

          this.initializeComponents();
        });
      });
    }
  }
  ngAfterViewInit(): void {}

  ngOnDestroy(): void {
    if (this.routeDataSubscription) {
      this.routeDataSubscription.unsubscribe();
    }

    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  setNoWork() {
    this.dailyLogComponent.NoWork = !this.dailyLogComponent.NoWork;
    this.saveDailyLog().subscribe(result => {
      if (this.dailyLogComponent.NoWork) {
        this.target.clear();
      } else {
        this.getComponents(this.permissionType).subscribe(result => {});
      }
    });
  }

  moveCalendar(num: number) {
    // this.dailyLogComponent.DateTimeInfo = new Date(this.dailyLogComponent.DateTimeInfo.getDate() + num);

    const cDate = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day + num, 0, 0, 0, 0);

    this.selectedDate = {
      year: cDate.getFullYear(),
      month: cDate.getMonth() + 1,
      day: cDate.getDate()
    };

    // this.router.navigateByUrl("/user/project/" + this.projectId + "/daily-log/" + this.selectedDate.year + "/" + this.selectedDate.month + "/" + this.selectedDate.day);
    // this.router.navigate(["/user", "project", this.projectId, "daily-log", this.selectedDate.year, this.selectedDate.month, this.selectedDate.day], { replaceUrl: false });

    this.initializeComponents();
  }

  selectToday() {
    this.dailyLogComponent.DateTimeInfo = new Date();
    this.selectedDate = {
      year: this.dailyLogComponent.DateTimeInfo.getFullYear(),
      month: this.dailyLogComponent.DateTimeInfo.getMonth() + 1,
      day: this.dailyLogComponent.DateTimeInfo.getDate()
    };

    // this.router.navigateByUrl("/user/project/" + this.projectId + "/daily-log/" + this.selectedDate.year + "/" + this.selectedDate.month + "/" + this.selectedDate.day);
    // this.router.navigate(["/user", "project", this.projectId, "daily-log", this.selectedDate.year, this.selectedDate.month, this.selectedDate.day], { replaceUrl: true, skipLocationChange: true });
    // this.router.navigate(["/user", "project", this.projectId, "daily-log", this.selectedDate.year, this.selectedDate.month, this.selectedDate.day], { replaceUrl: false });
    this.initializeComponents();
  }

  dateChanged() {
    this.dailyLogComponent.DateTimeInfo = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    this.dailyLogComponent.Year = this.selectedDate.year;
    this.dailyLogComponent.Month = this.selectedDate.month;
    this.dailyLogComponent.Day = this.selectedDate.day;
    // this.selectedDate = {
    //    year: this.dailyLogComponent.DateTimeInfo.getFullYear(),
    //    month: this.dailyLogComponent.DateTimeInfo.getMonth() + 1,
    //    day: this.dailyLogComponent.DateTimeInfo.getDate()
    // };

    // this.router.navigateByUrl("/user/project/" + this.projectId + "/daily-log/" + this.selectedDate.year + "/" + this.selectedDate.month + "/" + this.selectedDate.day);
    // this.router.navigate(["/user", "project", this.projectId, "daily-log", this.selectedDate.year, this.selectedDate.month, this.selectedDate.day], { replaceUrl: true, skipLocationChange: true });
    // this.router.navigate(["/user", "project", this.projectId, "daily-log", this.selectedDate.year, this.selectedDate.month, this.selectedDate.day], { replaceUrl: false });
    this.initializeComponents();
  }

  initializeComponents() {
    this.dailyLogService
      .GetDailyLog(this.projectId, this.selectedDate.year, this.selectedDate.month, this.selectedDate.day)
      .subscribe(log => {
        this.dailyLogComponent = log;
        this.approvalService.GetApproval(this.projectId, log.Id).subscribe(
          result => {
            this.approval = result;
            if (this.dailyLogComponent.Submit != null) {
              const aLevel = new AccessLevel();
              aLevel.AccessLevelId = 'readonly';
              this.permissionType = aLevel;
              this.dailyLogComponent.DateTimeInfo = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
              this.getComponents(aLevel).subscribe(result => {});
            } else {
              this.permissionType = this.originalPermission;
              this.dailyLogComponent.DateTimeInfo = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
              this.getComponents(this.originalPermission).subscribe(result => {});
            }
          },
          error => {
            this.growlService.addAlert(error._body, AlertType.DANGER);
          }
        );
      });
  }

  getComponents(accessLevel: AccessLevel): Observable<boolean> {
    return new Observable<boolean>(obs => {
      if (!this.dailyLogComponent.NoWork) {
        this.projectService.GetProjectSection(this.projectId, ComponentIdentifier.DAILY_LOG).subscribe(comp => {
          this.gatherComponent(comp as DailyLogComponent, accessLevel).subscribe(complet => {
            obs.next(true);
            obs.complete();
          });
        });
      } else {
        this.target.clear();
        obs.next(true);
        obs.complete();
      }
    });
  }

  gatherComponent(comp: DailyLogComponent, accessLevel: AccessLevel): Observable<boolean> {
    const obs = new Subject<boolean>();
    this.target.clear();

    this.dynamicComponents = [];

    for (const c of comp.Components) {
      switch (c.ComponentIdentifier.toLowerCase()) {
        case ComponentIdentifier.TIMECARD.toLowerCase():
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TimeCardComponentView);
          const timeCard = this.target.createComponent(componentFactory).instance as TimeCardComponentView;
          timeCard.dateInfo = this.dailyLogComponent.DateTimeInfo;
          timeCard.permissionType = accessLevel;
          timeCard.projectId = this.projectId;
          timeCard.view = this.view;
          timeCard.userId = this.userId;
          timeCard.year = this.selectedDate.year;
          timeCard.month = this.selectedDate.month;
          timeCard.day = this.selectedDate.day;
          this.dynamicComponents.push(timeCard);
          break;
        case ComponentIdentifier.WEATHER.toLowerCase():
          const weatherCompFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectWeatherComponent);
          const weatherComp = this.target.createComponent(weatherCompFactory).instance as ProjectWeatherComponent;
          weatherComp.dateInfo = this.dailyLogComponent.DateTimeInfo;
          weatherComp.permissionType = accessLevel;
          weatherComp.projectId = this.projectId;
          weatherComp.view = this.view;
          weatherComp.userId = this.userId;
          weatherComp.year = this.selectedDate.year;
          weatherComp.month = this.selectedDate.month;
          weatherComp.day = this.selectedDate.day;
          this.dynamicComponents.push(weatherComp);
          break;
        case ComponentIdentifier.SITE_CONDITIONS.toLowerCase():
          const siteConditionCompFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectSiteConditionsComponent);
          const siteComp = this.target.createComponent(siteConditionCompFactory).instance as ProjectSiteConditionsComponent;
          siteComp.dateInfo = this.dailyLogComponent.DateTimeInfo;
          siteComp.permissionType = accessLevel;
          siteComp.projectId = this.projectId;
          siteComp.view = this.view;
          siteComp.userId = this.userId;
          siteComp.year = this.selectedDate.year;
          siteComp.month = this.selectedDate.month;
          siteComp.day = this.selectedDate.day;
          this.dynamicComponents.push(siteComp);
          break;
        case ComponentIdentifier.NOTES.toLowerCase():
          const notesCompFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectNotesComponent);
          const notesComp = this.target.createComponent(notesCompFactory).instance as ProjectNotesComponent;
          notesComp.dateInfo = this.dailyLogComponent.DateTimeInfo;
          notesComp.permissionType = accessLevel;
          notesComp.projectId = this.projectId;
          notesComp.view = this.view;
          notesComp.userId = this.userId;
          notesComp.year = this.selectedDate.year;
          notesComp.month = this.selectedDate.month;
          notesComp.day = this.selectedDate.day;
          this.dynamicComponents.push(notesComp);
          break;
        case ComponentIdentifier.PHOTOS.toLowerCase():
          const photosCompFactory = this.componentFactoryResolver.resolveComponentFactory(ProjectPhotosComponent);
          const photosComp = this.target.createComponent(photosCompFactory).instance as ProjectPhotosComponent;
          photosComp.dateInfo = this.dailyLogComponent.DateTimeInfo;
          photosComp.permissionType = accessLevel;
          photosComp.projectId = this.projectId;
          photosComp.view = this.view;
          photosComp.userId = this.userId;
          photosComp.year = this.selectedDate.year;
          photosComp.month = this.selectedDate.month;
          photosComp.day = this.selectedDate.day;
          this.dynamicComponents.push(photosComp);
          break;
        default:
          this.toastr.error('The component ' + c.ComponentIdentifier + ' cannot be accessed at this time.');
          break;
      }
    }

    obs.next(true);
    obs.complete();

    return obs.asObservable();
  }

  save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.saveDailyLog().subscribe(
        result => {
          const f = Array();

          for (const c of this.dynamicComponents) {
            f.push(c.Save());
          }

          Observable.forkJoin(f).subscribe(
            result => {
              obs.next(true);
              obs.complete();
            },
            err => {
              obs.error();
            },
            () => {
              obs.next(true);
              obs.complete();
            }
          );
        },
        err => {
          obs.error(err);
        }
      );
    });
  }

  saveAll() {
    this.isPersisting = true;

    this.save().subscribe(
      result => {
        this.isPersisting = false;
        this.toastr.success('Daily log saved successfully');
      },
      err => {
        this.isPersisting = false;
        this.toastr.error('There was an issue during the save.');
      }
    );
  }

  saveDailyLog(): Observable<boolean> {
    const obs = new Subject<boolean>();

    if (this.view == ComponentView.FinalApprover) {
      Observable.timer(100).subscribe(result => {
        obs.next(true);
        obs.complete();
      });
    } else {
      this.dailyLogComponent.Year = this.selectedDate.year;
      this.dailyLogComponent.Month = this.selectedDate.month;
      this.dailyLogComponent.Day = this.selectedDate.day;
      this.dailyLogService.SaveDailyLog(this.projectId, this.dailyLogComponent).subscribe(
        dLog => {
          obs.next(true);
          obs.complete();
        },
        err => {
          this.toastr.error('There was an issue saving the daily log component.');
          obs.error(err);
        }
      );
    }

    return obs.asObservable();
  }

  submit() {
    const validations = Array<Observable<ValidationResult>>();
    this.isPersisting = true;

    for (const c of this.dynamicComponents) {
      validations.push(c.Validate());
    }

    Observable.forkJoin(validations).subscribe(
      result => {
        let isvalid = true;
        if (!this.dailyLogComponent.NoWork) {
          for (const val of result) {
            if (!val.IsValid) {
              isvalid = false;
              this.toastr.error(val.Message);
              break;
            }
          }
        }

        if (isvalid) {
          this.confirmService.open('You are submitting this daily log as complete. Are you sure you are ready to do this?').result.then(
            item => {
              this.save().subscribe(
                result => {
                  if (result) {
                    this.dailyLogService.SubmitDailyLog(this.projectId, this.dailyLogComponent.Id).subscribe(
                      result => {
                        this.dailyLogComponent.Submit = result;

                        const date = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);

                        this.approvalService.AddApproval(this.projectId, this.dailyLogComponent.Id, date).subscribe(
                          approval => {
                            this.toastr.success('Daily Log has been submitted');
                            this.initializeComponents();
                            this.isPersisting = false;
                          },
                          err => {
                            this.isPersisting = false;
                          }
                        );
                      },
                      err => {
                        this.toastr.error('There was an issue submitting the daily log component.');
                        this.isPersisting = false;
                      }
                    );
                  } else {
                    this.isPersisting = false;
                  }
                },
                err => {
                  this.isPersisting = false;
                }
              );
            },
            rejected => {
              console.log('No Close');
              this.isPersisting = false;
            }
          );
        } else {
          this.isPersisting = false;
        }
      },
      err => {
        this.isPersisting = false;
      }
    );
  }

  validateComponents(): Observable<boolean> {
    this.isPersisting = true;
    return new Observable<boolean>(obs => {
      const isvalid = true;
      const validations = Array<Observable<ValidationResult>>();
      this.isPersisting = true;

      for (const c of this.dynamicComponents) {
        validations.push(c.Validate());
      }

      Observable.forkJoin(validations).subscribe(
        result => {
          let isvalid = true;
          if (!this.dailyLogComponent.NoWork) {
            for (const val of result) {
              if (!val.IsValid) {
                isvalid = false;
                this.toastr.error(val.Message);
                break;
              }
            }
          }

          obs.next(isvalid);
          obs.complete();
          this.isPersisting = false;
        },
        err => {
          this.isPersisting = false;
        }
      );
    });
  }

  unSubmit() {
    this.isPersisting = true;
    this.confirmService.open('Are you sure you want to unsubmit this time card?').result.then(
      item => {
        this.dailyLogService.UnSubmitDailyLog(this.projectId, this.dailyLogComponent.Id).subscribe(
          result => {
            this.approvalService.RemoveApproval(this.projectId, this.dailyLogComponent.Id).subscribe(
              result => {
                this.toastr.success('Daily Log has been un-submitted');
                this.initializeComponents();
                this.isPersisting = false;
              },
              err => {
                this.isPersisting = false;
              }
            );
          },
          err => {
            this.isPersisting = false;
          }
        );
      },
      err => {
        this.isPersisting = false;
      }
    );
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      const vResult = new ValidationResult();
      vResult.IsValid = true;
      obs.next(vResult);
      obs.complete();
    });
  }

  clearComponents() {
    this.clear().subscribe(result => {
      if (!result) {
        this.toastr.warning('There may have been an issue clear all items in the daily log.');
      }
    });
  }

  clear(): Observable<boolean> {
    if (this.dynamicComponents.length > 0) {
      const obs = new Subject<boolean>();
      const validations = Array<Observable<boolean>>();

      for (const c of this.dynamicComponents) {
        validations.push(c.Clear());
      }

      Observable.forkJoin(validations).subscribe(result => {
        let hasIssue = false;
        for (const item of result) {
          if (!result) {
            hasIssue = true;
            break;
          }
        }

        if (hasIssue) {
          obs.next(false);
        } else {
          obs.next(true);
        }

        obs.complete();
      });

      return obs.asObservable();
    } else {
      return Observable.of(true);
    }
  }

  remove() {
    this.isPersisting = true;
    const confirm = this.confirmService.open('Are you sure you would like to delete this daily log?').result.then(
      result => {
        // var validations = Array<Observable<boolean>>();

        // for (let c of this.dynamicComponents) {
        //    validations.push(c.Remove());
        // }

        const obs = Observable.range(0, this.dynamicComponents.length);
        const hasIssue = false;

        obs
          .flatMap((idx: number) => {
            return this.dynamicComponents[idx].Remove();
          })
          .take(1)
          .subscribe(
            result => {
              if (hasIssue) {
                this.toastr.warning('There was an issue removing a section of the daily log.');
              }
            },
            err => {
              this.isPersisting = false;
            },
            () => {
              this.isPersisting = false;
              this.toastr.success('Daily log has been deleted.');
            }
          );
      },
      err => {
        this.isPersisting = false;
      }
    );
  }
}
