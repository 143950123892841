import { Component, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FileUploader, FileItem } from 'ng2-file-upload';
import * as UserApi from '../../../user/models';
import { EquipmentService } from '../../services/equipment.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'modal-equipment-import',
  templateUrl: './equipment.import.modal.html'
})
export class EquipmentImportModal implements OnInit {
  public uploader: FileUploader;
  templateUrl: string;

  constructor(private activeModal: NgbActiveModal, private authService: AuthService, private equipmentService: EquipmentService) {}
  ngOnInit(): void {
    const URL = environment.api + '/user/equipment/EquipmentImport';
    this.authService.getAccessToken().subscribe(result => {
      this.uploader = new FileUploader({ url: URL, authToken: 'Bearer ' + result });
      this.uploader.options.autoUpload = true;
      this.uploader.onBeforeUploadItem = (item: FileItem) => {
        console.log(item);
        item.headers.push('Bearer ' + result);
      };

      this.uploader.onCompleteItem = (item: FileItem, response: any, status: number) => {
        this.activeModal.close(JSON.parse(response) as UserApi.EquipmentItem[]);
      };
    });
  }

  import() {}

  close() {
    this.activeModal.dismiss();
  }
}
