import pica from 'pica/dist/pica';
import { Observable } from 'rxjs/Observable';
import { Subject, Subscription, BehaviorSubject } from 'rxjs';
import { CivCastPicaErrorInterface, CivCastPicaErrorType } from './pica.error.interface';
import { Injectable } from '@angular/core';
import { CivCastPicaExifService } from './pica.exif.service';
import { CivCastPicaResizeOptionsInterface } from './pica.resize.option.interface';

@Injectable()
export class CivCastPicaService {
  private picaCompressor = new pica();
  private MAX_STEPS: number = 20;

  constructor(private _civCastPicaExifService: CivCastPicaExifService) {}

  public resizeImages(files: File[], width: number, height: number, autoRotate: boolean): Observable<File> {
    const resizedImage: Subject<File> = new Subject();
    const totalFiles: number = files.length;

    if (totalFiles > 0) {
      const nextFile: Subject<File> = new Subject();
      let index: number = 0;

      const subscription: Subscription = nextFile.subscribe((file: File) => {
        this.resizeImage(file, width, height, autoRotate).subscribe(
          imageResized => {
            index++;

            resizedImage.next(imageResized);

            if (index < totalFiles) {
              nextFile.next(files[index]);
            } else {
              resizedImage.complete();
              subscription.unsubscribe();
            }
          },
          err => {
            const picaError: CivCastPicaErrorInterface = {
              err: err,
              file: file
            };

            resizedImage.error(picaError);
          }
        );
      });

      nextFile.next(files[index]);
    } else {
      const picaError: CivCastPicaErrorInterface = {
        err: CivCastPicaErrorType.NO_FILES_RECEIVED
      };

      resizedImage.error(picaError);
      resizedImage.complete();
    }

    return resizedImage.asObservable();
  }

  public resizeImage(file: File, width: number, height: number, autoRotate: boolean, options?: CivCastPicaResizeOptionsInterface): Observable<File> {
    const resizedImage: Subject<File> = new Subject();
    const originCanvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx = originCanvas.getContext('2d');
    const img = new Image();
    img.src = window.URL.createObjectURL(file);
    if (ctx) {
      img.onload = () => {
        if (autoRotate) {
          this._civCastPicaExifService.getExifOrientedImagePromise(img).subscribe(orientedImage => {
            window.URL.revokeObjectURL(img.src);
            originCanvas.width = orientedImage.width;
            originCanvas.height = orientedImage.height;

            ctx.drawImage(orientedImage, 0, 0);

            let imageData = ctx.getImageData(0, 0, orientedImage.width, orientedImage.height);
            if (options && options.aspectRatio && options.aspectRatio.keepAspectRatio) {
              let ratio = 0;

              if (options.aspectRatio.forceMinDimensions) {
                ratio = Math.max(width / imageData.width, height / imageData.height);
              } else {
                ratio = Math.min(width / imageData.width, height / imageData.height);
              }

              width = Math.round(imageData.width * ratio);
              height = Math.round(imageData.height * ratio);
            }

            const destinationCanvas: HTMLCanvasElement = document.createElement('canvas');
            destinationCanvas.width = width;
            destinationCanvas.height = height;

            this.picaResize(file, originCanvas, destinationCanvas, options)
              .catch(err => resizedImage.error(err))
              .then((imgResized: File) => {
                resizedImage.next(imgResized);
                resizedImage.complete();
              });
          });
        } else {
          originCanvas.width = img.width;
          originCanvas.height = img.height;

          ctx.drawImage(img, 0, 0);

          let imageData = ctx.getImageData(0, 0, img.width, img.height);
          if (options && options.aspectRatio && options.aspectRatio.keepAspectRatio) {
            let ratio = 0;

            if (options.aspectRatio.forceMinDimensions) {
              ratio = Math.max(width / imageData.width, height / imageData.height);
            } else {
              ratio = Math.min(width / imageData.width, height / imageData.height);
            }

            width = Math.round(imageData.width * ratio);
            height = Math.round(imageData.height * ratio);
          }

          const destinationCanvas: HTMLCanvasElement = document.createElement('canvas');
          destinationCanvas.width = width;
          destinationCanvas.height = height;

          this.picaResize(file, originCanvas, destinationCanvas, options)
            .catch(err => resizedImage.error(err))
            .then((imgResized: File) => {
              resizedImage.next(imgResized);
              resizedImage.complete();
            });
        }
      };
    } else {
      resizedImage.error(CivCastPicaErrorType.CANVAS_CONTEXT_IDENTIFIER_NOT_SUPPORTED);
    }

    return resizedImage.asObservable();
  }

  public compressImages(files: File[], sizeInMB: number, autoRotate: boolean): Observable<File> {
    const compressedImage: Subject<File> = new Subject();
    const totalFiles: number = files.length;

    if (totalFiles > 0) {
      const nextFile: Subject<File> = new Subject();
      let index: number = 0;

      const subscription: Subscription = nextFile.subscribe((file: File) => {
        this.compressImage(file, sizeInMB, true).subscribe(
          imageCompressed => {
            index++;
            compressedImage.next(imageCompressed);

            if (index < totalFiles) {
              nextFile.next(files[index]);
            } else {
              compressedImage.complete();
              subscription.unsubscribe();
            }
          },
          err => {
            const ngxPicaError: CivCastPicaErrorInterface = {
              file: file,
              err: err
            };

            compressedImage.error(ngxPicaError);
          }
        );
      });

      nextFile.next(files[index]);
    } else {
      const ngxPicaError: CivCastPicaErrorInterface = {
        err: CivCastPicaErrorType.NO_FILES_RECEIVED
      };

      compressedImage.error(ngxPicaError);
      compressedImage.complete();
    }

    return compressedImage.asObservable();
  }

  public compressImage(file: File, sizeInMB: number, autoRotate: boolean): Observable<File> {
    const compressedImage: Subject<File> = new Subject();

    if (this.bytesToMB(file.size) <= sizeInMB) {
      setTimeout(() => {
        return new BehaviorSubject<File>(file).asObservable();
      }, 0);
    } else if (file.size > 3000000 && file.size < 10000000) {
      sizeInMB = 1; //this should be enough space to compress a large file
      //compressedImage.error(new Error("File too large to process"));
    } else if (file.size >= 10000000) {
      sizeInMB = 2;
    }

    const originCanvas: HTMLCanvasElement = document.createElement('canvas');
    const ctx = originCanvas.getContext('2d');
    const img = new Image();

    if (ctx) {
      img.onload = () => {
        if (autoRotate) {
          this._civCastPicaExifService.getExifOrientedImagePromise(img).subscribe(orientedImage => {
            window.URL.revokeObjectURL(img.src);
            originCanvas.width = orientedImage.width;
            originCanvas.height = orientedImage.height;

            ctx.drawImage(orientedImage, 0, 0);

            this.getCompressedImage(originCanvas, file.type, 1, sizeInMB, 0)
              .catch(err => compressedImage.error(err))
              .then((blob: Blob) => {
                let imgCompressed: File = this.blobToFile(blob, file.name, file.type, new Date().getTime());

                compressedImage.next(imgCompressed);
              });
          });
        } else {
          originCanvas.width = img.width;
          originCanvas.height = img.height;

          ctx.drawImage(img, 0, 0);

          this.getCompressedImage(originCanvas, file.type, 1, sizeInMB, 0)
            .catch(err => compressedImage.error(err))
            .then((blob: Blob) => {
              let imgCompressed: File = this.blobToFile(blob, file.name, file.type, new Date().getTime());

              compressedImage.next(imgCompressed);
            });
        }
      };

      img.src = window.URL.createObjectURL(file);
    } else {
      compressedImage.error(CivCastPicaErrorType.CANVAS_CONTEXT_IDENTIFIER_NOT_SUPPORTED);
    }

    return compressedImage.asObservable();
  }

  private getCompressedImage(canvas: HTMLCanvasElement, type: string, quality: number, sizeInMB: number, step: number): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      this.picaCompressor
        .toBlob(canvas, type, quality)
        .catch(err => reject(err))
        .then((blob: Blob) => {
          this.checkCompressedImageSize(canvas, blob, quality, sizeInMB, step)
            .catch(err => reject(err))
            .then((blob: Blob) => {
              resolve(blob);
            });
        });
    });
  }
  private checkCompressedImageSize(canvas: HTMLCanvasElement, blob: Blob, quality: number, sizeInMB: number, step: number): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      if (step > this.MAX_STEPS) {
        reject(CivCastPicaErrorType.NOT_BE_ABLE_TO_COMPRESS_ENOUGH);
      }

      if (this.bytesToMB(blob.size) < sizeInMB) {
        resolve(blob);
      } else {
        const newQuality: number = quality - quality * 0.1;
        const newStep: number = step + 1;

        // recursively compression
        resolve(this.getCompressedImage(canvas, blob.type, newQuality, sizeInMB, newStep));
      }
    });
  }

  private picaResize(file: File, from: HTMLCanvasElement, to: HTMLCanvasElement, options: any): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      this.picaCompressor
        .resize(from, to, options)
        .catch(err => reject(err))
        .then((resizedCanvas: HTMLCanvasElement) => this.picaCompressor.toBlob(resizedCanvas, file.type))
        .then((blob: Blob) => {
          let fileResized: File = this.blobToFile(blob, file.name, file.type, new Date().getTime());
          resolve(fileResized);
        });
    });
  }

  private blobToFile(blob: Blob, name: string, type: string, lastModified: number): File {
    var b: any = blob;
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    b.lastModifiedDate = lastModified;
    b.name = name;

    //Cast to a File() type
    return <File>b;
    //return new File([blob], name, { type: type, lastModified: lastModified });
  }

  private bytesToMB(bytes: number) {
    return bytes / 1048576;
  }
}
