import { Injectable, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmModalComponent } from './confirm.modal.component';

@Injectable()
export class ConfirmService {
  @Output() close: EventEmitter<any> = new EventEmitter();
  //private modalRef;
  constructor(private modal: NgbModal) {}

  open(question: string): NgbModalRef {
    var ref = this.modal.open(ConfirmModalComponent, { size: 'lg' });
    ref.componentInstance.confirmQuestion = question;
    return ref;
  }
}
