import { Injectable, EventEmitter } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessControlService } from './services/access.control.service';
import { AccessLevelTypes } from './models/accesslevel.enum';
import { TeamService } from '../user.project/components/team/team.service';

@Injectable()
export class ProjectPermissionGuardService implements CanActivate {
  constructor(private accessControlServer: AccessControlService, private teamService: TeamService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(obs => {
      var projectId = null;
      if (route.params.id) {
        projectId = route.params.id;
      } else if (route.parent.params.id) {
        projectId = route.parent.params.id;
      } else if (route.parent.parent.params.id) {
        projectId = route.parent.parent.params.id;
      } else if (route.parent.parent.parent.params.id) {
        projectId = route.parent.parent.parent.params.id;
      } else if (route.parent.parent.parent.parent.params.id) {
        projectId = route.parent.parent.parent.parent.params.id;
      }

      var permissionId = route.data['permissionId'] as string;
      var permissionGroup = route.data['permissionGroup'] as string;
      this.accessControlServer.GetProjectAccessLevel(projectId, permissionId, permissionGroup).subscribe(result => {
        if (result.AccessLevelId === AccessLevelTypes.DENY) {
          this.router.navigate(['/user', 'project', projectId, 'unauthorized']);
          obs.next(false);
        } else if (result.AccessLevelId === AccessLevelTypes.READ_ONLY) {
          this.teamService.setReadOnlyProp(true);
          obs.next(true);
        } else if (result.AccessLevelId === AccessLevelTypes.FULL_ACCESS) {
          obs.next(true);
        } else {
          this.router.navigate(['/user', 'project', projectId, 'unauthorized']);
          obs.next(false);
        }

        obs.complete();
      });
    });
  }
}
