import { Injectable, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs';
import * as EXIF from '@fengyuanchen/exif';

@Injectable()
export class CivCastPicaExifService implements OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  public getExifOrientedImagePromise(image: HTMLImageElement): Observable<HTMLImageElement> {
    return new Observable<HTMLImageElement>(result => {
      this.getOrientation(image)
        .takeUntil(this.unsubscribe)
        .subscribe(
          orientation => {
            const exifOrientation = orientation;

            if (exifOrientation) {
              const canvas: HTMLCanvasElement = document.createElement('canvas'),
                ctx = canvas.getContext('2d'),
                width = image.width,
                height = image.height;

              if ([5, 6, 7, 8].indexOf(exifOrientation) > -1) {
                canvas.width = height;
                canvas.height = width;
              } else {
                canvas.width = width;
                canvas.height = height;
              }

              if (ctx) {
                // transform context before drawing image
                switch (exifOrientation) {
                  case 2:
                    ctx.transform(-1, 0, 0, 1, width, 0);
                    break;
                  case 3:
                    ctx.transform(-1, 0, 0, -1, width, height);
                    break;
                  case 4:
                    ctx.transform(1, 0, 0, -1, 0, height);
                    break;
                  case 5:
                    ctx.transform(0, 1, 1, 0, 0, 0);
                    break;
                  case 6:
                    ctx.transform(0, 1, -1, 0, height, 0);
                    break;
                  case 7:
                    ctx.transform(0, -1, -1, 0, height, width);
                    break;
                  case 8:
                    ctx.transform(0, -1, 1, 0, 0, width);
                    break;
                  default:
                    ctx.transform(1, 0, 0, 1, 0, 0);
                }

                // Draw img into canvas
                ctx.drawImage(image, 0, 0, width, height);

                const img = new Image();

                if ([5, 6, 7, 8].indexOf(exifOrientation) > -1) {
                  img.width = height;
                  img.height = width;
                } else {
                  img.width = width;
                  img.height = height;
                }

                img.onload = () => {
                  result.next(img);
                  result.complete();
                };
                var quality = 0.7;
                img.src = canvas.toDataURL('image/jpeg', quality);
              }
            } else {
              result.next(image);
              result.complete();
            }
          },
          err => {
            result.next(image);
            result.complete();
          }
        );
    });
  }

  public getAllTags(image: HTMLImageElement): Observable<any> {
    var imageTags = new Subject<any>();

    var ex = new EXIF(image, {
      done: function (tags) {
        imageTags.next(tags);
        imageTags.complete();
      },
      fail: function (message) {
        imageTags.error(message);
        imageTags.complete();
      }
    });

    return imageTags.asObservable();
  }

  public getOrientation(image: HTMLImageElement): Observable<number> {
    var orientationTag = new Subject<number>();

    var ex = new EXIF(image, {
      done: function (tags) {
        var o = tags.Orientation;
        orientationTag.next(o);
        orientationTag.complete();
      },
      fail: function (message) {
        orientationTag.error(message);
        orientationTag.complete();
      }
    });

    return orientationTag.asObservable();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
