//Global
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
//Modules
import { UserModule } from '../user/user.module';
import { DndModule } from 'ng2-dnd';
import { InlineEditorModule } from '@qontu/ngx-inline-editor';
import { CivCastCommonControlsModule } from '../custom.controls/custom.controls.module';
import { CivCastFormModule } from '../civcast.form/civcast.form.module';
import { FileUploadModule } from 'ng2-file-upload';
import { Select2Module } from 'ng2-select2';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from 'ngx-loading';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ToastrModule } from 'ngx-toastr';
import { CivCastImageModule } from '../custom.controls/image/civcast.image.module';
import { UserTimeCardModule } from '../user.project.timecard/user.timecard.module';
import { HttpClientModule } from '@angular/common/http';

//Components
import { ProjectCostCodesComponent } from './components/costcodes/project.costcodes.component';
import { ProjectListComponent } from './components/projectlist.component';
import { ProjectComponent } from './components/project.component';
import { ProjectDashboardComponent } from './components/dashboard/project.dashboard.component';
import { ProjectDailyLogComponent } from './components/dailylog/project.dailylog.component';
import { ProjectBidItemsComponent } from './components/biditems/project.biditems.component';
import { ProjectPlanRoomComponent } from './components/planroom/project.planroom.component';
import { ProjectNewComponent } from './components/settings/project.new.component';
import { ProjectSettingsCrewsComponent } from './components/settings/areas/project.settings.crews.component';
import { ProjectSettingsSideNavigationComponent } from './components/settings/areas/project.settings.sidenavigation.component';
import { ProjectSettingsThemeComponent } from './components/settings/areas/project.settings.theme.component';
import { ProjectTeamComponent } from './components/team/project.team.component';
import { ProjectVendorLibraryComponent } from './components/vendorlibrary/project.vendorlibrary.component';
import { ProjectSidenavComponent } from './components/project.sidenav.component';
import { ProjectActivityComponent } from './components/activity/project.activity.component';
import { ProjectWeatherComponent } from './components/weather/weather.component';
import { ProjectSettingsComponent } from './components/settings/project.settings.component';
import { DailyLogSettingsComponent } from './components/dailylog/dailylog.settings.component';
import { SettingsListComponent } from './components/settings/settings.list.component';
import { SettingsSub1Component } from './components/settings/settings.sub1.component';
import { SettingsLocalComponent } from './components/settings/settings.local.component';
import { WeatherSettingsComponent } from './components/weather/weather.settings.component';
import { SettingsParentComponent } from './components/settings/settings.parent.component';
import { TimeReasonsSettingsComponent } from '../user.project.timecard/time-reasons-settings/time-reasons-settings.component';

//services
import { UserProjectService } from './user.project.service';
import { TeamService } from './components/team/team.service';
import { ProjectActivityService } from './components/activity/project.activity.service';
import { WeatherService } from './components/weather/weather.service';
import { DailyLogService } from './components/dailylog/dailylog.service';
import { SettingsService } from './components/settings/settings.service';

//Modals
import { ModalSaveProject } from './components/modal.saveproject.component';
import { ModalCostCodesImport } from './components/costcodes/project.costcodes.import.modal';
import { ModalAddCostCode } from './components/costcodes/project.costcodes.add.modal';
import { ModalProjectSideNav } from './components/settings/project.sidenav.modal';
import { TeamMemberEditModal } from './components/team/teammember.edit.modal';
import { ProjectSiteConditionsComponent } from './components/siteconditions/site.conditions.component';
import { SiteConditionsService } from './components/siteconditions/site.conditions.service';
import { ProjectNotesComponent } from './components/notes/notes.component';
import { NotesService } from './components/notes/notes.service';
import { SiteConditionsSettingsComponent } from './components/siteconditions/site.conditions.settings.component';
import { ProjectInfoComponent } from './components/info/project.info.component';
import { ProjectInfoSettingsComponent } from './components/info/project.info.settings.component';
import { InfoService } from './components/info/info.service';
import { TeamUnauthorizedComponent } from './components/team/team.unauthorized.component';
import { TeamGuardService } from './components/team/team.guard.service';
import { TeamPermissionGuardService } from './components/team/team.permission.guard.service';
import { UserBaseComponent } from '../user/user.base.component';
import { ProjectInfoInputComponent } from './components/info/project.info.input.component';
import { ProjectSettingsDelegatesComponent } from '../user/components/delegation/project.settings.delegates.component';
import { UserClassficationsComponent } from '../user/components/delegation/user.classifications.component';
import { ActivityHistoryComponent } from '../user/components/history/activity.history.component';
import { EquipmentComponent } from '../user/components/Equipment/equipment.component';
import { EmployeeComponent } from '../user/components/employee/employee.component';
import { SettingsComponent } from '../user/settings/settings.component';
import { TimeCardApprovalComponent } from './components/timecard.approval/timecard.approval.component';
import { TeamPermissionResolve } from './components/team/team.permission.resolve';
import { TeamSettingsComponent } from './components/team/team.settings.component';
import { ManageUserPermissionComponent } from '../user.permissions/components/user/manage.user.component';
import { UserBasePermissionComponent } from '../user.permissions/components/user.base.permission.component';
import { CreatePolicyComponent } from '../user.permissions/components/policy/create.policy.component';
import { ManagePolicyComponent } from '../user.permissions/components/policy/manage.policy.component';
import { UserPermissionsModule } from '../user.permissions/user.permissions.module';
import { DelegateResolve } from '../user/resolves/delegate.resolve';
import { PermissionGuardService } from '../user.permissions/permission.guard.service';
import { PermissionResolve } from '../user.permissions/permission.resolve';
import { ProjectPermissionGuardService } from '../user.permissions/project.permission.guard.service';
import { ProjectPermissionResolve } from '../user.permissions/project.permission.resolve';
import { NgPipesModule } from 'ngx-pipes';
import { DailyLogModalComponent } from './components/dailylog/dailylog.modal.component';
import { UserChangeDelegationComponent } from '../user/components/user.changedelegation';
import { CostCodeService } from './components/costcodes/costcode.service';
import { ReportsComponent } from './components/report/report.component';
import { ReportsService } from './components/report/report.service';
import { DailyLogReportComponent } from './components/report/components/dailylog.report.component';
import { DailyLogSettingsBaseComponent } from './components/dailylog/dailylog.settings.base.component';
import { DelegateSettingsComponent } from '../user/components/delegation/delegate.settings.component';
import { UserReportsComponent } from '../user/components/reports/user.reports.component';
import { ProjectPhotosComponent } from './components/photos/photos.component';
import { PhotosUploadComponent } from './components/photos/photos.upload.component';
import { PhotosViewComponent } from './components/photos/photos.view.component';
import { PhotosAllComponent } from './components/photos/photos.all.component';
import { PhotosService } from './components/photos/photos.service';
import { ApprovalService } from '../user/approval/approval.service';
import { ApprovalHistoryModalComponent } from '../user/approval/approvalhistory.modal.component';
import { UserApprovalViewComponent } from '../user/approval/approval.view.component';
import { TimeCardComponentView } from '../user.project.timecard/timecard.component';
import { FilesComponent } from './components/files/files.component';
import { PunchListComponent } from './components/punchlist/punchlist.component';
import { AuthGuard } from 'src/app/guards/auth.guard';
import { UnauthorizedComponent } from 'src/app/components/unauthorized/auth.unauthorized.component';

//pipes

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    InlineEditorModule,
    ReactiveFormsModule,
    NgPipesModule,
    NgbModule,
    HttpClientModule,
    DndModule.forRoot(),
    UserPermissionsModule.forRoot(),
    CivCastFormModule.forRoot(),
    UserModule.forRoot(),
    UserTimeCardModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    CivCastCommonControlsModule,
    UserTimeCardModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.rectangleBounce,
      backdropBackgroundColour: 'rgba(0, 79, 150, 0.8)'
    }),
    Select2Module,
    RouterModule.forRoot([
      {
        path: 'user',
        component: UserBaseComponent,
        canActivate: [AuthGuard],
        children: [
          {
            path: 'approvals',
            component: UserApprovalViewComponent,
            canActivate: [PermissionGuardService],
            data: { permissionGroup: 'global', permissionId: 'global::approvals' },
            resolve: { permissionType: PermissionResolve }
          },
          { path: 'change-delegation', component: UserChangeDelegationComponent },
          {
            path: 'project-list',
            component: ProjectListComponent
          },
          { path: 'project-new', component: ProjectNewComponent },
          { path: 'team-unauthorized', component: TeamUnauthorizedComponent },
          {
            path: 'reports',
            component: UserReportsComponent
          },
          {
            path: 'settings-delegates',
            component: ProjectSettingsDelegatesComponent,
            canActivate: [PermissionGuardService],
            data: { permissionGroup: 'global', permissionId: 'global::managedelegates' },
            resolve: { permissionType: PermissionResolve }
          },
          {
            path: ':delegateId/delegate',
            component: DelegateSettingsComponent,
            canActivate: [PermissionGuardService],
            data: { permissionGroup: 'global', permissionId: 'global::managedelegates' },
            resolve: { permissionType: PermissionResolve }
          },
          {
            path: 'settings-classifications',
            component: UserClassficationsComponent,
            canActivate: [PermissionGuardService],
            data: { permissionGroup: 'global', permissionId: 'global::classifications', PageTitle: 'Classifications' },
            resolve: { permissionType: PermissionResolve }
          },
          {
            path: 'activity',
            component: ActivityHistoryComponent,
            canActivate: [PermissionGuardService],
            data: { permissionGroup: 'global', permissionId: 'global::activity', PageTitle: 'Activity' },
            resolve: { permissionType: PermissionResolve }
          },
          {
            path: 'settings-equipment',
            component: EquipmentComponent,
            canActivate: [PermissionGuardService],
            data: { permissionGroup: 'global', permissionId: 'global::equipment', PageTitle: 'Equipment' },
            resolve: { permissionType: PermissionResolve }
          },
          {
            path: 'settings-employee-setup',
            component: EmployeeComponent,
            canActivate: [PermissionGuardService],
            data: { permissionGroup: 'global', permissionId: 'global::employees', PageTitle: 'Crews' },
            resolve: { permissionType: PermissionResolve }
          },
          { path: 'settings', component: SettingsComponent },
          {
            path: 'crews',
            component: ProjectSettingsCrewsComponent,
            canActivate: [PermissionGuardService],
            data: { permissionGroup: 'global', permissionId: 'global::employees', PageTitle: 'Crews' },
            resolve: { permissionType: PermissionResolve }
          },
          {
            path: 'project-settings',
            component: SettingsLocalComponent,
            data: {
              PageTitle: 'New Project Settings',
              ComponentType: 'global',
              PageInstructions:
                'Choose the features you want in your project menu. Customize features. Drag and drop to arrange the order. Each new project you create will inherit these settings. Settings can be edited on a per project basis after the project is created.'
            },
            children: [
              {
                path: 'menu',
                component: SettingsListComponent
              },
              {
                path: 'tools',
                canActivate: [PermissionGuardService],
                data: { permissionGroup: 'global', permissionId: 'global::pstools::hsa', PageTitle: 'Info hide/show/arrage' },
                resolve: { permissionType: PermissionResolve },
                component: ProjectSettingsComponent
              },
              {
                path: 'info',
                canActivate: [PermissionGuardService],
                data: { permissionGroup: 'global', permissionId: 'global::psinfo::hsa', PageTitle: 'Info hide/show/arrage' },
                resolve: { permissionType: PermissionResolve },
                component: ProjectInfoSettingsComponent
              },
              {
                path: 'dailylog',
                component: DailyLogSettingsBaseComponent,
                children: [
                  {
                    path: 'menu',
                    canActivate: [PermissionGuardService],
                    data: { permissionGroup: 'global', permissionId: 'global::psdailylog::hsa', PageTitle: 'Daily Log hide/show/arrage' },
                    resolve: { permissionType: PermissionResolve },
                    component: DailyLogSettingsComponent
                  },
                  {
                    path: 'weather',
                    canActivate: [PermissionGuardService],
                    data: { permissionGroup: 'global', permissionId: 'global::psdailylog::weather', PageTitle: 'Daily Log - Weather' },
                    resolve: { permissionType: PermissionResolve },
                    component: WeatherSettingsComponent
                  },
                  {
                    path: 'site-conditions',
                    canActivate: [PermissionGuardService],
                    data: { permissionGroup: 'global', permissionId: 'global::psdailylog::sc', PageTitle: 'Daily Log - Site Conditions' },
                    resolve: { permissionType: PermissionResolve },
                    component: SiteConditionsSettingsComponent
                  },
                  {
                    path: 'work-day-options',
                    canActivate: [PermissionGuardService],
                    data: {
                      permissionGroup: 'global',
                      permissionId: 'global::psdailylog::workdayoptions',
                      PageTitle: 'Daily Log - Work Day Options'
                    },
                    resolve: { permissionType: PermissionResolve },
                    component: TimeReasonsSettingsComponent
                  }
                ]
              }
            ]
          },
          {
            path: 'team-settings',
            component: TeamSettingsComponent,
            canActivate: [PermissionGuardService],
            data: { permissionGroup: 'global', permissionId: 'global::team', PageTitle: 'Crews' },
            resolve: { permissionType: PermissionResolve }
          },
          {
            path: 'permission',
            component: UserBasePermissionComponent,

            children: [
              {
                path: 'manageuser/:delegateId',
                component: ManageUserPermissionComponent,
                data: { permissionGroup: 'global', permissionId: 'global::managepolicies' },
                resolve: { delegate: DelegateResolve, permissionType: PermissionResolve }
              },
              {
                path: 'policies',
                component: CreatePolicyComponent,
                canActivate: [PermissionGuardService],
                data: { permissionGroup: 'global', permissionId: 'global::managepolicies', PageTitle: 'Policies' },
                resolve: { permissionType: PermissionResolve }
              },
              {
                path: 'policies/:policyId',
                component: ManagePolicyComponent,
                canActivate: [PermissionGuardService],
                data: { permissionGroup: 'global', permissionId: 'global::managepolicies', PageTitle: 'Policies' },
                resolve: { permissionType: PermissionResolve }
              }
            ]
          },
          {
            path: 'project/:id',
            component: ProjectComponent,
            canActivate: [TeamGuardService],
            children: [
              { path: 'dashboard', component: ProjectDashboardComponent },
              {
                path: 'photos',
                component: PhotosAllComponent,
                canActivate: [TeamGuardService, ProjectPermissionGuardService],
                data: { permissionGroup: 'project', permissionId: 'project::photos', PageTitle: 'Photos' },
                resolve: { permissionType: ProjectPermissionResolve }
              },
              {
                path: 'files',
                component: FilesComponent
                //canActivate: [TeamGuardService, ProjectPermissionGuardService],
                //data: { permissionGroup: "project", permissionId: "project::photos", PageTitle: "Photos" },
                //resolve: { permissionType: ProjectPermissionResolve }
              },
              {
                path: 'punchlist',
                component: PunchListComponent
                //canActivate: [TeamGuardService, ProjectPermissionGuardService],
                //data: { permissionGroup: "project", permissionId: "project::photos", PageTitle: "Photos" },
                //resolve: { permissionType: ProjectPermissionResolve }
              },
              {
                path: 'photos-gallery',
                component: PhotosViewComponent
              },
              {
                path: 'permission/:delegateId',
                component: ManageUserPermissionComponent,
                data: { permissionGroup: 'project' },
                canActivate: [TeamGuardService],
                resolve: { delegate: DelegateResolve }
              },
              {
                path: 'info',
                component: ProjectInfoInputComponent,
                canActivate: [TeamGuardService, ProjectPermissionGuardService],
                data: { permissionGroup: 'project', permissionId: 'project::info', PageTitle: 'Info' },
                resolve: { permissionType: ProjectPermissionResolve }
              },
              {
                path: 'daily-log/:year/:month/:day',
                component: ProjectDailyLogComponent,
                canActivate: [TeamGuardService, ProjectPermissionGuardService],
                data: { permissionGroup: 'project', permissionId: 'project::dailylog', PageTitle: 'Daily Log' },
                resolve: { permissionType: ProjectPermissionResolve }
              },
              {
                path: 'daily-log',
                component: ProjectDailyLogComponent,
                canActivate: [TeamGuardService, ProjectPermissionGuardService],
                data: { permissionGroup: 'project', permissionId: 'project::dailylog', PageTitle: 'Daily Log' },
                resolve: { permissionType: ProjectPermissionResolve }
              },
              { path: 'time-card', component: TimeCardComponentView },
              {
                path: 'cost-codes',
                component: ProjectCostCodesComponent,
                canActivate: [TeamGuardService, ProjectPermissionGuardService],
                data: { permissionGroup: 'project', permissionId: 'project::costcodes', PageTitle: 'Cost Codes' },
                resolve: { permissionType: ProjectPermissionResolve }
              },
              { path: 'bid-items', component: ProjectBidItemsComponent },
              { path: 'plan-room', component: ProjectPlanRoomComponent },
              {
                path: 'reports',
                component: ReportsComponent,
                canActivate: [TeamGuardService]
                //data: { permissionGroup: "project", permissionId: "project::reports", PageTitle: "Reports" },
                //resolve: { permissionType: ProjectPermissionResolve }
              },
              {
                path: 'team',
                component: ProjectTeamComponent,
                canActivate: [TeamGuardService, ProjectPermissionGuardService],
                data: { permissionGroup: 'project', permissionId: 'project::team', PageTitle: 'Team' },
                resolve: { permissionType: ProjectPermissionResolve }
              },
              { path: 'library-vendors', component: ProjectVendorLibraryComponent },
              { path: 'project-navbar', component: ProjectSidenavComponent },
              {
                path: 'history',
                component: ProjectActivityComponent,
                canActivate: [TeamGuardService, ProjectPermissionGuardService],
                data: { permissionGroup: 'project', permissionId: 'project::activity', PageTitle: 'History' },
                resolve: { permissionType: ProjectPermissionResolve }
              },
              {
                path: 'timecardapproval',
                component: TimeCardApprovalComponent,
                canActivate: [TeamGuardService, ProjectPermissionGuardService],
                data: { permissionGroup: 'project', permissionId: 'project::timecardapproval', PageTitle: 'Time Card Approval' },
                resolve: { permissionType: ProjectPermissionResolve }
              },
              { path: 'unauthorized', component: UnauthorizedComponent },
              {
                path: 'settings',
                redirectTo: 'settings/menu',
                pathMatch: 'full'
              },
              {
                path: 'settings',
                component: SettingsLocalComponent,
                data: {
                  PageTitle: 'Settings',
                  ComponentType: 'project',
                  PageInstructions: 'Choose the features you want in your project menu. Customize features. Drag and drop to arrange the order.'
                },
                children: [
                  {
                    path: 'menu',
                    component: SettingsListComponent
                  },
                  {
                    path: 'tools',
                    canActivate: [ProjectPermissionGuardService],
                    data: { permissionGroup: 'project', permissionId: 'project::pstools::hsa', PageTitle: 'Info hide/show/arrage' },
                    resolve: { permissionType: ProjectPermissionResolve },
                    component: ProjectSettingsComponent
                  },
                  {
                    path: 'info',
                    canActivate: [ProjectPermissionGuardService],
                    data: { permissionGroup: 'project', permissionId: 'project::psinfo::hsa', PageTitle: 'Info hide/show/arrage' },
                    resolve: { permissionType: ProjectPermissionResolve },
                    component: ProjectInfoSettingsComponent
                  },
                  {
                    path: 'dailylog',
                    component: DailyLogSettingsBaseComponent,
                    children: [
                      {
                        path: 'menu',
                        canActivate: [ProjectPermissionGuardService],
                        data: { permissionGroup: 'project', permissionId: 'project::psdailylog::hsa', PageTitle: 'Daily Log hide/show/arrage' },
                        resolve: { permissionType: ProjectPermissionResolve },
                        component: DailyLogSettingsComponent
                      },
                      {
                        path: 'weather',
                        canActivate: [ProjectPermissionGuardService],
                        data: { permissionGroup: 'project', permissionId: 'project::psdailylog::weather', PageTitle: 'Daily Log - Weather' },
                        resolve: { permissionType: ProjectPermissionResolve },
                        component: WeatherSettingsComponent
                      },
                      {
                        path: 'site-conditions',
                        canActivate: [ProjectPermissionGuardService],
                        data: { permissionGroup: 'project', permissionId: 'project::psdailylog::sc', PageTitle: 'Daily Log - Site Conditions' },
                        resolve: { permissionType: ProjectPermissionResolve },
                        component: SiteConditionsSettingsComponent
                      },
                      {
                        path: 'cost-codes',
                        canActivate: [ProjectPermissionGuardService],
                        data: { permissionGroup: 'project', permissionId: 'project::psdailylog::costcodes', PageTitle: 'Cost Codes' },
                        resolve: { permissionType: ProjectPermissionResolve },
                        component: ProjectCostCodesComponent
                      },
                      {
                        path: 'work-day-options',
                        canActivate: [ProjectPermissionGuardService],
                        data: {
                          permissionGroup: 'project',
                          permissionId: 'project::psdailylog::workdayoptions',
                          PageTitle: 'Daily Log - Work Day Options'
                        },
                        resolve: { permissionType: ProjectPermissionResolve },
                        component: TimeReasonsSettingsComponent
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]),
    FileUploadModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      newestOnTop: true,
      positionClass: 'toast-bottom-left',
      maxOpened: 3,
      enableHtml: true,
      closeButton: true
    }),
    CivCastImageModule
  ],
  declarations: [
    ProjectNewComponent,
    ProjectSettingsCrewsComponent,
    ProjectSettingsSideNavigationComponent,
    ProjectSettingsThemeComponent,
    ProjectCostCodesComponent,
    ProjectListComponent,
    ProjectDashboardComponent,
    ProjectInfoComponent,
    ProjectDailyLogComponent,
    ProjectBidItemsComponent,
    ProjectPlanRoomComponent,
    ProjectVendorLibraryComponent,
    ProjectTeamComponent,
    ProjectComponent,
    ProjectSidenavComponent,
    ModalSaveProject,
    ModalProjectSideNav,
    TeamMemberEditModal,
    ModalCostCodesImport,
    ModalAddCostCode,
    ProjectActivityComponent,
    ProjectWeatherComponent,
    ProjectSettingsComponent,
    DailyLogSettingsComponent,
    SettingsLocalComponent,
    WeatherSettingsComponent,
    SettingsParentComponent,
    SettingsListComponent,
    SettingsSub1Component,
    ProjectSiteConditionsComponent,
    ProjectNotesComponent,
    SiteConditionsSettingsComponent,
    ProjectInfoComponent,
    ProjectInfoInputComponent,
    ProjectInfoSettingsComponent,
    TeamUnauthorizedComponent,
    TimeCardApprovalComponent,
    TeamSettingsComponent,
    DailyLogModalComponent,
    ReportsComponent,
    DailyLogReportComponent,
    DailyLogSettingsBaseComponent,
    ProjectPhotosComponent,
    PhotosUploadComponent,
    PhotosViewComponent,
    PhotosAllComponent,
    FilesComponent,
    PunchListComponent
  ],
  entryComponents: [
    ModalSaveProject,
    ModalCostCodesImport,
    ModalAddCostCode,
    ModalProjectSideNav,
    TeamMemberEditModal,
    ProjectWeatherComponent,
    DailyLogSettingsComponent,
    WeatherSettingsComponent,
    ProjectSiteConditionsComponent,
    ProjectNotesComponent,
    SiteConditionsSettingsComponent,
    ProjectInfoSettingsComponent,
    ApprovalHistoryModalComponent,
    DailyLogModalComponent,
    ProjectSettingsComponent,
    ProjectPhotosComponent
  ],
  providers: [],
  exports: [DailyLogSettingsBaseComponent]
})
export class UserProjectModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UserProjectModule,
      providers: [
        UserProjectService,
        TeamService,
        ProjectActivityService,
        DailyLogService,
        WeatherService,
        SettingsService,
        SiteConditionsService,
        NotesService,
        InfoService,
        TeamGuardService,
        TeamPermissionGuardService,
        TeamPermissionResolve,
        ApprovalService,
        CostCodeService,
        ReportsService,
        PhotosService
      ]
    };
  }
}
