import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as UserApi from '../../models';

import { ModalClassification } from './modal.classification';
import { DelegateService } from '../../services/delegate.service';
import { ActivatedRoute } from '@angular/router';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';

@Component({
  selector: 'user-classifications-component',
  templateUrl: './user.classifications.component.html'
})
export class UserClassficationsComponent implements OnInit {
  public Classifications: UserApi.Classification[] = [];
  @Input() permissionType: AccessLevel;

  constructor(private route: ActivatedRoute, private modalService: NgbModal, private delegateService: DelegateService) {}

  ngOnInit(): void {
    this.route.data.subscribe(params => {
      this.permissionType = params.permissionType;
      this.delegateService.GetClassifications().subscribe(classifications => {
        this.Classifications = classifications;
      });
    });
  }

  addClassification() {
    const modalRef = this.modalService.open(ModalClassification, { size: 'lg' });
    modalRef.componentInstance.modalType = 'add';
    modalRef.componentInstance.classification = new UserApi.Classification();
    modalRef.result.then(result => {
      this.Classifications.push(result);
    });
  }

  editClassification(idx, classification) {
    const modalRef = this.modalService.open(ModalClassification, { size: 'lg' });
    modalRef.componentInstance.modalType = 'edit';
    modalRef.componentInstance.classification = Object.assign({}, classification);
    modalRef.result.then(result => {
      this.Classifications.splice(idx, 1, result);
    });
  }

  removeClassification(idx, classification) {
    this.delegateService.RemoveClassification(classification).subscribe(x => {
      this.Classifications.splice(idx, 1);
    });
  }
}
