import { PhotoStorage } from './photo.storage';

export class Photo {
  PhotoId: string;
  FileName: string;
  LargeStorage: PhotoStorage;
  ThumbnailStorage: PhotoStorage;
  Title: string;
  Description: string;
  Data: any;
  Width: number;
  Height: number;
  UploadDate: Date;
  LastModifiedDate: Date;
  PhotoType: string;
  PhotoSize: number;
}
