import { Component, OnInit, Input, ViewContainerRef, ViewChild, ComponentFactoryResolver, AfterViewInit } from '@angular/core';
import { UserProjectService } from '../../user.project.service';
import * as ProjectApi from '../../../user.project/models';
import { SettingsService } from '../settings/settings.service';
import { AlertType } from '../../../custom.controls/growl/alert-type.enum';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { InfoComponent } from './models/infoComponent';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPropertyEdit } from '../../../civcast.form/components/modals/property.edit.modal';
import { InfoItem } from '../../../civcast.form/models/InfoItem';
import { IComponent } from '../../models/IComponent';
import { Observable } from 'rxjs/Observable';
import { ValidationResult } from '../../models/validationResult';

@Component({
  selector: 'project-info-settings',
  templateUrl: './project.info.settings.component.html',
  styleUrls: ['../project.component.css']
})
export class ProjectInfoSettingsComponent implements OnInit, AfterViewInit, IComponent {
  @ViewChild('projectProperties', { static: false, read: ViewContainerRef }) target: ViewContainerRef;
  @Input() project: ProjectApi.Project = null;
  @Input() mainComponent: InfoComponent = null;
  @Input() componentType: string;
  infoComponent: InfoComponent = null;
  cMode: string = 'edit';

  constructor(
    private userProjectService: UserProjectService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private settingsService: SettingsService,
    private growlService: GrowlService,
    private userSharedService: UserSharedService,
    private modalService: NgbModal,
    private projectService: UserProjectService
  ) {}

  ngAfterViewInit(): void {}

  ngOnInit() {
    if (this.settingsService.componentType === 'global') {
      this.userSharedService.GetComponent('info').subscribe(result => {
        this.infoComponent = result as InfoComponent;
      });
    } else if (this.settingsService.componentType === 'project') {
      this.userProjectService.GetProjectSection(this.settingsService.pId, 'info').subscribe(result => {
        this.infoComponent = result as InfoComponent;
      });
    }
  }

  remove(item) {
    var itemIdx = this.infoComponent.Properties.indexOf(item);
    this.infoComponent.Properties.splice(itemIdx, 1);
  }

  hide(item) {
    var itemIdx = this.infoComponent.Properties.indexOf(item);
    this.infoComponent.Properties[itemIdx].IsHidden = !item.IsHidden;
  }

  addItem(item) {}

  editItem(item) {
    var itemIdx = this.infoComponent.Properties.indexOf(item);
    this.infoComponent.Properties.splice(itemIdx, 1, item);
  }

  add() {
    const modalRef = this.modalService.open(ModalPropertyEdit, { size: 'lg' });
    modalRef.componentInstance.infoItem = Object.assign({}, new InfoItem());
    modalRef.componentInstance.mode = 'Add';
    modalRef.result.then(result => {
      if (!this.infoComponent.Properties) {
        this.infoComponent.Properties = [];
      }
      this.infoComponent.Properties.push(result);
    });
  }

  saveComponent() {
    this.Save().subscribe(result => {
      this.growlService.addAlert('Info settings have been saved', AlertType.SUCCESS);
    });
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.infoComponent['_t'] = ['BaseEntity', 'Component', 'ProjectComponent', 'InfoComponent'];

      if (this.settingsService.componentType.toLowerCase() === 'project') {
        this.projectService.SaveComponent(this.settingsService.pId, this.infoComponent).subscribe(
          comp => {
            this.projectService.SaveProject(this.project).subscribe(project => {
              this.project = project;

              obs.next(true);
              obs.complete();
            });
          },
          err => {
            obs.error(err);
          }
        );
      } else {
        this.userSharedService.SaveComponent(this.infoComponent).subscribe(
          comp => {
            obs.next(true);
            obs.complete();
          },
          err => {
            obs.error(err);
          }
        );
      }
    });
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var vResult = new ValidationResult();
      vResult.IsValid = true;
      obs.next(vResult);
      obs.complete();
    });
  }

  Clear(): Observable<boolean> {
    return Observable.of(true);
  }

  Remove(): Observable<boolean> {
    return Observable.of(true);
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
