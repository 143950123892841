import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { UserProjectService } from '../../../user.project.service';
import { ReportsService } from '../report.service';
import { TeamService } from '../../team/team.service';
import { AccessLevel } from '../../../../user.permissions/models/accessLevel';
import { TeamMember } from '../../team/models/TeamMember';
import { GrowlService } from '../../../../custom.controls/growl/growl.service';
import { AlertType } from '../../../../custom.controls/growl/alert-type.enum';
import { AccessControlService } from '../../../../user.permissions/services/access.control.service';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SelectedData } from '../../../../core/SelectedData';

@Component({
  selector: 'dailylog-report-component',
  templateUrl: './dailylog.report.component.html'
})
export class DailyLogReportComponent implements OnInit {
  templateUrl: string;
  @Input() permissionType: AccessLevel;
  @Input() projectId: string;
  teamMembers: Array<TeamMember> = [];

  startDate: NgbDateStruct;
  endDate: NgbDateStruct;

  isAllowed: boolean = false;
  isRunning: boolean = false;

  isEndDateChanged: boolean = false;

  options: any = {};
  layoutOptions = [];

  public s2ForemanOptions: any = {};
  current: string;

  public foremanS2: Array<SelectedData>;
  public selectedFormen: Array<SelectedData>;

  constructor(
    private route: ActivatedRoute,
    private userProjectService: UserProjectService,
    private reportService: ReportsService,
    private growlService: GrowlService,
    private accessControlService: AccessControlService
  ) {
    this.permissionType = new AccessLevel();
    this.permissionType.AccessLevelId = 'fullaccess';

    this.s2ForemanOptions = {
      singleSelection: false,
      idField: 'ItemId',
      textField: 'ItemText',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };

    this.options = {
      PageLayout: 0,
      ShowPhotos: true
    };

    this.layoutOptions = [
      {
        Name: 'Portrait',
        Value: 0
      },
      {
        Name: 'Landscape',
        Value: 1
      }
    ];
  }

  ngOnInit() {
    if (this.userProjectService.project) {
      this.projectId = this.userProjectService.project.Id;
      this.initialize();
    } else {
      this.userProjectService.mainProject$.subscribe(result => {
        this.projectId = result.Id;
        this.initialize();
      });
    }

    var d = new Date();
    d.setDate(d.getDate() - 1);

    this.startDate = {
      day: d.getDate(),
      month: d.getMonth() + 1,
      year: d.getFullYear()
    };

    this.dateSync();
  }

  dateSync() {
    if (!this.isEndDateChanged) {
      this.endDate = Object.assign({}, this.startDate);
      this.isEndDateChanged = false;
    }
  }

  changeEndDate() {
    this.isEndDateChanged = true;
  }

  initialize() {
    this.reportService.GetReportUsers(this.projectId).subscribe(teamMembers => {
      this.teamMembers = teamMembers;

      this.foremanS2 = new Array();
      this.selectedFormen = new Array();
      for (let c of teamMembers) {
        var pData = {
          ItemId: c.Id,
          ItemText: c.DelegateUser.FirstName + ' ' + c.DelegateUser.LastName
        };

        this.foremanS2.push(pData);
        this.selectedFormen.push(pData);
      }

      var permissionId = 'project::reports::rundailylog';
      var permissionGroup = 'project';
      this.accessControlService.GetProjectAccessLevel(this.projectId, permissionId, permissionGroup).subscribe(result => {
        if (result.AccessLevelId.toLowerCase() === 'fullaccess') {
          this.isAllowed = true;
        }
      });
    });
  }

  //selectAll() {
  //    this.selectedUsers = new Array();
  //    for (let c of this.teamMembersS2) {

  //        this.selectedUsers.push(c.id);

  //    }

  //}

  runDailyLogReport() {
    if (this.startDate == null) {
      this.growlService.addAlert('You must select a start date', AlertType.DANGER);
      return;
    }

    if (this.endDate == null) {
      this.growlService.addAlert('You must select an end date', AlertType.DANGER);
      return;
    }

    var sDate = new Date(this.startDate.year, this.startDate.month, this.startDate.day);
    var eDate = new Date(this.endDate.year, this.endDate.month, this.endDate.day);

    if (sDate > eDate) {
      this.growlService.addAlert('End date must be greater or equal to the start date', AlertType.DANGER);
      return;
    }

    this.isRunning = true;

    this.reportService.RunDailyReport(this.projectId, this.startDate, this.endDate, this.selectedFormen, this.options).subscribe(
      result => {
        this.isRunning = false;
      },
      err => {
        this.growlService.addAlert('There was an error running the report.', AlertType.DANGER);
        this.isRunning = false;
      }
    );
  }

  onSelectAll(event) {}

  onItemSelect(event) {}

  //changed(data: { value: string[] }) {
  //    this.selectedUsers = data.value;
  //}
}
