import { Component, Input, OnInit, Injectable } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as UserApi from '../../../user/models';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'modal-employee-add',
  templateUrl: './employee.add.modal.html'
})
export class EmployeeAddModal implements OnInit {
  @Input() employee: UserApi.Employee;
  //@Input() foremen: UserApi.Foreman[] = [];
  @Input() formType: string;

  error: string = null;

  constructor(private activeModal: NgbActiveModal, private employeeService: EmployeeService) {}

  ngOnInit(): void {}

  saveEmployee() {
    this.employee._t = ['BaseEntity', 'Employee'];

    if (this.formType == 'Add') {
      this.employeeService.AddEmployee(this.employee).subscribe(
        emp => {
          this.activeModal.close(emp);
        },
        err => {
          this.error = err._body;
        }
      );
    } else if (this.formType == 'Edit') {
      this.employeeService.SaveEmployee(this.employee).subscribe(
        emp => {
          this.activeModal.close(emp);
        },
        err => {
          this.error = err._body;
        }
      );
    } else {
      this.error = 'The system does not know this operation.';
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}
