import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'project-bid-items',
  templateUrl: './project.vendorlibrary.component.html',
  styleUrls: ['../project.component.css']
})
export class ProjectVendorLibraryComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
