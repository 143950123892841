import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseComponentService<T> {
  private baseApiUrl: string;
  private httpClient: HttpClient;
  constructor(baseApiUrl: string, client: HttpClient) {
    this.baseApiUrl = baseApiUrl;
    this.httpClient = client;
  }

  SaveComponent(component: T, projectId: string): Observable<T> {
    return this.httpClient.post<T>(`/component/${this.baseApiUrl}/SaveComponent/${projectId}`, component);
  }

  public GetComponent(projectId: string, year: number, month: number, day: number): Observable<T> {
    return this.httpClient.get<T>(`/component/${this.baseApiUrl}/GetComponent/${projectId}/${year}/${month}/${day}`);
  }

  public GetComponentByUser(projectId: string, userId: string, year: number, month: number, day: number): Observable<T> {
    return this.httpClient.get<T>(`/component/${this.baseApiUrl}/GetComponentByUser/${projectId}/${userId}/${year}/${month}/${day}`);
  }

  public DeleteComponent(projectId: string, year: number, month: number, day: number): Observable<boolean> {
    return this.httpClient.delete<boolean>(`/component/${this.baseApiUrl}/DeleteComponent/${projectId}/${year}/${month}/${day}`);
  }
}
