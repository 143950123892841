import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as UserApi from '../../models';
import { DelegateService } from '../../services/delegate.service';

@Component({
  selector: 'modal-add-delegate',
  templateUrl: './modal.adddelegate.html'
})
export class ModalAddDelegate implements OnInit {
  closeResult: string;
  searchedDelegates: UserApi.Delegate[] = [];
  search: string;
  //currentPermissions: UserApi.Permission[] = [];
  @Input() currentDelegates: UserApi.Delegate[] = [];

  constructor(public activeModal: NgbActiveModal, private delegateService: DelegateService) {}

  ngOnInit(): void {
    //this.authService.GetData(Authentication.HttpMethods.GET, "/api/global/GetStaticData/Permissions").subscribe(response => {
    //    this.currentPermissions = response.json() as UserApi.Permission[];
    //}, err => {
    //});
  }

  searchUsers() {
    let search = { Search: this.search };

    this.delegateService.FindDelegates(search).subscribe(searchedDelegates => {
      this.searchedDelegates = searchedDelegates;
      for (var d of this.currentDelegates) {
        var f = this.searchedDelegates.filter(x => x.Id == d.Id);
        if (f.length > 0) {
          f[0]['IsOnList'] = true;
        }
      }
    });
  }

  addDelegates() {
    var aDelegates: UserApi.Delegate[] = [];

    for (let user of this.searchedDelegates) {
      if (user['IsActive']) {
        //user.Permissions = this.currentPermissions;
        aDelegates.push(user);
      }
    }

    this.delegateService.AddDelegates(aDelegates).subscribe(response => {
      this.activeModal.close(aDelegates);
    });
  }

  close() {
    this.activeModal.dismiss();
  }

  checkDelegate(delegate) {
    if (delegate.IsActive == undefined || delegate.IsActive == null) {
      delegate['IsActive'] = true;
    } else {
      if (delegate.IsActive) {
        delegate.IsActive = false;
      } else {
        delegate.IsActive = true;
      }
    }
  }
}
