import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'project-settings-costcodes',
  templateUrl: './project.settings.crews.component.html'
})
export class ProjectSettingsCrewsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
