import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NavService } from '../../../services/nav.service';
import { UserProjectService } from '../user.project.service';
import { animate, state, style, transition, trigger } from '@angular/animations';

import * as ProjectApi from '../../user.project/models';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { ComponentIcons } from '../../../shared/component.icons';

type PaneType = 'left' | 'right';

@Component({
  selector: 'project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  animations: [
    trigger('slide', [
      state('left', style({ transform: 'translateX(0)' })),
      state('right', style({ transform: 'translateX(-50%)' })),
      transition('* => *', animate(300))
    ])
  ]
})
export class ProjectComponent implements OnInit {
  @Input() activePane: PaneType = 'left';
  project: ProjectApi.Project;
  isExpanded = true;
  isClosed = false;
  projetComponentInfo: ProjectApi.Component[] = [];
  icons: typeof ComponentIcons = ComponentIcons;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public navService: NavService,
    private userProjectService: UserProjectService
  ) {}

  ngOnInit() {
    this.navService.showNavIcon = true;

    this.initialize();

    this.navService.isNavOpenTest$.subscribe(val => {
      if (val) {
        this.isClosed = false;
        this.isExpanded = true;
      } else {
        this.isClosed = true;
        this.isExpanded = false;
      }
    });

    this.userProjectService.projectComponentUpdate$.subscribe(update => {
      this.initialize();
    });
  }

  initialize() {
    this.route.params.subscribe(params => {
      const id = params['id'];

      this.userProjectService.GetProject(id).subscribe(project => {
        this.project = project;
        this.userProjectService.project = project;
        this.userProjectService.mainProject.next(project);

        this.userProjectService.GetProjectComponentInfo(id).subscribe(components => {
          this.project.Components = components;
          this.userProjectService.project.Components = components;
        });
      });
    });
  }
}
