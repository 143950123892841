import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { ImageInfo } from '../models/imageinfo';
import * as HAMMER from 'hammerjs';
import { CivCastImageService } from '../image.service';
import { PhotoViews } from '../models/photoview.enum';

@Component({
  selector: 'civcast-image-viewer',
  templateUrl: './image.viewer.component.html'
})
export class CivCastImageViewerComponent implements OnInit {
  @Input() images: Array<ImageInfo>;
  @Input() currentImage: ImageInfo;
  @Input() photoView: PhotoViews = PhotoViews.Full;
  @Input() mainView: string = 'Normal';
  @Output() nextImageEvent = new EventEmitter();
  @Output() previousImageEvent = new EventEmitter();
  @Output() closeViewerEvent = new EventEmitter();
  overLayTimer: any;
  showOverlay: boolean = false;

  constructor(private imageService: CivCastImageService) {
    document.addEventListener('keyup', evt => {
      console.log('keys pressed ', evt);
      if (evt.keyCode === 37) {
        this.previousImage();
      } else if (evt.keyCode === 39) {
        this.nextImage();
      } else if (evt.keyCode === 27) {
        this.closeViewer();
      }
    });

    this.imageService.clearPhotos.subscribe(result => {
      if (result) {
        this.closeViewer();
      }
    });
  }

  eventNoFire(event) {
    return event.stopPropagation();
  }

  ngOnInit(): void {
    var myElement = document.getElementById('hammerelement');
    var ham = new HAMMER(myElement);

    ham.on('swipeleft swiperight', evt => {
      if (evt.type === 'swipeleft') {
        this.nextImage();
      } else if (evt.type === 'swiperight') {
        this.previousImage();
      }
    });
  }

  selectImage(idx: number) {
    if (this.images) {
      if (this.images.length > 0) {
        if (idx == this.images.length) {
          this.currentImage = this.images[0];
        } else if (idx < 0) {
          this.currentImage = this.images[this.images.length - 1];
        } else {
          this.currentImage = this.images[idx];
        }
      }
    }
  }

  nextImage() {
    if (this.currentImage) {
      var imageIndex = this.images.indexOf(this.currentImage);
      this.selectImage(++imageIndex);
    } else {
      this.selectImage(0);
    }

    return event.stopPropagation();
  }

  previousImage() {
    if (this.currentImage) {
      var imageIndex = this.images.indexOf(this.currentImage);
      this.selectImage(--imageIndex);
    } else {
      this.selectImage(0);
    }

    return event.stopPropagation();
  }

  viewSelectedImage(image: ImageInfo) {
    this.currentImage = image;
  }

  showOverLay() {
    this.showOverlay = !this.showOverlay;
  }

  closeViewer() {
    this.closeViewerEvent.next();
  }

  onTitleChange(event) {
    this.imageService.photoUpdate.next(this.currentImage);
  }

  onDescChange(event) {
    this.imageService.photoUpdate.next(this.currentImage);
  }
}
