import * as UserApi from '../';
import * as Core from '../../../core';

export class Foreman extends Core.BaseEntity {
  public _t: string[] = ['BaseEntity', 'Foreman'];
  public Crew: UserApi.Employee[] = [];
  public IsActive: boolean;
  public DelegateUserId: string;
  public ForemanId: string;
  public FirstName: string;
  public LastName: string;
}
