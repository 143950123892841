import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-auth-validation',
  templateUrl: './auth-validation.component.html',
  styleUrls: ['./auth-validation.component.css']
})
export class AuthValidationComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    Auth.federatedSignIn();
  }
}
