import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Policy } from '../../models/policy';
import { UACPolicyService } from '../../services/uac.policy.service';

@Component({
  selector: 'policy-user',
  templateUrl: './user.policy.component.html',
  styleUrls: []
})
export class UserPolicyComponent implements OnInit {
  constructor(private userSharedService: UACPolicyService) {}

  @Input() policies: Array<Policy> = [];

  @Output() removePolicy = new EventEmitter<Policy>();

  ngOnInit(): void {}

  removeAPolicy(p: Policy) {
    this.removePolicy.next(p);
  }
}
