import { Component, OnInit, Input } from '@angular/core';
import * as ProjectApi from '../../../user.project/models';
import * as UserApi from '../../../user/models';
import { TeamComponent } from './models/TeamComponent';
import { TeamService } from './team.service';
import { TeamMember } from './models/TeamMember';
import { Observable } from 'rxjs/Rx';
import { IComponent } from '../../models/IComponent';
import { ValidationResult } from '../../models/validationResult';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';

@Component({
  selector: 'team-settings',
  templateUrl: './team.settings.component.html',
  styleUrls: ['../project.component.css']
})
export class TeamSettingsComponent implements OnInit, IComponent {
  @Input() project: ProjectApi.Project = null;
  @Input() mainComponent: ProjectApi.Component = null;
  @Input() componentType: string;
  @Input() permissionType: AccessLevel;
  teamComponent: TeamComponent = null;
  cMode: string = 'edit';
  user: UserApi.CMUser;
  projectId: string;
  public loaded = false;

  constructor(private teamService: TeamService) {}
  ngOnInit() {
    this.initialize();
  }
  initialize() {
    this.loaded = false;
    this.teamService.GetTeamMemberSettings().subscribe(comp => {
      this.teamComponent = comp;
      this.loaded = true;
    });
  }
  addAll() {
    var settingsItems = new Array();

    for (let teamMember of this.teamComponent.TeamMembers) {
      settingsItems.push(this.teamService.ActivateTeamSettingsMember(teamMember.Id, false));
    }

    Observable.forkJoin<TeamMember>(settingsItems).subscribe(data => {
      for (let teamMember of this.teamComponent.TeamMembers) {
        teamMember.IsDeleted = false;
      }
    });
  }

  removeAll() {
    var settingsItems = new Array();

    for (let teamMember of this.teamComponent.TeamMembers) {
      settingsItems.push(this.teamService.ActivateTeamSettingsMember(teamMember.Id, true));
    }

    Observable.forkJoin<TeamMember>(settingsItems).subscribe(data => {
      for (let teamMember of this.teamComponent.TeamMembers) {
        teamMember.IsDeleted = true;
      }
    });
  }

  addTeamMember(teamMember: TeamMember) {
    this.teamService.ActivateTeamSettingsMember(teamMember.Id, false).subscribe(response => {
      teamMember.IsDeleted = false;
    });
  }

  removeTeamMember(idx: number, teamMember: TeamMember) {
    this.teamService.ActivateTeamSettingsMember(teamMember.Id, true).subscribe(response => {
      teamMember.IsDeleted = true;
    });
  }

  Save(): Observable<boolean> {
    return Observable.of(true);
  }

  Clear(): Observable<boolean> {
    return Observable.of(true);
  }
  Remove(): Observable<boolean> {
    return Observable.of(true);
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var vResult = new ValidationResult();
      vResult.IsValid = true;
      obs.next(vResult);
      obs.complete();
    });
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
