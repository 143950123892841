import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { SiteConditionComponent } from './models/siteconditioncomponent';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable()
export class SiteConditionsService {
  constructor(private client: HttpClient) {}

  public GetSiteConditionComponent(projectId: string, year: number, month: number, day: number): Observable<SiteConditionComponent> {
    const url = `/component/sitecondition/GetSiteConditionComponent/${projectId}/${year}/${month}/${day}`;
    return this.client.get<SiteConditionComponent>(url);
  }

  public GetSiteConditionComponentByUser(
    projectId: string,
    userId: string,
    year: number,
    month: number,
    day: number
  ): Observable<SiteConditionComponent> {
    const url = `/component/sitecondition/GetSiteConditionComponentByUser/${projectId}/${userId}/${year}/${month}/${day}`;
    return this.client.get<SiteConditionComponent>(url);
  }

  public SaveTimeCardComponent(projectId: string, sc: SiteConditionComponent): Observable<SiteConditionComponent> {
    return this.client.post<SiteConditionComponent>(`/component/sitecondition/SaveSiteConditionComponent/${projectId}`, sc);
  }

  public DeleteSiteConditionComponent(projectId: string, year: number, month: number, day: number): Observable<boolean> {
    return this.client.delete<boolean>(`/component/sitecondition/DeleteSiteConditionComponent/${projectId}/${year}/${month}/${day}`);
  }
}
