import { Component, OnInit, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { DailyLogService } from '../dailylog/dailylog.service';
import { DailyLogComponent } from '../dailylog/dailylogcomponent';
import { ComponentIdentifier } from '../../models/componentIdentifier';
import { UserProjectService } from '../../user.project.service';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { DailyLogModalComponent } from '../dailylog/dailylog.modal.component';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { AlertType } from '../../../custom.controls/growl/alert-type.enum';
import { FormControl, Validators } from '@angular/forms';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import { GroupByPipe, ValuesPipe } from 'ngx-pipes';
import { ApprovalService } from '../../../user/approval/approval.service';
import { Approval } from '../../../user/approval/models/approval';

@Component({
  selector: 'timecard-approval',
  templateUrl: './timecard.approval.component.html'
})
export class TimeCardApprovalComponent implements OnInit {
  approvals: Array<Approval> = [];
  oApprovals: Array<Approval> = [];
  projectId: string;
  currentFilter = 'Unapproved';
  currentType = 'unapproved';
  groupByFilter = 'Date';
  search = new FormControl();
  groupItem: string;
  filterOptions: Array<FilterOption> = [];
  notSubmitted: Array<DailyLogComponent> = [];
  noData: Array<Date> = [];
  nsAccessLevel: AccessLevel;
  gbImpure: GroupByPipe = new GroupByPipe();
  @ViewChild('timeCard', { static: false }) target: ViewContainerRef;
  vPipe: ValuesPipe = new ValuesPipe();
  inputStartDate = false;

  constructor(
    private approvalService: ApprovalService,
    private dailyLogService: DailyLogService,
    private userProjectService: UserProjectService,
    private userSharedService: UserSharedService,
    private growlService: GrowlService,
    private modal: NgbModal,
    private componentFactoryResolver: ComponentFactoryResolver,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.projectId = params.id;

      const approved = new FilterOption('Approved', 'approved');
      const unapproved = new FilterOption('Unapproved', 'unapproved');
      const all = new FilterOption('All', 'all');
      const notsubmitted = new FilterOption('Not Submitted', 'notsubmitted');

      this.filterOptions.push(approved);
      this.filterOptions.push(unapproved);
      this.filterOptions.push(all);
      this.filterOptions.push(notsubmitted);

      this.changeGroupByFilter(this.groupByFilter);
      this.filter(this.currentType);
    });

    this.search.valueChanges.debounceTime(1000).subscribe(value => {
      this.filter(this.currentType);
    });
  }

  changeGroupByFilter(filter: string) {
    this.groupByFilter = filter;

    if (filter === 'Date') {
      if (this.currentType === 'notsubmitted') {
        this.groupItem = 'DateTimeInfo';
      } else {
        this.groupItem = 'DailyLogTimeStamp';
        this.approvals = this.vPipe.transform(this.gbImpure.transform(Object.assign([], this.oApprovals), this.groupItem));
      }
    } else if (filter === 'Crew') {
      if (this.currentType === 'notsubmitted') {
        this.groupItem = '';
      } else {
        this.groupItem = 'UserId';
        this.approvals = this.vPipe.transform(this.gbImpure.transform(Object.assign([], this.oApprovals), this.groupItem));
      }
    }
  }

  filter(filter: string) {
    this.currentType = filter;
    if (this.currentType === 'unapproved') {
      this.currentFilter = 'Unapproved';
      // this.setDefaultView();
      this.approvalService.GetUnApproved(this.projectId, this.search.value).subscribe(
        result => {
          this.oApprovals = result;
          this.approvals = this.vPipe.transform(this.gbImpure.transform(Object.assign([], result), this.groupItem));
        },
        error => {
          this.growlService.addAlert(error._body, AlertType.DANGER);
        }
      );
    } else if (this.currentType === 'approved') {
      this.currentFilter = 'Approved';
      // this.setDefaultView();
      this.approvalService.GetApproved(this.projectId, this.search.value).subscribe(
        result => {
          this.oApprovals = result;
          this.approvals = this.vPipe.transform(this.gbImpure.transform(Object.assign([], result), this.groupItem));
        },
        err => {
          this.growlService.addAlert(err._body, AlertType.DANGER);
        }
      );
    } else if (this.currentType === 'all') {
      this.currentFilter = 'All';
      // this.setDefaultView();
      this.approvalService.GetAll(this.projectId, this.search.value).subscribe(
        result => {
          this.oApprovals = result;
          this.approvals = this.vPipe.transform(this.gbImpure.transform(Object.assign([], result), this.groupItem));
        },
        err => {
          this.growlService.addAlert(err._body, AlertType.DANGER);
        }
      );
    } else if (this.currentType === 'notsubmitted') {
      this.approvals = [];
      this.noData = [];
      this.currentFilter = 'Not Submitted';
      // this.setDefaultView();
      this.changeGroupByFilter(this.groupByFilter);
      // this.dailyLogService.GetNotSubmitted(this.projectId, this.search.value).subscribe(result => {
      //    this.nsAccessLevel = new AccessLevel();
      //    this.nsAccessLevel.AccessLevelId = "readonly";
      //    for (let comp of result) {
      //        comp.DateTimeInfo = new Date(comp.DateTimeInfo);
      //    }

      //    this.notSubmitted = result;
      // });
    } else if (this.currentType === 'nodata') {
      this.approvals = [];
      this.notSubmitted = [];
      this.currentFilter = 'No Data';
      this.search.setValue(null, { emitEvent: false });
      // this.groupByFilter = "";

      this.userProjectService.GetProjectSection(this.projectId, ComponentIdentifier.INFO).subscribe(info => {
        let startDate = null;
        const projectInfo = info;
        const startDateItem = info.Properties.filter(x => x.Name === 'Act. Start Date');
        if (startDateItem.length > 0) {
          if (startDateItem[0].Value != '' && startDateItem[0].Value !== null) {
            startDate = new Date(startDateItem[0].Value);
          }
        }

        if (startDate != null) {
          this.getDailyLogNoData(this.projectId, startDate, new Date());
        } else {
          this.growlService.addAlert(
            'To user this feature the project must have a start date. Please input a start date in the info section of the project',
            AlertType.INFO
          );
        }
      });
    }
  }

  updateApproval(approval: Approval) {
    let idx = 0;
    for (const item of this.approvals) {
      if (item[0].DailyLogId === approval.DailyLogId) {
        item[0] = approval;
      }
      idx++;
    }
  }

  removeApproval(approval: Approval) {
    let idx = 0;
    for (const item of this.approvals) {
      if (item[0].DailyLogId === approval.DailyLogId) {
        break;
      }
      idx++;
    }

    this.approvals.splice(idx, 1);
  }

  showDailyLog(dailyLog: DailyLogComponent) {
    const aLevel = new AccessLevel();
    aLevel.AccessLevelId = 'readonly';

    const ref = this.modal.open(DailyLogModalComponent, { size: 'lg' });
    ref.componentInstance.projectId = dailyLog.ProjectId;
    ref.componentInstance.dailyLogId = dailyLog.Id;
    ref.componentInstance.permission = aLevel;
    ref.componentInstance.userId = dailyLog.UserId;
    ref.componentInstance.dateTimeInfo = new Date(dailyLog.DateTimeInfo);
  }

  getDailyLogNoData(projectId: string, startDate: Date, endDate: Date) {
    // this.dailyLogService.GetDailyLogNoData(this.projectId, startDate, endDate).subscribe(result => {
    //    for (let item of result) {
    //        item = new Date(item);
    //    }
    //    this.noData = result;
    // });
  }

  setDefaultView() {
    if (this.groupByFilter === '') {
      this.changeGroupByFilter('Date');
    }
  }
}

export class FilterOption {
  constructor(name: string, value: string) {
    this.Name = name;
    this.Value = value;
  }

  Name: string;
  Value: string;
}
