import { Component, OnInit, Input, ViewContainerRef, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Delegate } from '../../../user/models/delegate';
import { UACUserService } from '../../services/uac.user.service';
import { Permission } from '../../models/permission';
import { UACPolicyService } from '../../services/uac.policy.service';
import { Policy } from '../../models/policy';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { UACProjectService } from '../../services/uac.project.service';
import { UserProjectService } from '../../../user.project/user.project.service';
import { UserPermissionServiceSerializer } from '../../models/userPermissionSerializer';
import { Observable } from 'rxjs/Observable';
import { GlobalService } from '../../../../services/global.service';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import { UserService } from '../../../user/services/user.service';
import { User } from '../../../user/models/user';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'permission-manage-user',
  templateUrl: './manage.user.component.html',
  styleUrls: []
})
export class ManageUserPermissionComponent implements OnInit {
  delegate: Delegate;
  userPermissions: Array<Permission> = [];
  permissions: Array<Permission> = [];
  cPermissions: Array<Permission> = [];
  userPolicies: Array<Policy> = [];
  policies: Array<Policy> = [];
  permissionGroup: string;
  user: User;
  serializer: UserPermissionServiceSerializer = new UserPermissionServiceSerializer();
  @ViewChild('userPermissions', { static: false, read: ViewContainerRef }) target: ViewContainerRef;
  constructor(
    private route: ActivatedRoute,
    private uacUserService: UACUserService,
    private uacProjectService: UACProjectService,
    private uacPolicyService: UACPolicyService,
    private projectService: UserProjectService,
    private globalService: GlobalService,
    private userService: UserService,
    private userSharedService: UserSharedService,
    private toastr: ToastrService,
    private growlService: GrowlService
  ) {}

  ngOnInit(): void {
    this.route.data.subscribe(info => {
      this.permissionGroup = info.permissionGroup;

      if (info.delegate) {
        this.delegate = info.delegate;
        this.setupPolicies();
        this.setupPermissions();
      }
    });

    this.userService.getUser().subscribe(user => {
      this.user = user;
    });
  }

  setupPolicies() {
    Observable.forkJoin(this.globalService.GetPolicies(), this.userSharedService.GetPolicies()).subscribe(info => {
      var policies = info[0].concat(info[1]);
      this.policies = policies;

      this.uacPolicyService.GetPolicies(this.delegate.Id).subscribe(policySet => {
        if (policySet) {
          this.userPolicies = policySet.Policies;
          this.initiateActivePolicies();
        }
      });
    });
  }

  setupPermissions() {
    this.globalService.GetPermissions().subscribe(perms => {
      for (let item of perms) {
        this.serializer.SerializePermission(item);
      }

      this.cPermissions = Object.assign([], perms);

      this.uacUserService.GetUserPermissions(this.delegate.Id).subscribe(set => {
        if (set) {
          this.userPermissions = set.Permissions;
        }

        if (this.permissionGroup) {
          if (this.permissionGroup.toLowerCase() === 'project') {
            this.permissions = perms.filter(x => x.Group.toLowerCase() === 'project');

            if (this.projectService.project) {
              this.initializeProject(this.projectService.project.Id);
            } else {
              this.projectService.mainProject.subscribe(result => {
                this.initializeProject(result.Id);
              });
            }
          } else if (this.permissionGroup.toLowerCase() === 'policy') {
            this.permissions = perms.filter(x => x.Access.AccessLevelId.toLowerCase() !== 'deny');
            this.initiateActivePermission();
          } else {
            this.permissions = perms;
            this.initiateActivePermission();
          }
        }
      });
    });
  }

  initializeProject(projectId) {
    this.uacProjectService.GetUserPermissions(this.delegate.Id, projectId).subscribe(set => {
      if (set) {
        this.userPermissions = set.Permissions;
        this.initiateActivePermission();
      }
    });
  }

  initiateActivePermission() {
    for (let sPermission of this.permissions) {
      var permission = this.userPermissions.filter(
        x =>
          x.PermissionId === sPermission.PermissionId && x.Group === sPermission.Group && x.Access.AccessLevelId === sPermission.Access.AccessLevelId
      );

      if (permission.length > 0) {
        sPermission['Active'] = true;
      } else {
        sPermission['Active'] = false;
      }
    }
  }

  initiateActivePolicies() {
    for (let p of this.policies) {
      var hasPolicy = this.userPolicies.filter(x => x.PolicyId === p.PolicyId);

      if (hasPolicy.length > 0) {
        p['Active'] = true;
      } else {
        p['Active'] = false;
      }
    }
  }

  userPermissionFactory;
  userPermissionElement;

  addPermission(perm: Permission) {
    var hasPerm = this.userPermissions.filter(x => x.Group == perm.Group && x.SubGroup == perm.SubGroup);
    if (hasPerm.length <= 0) {
      this.serializer.SerializePermission(perm);

      this.serializer.SerializePermission(perm);

      try {
        delete perm['Active'];
      } catch (e) {}

      if (this.permissionGroup.toLowerCase() === 'global') {
        this.uacUserService.AddUserPermissions(this.delegate.Id, perm).subscribe(nPerm => {
          if (!this.userPermissions) {
            this.userPermissions = [];
          }

          this.userPermissions.push(nPerm);
          this.initiateActivePermission();
        });
      } else if (this.permissionGroup.toLowerCase() === 'project') {
        this.uacProjectService.AddUserPermissions(this.delegate.Id, this.projectService.project.Id, perm).subscribe(nPerm => {
          if (!this.userPermissions) {
            this.userPermissions = [];
          }

          this.userPermissions.push(nPerm);
          this.initiateActivePermission();
        });
      }
    } else {
      this.toastr.error(
        'Permission <b>' +
          hasPerm[0].Name +
          ' - ' +
          hasPerm[0].Access.Name +
          '</b> exists on the policy.  You can only have a single type of permission per policy. You must remove that permission first before adding the new permission'
      );
      //this.growlService.addAlert("Permission <b>" + hasPerm[0].Name + " - " + hasPerm[0].Access.Name + "</b> exists on the policy.  You can only have a single type of permission per policy. You must remove that permission first before adding the new permission", AlertType.DANGER);
    }
  }

  addPolicy(policy: Policy) {
    var hasPolicy = this.userPolicies.filter(x => x.PolicyId == policy.PolicyId);
    if (hasPolicy.length <= 0) {
      try {
        delete policy['Active'];
      } catch (e) {}

      this.uacPolicyService.AddPolicy(this.delegate.Id, policy).subscribe(nPolicy => {
        if (!this.userPolicies) {
          this.userPolicies = [];
        }

        this.userPolicies.push(nPolicy);
        this.initiateActivePolicies();
      });
    } else {
      this.toastr.error('Policy < b > ' + policy.PolicyName + ' </b> already exists on this user');
      //this.growlService.addAlert("Policy <b>" + policy.PolicyName + "</b> already exists on this user", AlertType.DANGER);
    }
  }

  removePolicy(policy: Policy) {
    this.uacPolicyService.RemovePolicy(this.delegate.Id, policy.PolicyId).subscribe(result => {
      var rIdx = null;
      for (var x = 0; x < this.userPolicies.length; x++) {
        var uPolicy = this.userPolicies[x];
        if (uPolicy.PolicyId == policy.PolicyId) {
          rIdx = x;
          break;
        }
      }

      if (rIdx != null) {
        this.userPolicies.splice(rIdx, 1);

        this.initiateActivePolicies();
      }
    });
  }

  removePermission(perm: Permission) {
    if (this.permissionGroup.toLowerCase() === 'global') {
      this.uacUserService.DeleteUserPermission(this.delegate.Id, perm.PermissionId).subscribe(result => {
        var rIdx = null;
        for (var x = 0; x < this.userPermissions.length; x++) {
          var uPerm = this.userPermissions[x];
          if (uPerm.PermissionId === perm.PermissionId && uPerm.Group === perm.Group && uPerm.Access.AccessLevelId === perm.Access.AccessLevelId) {
            rIdx = x;
            break;
          }
        }

        if (rIdx != null) {
          this.userPermissions.splice(rIdx, 1);
          this.initiateActivePermission();
        }
      });
    } else if (this.permissionGroup.toLowerCase() === 'project') {
      this.uacProjectService.DeleteUserPermission(this.delegate.Id, this.projectService.project.Id, perm.PermissionId).subscribe(result => {
        var rIdx = null;
        for (var x = 0; x < this.userPermissions.length; x++) {
          var uPerm = this.userPermissions[x];
          if (uPerm.PermissionId === perm.PermissionId && uPerm.Group === perm.Group && uPerm.Access.AccessLevelId === perm.Access.AccessLevelId) {
            rIdx = x;
            break;
          }
        }

        if (rIdx != null) {
          this.userPermissions.splice(rIdx, 1);
          this.initiateActivePermission();
        }
      });
    }
  }
}
