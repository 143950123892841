import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AccessLevel } from '../models/accessLevel';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable()
export class AccessControlService {
  constructor(private client: HttpClient) {}

  public GetAccessLevel(permissionId: string, permissionGroup: string): Observable<AccessLevel> {
    return this.client.get<AccessLevel>(`/uac/access/GetAccessLevel/${permissionId}/${permissionGroup}`);
  }

  public GetProjectAccessLevel(projectId: string, permissionId: string, permissionGroup: string): Observable<AccessLevel> {
    return this.client.get<AccessLevel>(`/uac/access/GetAccessLevelProject/${projectId}/${permissionId}/${permissionGroup}`);
  }
}
