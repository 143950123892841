import { ValueTypes } from './valueTypes.enum';

export class FormOption {
  constructor(name: string, valueType: ValueTypes) {
    this.Name = name;
    this.Value = valueType;
  }
  Name: string;
  Value: ValueTypes;
}
