import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import * as ProjectApi from '../../models';
import { SettingsService } from './settings.service';
import { UserProjectService } from '../../user.project.service';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { AlertType } from '../../../custom.controls/growl/alert-type.enum';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';

@Component({
  selector: 'settings-list',
  templateUrl: './settings.list.component.html',
  styleUrls: ['../project.component.css']
})
export class SettingsListComponent implements OnInit, AfterViewInit {
  projectId: string;
  allComponents$: ProjectApi.Component[];
  @Input() permissionType: AccessLevel;
  localComponentType: string;
  public PageTitle = '';
  public PageInstructions = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userSharedService: UserSharedService,
    private settingsService: SettingsService,
    private userProjectService: UserProjectService,
    private growlService: GrowlService
  ) {}

  ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.PageTitle = this.route.snapshot.data['PageTitle'];
      this.PageInstructions = this.route.snapshot.data['PageInstructions'];
      this.localComponentType = this.settingsService.componentType;
      //if (params.id) {
      //    this.settingsService.SetProjectId(params['id']);
      //    this.settingsService.componentType = "project";
      //} else {
      //    this.settingsService.componentType = "global";
      //}
    });
  }

  ngAfterViewInit(): void {}

  setActive(c: ProjectApi.Component) {
    c['IsActive'] = !c['IsActive'];
  }

  removeComponent(c: ProjectApi.Component) {
    if (this.settingsService.componentType.toLowerCase() === 'project') {
      this.userProjectService.RemoveComponent(this.settingsService.pId, c.ComponentIdentifier).subscribe(
        x => {},
        error => {
          this.growlService.addAlert('Error', AlertType.DANGER);
        }
      );
    } else {
      this.userSharedService.RemoveComponent(c.ComponentIdentifier).subscribe(
        comps => {
          //this.growlService.addAlert("Components Saved", AlertType.SUCCESS);
        },
        error => {
          this.growlService.addAlert('Error', AlertType.DANGER);
        }
      );
    }
  }

  addComponent(c: ProjectApi.Component) {
    if (this.settingsService.componentType.toLowerCase() === 'project') {
      this.userProjectService.AddComponent(this.settingsService.pId, c.ComponentIdentifier).subscribe(
        x => {},
        error => {
          this.growlService.addAlert('Error', AlertType.DANGER);
        }
      );
    } else {
      this.userSharedService.AddComponent(c.ComponentIdentifier).subscribe(
        comps => {
          //this.growlService.addAlert("Components Saved", AlertType.SUCCESS);
        },
        error => {
          this.growlService.addAlert('Error', AlertType.DANGER);
        }
      );
    }
  }

  editSettings(c: ProjectApi.Component) {
    if (this.settingsService.componentType.toLowerCase() === 'project') {
      this.router.navigate(['user', 'project', this.settingsService.pId, 'settings', c.ComponentIdentifier.toLowerCase()]);
    } else {
      this.router.navigate(['user', 'project-settings-template', c.ComponentIdentifier.toLowerCase()]);
    }
  }

  reOrderComponents() {
    var checkedComponents = [];

    var components = Object.assign({}, this.allComponents$);

    for (let component in components) {
      if (components[component]['IsActive']) {
        var c = components[component];
        c['_t'] = ['BaseEntity', 'Component'];
        c['Id'] = component;
        //delete c["IsChecked"];

        checkedComponents.push(c);
      }
    }

    if (this.settingsService.componentType.toLowerCase() === 'project') {
      this.userProjectService.ReOrderComponents(this.settingsService.pId, checkedComponents).subscribe(
        reponse => {
          //this.growlService.addAlert("Components Saved", AlertType.SUCCESS);
        },
        error => {
          this.growlService.addAlert('Error', AlertType.DANGER);
        }
      );
    } else {
      this.userSharedService.ReOrderComponents(checkedComponents).subscribe(
        comps => {
          //this.growlService.addAlert("Components Saved", AlertType.SUCCESS);
        },
        error => {
          this.growlService.addAlert('Error', AlertType.DANGER);
        }
      );
    }
  }
}
