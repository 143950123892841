import { Component, Input } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup } from '@angular/forms';
import { InfoItem } from '../../models/InfoItem';

@Component({
  selector: 'modal-property-add',
  templateUrl: './property.add.modal.html'
})
export class ModalPropertyAdd {
  closeResult: string;
  @Input() infoItem: InfoItem;

  constructor(public activeModal: NgbActiveModal) {
    //this.complexForm = fb.group({
    //});
  }

  addCostCode() {
    this.activeModal.close(this.infoItem);
  }

  close() {
    this.activeModal.dismiss();
  }
}
