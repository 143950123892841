import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AmplifyService } from 'aws-amplify-angular';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

/* As the AWS Amplify SDK does not notify when the current access token expires
 * we have to check the current token on every HTTP call.
 * Amplify Auth.currentSession() checks the access token and refreshes it if required.
 */
@Injectable({
  providedIn: 'root'
})
export class AWSCurrentSessionInterceptor implements HttpInterceptor {
  constructor(protected amplifyService: AmplifyService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(Auth.currentSession()).pipe(
      switchMap((session: CognitoUserSession) => next.handle(request)),
      catchError(error => {
        console.log('Error: ', error);
        return next.handle(request);
      })
    );
  }
}
