import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'civcast-confirm',
  templateUrl: './confirm.modal.component.html',
  styleUrls: ['./confirm.css']
})
export class ConfirmModalComponent {
  @Input() confirmQuestion: string;
  constructor(private activeModal: NgbActiveModal) {}

  yes() {
    this.activeModal.close();
  }

  no() {
    this.activeModal.dismiss();
  }
}
