import { PipeTransform, Pipe, Injectable } from '@angular/core';

@Pipe({
  name: 'classification'
})
@Injectable()
export class ClassificationPipe implements PipeTransform {
  transform(items: any[], args: any[]): any {
    return items.filter(item => item.Group.indexOf(args[0]) !== -1);
  }
}
