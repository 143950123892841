import { Component, OnInit, Input, AfterViewInit, ViewContainerRef, ViewChild, ComponentFactoryResolver, Output, EventEmitter } from '@angular/core';
import { DatePipe, CurrencyPipe } from '@angular/common';

import { InfoItem } from '../../models/InfoItem';
import { StringElement } from './string.element';
import { DateElement } from './date.element';
import { NumberElement } from './number.element';
import { BooleanElement } from './boolean.element';
import { ValueTypes } from '../../models/valueTypes.enum';
import { CurrencyElement } from './currency.element';
import { FormService } from '../../civcast.form.service';

@Component({
  selector: 'parent-element',
  templateUrl: './parent.element.html'
})
export class ParentElement implements OnInit, AfterViewInit {
  @Input() infoItem: InfoItem;
  @Input() mode: string;
  @Input() showTitle: boolean;
  @ViewChild('childItem', { static: true, read: ViewContainerRef }) target: ViewContainerRef;

  vType: ValueTypes;
  componentFactory;
  element;

  constructor(private componentFactoryResolver: ComponentFactoryResolver, private formService: FormService) {}

  ngOnInit(): void {
    if (this.infoItem) {
      this.addElement(this.infoItem);
    }
  }

  addElement(infoItem: InfoItem) {
    if (infoItem.IsHidden) {
    }
    switch (this.infoItem.ValueType) {
      case ValueTypes.String:
        this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(StringElement);
        this.element = <StringElement>this.target.createComponent(this.componentFactory).instance;
        this.element.mode = this.mode;
        this.formService.setReadableName(this.infoItem);
        if (this.mode.toLowerCase() === 'view') {
          if (this.infoItem.Value !== null && this.infoItem.Value !== undefined) {
            this.infoItem.ReadableValue = this.infoItem.Value as string;
          }
        }
        this.element.infoItem = this.infoItem;

        break;
      case ValueTypes.Date:
        this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(DateElement);
        this.element = <DateElement>this.target.createComponent(this.componentFactory).instance;
        this.element.mode = this.mode;
        this.formService.setReadableName(this.infoItem);
        var datePipe = new DatePipe('en');
        if (this.mode.toLowerCase() === 'view') {
          if (this.infoItem.Value !== null && this.infoItem.Value !== undefined) {
            this.infoItem.ReadableValue = datePipe.transform(this.infoItem.Value, 'MM/dd/yyyy') as string;
          }
        }
        this.element.infoItem = this.infoItem;

        break;
      case ValueTypes.Decimal:
        this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(NumberElement);
        this.element = <NumberElement>this.target.createComponent(this.componentFactory).instance;
        this.element.mode = this.mode;
        this.formService.setReadableName(this.infoItem);
        if (this.mode.toLowerCase() === 'view') {
          if (this.infoItem.Value !== null && this.infoItem.Value !== undefined) {
            this.infoItem.ReadableValue = (<number>this.infoItem.Value).toString();
          }
        }
        this.element.infoItem = this.infoItem;

        break;
      case ValueTypes.Boolean:
        this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(BooleanElement);
        this.element = <BooleanElement>this.target.createComponent(this.componentFactory).instance;
        this.element.mode = this.mode;
        this.formService.setReadableName(this.infoItem);
        if (this.mode.toLowerCase() === 'view') {
          if (this.infoItem.Value !== null && this.infoItem.Value !== undefined) {
            var bType = <boolean>this.infoItem.Value;
            if (bType === true) {
              this.infoItem.ReadableValue = 'Yes';
            } else {
              this.infoItem.ReadableValue = 'No';
            }
          }
        }

        this.element.infoItem = this.infoItem;

        break;
      case ValueTypes.Currency:
        this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(CurrencyElement);
        this.element = <CurrencyElement>this.target.createComponent(this.componentFactory).instance;
        this.element.mode = this.mode;
        this.formService.setReadableName(this.infoItem);
        if (this.mode.toLowerCase() === 'view') {
          if (this.infoItem.Value !== null && this.infoItem.Value !== undefined) {
            var cPipe = new CurrencyPipe('en');
            this.infoItem.ReadableValue = cPipe.transform(this.infoItem.Value, 'USD', true, '1.2-2');
          }
        }
        this.element.infoItem = this.infoItem;
        break;
    }
  }

  ngAfterViewInit(): void {}
}
