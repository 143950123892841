import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import * as UserApi from '../models';
import { HttpClient } from '@angular/common/http';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { Auth } from 'aws-amplify';

@Injectable()
export class UserService {
  storedUser: UserApi.User;

  user = new Subject<UserApi.User>();
  cmUser = new Subject<UserApi.CMUser>();

  constructor(private client: HttpClient, private authService: AuthService) {}

  public getDelegates(): Observable<Response> {
    return null;
  }

  getClaimsUser(): Observable<UserApi.User> {
    return this.user.asObservable();
  }

  getCMUser(): Observable<UserApi.CMUser> {
    return this.cmUser.asObservable();
  }

  setClaimsUser(claimsUser: UserApi.User) {
    this.user.next(claimsUser);
  }

  setCMUser(cmUser: UserApi.CMUser) {
    this.cmUser.next(cmUser);
  }

  public getDelegate(id: number | string): Observable<Response> {
    return null;
  }

  initializeCMUser(): Observable<UserApi.User> {
    return new Observable<UserApi.User>(obs => {
      this.client.post('/api/user/InitiateCMUser', '').subscribe(
        result => {
          obs.next(result as UserApi.User);
          obs.complete();
        },
        err => {
          obs.error(err);
        }
      );
    });
  }

  //todo: test id token info
  public getUser(): Observable<UserApi.User> {
    return new Observable<UserApi.User>(obs => {
      if (this.storedUser == null) {
        this.authService.getIdentityToken().subscribe(
          idToken => {
            var token = JSON.parse(atob(idToken.split('.')[1]));
            Auth.currentUserInfo().then(
              result => {
                var user = new UserApi.User();
                user._id = result.attributes.sub;

                obs.next(user);
                obs.complete();
              },
              err => {
                obs.error(err);
              }
            );
          },
          err => {
            obs.error(err);
          }
        );
      } else {
        obs.next(this.storedUser);
      }
    });
  }

  public getUserClaims(): Observable<UserApi.User> {
    return new Observable<UserApi.User>(obs => {
      obs.next(new UserApi.User());
    });
  }

  public searchUsers(search: string): Observable<Response> {
    var searchData = {
      data: search
    };

    return null;
    //return this.authService.AuthPost("/user/search", searchData);
  }

  private CreateUserBasedOnClaims(data: any): UserApi.User {
    let user: UserApi.User = new UserApi.User();
    return user;
  }
}
