import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { WeatherComponent } from './models/weather.component';
import { WeatherService } from './weather.service';
import { UserProjectService } from '../../user.project.service';
import { ComponentIdentifier } from '../../models/componentIdentifier';
import * as ProjectApi from '../../models';
import { Sky } from './models/Sky';
import { Temperature } from './models/Temperature';
import { SettingsService } from '../settings/settings.service';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { AlertType } from '../../../custom.controls/growl/alert-type.enum';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { IComponent } from '../../models/IComponent';
import { ValidationResult } from '../../models/validationResult';

@Component({
  selector: 'weather-settings',
  templateUrl: './weather.settings.component.html',
  styleUrls: ['../project.component.css']
})
export class WeatherSettingsComponent implements OnInit, IComponent {
  weatherComponent: WeatherComponent = null;
  projectId: string;
  skyName: string;
  tempName: string;

  @Input() mainComponent: ProjectApi.Component;
  @Input() componentType: string;

  constructor(
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private growlService: GrowlService,
    private userSharedService: UserSharedService,
    private projectService: UserProjectService
  ) {}

  ngOnInit() {
    this.route.parent.params.subscribe(params => {
      this.settingsService.parentName = params.parent;

      if (this.settingsService.componentType === 'global') {
        this.userSharedService.GetComponent('dailylog').subscribe(result => {
          var weatherComp = result.Components.filter(x => x.ComponentIdentifier.toLowerCase() === 'weather');

          if (weatherComp.length > 0) {
            this.weatherComponent = weatherComp[0] as WeatherComponent;
          }
        });
      } else if (this.settingsService.componentType === 'project') {
        this.projectService.GetProjectSection(this.settingsService.pId, 'dailylog').subscribe(result => {
          var weatherComp = result.Components.filter(x => x.ComponentIdentifier.toLowerCase() === 'weather');

          if (weatherComp.length > 0) {
            this.weatherComponent = weatherComp[0] as WeatherComponent;
          }
        });
      }
    });
  }

  addSky() {
    var sky: Sky = new Sky();
    sky.Description = this.skyName;
    sky.Name = this.skyName;

    this.weatherComponent.Skys === undefined ? (this.weatherComponent['Skys'] = []) : null;
    this.weatherComponent.Skys === null ? (this.weatherComponent.Skys = []) : this.weatherComponent.Skys;

    sky.SkyId = this.weatherComponent.Skys.length + 1;

    this.weatherComponent.Skys.push(sky);

    this.Save().subscribe(x => {});

    this.skyName = '';
  }

  addTemperature() {
    var temps: Temperature = new Temperature();
    temps.Description = this.tempName;
    temps.Name = this.tempName;

    this.weatherComponent.Temperatures === undefined ? (this.weatherComponent['Temperatures'] = []) : null;

    this.weatherComponent.Temperatures === null ? (this.weatherComponent.Temperatures = []) : this.weatherComponent.Temperatures;

    temps.TemperatureId = this.weatherComponent.Temperatures.length + 1;

    this.weatherComponent.Temperatures.push(temps);

    this.Save().subscribe(x => {});

    this.tempName = '';
  }

  inputBoxChange(item) {
    this.Save().subscribe(x => {});
  }

  removeTemp(idx: number, item: Temperature) {
    this.weatherComponent.Temperatures.splice(idx, 1);
    this.Save().subscribe(x => {});
    this.tempName = '';
  }

  removeSky(idx: number, item: Sky) {
    this.weatherComponent.Skys.splice(idx, 1);
    this.Save().subscribe(x => {});
    this.skyName = '';
  }

  skyDragEnd() {
    this.Save().subscribe(x => {});
  }

  tempDragEnd() {
    this.Save().subscribe(x => {});
  }

  Clear(): Observable<boolean> {
    return Observable.of(true);
  }
  Remove(): Observable<boolean> {
    return Observable.of(true);
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.weatherComponent['_t'] = ['BaseEntity', 'Component', 'ProjectComponent', 'WeatherComponent'];

      if (this.settingsService.componentType.toLowerCase() === 'project') {
        this.projectService.SaveSub1Component(this.settingsService.pId, 'dailylog', 'weather', this.weatherComponent).subscribe(comp => {
          //this.growlService.addAlert("Weather settings have been saved", AlertType.SUCCESS);
          obs.next(true);
          obs.complete();
        });
      } else {
        this.userSharedService.SaveSub1Component('dailylog', 'weather', this.weatherComponent).subscribe(comp => {
          //this.growlService.addAlert("Weather settings have been saved", AlertType.SUCCESS);
          obs.next(true);
          obs.complete();
        });
      }
    });
  }

  Validate(): Observable<ValidationResult> {
    var vResult = new ValidationResult();
    vResult.IsValid = true;
    return Observable.of(vResult);
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
