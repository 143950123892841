import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Rx';
import * as UserApi from '../models';
import * as ProjectApi from '../../user.project/models';
import { Policy } from '../../user.permissions/models/policy';
import { Permission } from '../../user.permissions/models/permission';
import { TeamPermissionType } from '../../user.project/components/team/models/teamPermissionType.enum';
import { PagedItem } from '../../../models/PagedItem';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserSharedService {
  CachedProfiles: Array<UserApi.UserProfile> = new Array<UserApi.UserProfile>();

  constructor(private client: HttpClient) {}

  public GetComponents(): Observable<ProjectApi.Component[]> {
    return this.client.get<ProjectApi.Component[]>('/api/user/GetComponents');
  }

  public GetComponent(componentId: string): Observable<ProjectApi.Component> {
    return this.client.get<ProjectApi.Component>(`/api/user/GetComponent/${componentId}`);
  }

  public GetSubComponent(componentId: string, subComponentId: string): Observable<ProjectApi.Component> {
    return this.client.get<ProjectApi.Component>(`/api/user/GetSubComponent/${componentId}/${subComponentId}`);
  }

  public GetDelegate(delegateId: string): Observable<UserApi.Delegate> {
    return this.client.get<UserApi.Delegate>(`/api/user/GetDelegate/${delegateId}`);
  }

  public GetPolicy(policyId: string): Observable<Policy> {
    return this.client.get<Policy>(`/api/user/GetPolicy/${policyId}`);
  }

  public GetPolicies(): Observable<Policy[]> {
    return this.client.get<Policy[]>('/api/user/GetPolicies');
  }

  public AddNewPolicy(policy: Policy): Observable<Policy> {
    return this.client.post<Policy>('/api/user/AddNewPolicy', policy);
  }

  public AddComponent(componentId: string): Observable<ProjectApi.Component> {
    return this.client.post<ProjectApi.Component>(`/api/user/AddComponent/${componentId}`, '');
  }

  public RemoveComponent(componentId: string): Observable<boolean> {
    return this.client.delete<boolean>(`/api/user/RemoveComponent/${componentId}`);
  }

  public AddSubComponent(componentId: string, subComponentId: string): Observable<ProjectApi.Component> {
    return this.client.post<ProjectApi.Component>(`/api/user/AddSubComponent/${componentId}/${subComponentId}`, '');
  }

  public RemoveSubComponent(componentId: string, subComponentId: string): Observable<boolean> {
    return this.client.delete<boolean>(`/api/user/RemoveSubComponent/${componentId}/${subComponentId}`);
  }

  public SaveComponents(checkedComponents: ProjectApi.Component[]): Observable<boolean> {
    return this.client.post<boolean>('/api/user/SaveComponents', checkedComponents);
  }

  public ReplaceSubComponents(componentId: string, components: ProjectApi.Component[]): Observable<ProjectApi.Component[]> {
    return this.client.put<ProjectApi.Component[]>(`/api/user/ReplaceSubComponents/${componentId}`, components);
  }

  public ReOrderComponents(components: ProjectApi.Component[]): Observable<boolean> {
    return this.client.put<boolean>('/api/user/ReOrderComponents', components);
  }

  public ReOrderSubComponents(componentId: string, components: ProjectApi.Component[]): Observable<ProjectApi.Component[]> {
    return this.client.put<ProjectApi.Component[]>(`/api/user/ReOrderSubComponents/${componentId}`, components);
  }

  public SaveSub1Component(componentId: string, sub1Id: string, component: ProjectApi.Component): Observable<ProjectApi.Component> {
    return this.client.put<ProjectApi.Component>(`/api/user/SaveSub1Component/${componentId}/${sub1Id}`, component);
  }

  public SaveComponent(component: ProjectApi.Component): Observable<ProjectApi.Component> {
    return this.client.put<ProjectApi.Component>('/api/user/SaveComponent', component);
  }

  public GetDelegates(): Observable<UserApi.CMUser> {
    return this.client.get<UserApi.CMUser>('/api/user/GetDelegates');
  }

  public GetMyDelegates(): Observable<UserApi.CMUser> {
    return this.client.get<UserApi.CMUser>('/api/user/GetMyDelegates');
  }

  public GetUserActivity(): Observable<UserApi.UserActivity[]> {
    return this.client.get<UserApi.UserActivity[]>('/api/user/GetUserActivity');
  }

  public GetUserActivityPaged(
    projectId: string,
    currentPage: number,
    orderBy: string,
    isReversed: boolean,
    searchValue: string
  ): Observable<PagedItem> {
    const search = {
      item: searchValue
    };
    return this.client.post<PagedItem>(`/api/user/GetUserActivityPaged/${currentPage}/${orderBy}/${isReversed}`, search);
  }

  public GetPermission(group: string, permissionId: string): Observable<TeamPermissionType> {
    return this.client.get<TeamPermissionType>(`/api/user/GetPermission/${group}/${permissionId}`);
  }

  public ReOrderPolicies(policies: Array<Policy>): Observable<boolean> {
    return this.client.put<boolean>('/api/user/ReOrderPolicies', policies);
  }

  public AddPermissionToPolicy(policyId: string, perm: Permission): Observable<boolean> {
    return this.client.put<boolean>(`/api/user/AddPermissionToPolicy/${policyId}`, perm);
  }

  public RemovePermissionFromPolicy(policyId: string, permissionId: string): Observable<boolean> {
    return this.client.put<boolean>(`/api/user/RemovePermissionFromPolicy/${policyId}/${permissionId}`, '');
  }

  public RemovePolicy(policyId: string): Observable<boolean> {
    return this.client.delete<boolean>(`/api/user/RemovePolicy/${policyId}`);
  }

  public GetProfile(userId: string): Observable<UserApi.UserProfile> {
    return new Observable<UserApi.UserProfile>(obs => {
      const userProfile = this.GetProfileFromCache(userId);

      if (userProfile === null) {
        this.client.get(`/api/user/GetUserProfile/${userId}`).subscribe(
          response => {
            const userProfile = response as UserApi.UserProfile;
            this.AddProfileToCache(userProfile);
            obs.next(userProfile);
            obs.complete();
          },
          err => {
            obs.error(err);
          }
        );
      } else {
        obs.next(userProfile);
        obs.complete();
      }
    });
  }

  private AddProfileToCache(userProfile: UserApi.UserProfile): void {
    const hasProfile = this.CachedProfiles.filter(x => x.UserId === userProfile.UserId);
    if (hasProfile.length <= 0) {
      this.CachedProfiles.push(userProfile);
    }
  }

  private GetProfileFromCache(userId: string): UserApi.UserProfile {
    const hasProfile = this.CachedProfiles.filter(x => x.UserId === userId);
    if (hasProfile.length > 0) {
      return hasProfile[0];
    }

    return null;
  }

  public PrintCache(): void {
    for (const item of this.CachedProfiles) {
      console.log(item);
    }
  }
}
