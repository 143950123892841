import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { GlobalService } from '../../../services/global.service';
import { Permission } from '../models/permission';
import { FormControl } from '@angular/forms';
import { UserPermissionServiceSerializer } from '../models/userPermissionSerializer';

@Component({
  selector: 'permissions',
  templateUrl: './permission.component.html',
  styleUrls: []
})
export class PermissionComponent implements OnInit {
  constructor(private globalService: GlobalService) {}
  searchPermission = new FormControl();
  isShowSelected = false;

  @Input() permissions: Array<Permission>;
  @Input() cPermissions: Array<Permission>;
  @Output() addPermission = new EventEmitter<Permission>();
  @Output() removePermission = new EventEmitter<Permission>();
  @Input() permissionGroup: string;
  @Input() view: PermissionView = PermissionView.None;

  selectedPermission: Permission = null;

  serializer: UserPermissionServiceSerializer = new UserPermissionServiceSerializer();
  ngOnInit(): void {
    this.searchPermission.valueChanges.debounceTime(400).subscribe(value => {
      this.executeFilter();
      //var sValue = value as string;
      //this.permissions = this.cPermissions.filter(x => x.Name.toLowerCase().indexOf(sValue.toLowerCase()) > -1);
    });

    //this.changePermissionStatus.subscribe(result => {
    //    //result["Active"] = result.Permission;
    //    //result["Active"] = result.Permission;
    //});
  }

  addAPermission(permission: Permission) {
    this.addPermission.next(permission);
  }

  removeAPermission(permission: Permission) {
    this.removePermission.next(permission);
  }

  showSelectedPermissions() {
    this.isShowSelected = !this.isShowSelected;
    this.executeFilter();
  }

  executeFilter() {
    var sValue = this.searchPermission.value as string;

    if (sValue) {
      if (sValue.length > 0) {
        if (this.isShowSelected) {
          if (this.permissionGroup.toLowerCase() === 'project') {
            this.permissions = this.cPermissions.filter(
              x =>
                x.Name.toLowerCase().indexOf(sValue.toLowerCase()) > -1 &&
                x['Active'] === true &&
                x.Group.toLowerCase() === this.permissionGroup.toLowerCase()
            );
          } else {
            this.permissions = this.cPermissions.filter(x => x.Name.toLowerCase().indexOf(sValue.toLowerCase()) > -1 && x['Active'] === true);
          }
        } else {
          if (this.permissionGroup.toLowerCase() === 'project') {
            this.permissions = this.cPermissions.filter(
              x => x.Name.toLowerCase().indexOf(sValue.toLowerCase()) > -1 && x.Group.toLowerCase() === this.permissionGroup.toLowerCase()
            );
          } else {
            this.permissions = this.cPermissions.filter(x => x.Name.toLowerCase().indexOf(sValue.toLowerCase()) > -1);
          }
        }
      } else {
        if (this.permissionGroup.toLowerCase() === 'project') {
          this.permissions = this.cPermissions.filter(x => x.Group.toLowerCase() === this.permissionGroup.toLowerCase());
        } else {
          this.permissions = this.cPermissions;
        }
      }
    } else {
      if (this.isShowSelected) {
        if (this.permissionGroup.toLowerCase() === 'project') {
          this.permissions = this.cPermissions.filter(x => x['Active'] === true && x.Group.toLowerCase() === this.permissionGroup.toLowerCase());
        } else {
          this.permissions = this.cPermissions.filter(x => x['Active'] === true);
        }
      } else {
        if (this.permissionGroup.toLowerCase() === 'project') {
          this.permissions = this.cPermissions.filter(x => x.Group.toLowerCase() === this.permissionGroup.toLowerCase());
        } else {
          this.permissions = this.cPermissions;
        }
      }
    }
  }
}

export enum PermissionView {
  None,
  DropBox
}
