import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'user-base',
  templateUrl: './user.base.component.html'
})
export class UserBaseComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit() {
    //this.authService.isUserLoggedIn().subscribe(val => {
    //    this.authService.isLoggedIn.next(true);
    //}, error => {
    //    this.authService.isLoggedIn.next(false);
    //    });
  }
}
