import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-feature',
  templateUrl: './home-feature.component.html',
  styleUrls: ['./home-feature.component.css']
})
export class HomeFeatureComponent implements OnInit {
  isLoggedIn: boolean;
  constructor() {}

  ngOnInit() {}
}
