import { Permission } from './permission';

export class UserPermissionServiceSerializer {
  SerializePermission(perm: Permission) {
    if (perm.Access.AccessLevelId.toLowerCase() === 'fullaccess') {
      perm.Access['_t'] = ['FullAccessLevel'];
    } else if (perm.Access.AccessLevelId.toLowerCase() === 'denyaccess') {
      perm.Access['_t'] = ['DenyAccessLevel'];
    }

    if (perm.MetaData != null) {
      for (let item of perm.MetaData) {
        if (item.TypeId.toLowerCase() === 'integer') {
          item['_t'] = ['IntegerDataType'];
        }
      }
    }
  }
}
