import { ValueTypes } from './valueTypes.enum';

export class InfoItem {
  InfoItemId: string;
  Name: string;
  Value: any;
  ValueType: ValueTypes;
  ReadableValue: string;
  TypeName: string;
  IsLocked: boolean;
  IsHidden: boolean;
}
