import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as UserApi from '../../../user/models';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'modal-delegate-settings',
  templateUrl: './modal.delegate.settings.html'
})
export class ModalDelegateSettings implements OnInit {
  closeResult: string;
  @Input() delegateUser: UserApi.Delegate;
  @Input() userClassifications: UserApi.Classification[] = [];
  model = 1;
  // classification: Classification;

  constructor(public activeModal: NgbActiveModal, private client: HttpClient) {}

  // todo: move http to service
  ngOnInit(): void {
    this.client.get('/api/user/GetClassifications').subscribe(
      response => {
        this.userClassifications = response as UserApi.Classification[];
      },
      err => {}
    );
  }

  // todo: move http to service
  saveDelegate() {
    this.client.post('/api/user/SaveDelegateUser', this.delegateUser).subscribe(
      response => {
        this.activeModal.close(this.delegateUser);
      },
      err => {}
    );
  }

  classificationChange(classification: UserApi.Classification) {
    this.delegateUser.Title = classification.Name;
  }

  close() {
    this.activeModal.dismiss();
  }
}
