import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { ImageInfo } from '../models/imageinfo';
import { CivCastImageService } from '../image.service';
import { ImageSizes } from '../picture/picture.component';
import { PhotoViews } from '../models/photoview.enum';

@Component({
  selector: 'civcast-gallery',
  templateUrl: './civcast.gallery.component.html',
  styleUrls: ['./gallery.css']
})
export class CivCastGalleryComponent implements OnInit {
  @Input() imageList: Array<ImageInfo> = new Array<ImageInfo>();
  @Input() photoView: PhotoViews = PhotoViews.Full;
  currentImage: ImageInfo = null;
  imageView: string = 'normal';
  iSize: ImageSizes = ImageSizes.Thumbnail;

  constructor(private imageService: CivCastImageService) {}

  ngOnInit(): void {
    if (!this.imageList) {
      this.imageList = new Array<ImageInfo>();
    }
    this.imageService.addPhotoHandler$.subscribe(result => {
      this.imageList.push(result);
    });

    this.imageService.photoMarkDeletionHandler.subscribe(result => {
      for (var x = 0; x < this.imageList.length; x++) {
        var image = this.imageList[x];

        if (image.ImageId === result.ImageId) {
          image.MarkForDeletion = result.MarkForDeletion;
          break;
        }
      }
    });

    this.imageService.removePhotoHandler$.subscribe(result => {
      var idx = this.imageList.indexOf(result);
      this.imageList.splice(idx, 1);
    });

    this.imageService.removePhotoByIdHandler$.subscribe(result => {
      for (var x = 0; x < this.imageList.length; x++) {
        var image = this.imageList[x];

        if (image.ImageId === result) {
          this.imageList.splice(x, 1);
          break;
        }
      }
    });
  }

  removeImage(image: ImageInfo) {
    if (!image.UploadDateTime) {
      this.imageService.removeImage(image);
      this.imageService.markPhotoForDeletion(image);
    } else {
      this.imageService.markPhotoForDeletion(image);
    }
  }

  editImage(image: ImageInfo) {
    this.currentImage = image;
    this.imageView = 'edit';
  }

  viewImage(image: ImageInfo) {
    this.currentImage = image;
    this.imageView = 'view';
  }

  closeViewer(evt) {
    this.imageView = 'Normal';
  }
}
