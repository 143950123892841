import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as UserApi from '../../../user/models';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'modal-foreman-deactivate',
  templateUrl: './foreman.deactivate.modal.html'
})
export class ForemanDeactivateModal implements OnInit {
  @Input() foreman: UserApi.Foreman;
  @Input() foremen: UserApi.Foreman[] = [];
  public selectedForeman: UserApi.Foreman;

  constructor(private activeModal: NgbActiveModal, private employeeService: EmployeeService) {}

  ngOnInit(): void {
    var rIdx = null;
    for (var x = 0; x < this.foremen.length; x++) {
      var f = this.foremen[x];

      if (f.ForemanId === this.foreman.ForemanId) {
        rIdx = x;
        break;
      }
    }

    if (rIdx != null) {
      this.foremen.splice(rIdx, 1);
    }
  }

  changeForeman() {
    var formanId = 'null';
    if (this.selectedForeman == undefined || this.selectedForeman == null) {
      formanId = 'null';
    } else {
      formanId = this.selectedForeman.ForemanId;
    }

    this.employeeService.ChangeForeman(this.foreman.ForemanId, formanId).subscribe(x => {
      this.employeeService.ForemanActivation(this.foreman.Id, false).subscribe(z => {
        this.activeModal.close();
      });
    });
  }

  close() {
    this.activeModal.dismiss();
  }
}
