import { Component } from '@angular/core';
import Amplify, { Auth, Hub } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { AuthService } from './services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from './modules/user/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public loading = false;
  isLoggedIn = false;
  showNav = false;

  constructor(private authService: AuthService, private userService: UserService, private aRoute: ActivatedRoute, private router: Router) {
    Amplify.configure({
      Auth: environment.auth
    });

    Hub.listen('auth', data => {
      switch (data.payload.event) {
        case 'signIn':
          this.userService.initializeCMUser().subscribe(result => {
            this.router.navigate(['/user', 'project-list']);
            this.authService.isLoggedIn.next(true);
          });

          break;
        case 'signIn_failure':
          // this.errorService.handleError("There was an issue signing in. Please try again.", data, true);
          break;
        default:
          break;
      }
    });

    Auth.currentUserInfo().then(result => {
      if (result) {
        Auth.currentAuthenticatedUser()
          .then(result => {
            this.isLoggedIn = true;
            this.authService.isLoggedIn.next(true);
            if (window.location.pathname === '/' || window.location.pathname === '/home') {
              this.router.navigate(['/user', 'project-list']);
            }
          })
          .catch(() => {});
      }
    });
  }
}
