import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Rx';
import { Subject } from 'rxjs/Subject';
import * as ProjectApi from './models';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class UserProjectService {
  projectComponentUpdate = new Subject<boolean>();
  projectComponentUpdate$ = this.projectComponentUpdate.asObservable();

  mainProject = new Subject<ProjectApi.Project>();
  mainProject$ = this.mainProject.asObservable();

  project: ProjectApi.Project;

  constructor(private http: HttpClient) {}

  public HighlightProject(projectId: string, isHighlighted: boolean): Observable<boolean> {
    return this.http.put<boolean>(`/api/project/HighlightProject/${projectId}/${isHighlighted}`, '');
  }

  public SaveProject(project: ProjectApi.Project): Observable<ProjectApi.Project> {
    return this.http.post<ProjectApi.Project>('/api/project/SaveProject', project);
  }

  public GetProject(projectId: string): Observable<ProjectApi.Project> {
    return this.http.get<ProjectApi.Project>(`/api/project/GetProject/${projectId}`);
  }

  public GetProjectSection(projectId: string, sectionName): Observable<any> {
    return this.http.get<any>(`/api/project/GetComponent/${projectId}/${sectionName}`);
  }

  public GetRecentProjects(): Observable<ProjectApi.RecentProject[]> {
    return this.http.get<ProjectApi.RecentProject[]>('/api/project/GetRecentProjects');
  }

  public GetAllProjects(): Observable<ProjectApi.Project[]> {
    return this.http.get<ProjectApi.Project[]>('/api/project/GetAllProjects');
  }

  public GetTeamProjects(): Observable<ProjectApi.Project[]> {
    return this.http.get<ProjectApi.Project[]>('/api/project/GetTeamProjects');
  }

  public GetProjects(): Observable<ProjectApi.Project[]> {
    return this.http.get<ProjectApi.Project[]>('/api/project/GetProjects');
  }

  public CreateProject(project: ProjectApi.Project): Observable<ProjectApi.Project> {
    return this.http.post<ProjectApi.Project>('/api/project/CreateProject', project);
  }

  public GetProjectComponentInfo(projectId: string): Observable<ProjectApi.Component[]> {
    return this.http.get<ProjectApi.Component[]>(`/api/project/GetComponentInfo/${projectId}`);
  }

  public ReOrderComponents(projectId: string, orderedComponents: ProjectApi.Component[]): Observable<boolean> {
    return this.http.put<boolean>(`/api/project/ReOrderComponents/${projectId}`, orderedComponents);
  }

  public AddSubComponent(projectId: string, componentId: string, subComponentId: string): Observable<ProjectApi.Component> {
    return this.http.post<ProjectApi.Component>(`/api/project/AddSubComponent/${projectId}/${componentId}/${subComponentId}`, '');
  }

  public RemoveSubComponent(projectId: string, componentId: string, subComponentId: string): Observable<boolean> {
    return this.http.delete<boolean>(`/api/project/RemoveSubComponent/${projectId}/${componentId}/${subComponentId}`);
  }

  public AddComponent(projectId: string, componentId: string): Observable<ProjectApi.Component> {
    return this.http.post<ProjectApi.Component>(`/api/project/AddComponent/${projectId}/${componentId}`, '');
  }

  public RemoveComponent(projectId: string, componentId: string): Observable<boolean> {
    return this.http.delete<boolean>(`/api/project/RemoveComponent/${projectId}/${componentId}`);
  }

  public ReplaceProjectComponents(projectId: string, components: ProjectApi.Component[]): Observable<ProjectApi.Component[]> {
    return this.http.put<ProjectApi.Component[]>(`/api/project/ReplaceComponents/${projectId}`, components);
  }

  public ReplaceSubComponents(
    projectId: string,
    componentId: string,
    components: ProjectApi.Component[]
  ): Observable<ProjectApi.Component[]> {
    return this.http.put<ProjectApi.Component[]>(`/api/project/ReplaceSubComponents/${projectId}/${componentId}`, components);
  }

  public SaveSub1Component(
    projectId: string,
    componentId: string,
    sub1Id: string,
    component: ProjectApi.Component
  ): Observable<ProjectApi.Component> {
    return this.http.put<ProjectApi.Component>(`/api/project/SaveSub1Component/${projectId}/${componentId}/${sub1Id}`, component);
  }

  public SaveComponent(projectId: string, component: ProjectApi.Component): Observable<ProjectApi.Component> {
    return this.http.put<ProjectApi.Component>(`/api/project/SaveComponent/${projectId}`, component);
  }

  public RemoveDelegateFromComponents(delegateId: string): Observable<boolean> {
    return this.http.delete<boolean>(`/api/project/RemoveDelegateFromComponents/${delegateId}`);
  }

  public SearchProjects(searchInfo: string): Observable<ProjectApi.Project[]> {
    const search = {
      searchInfo
    };
    return this.http.post<ProjectApi.Project[]>('/api/project/SearchProjects', search);
  }
}
