import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'unauthorized',
  templateUrl: './auth.unauthorized.component.html'
})
export class UnauthorizedComponent implements OnInit {
  pageTitle: string;

  constructor(private router: ActivatedRoute) {}

  ngOnInit(): void {
    this.pageTitle = this.router.parent.data['PageTitle'];
  }
}
