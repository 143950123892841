import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../services/user.service';
import { User } from '../models/user';
import { ModalProjectSideNav } from '../../user.project/components/settings/project.sidenav.modal';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  user: User;
  constructor(private route: ActivatedRoute, private router: Router, private userService: UserService, private modalService: NgbModal) {}

  ngOnInit() {
    if (this.userService.storedUser == null) {
      this.userService.user.subscribe(user => {
        console.log(user);
        this.user = user;
      });
    } else {
      this.user = this.userService.storedUser;
    }
  }

  projectNavSettings() {
    const modalRef = this.modalService.open(ModalProjectSideNav, { size: 'lg' });

    modalRef.result.then(result => {});
  }

  goToAccountProfile(route) {
    window.location.replace(environment.id_server + route + '?redirecturi=' + encodeURIComponent(window.location.href));
  }
}
