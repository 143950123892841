import { Component, OnInit, Input } from '@angular/core';
import { InfoItem } from '../../models/InfoItem';

@Component({
  selector: 'string-element',
  templateUrl: './string.element.html'
})
export class StringElement implements OnInit {
  @Input() infoItem: InfoItem;
  @Input() mode: string;

  ngOnInit(): void {}
}
