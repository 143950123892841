import { DailyProjectComponent } from '../../daily.project.component';
import { Photo } from './photo';

export class PhotosComponent extends DailyProjectComponent {
  constructor() {
    super();
    this.Photos = new Array<Photo>();
  }

  Photos: Array<Photo>;
}
