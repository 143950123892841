import { Component, OnInit, ComponentFactoryResolver, ViewContainerRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from './settings.service';
import { UserProjectService } from '../../user.project.service';
import { ComponentFactory } from '../../models/componentFactory';
import * as ProjectApi from '../../models';
import { UserSharedService } from '../../../user/services/user.shared.sevice';

@Component({
  selector: 'settings-sub1',
  templateUrl: './settings.sub1.component.html',
  styleUrls: ['../project.component.css']
})
export class SettingsSub1Component implements OnInit {
  @ViewChild('component', { static: false }) target: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private userProjectService: UserProjectService,
    private userSharedService: UserSharedService,
    private settingsService: SettingsService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.settingsService.parentName = params.parent;
      this.settingsService.sub1 = params.sub1;

      if (this.settingsService.componentType.toLowerCase() === 'project') {
        this.userProjectService.GetProjectSection(this.settingsService.pId, this.settingsService.parentName).subscribe(comp => {
          if (comp) {
            var asComp = comp as ProjectApi.Component;

            if (asComp.Components) {
              var subComp = asComp.Components.filter(
                x => x.ComponentIdentifier.toLowerCase().indexOf(this.settingsService.sub1.toLowerCase()) !== -1
              );

              if (subComp.length > 0) {
                var factory = new ComponentFactory(this.target, this.componentFactoryResolver);
                factory.InitializeComponent(subComp[0], this.settingsService.componentType);
              }
            }
          }
        });
      } else {
        this.userSharedService.GetComponent(this.settingsService.parentName).subscribe(comp => {
          if (comp) {
            var asComp = comp as ProjectApi.Component;

            if (asComp.Components) {
              var subComp = asComp.Components.filter(
                x => x.ComponentIdentifier.toLowerCase().indexOf(this.settingsService.sub1.toLowerCase()) !== -1
              );

              if (subComp.length > 0) {
                var factory = new ComponentFactory(this.target, this.componentFactoryResolver);
                factory.InitializeComponent(subComp[0], this.settingsService.componentType);
              }
            }
          }
        });
      }
    });
  }
}
