import { Component, OnInit, Input, ComponentFactoryResolver } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserProjectService } from '../../user.project.service';
import * as ProjectApi from '../../../user.project/models';
import { InfoItem } from '../../../civcast.form/models/InfoItem';
import { ComponentIdentifier } from '../../models/componentIdentifier';
import { DatePipe } from '@angular/common';
import { ValueTypes } from '../../../civcast.form/models/valueTypes.enum';
import { InfoComponent } from './models/infoComponent';
import { IComponent } from '../../models/IComponent';
import { Observable } from 'rxjs/Observable';
import { ValidationResult } from '../../models/validationResult';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';

@Component({
  selector: 'project-info-input',
  templateUrl: './project.info.input.component.html',
  styleUrls: ['../project.component.css', './info.css']
})
export class ProjectInfoInputComponent implements OnInit, IComponent {
  @Input() infoItem: InfoItem;
  @Input() infoComponent: InfoComponent = new InfoComponent();
  cMode: string = 'view';
  project: ProjectApi.Project;
  inputProject: ProjectApi.Project;
  inputInfoComponent: InfoComponent = new InfoComponent();
  projectHasChanges: boolean = false;
  @Input() permissionType: AccessLevel;

  constructor(private userProjectService: UserProjectService, private projectService: UserProjectService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.data.subscribe(params => {
      this.permissionType = params.permissionType;
      this.project = this.userProjectService.project;

      this.infoComponent.Properties = [];

      if (this.userProjectService.project !== null && this.userProjectService.project !== undefined) {
        this.project = this.userProjectService.project;
        this.inputProject = Object.assign({}, this.userProjectService.project);
        this.userProjectService.GetProjectSection(this.project.Id, ComponentIdentifier.INFO).subscribe(comp => {
          this.infoComponent = comp;
          this.inputInfoComponent = Object.assign({}, comp);
        });
      } else {
        this.userProjectService.mainProject$.subscribe(project => {
          this.project = project;
          this.inputProject = Object.assign({}, project);
          this.userProjectService.GetProjectSection(project.Id, ComponentIdentifier.INFO).subscribe(comp => {
            this.infoComponent = comp;
            this.inputInfoComponent = Object.assign({}, comp);
          });
        });
      }
    });

    //this.route.parent.params.subscribe(params => {
    //    var projectId = params['id'];
    //    this.userProjectService.GetProject(projectId).subscribe(project => {

    //    });
    //});
  }

  fixProperties(props: InfoItem[]) {
    if (props) {
      var dateItems = props.filter(x => x.ValueType == ValueTypes.Date);
      var datePipe = new DatePipe('en');
      for (let d of dateItems) {
        d.Value = datePipe.transform(d.Value, 'MM/dd/yyyy') as String;
      }
    }
  }

  setHasProjectChanges(item) {
    this.projectHasChanges = true;
  }

  infoDragEnd() {
    this.inputInfoComponent['_t'] = ['BaseEntity', 'Component', 'ProjectComponent', 'InfoComponent'];

    this.projectService.SaveComponent(this.project.Id, this.inputInfoComponent).subscribe(comp => {
      var iComp = comp as InfoComponent;
      this.fixProperties(iComp.Properties);
      this.infoComponent = iComp;
      this.inputInfoComponent = Object.assign({}, iComp);
      //this.growlService.addAlert("Project Info settings have been saved", AlertType.SUCCESS);
    });
  }

  SaveInfo() {
    this.Save().subscribe();
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.inputInfoComponent['_t'] = ['BaseEntity', 'Component', 'ProjectComponent', 'InfoComponent'];
      var items = [];
      if (this.projectHasChanges) {
        this.projectService.SaveProject(this.inputProject).subscribe(project => {
          this.project = project;
          this.userProjectService.project = project;
          this.inputProject = Object.assign({}, project);
          this.userProjectService.projectComponentUpdate.next(true);
          this.projectHasChanges = false;

          this.saveComponent().subscribe(result => {
            obs.next(true);
            obs.complete();
          });
        });
      } else {
        this.saveComponent().subscribe(result => {
          obs.next(true);
          obs.complete();
        });
      }
    });
  }

  saveComponent(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.projectService.SaveComponent(this.project.Id, this.inputInfoComponent).subscribe(comp => {
        var iComp = comp as InfoComponent;
        this.fixProperties(iComp.Properties);
        this.infoComponent = iComp;
        this.inputInfoComponent = Object.assign({}, iComp);
        //this.growlService.addAlert("Project Info settings have been saved", AlertType.SUCCESS);
        obs.next(true);
        obs.complete();
      });
    });
  }

  changeMode(mode: string) {
    this.cMode = mode;

    if (this.cMode == 'input') {
    }
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var vResult = new ValidationResult();
      vResult.IsValid = true;
      obs.next(vResult);
      obs.complete();
    });
  }

  Clear(): Observable<boolean> {
    return Observable.of(true);
  }

  Remove(): Observable<boolean> {
    return Observable.of(true);
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
