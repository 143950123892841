import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isLoggedIn: boolean;
  constructor(private authService: AuthService) { 
    this.authService.isLoggedIn$.subscribe(val => {
      console.log("SubLogged", val);
      this.isLoggedIn = val;            
  });
  }

  ngOnInit() {
  }

}
