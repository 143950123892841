import { Injectable, EventEmitter } from '@angular/core';
import { CanActivate, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessControlService } from './services/access.control.service';
import { AccessLevelTypes } from './models/accesslevel.enum';
import { TeamService } from '../user.project/components/team/team.service';

@Injectable()
export class PermissionGuardService implements CanActivate {
  constructor(private accessControlServer: AccessControlService, private teamService: TeamService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return new Observable<boolean>(obs => {
      var permissionId = route.data['permissionId'] as string;
      var permissionGroup = route.data['permissionGroup'] as string;
      this.accessControlServer.GetAccessLevel(permissionId, permissionGroup).subscribe(result => {
        if (result.AccessLevelId === AccessLevelTypes.DENY) {
          this.router.navigate(['/unauthorized']);
          obs.next(false);
        } else if (result.AccessLevelId === AccessLevelTypes.READ_ONLY) {
          this.teamService.setReadOnlyProp(true);
          obs.next(true);
        } else if (result.AccessLevelId === AccessLevelTypes.FULL_ACCESS) {
          obs.next(true);
        } else {
          this.router.navigate(['/unauthorized']);
          obs.next(false);
        }

        obs.complete();
      });
    });
  }
}
