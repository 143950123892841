import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { WeatherComponent } from './models/weather.component';
import { WeatherService } from './weather.service';
import { UserProjectService } from '../../user.project.service';
import { ComponentIdentifier } from '../../models/componentIdentifier';
import { DailyLogComponent } from '../dailylog/dailylogcomponent';
import { Sky } from './models/Sky';
import { Temperature } from './models/Temperature';
import { Observable, Subject } from 'rxjs/Rx';
import { IComponent, ComponentView } from '../../models/IComponent';
import { ValidationResult } from '../../models/validationResult';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { SelectedData } from '../../../core/SelectedData';
import { ToastrService } from 'ngx-toastr';
import { DataBoundComponent } from '../../models/DataboundComponent';

@Component({
  selector: 'weather',
  templateUrl: './weather.component.html',
  styleUrls: ['../project.component.css']
})
export class ProjectWeatherComponent extends DataBoundComponent<WeatherComponent> implements OnInit, IComponent {
  weatherComponent: WeatherComponent;

  @Input() month: number;
  @Input() day: number;
  @Input() year: number;
  @Input() projectId: string = null;
  @Input() dateInfo: Date;
  @Input() permissionType: AccessLevel;
  @Input() view: ComponentView = ComponentView.Normal;
  @Input() userId: string = null;
  @Input() skySelectData: Array<any>;
  skyDropDownSettings: any = {};

  public loading: boolean = false;
  public selectedTemp: Sky = null;
  public selectedSky: Temperature = null;

  constructor(
    private route: ActivatedRoute,
    private weatherService: WeatherService,
    private userProjectService: UserProjectService,
    private toastrService: ToastrService
  ) {
    super();
    this.weatherComponent = new WeatherComponent();
    this.weatherComponent.TempId = 0;
    this.weatherComponent.SkyId = 0;
  }

  public DataBindByProjectInfo(): Observable<WeatherComponent> {
    return this.weatherService.GetWeatherComponent(this.projectId, this.year, this.month, this.day);
  }
  public DataBindByUserInfo(): Observable<WeatherComponent> {
    return this.weatherService.GetWeatherComponentByUser(this.projectId, this.userId, this.year, this.month, this.day);
  }

  ngOnInit() {
    this.loading = true;
    if (this.projectId === null) {
      this.route.parent.params.subscribe(params => {
        this.projectId = params.id;
        this.Initialize();
      });
    } else {
      this.Initialize();
    }

    this.skyDropDownSettings = {
      singleSelection: false,
      idField: 'ItemId',
      textField: 'ItemText',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: true
    };
  }

  Initialize() {
    var subscriptions = new Array<any>();

    this.InitializeDataCView(this.view).subscribe(
      result => {
        this.weatherComponent = result;

        if (this.weatherComponent.CreateDate === null) {
          this.userProjectService.GetProjectSection(this.projectId, ComponentIdentifier.DAILY_LOG).subscribe(comp => {
            var dLog = comp as DailyLogComponent;

            if (dLog) {
              var weatherComp = dLog.Components.filter(x => x.ComponentIdentifier.indexOf(ComponentIdentifier.WEATHER) !== -1);

              if (weatherComp.length > 0) {
                var wComp = weatherComp[0] as WeatherComponent;
                this.weatherComponent.Skys = Object.assign([], wComp.Skys);
                this.weatherComponent.Temperatures = Object.assign([], wComp.Temperatures);

                this.setupSkyData();
              }
            }

            this.loading = false;
          });
        } else {
          this.setupSkyData();
          this.loading = false;
        }
      },
      err => {
        this.loading = false;
      }
    );

    // Observable.forkJoin(subscriptions).subscribe(info => {

    //     this.weatherComponent = info[0] as WeatherComponent;

    //     if (this.weatherComponent.CreateDate === null) {
    //         this.userProjectService.GetProjectSection(this.projectId, ComponentIdentifier.DAILY_LOG).subscribe(comp => {
    //             var dLog = comp as DailyLogComponent;

    //             if (dLog) {
    //                 var weatherComp = dLog.Components.filter(x => x.ComponentIdentifier.indexOf(ComponentIdentifier.WEATHER) !== -1);

    //                 if (weatherComp.length > 0) {

    //                     var wComp = weatherComp[0] as WeatherComponent;
    //                     this.weatherComponent.Skys = Object.assign([], wComp.Skys);
    //                     this.weatherComponent.Temperatures = Object.assign([], wComp.Temperatures);

    //                     this.setupSkyData();
    //                 }
    //             }

    //             this.loading = false;
    //         });
    //     } else {
    //         this.setupSkyData();
    //         this.loading = false;
    //     }
    // });
  }

  setupSkyData() {
    this.skySelectData = new Array();

    for (let c of this.weatherComponent.Skys) {
      var pData: SelectedData = {
        ItemId: c.SkyId.toString(),
        ItemText: c.Name
      };

      this.skySelectData.push(pData);
    }
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      this.loading = true;

      if (this.view === ComponentView.FinalApprover) {
        Observable.timer(100).subscribe(
          result => {
            obs.complete();
            obs.next(true);
            this.loading = false;
          },
          err => {
            obs.error(err);
          }
        );
      } else {
        this.weatherService.SaveWeatherComponent(this.projectId, this.weatherComponent).subscribe(
          comp => {
            this.weatherComponent = comp;
            this.loading = false;
            obs.next(true);
            obs.complete();
          },
          error => {
            this.loading = false;
            obs.next(false);
            obs.error(error);
          }
        );
      }
    });
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var vResult = new ValidationResult();

      if (this.error.hasError) {
        vResult.IsValid = false;
        this.toastrService.error('Notes has an error that is unresolved Cannot not save at this time: ' + this.error.messages.join(','));
      } else {
        vResult.IsValid = true;
      }

      obs.next(vResult);
      obs.complete();
    });
  }

  Clear(): Observable<boolean> {
    this.loading = true;
    return new Observable<boolean>(obs => {
      this.weatherComponent.SkyId = null;
      this.weatherComponent.TempId = null;
      this.weatherComponent.SelectedSkys = new Array();
      //this.setupSkyData();
      this.loading = false;

      obs.next(true);
      obs.complete();
    });
  }

  onItemSelect(event) {}

  onSelectAll(event) {}

  Remove(): Observable<boolean> {
    this.loading = true;
    return new Observable<boolean>(obs => {
      this.weatherService.DeleteWeatherComponent(this.projectId, this.year, this.month, this.day).subscribe(result => {
        this.Clear().subscribe(result => {
          this.loading = false;
          obs.next(true);
          obs.complete();
        });
      });
    });
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
