import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-above-the-fold',
  templateUrl: './above-the-fold.component.html',
  styleUrls: ['./above-the-fold.component.css']
})
export class AboveTheFoldComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
