export class CostCode {
  CostCodeId: string;
  Phase: string;
  Description: string;
  Hours: number;
  Units: number;
  UnitOfMeasure: string;
  UserValue: number;
  QtyCompl: number;
}
