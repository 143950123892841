import { Component, OnInit } from '@angular/core';
import { Auth } from 'aws-amplify';

@Component({
  selector: 'app-auth-signout-callback',
  templateUrl: './auth-signout-callback.component.html',
  styleUrls: ['./auth-signout-callback.component.css']
})
export class AuthSignoutCallbackComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    Auth.signOut();
  }
}
