import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { InfoItem } from '../../models/InfoItem';

@Component({
  selector: 'date-element',
  templateUrl: './date.element.html'
})
export class DateElement implements OnInit, AfterViewInit {
  @Input() infoItem: InfoItem;
  @Input() mode: string;
  selectedDate: NgbDateStruct;
  ngOnInit(): void {}

  ngAfterViewInit(): void {
    if (this.infoItem) {
      if (this.infoItem.Value !== null && this.infoItem.Value !== undefined) {
        var dd = new Date(this.infoItem.Value.toString());
        this.selectedDate = {
          year: dd.getFullYear(),
          month: dd.getMonth() + 1,
          day: dd.getDate()
        };
      }
    }
  }

  dateChanged() {
    this.infoItem.Value = new Date(this.selectedDate.year, this.selectedDate.month - 1, this.selectedDate.day);
    //if (this.infoItem.Value === null || this.infoItem.Value === undefined) {
    //    this.infoItem["Value"] = new Date();
    //}
    //var dd = this.infoItem.Value as Date;
    //this.selectedDate = {
    //    year: dd.getFullYear(),
    //    month: dd.getMonth(),
    //    day: dd.getDate()
    //};
  }
}
