import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IComponent } from '../../user.project/models/IComponent';
import { Observable, Subject } from 'rxjs';
import { ValidationResult } from '../../user.project/models/validationResult';
import { SettingsService } from '../../user.project/components/settings/settings.service';
import { GrowlService } from '../../custom.controls/growl/growl.service';
import { UserSharedService } from '../../user/services/user.shared.sevice';
import { UserProjectService } from '../../user.project/user.project.service';
import { TimeCardComponent } from '../models/timeCardComponent';
import * as ProjectApi from '../../user.project/models';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-time-reasons-settings',
  templateUrl: './time-reasons-settings.component.html',
  styleUrls: ['./time-reasons-settings.component.css']
})
export class TimeReasonsSettingsComponent implements OnInit, IComponent {
  timeCardComponent: TimeCardComponent;
  timeReasonName: string;

  constructor(
    private aRoute: ActivatedRoute,
    private settingsService: SettingsService,
    private userSharedService: UserSharedService,
    private projectService: UserProjectService,
    private growlService: GrowlService,
    private toast: ToastrService
  ) {}

  ngOnInit() {
    this.settingsService.parentName = this.aRoute.snapshot.params.parent;

    if (this.settingsService.componentType === 'global') {
      this.userSharedService.GetComponent('dailylog').subscribe(result => {
        var timeCardComp = result.Components.filter(x => x.ComponentIdentifier.toLowerCase() === 'timecard');

        if (timeCardComp.length > 0) {
          this.timeCardComponent = timeCardComp[0] as TimeCardComponent;

          this.checkWorkDay(this.timeCardComponent);
        }
      });
    } else if (this.settingsService.componentType === 'project') {
      this.projectService.GetProjectSection(this.settingsService.pId, 'dailylog').subscribe(result => {
        var timeCardComp = result.Components.filter(x => x.ComponentIdentifier.toLowerCase() === 'timecard');

        if (timeCardComp.length > 0) {
          this.timeCardComponent = timeCardComp[0];
          this.checkWorkDay(this.timeCardComponent);
        }
      });
    }
  }

  checkWorkDay(timeCardComponent: TimeCardComponent) {
    var hasWorkDayOption = timeCardComponent.TimeReasons.filter(x => x.toLowerCase() === 'work day');
    if (hasWorkDayOption.length <= 0) {
      timeCardComponent.TimeReasons.unshift('Work Day');
    }
  }

  tempDragEnd() {
    this.Save().subscribe(x => {});
  }

  inputBoxChange(idx, item) {
    this.timeCardComponent['TimeReasons'].splice(idx, 1, item);
    this.Save().subscribe(x => {});
  }

  addTimeReason() {
    if (!this.timeCardComponent['TimeReasons']) {
      this.timeCardComponent['TimeReasons'] = [];
    }

    if (this.timeReasonName && this.timeReasonName !== '') {
      var tr = this.timeCardComponent['TimeReasons'].filter(x => x.toLowerCase() === this.timeReasonName.toLowerCase());

      if (tr.length <= 0) {
        this.timeCardComponent['TimeReasons'].push(this.timeReasonName);
        this.Save().subscribe(result => {});
      } else {
        this.toast.error('Time Reason already exists');
      }
    }
  }

  removeTimeReason(idx, reason) {
    this.timeCardComponent['TimeReasons'].splice(idx, 1);
    this.Save().subscribe(result => {});
  }

  Save(): Observable<boolean> {
    var obs = new Subject<boolean>();
    this.timeCardComponent['_t'] = ['BaseEntity', 'Component', 'ProjectComponent', 'TimeCardComponent'];
    if (this.settingsService.componentType.toLowerCase() === 'project') {
      this.projectService.SaveSub1Component(this.settingsService.pId, 'dailylog', 'timecard', this.timeCardComponent).subscribe(
        result => {
          obs.next(true);
          obs.complete();
        },
        err => {
          obs.next(err);
        }
      );
    } else {
      this.userSharedService.SaveSub1Component('dailylog', 'timecard', this.timeCardComponent).subscribe(
        result => {
          obs.next(true);
          obs.complete();
        },
        err => {
          obs.next(err);
        }
      );
    }

    return obs.asObservable();
  }
  Validate(): Observable<ValidationResult> {
    var vResult = new ValidationResult();
    vResult.IsValid = true;
    return Observable.of(vResult);
  }
  Clear(): Observable<boolean> {
    return Observable.of(true);
  }
  Remove(): Observable<boolean> {
    return Observable.of(true);
  }
  Submit(): Observable<boolean> {
    return Observable.of(true);
  }
  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
