import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { ProjectActivity } from './projectActivity';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { PagedItem } from 'src/app/models/PagedItem';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProjectActivityService {
  constructor(private client: HttpClient) {}

  public GetProjectActivity(projectId: string): Observable<ProjectActivity[]> {
    return this.client.get<ProjectActivity[]>(`/api/project/GetProjectActivity/${projectId}`);
  }

  public GetProjectActivityPaged(
    projectId: string,
    currentPage: number,
    orderBy: string,
    isReversed: boolean,
    searchValue: string
  ): Observable<PagedItem> {
    const search = {
      item: searchValue
    };
    return this.client.post<PagedItem>(`/api/project/GetProjectActivityPaged/${projectId}/${currentPage}/${orderBy}/${isReversed}`, search);
  }
}
