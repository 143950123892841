import { Component, OnInit, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { Policy } from '../models/policy';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'policy',
  templateUrl: './policy.component.html',
  styleUrls: []
})
export class PolicyComponent implements OnInit, AfterViewInit {
  searchPolicy = new FormControl();
  cPolicies: Array<Policy> = [];

  @Input() policies: Array<Policy> = [];
  @Input() view: PolicyView = PolicyView.None;
  @Output() addPolicy = new EventEmitter<Policy>();
  @Output() removePolicy = new EventEmitter<Policy>();

  selectedPolicy: Policy = null;

  ngAfterViewInit(): void {}
  constructor() {
    this.searchPolicy.valueChanges.debounceTime(400).subscribe(value => {
      var sValue = value as string;
      this.policies = this.cPolicies.filter(x => x.PolicyName.toLowerCase().indexOf(sValue.toLowerCase()) > -1);
    });
  }

  ngOnInit(): void {
    this.cPolicies = Object.assign([], this.policies);
  }

  addAPolicy(p: Policy) {
    this.addPolicy.next(p);
  }

  removeAPolicy(p: Policy) {
    this.removePolicy.next(p);
  }
}

enum PolicyView {
  None,
  Dropdown
}
