import { Component, Input } from '@angular/core';
import { ImageInfo } from '../../../custom.controls/image/models/imageinfo';
import { CivCastImageService } from '../../../custom.controls/image/image.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'photo-upload',
  templateUrl: './photos.upload.component.html'
})
export class PhotosUploadComponent {
  @Input() maxFiles: number = 0;
  images: Array<ImageInfo> = new Array<ImageInfo>();

  constructor(private civcastImageService: CivCastImageService, private toastr: ToastrService) {}

  setSelectedFiles(files: FileList) {
    if (this.maxFiles > 0) {
      if (files.length > this.maxFiles) {
        this.toastr.error('Too many files. You are allowed to upload ' + this.maxFiles + ' at a time');
        return;
      }
    }

    var list = new Array<File>();
    for (var x = 0; x < files.length; x++) {
      var file = files.item(x);
      if (this.civcastImageService.isImage(file)) {
        list.push(file);
      } else {
        this.toastr.error('The file named ' + file.name + ' is not allowed.');
      }
    }

    for (let f of list) {
      this.processFile(f);
    }

    this.refreshList();
  }

  processFile(file: File) {
    // this.civcastImageService.addImage(file);
  }

  refreshList() {}
}
