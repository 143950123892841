import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs/Rx';
import * as UserApi from '../models';
import { UserProjectService } from '../../user.project/user.project.service';
import { TeamService } from '../../user.project/components/team/team.service';
import { TeamPermissionType } from '../../user.project/components/team/models/teamPermissionType.enum';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class DelegateService {
  constructor(private client: HttpClient, private teamService: TeamService, private projectService: UserProjectService) {}

  public GetClassifications(): Observable<UserApi.Classification[]> {
    return this.client.get<UserApi.Classification[]>('/api/user/getclassifications');
  }

  public AddClassification(classification: UserApi.Classification): Observable<UserApi.Classification> {
    return this.client.post<UserApi.Classification>('/api/user/AddClassification', classification);
  }

  public SaveClassification(classification: UserApi.Classification): Observable<UserApi.Classification> {
    return this.client.post<UserApi.Classification>('/api/user/SaveClassification', classification);
  }

  public RemoveClassification(classification: UserApi.Classification): Observable<boolean> {
    return this.client.delete<boolean>(`/api/user/RemoveClassification/${classification.Id}`);
  }

  public GetUser(): Observable<UserApi.CMUser> {
    return this.client.get<UserApi.CMUser>('/api/user/GetDelegates');
  }

  public GetPermission(permissionGroup: string, permissionId: string): Observable<TeamPermissionType> {
    return this.client.get<TeamPermissionType>(`/api/user/GetPermission/${permissionGroup}/${permissionId}`);
  }

  public AddDelegates(delegates: UserApi.Delegate[]): Observable<boolean> {
    return this.client.post<boolean>('/api/user/AddDelegateUsers', delegates);
  }

  public FindDelegates(search): Observable<UserApi.Delegate[]> {
    return this.client.post<UserApi.Delegate[]>('/api/user/FindDelegates', search);
  }

  public AccessDelegate(delegate: UserApi.Delegate): Observable<UserApi.CMUser> {
    return this.client.get<UserApi.CMUser>(`/api/user/AccessDelegate/${delegate.Id}`);
  }

  public RemoveDelegate(delegate: UserApi.Delegate): Observable<boolean> {
    const obs = new Subject<boolean>();
    this.client.delete(`/api/user/RemoveDelegate/${delegate.Id}`).subscribe(
      result => {
        this.projectService.RemoveDelegateFromComponents(delegate.Id);
        this.teamService.RemoveTeamMember(delegate.Id).subscribe(
          data => {
            obs.next(true);
            obs.complete();
          },
          err => {
            obs.error(err);
          }
        );
      },
      err => {
        obs.error(err);
      }
    );
    return obs.asObservable();
  }

  public RemoveCurrentDelegate(): Observable<boolean> {
    return this.client.get<boolean>('/api/user/RemoveDelegate');
  }
}
