import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { InfoItem } from '../../models/InfoItem';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalPropertyEdit } from '../modals/property.edit.modal';
import { FormService } from '../../civcast.form.service';

@Component({
  selector: 'edit-element',
  templateUrl: './element.editable.component.html'
})
export class ElementEditable implements OnInit {
  @Input() infoItem: InfoItem;
  @Input() mode: string;
  @Output() editItem = new EventEmitter<InfoItem>();
  @Output() hideItem = new EventEmitter<InfoItem>();
  @Output() removeItem = new EventEmitter<InfoItem>();

  constructor(private modalService: NgbModal, private formService: FormService) {}

  ngOnInit(): void {}

  edit() {
    const modalRef = this.modalService.open(ModalPropertyEdit, { size: 'lg' });
    modalRef.componentInstance.infoItem = Object.assign({}, this.infoItem);
    modalRef.componentInstance.mode = 'Edit';
    modalRef.result.then(
      result => {
        this.infoItem.Name = result.Name;
        this.infoItem.ValueType = result.ValueType;
        this.infoItem.Value = null;
        this.formService.setReadableName(this.infoItem);

        this.editItem.next(this.infoItem);
        ////this.element.Remove()
        //this.target.remove(0);
        //this.addElement(this.infoItem);
      },
      rejected => {
        if ((rejected as string).toLowerCase() === 'delete') {
          this.remove();
        }
      }
    );
  }

  hide() {
    this.hideItem.next(this.infoItem);
  }

  remove() {
    this.removeItem.next(this.infoItem);
  }
}
