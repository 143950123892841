import { Component, OnInit } from '@angular/core';
import { PhotosService } from './photos.service';
import { UserProjectService } from '../../user.project.service';
import { PhotosComponent } from './models/photos.component';
import { ImageInfo } from '../../../custom.controls/image/models/imageinfo';
import { AWSPhotoStorage } from './models/aws.photo.storage';
import { Photo } from './models/photo';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { PhotoViews } from '../../../custom.controls/image/models/photoview.enum';

@Component({
  selector: 'photos-all',
  templateUrl: './photos.all.component.html'
})
export class PhotosAllComponent implements OnInit {
  photosComponents: Array<PhotosComponent> = new Array<PhotosComponent>();
  groupByValue = 'UserId';
  projectId: string;
  permissionType: AccessLevel;
  pView: PhotoViews = PhotoViews.Full;

  constructor(
    private route: ActivatedRoute,
    private photosService: PhotosService,
    private projectsService: UserProjectService,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.projectId = params['id'];
      this.route.data.subscribe(dParams => {
        this.permissionType = dParams.permissionType;

        if (this.permissionType.AccessLevelId.toLowerCase() != 'fullaccess') {
          this.pView = PhotoViews.ViewOnly;
        }

        this.Initialize();
      });
    });
  }

  Initialize() {
    this.photosService.GetAllComps(this.projectId).subscribe(result => {
      this.photosComponents = result;

      for (let comp of result) {
        this.gatherPhotosFromComp(comp);
      }
    });
  }

  gatherPhotosFromComp = function (photoComp: PhotosComponent) {
    photoComp['imageList'] = new Array<ImageInfo>();
    for (let p of photoComp.Photos) {
      var image = this.getImageFromPhoto(p);
      photoComp['imageList'].push(image);
    }
  };

  getImageFromPhoto = function (photo: Photo): ImageInfo {
    var info: ImageInfo = new ImageInfo();

    if (photo.ThumbnailStorage) {
      var awsThumbnailStorage = photo.ThumbnailStorage as AWSPhotoStorage;
      var awsLargeStorage = photo.LargeStorage as AWSPhotoStorage;

      info.ThumbnailImageUrl.Url = awsThumbnailStorage.Location;
      info.ThumbnailImageUrl.SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(awsThumbnailStorage.Location);

      info.LargeImageUrl.Url = awsLargeStorage.Location;
      info.LargeImageUrl.SafeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(awsLargeStorage.Location);

      info.ImageId = photo.PhotoId;
      info.Title = photo.Title;
      info.Description = photo.Description;
      info.UploadDateTime = photo.UploadDate;
    } else {
      console.log('Storage type not supported');
    }

    return info;
  };
}
