import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import * as ProjectApi from '../modules/user.project/models';
import { Permission } from '../modules/user.permissions/models/permission';
import { Policy } from '../modules/user.permissions/models/policy';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';

@Injectable()
export class GlobalService {
  constructor(private client: HttpClient) {}

  public GetStaticData(staticDataId: string): Observable<ProjectApi.Component[]> {
    return this.client.get<ProjectApi.Component[]>(`/api/global/GetStaticData/${staticDataId}`);
  }

  public GetProjectComponent(componentId: string): Observable<ProjectApi.Component> {
    return this.client.get<ProjectApi.Component>(`/api/global/GetComponent/${componentId}`);
  }

  public GetPermissions(): Observable<Permission[]> {
    return this.client.get<Permission[]>('/api/global/GetStaticData/Permissions');
  }

  public GetPolicies(): Observable<Policy[]> {
    return this.client.get<Policy[]>('/api/global/GetStaticData/Policies');
  }

  public SendError(message: string): Observable<any> {
    console.log(message);
    return of();
  }
}
