import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import * as ProjectApi from '../../models';
import { ComponentIdentifier } from '../../models/componentIdentifier';
import { GlobalService } from '../../../../services/global.service';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import { Router } from '@angular/router';
import { SettingsService } from '../settings/settings.service';
import { UserProjectService } from '../../user.project.service';
import { AlertType } from '../../../custom.controls/growl/alert-type.enum';
import { IComponent } from '../../models/IComponent';
import { Observable } from 'rxjs/Observable';
import { ValidationResult } from '../../models/validationResult';

@Component({
  selector: 'dailylog-settings',
  templateUrl: './dailylog.settings.component.html',
  styleUrls: ['../project.component.css', './dailylog.settings.css']
})
export class DailyLogSettingsComponent implements OnInit, AfterViewInit, IComponent {
  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var vResult = new ValidationResult();
      vResult.IsValid = true;
      obs.next(vResult);
      obs.complete();
    });
  }

  @Input() mainComponent: ProjectApi.Component;
  @Input() componentType: string;
  projectId: string;
  constructor(
    private router: Router,
    private globalService: GlobalService,
    private settingsService: SettingsService,
    private userProjectService: UserProjectService,
    private userSharedService: UserSharedService
  ) {}

  ngOnInit(): void {
    if (this.settingsService.componentType === 'global') {
      this.userSharedService.GetComponent('dailylog').subscribe(result => {
        this.mainComponent = result;
      });
    } else if (this.settingsService.componentType === 'project') {
      this.userProjectService.GetProjectSection(this.settingsService.pId, 'dailylog').subscribe(result => {
        this.mainComponent = result;
      });
    }
  }

  setComponents(baseComponents: ProjectApi.Component[]) {
    var dailyLogSettings = baseComponents.filter(x => x.ComponentIdentifier.indexOf('DailyLog') !== -1);
    this.globalService.GetProjectComponent('DailyLog').subscribe(gComp => {
      if (dailyLogSettings.length > 0) {
        if (dailyLogSettings[0].Components) {
          for (let d of dailyLogSettings[0].Components) {
            if (this.mainComponent.Components) {
              var hasComponent = this.mainComponent.Components.filter(x => x.ComponentIdentifier.indexOf(d.ComponentIdentifier) !== -1);

              if (hasComponent.length <= 0) {
                var dobj = Object.assign({}, d);
                dobj['IsActive'] = false;
                if (this.mainComponent.Components) {
                  this.mainComponent.Components.push(dobj);
                } else {
                  this.mainComponent.Components = [];
                  this.mainComponent.Components.push(dobj);
                }
              } else {
                hasComponent[0]['IsActive'] = true;
              }
            }
          }
        }

        if (gComp.Components) {
          for (let g of gComp.Components) {
            g['IsActive'] = false;

            if (this.mainComponent.Components) {
              var hasComponent = this.mainComponent.Components.filter(x => x.ComponentIdentifier.indexOf(g.ComponentIdentifier) !== -1);

              if (hasComponent.length <= 0) {
                this.mainComponent.Components.push(g);
              } else {
                hasComponent['IsActive'] = true;
              }
            } else {
              this.mainComponent.Components = [];
              this.mainComponent.Components.push(g);
            }
          }
        }
      } else {
        if (gComp.Components) {
          for (let g of gComp.Components) {
            g['IsActive'] = false;

            if (this.mainComponent.Components) {
              var hasComponent = this.mainComponent.Components.filter(x => x.ComponentIdentifier.indexOf(g.ComponentIdentifier) !== -1);

              if (hasComponent.length <= 0) {
                this.mainComponent.Components.push(g);
              } else {
                hasComponent['IsActive'] = true;
              }
            } else {
              this.mainComponent.Components = [];
              this.mainComponent.Components.push(g);
            }
          }
        }
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.settingsService.componentType.toUpperCase() === 'PROJECT') {
      this.userProjectService.GetProjectComponentInfo(this.settingsService.pId).subscribe(components => {
        this.setComponents(components);
      });
    } else if (this.settingsService.componentType.toUpperCase() === 'GLOBAL') {
      this.userSharedService.GetComponents().subscribe(components => {
        this.setComponents(components);
      });
    }
  }

  reorderComponents() {
    var activeComponents: ProjectApi.Component[] = [];

    for (let c of this.mainComponent.Components) {
      if (c['IsActive'] === true) {
        activeComponents.push(c);
      }
    }

    if (this.settingsService.componentType.toLowerCase() === 'project') {
      this.userProjectService.ReplaceSubComponents(this.settingsService.pId, 'dailylog', activeComponents).subscribe(
        comps => {
          //this.growlService.addAlert("Daily Log settings have been saved.", AlertType.SUCCESS);
        },
        function (error) {
          this.growlService.addAlert('There was an issue saving the daily log settings', AlertType.DANGER);
        }
      );
    } else {
      this.userSharedService.ReOrderSubComponents('dailylog', activeComponents).subscribe(comps => {
        // this.growlService.addAlert("Daily Log settings have been saved.", AlertType.SUCCESS);
      });
    }
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      var activeComponents: ProjectApi.Component[] = [];

      for (let c of this.mainComponent.Components) {
        if (c['IsActive'] === true) {
          activeComponents.push(c);
        }
      }

      if (this.settingsService.componentType.toLowerCase() === 'project') {
        this.userProjectService.ReplaceSubComponents(this.settingsService.pId, 'dailylog', activeComponents).subscribe(
          comps => {
            obs.next(true);
            obs.complete();
          },
          function (error) {
            obs.next(true);
            obs.error(error);
            this.growlService.addAlert('There was an issue saving the daily log settings', AlertType.DANGER);
          }
        );
      } else {
        this.userSharedService.ReplaceSubComponents('dailylog', activeComponents).subscribe(comps => {
          // this.growlService.addAlert("Daily Log settings have been saved.", AlertType.SUCCESS);
          obs.next(true);
          obs.complete();
        });
      }
    });
  }

  setActive(c: ProjectApi.Component) {
    c['IsActive'] = !c['IsActive'];
  }

  removeComponent(c: ProjectApi.Component) {
    if (this.settingsService.componentType.toLowerCase() === 'project') {
      this.userProjectService.RemoveSubComponent(this.settingsService.pId, this.mainComponent.ComponentIdentifier, c.ComponentIdentifier).subscribe(
        comps => {
          this.setActive(c);
        },
        function (error) {
          this.growlService.addAlert('There was an issue saving the daily log settings', AlertType.DANGER);
        }
      );
    } else {
      this.userSharedService.RemoveSubComponent(this.mainComponent.ComponentIdentifier, c.ComponentIdentifier).subscribe(
        comps => {
          // this.growlService.addAlert("Daily Log settings have been saved.", AlertType.SUCCESS);
          this.setActive(c);
        },
        function (error) {
          this.growlService.addAlert('There was an issue saving the daily log settings', AlertType.DANGER);
        }
      );
    }
  }

  addComponent(c: ProjectApi.Component) {
    if (this.settingsService.componentType.toLowerCase() === 'project') {
      this.userProjectService.AddSubComponent(this.settingsService.pId, this.mainComponent.ComponentIdentifier, c.ComponentIdentifier).subscribe(
        comps => {
          this.setActive(c);
        },
        function (error) {
          this.growlService.addAlert('There was an issue saving the daily log settings', AlertType.DANGER);
        }
      );
    } else {
      this.userSharedService.AddSubComponent(this.mainComponent.ComponentIdentifier, c.ComponentIdentifier).subscribe(
        comps => {
          // this.growlService.addAlert("Daily Log settings have been saved.", AlertType.SUCCESS);
          this.setActive(c);
        },
        function (error) {
          this.growlService.addAlert('There was an issue saving the daily log settings', AlertType.DANGER);
        }
      );
    }
  }

  editSettings(c: ProjectApi.Component) {
    if (this.settingsService.componentType.toLowerCase() === 'project') {
      this.router.navigate(['user', 'project', this.settingsService.pId, 'settings', 'dailylog', c.ComponentIdentifier.toLowerCase()]);
    } else {
      this.router.navigate(['user', 'project-settings-template', 'dailylog', c.ComponentIdentifier.toLowerCase()]);
    }
  }

  SetSettings(component: ProjectApi.Component) {
    if (component.ComponentIdentifier === ComponentIdentifier.WEATHER) {
    }
  }

  Clear(): Observable<boolean> {
    return Observable.of(true);
  }
  Remove(): Observable<boolean> {
    return Observable.of(true);
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
