import { NgModule, ModuleWithProviders } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ManagePolicyComponent } from './components/policy/manage.policy.component';
import { CreatePolicyComponent } from './components/policy/create.policy.component';
import { ManageUserPermissionComponent } from './components/user/manage.user.component';
import { UserBasePermissionComponent } from './components/user.base.permission.component';
import { UACUserService } from './services/uac.user.service';
import { UACProjectService } from './services/uac.project.service';
import { UACPolicyService } from './services/uac.policy.service';
import { PermissionComponent } from './components/permission.component';
import { NgPipesModule } from 'ngx-pipes';
import { UserPermissionsComponent } from './components/user/user.permissions.component';
import { PolicyComponent } from './components/policy.component';
import { UserPolicyComponent } from './components/policy/user.policy.component';
import { ModalAddPolicy } from './components/policy/create.policy.modal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccessControlService } from './services/access.control.service';
import { ProjectPermissionGuardService } from './project.permission.guard.service';
import { PermissionGuardService } from './permission.guard.service';
import { ProjectPermissionResolve } from './project.permission.resolve';
import { PermissionResolve } from './permission.resolve';
import { DndModule } from 'ng2-dnd';
import { ToastrModule } from 'ngx-toastr';
import { HttpClientModule } from '@angular/common/http';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    DndModule.forRoot(),
    NgPipesModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      newestOnTop: true,
      positionClass: 'toast-bottom-left',
      maxOpened: 3,
      enableHtml: true,
      closeButton: true
    })
  ],
  declarations: [
    UserBasePermissionComponent,
    ManagePolicyComponent,
    CreatePolicyComponent,
    ManageUserPermissionComponent,
    PermissionComponent,
    UserPermissionsComponent,
    PolicyComponent,
    UserPolicyComponent,
    ModalAddPolicy
  ],
  providers: [],
  exports: [],
  entryComponents: [ModalAddPolicy, UserPermissionsComponent]
})
export class UserPermissionsModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: UserPermissionsModule,
      providers: [
        UACUserService,
        UACProjectService,
        UACPolicyService,
        AccessControlService,
        PermissionGuardService,
        ProjectPermissionGuardService,
        PermissionResolve,
        ProjectPermissionResolve
      ]
    };
  }
}
