import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { DailyLogComponent } from './dailylogcomponent';
import { SubmitInfo } from './submitInfo';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable()
export class DailyLogService {
  constructor(private client: HttpClient) {}

  public SaveDailyLog(projectId: string, dailyLog: DailyLogComponent): Observable<DailyLogComponent> {
    return this.client.post<DailyLogComponent>(`/component/dailylog/SaveDailyLog/${projectId}`, dailyLog);
  }

  public SubmitDailyLog(projectId: string, dailyLogId: string): Observable<SubmitInfo> {
    return this.client.put<SubmitInfo>(`/component/dailylog/SubmitDailyLog/${projectId}/${dailyLogId}`, '');
  }

  public UnSubmitDailyLog(projectId: string, dailyLogId: string): Observable<boolean> {
    return this.client.put<boolean>(`/component/dailylog/UnSubmitDailyLog/${projectId}/${dailyLogId}`, '');
  }

  public GetDailyLog(projectId: string, year: number, month: number, day: number): Observable<DailyLogComponent> {
    return this.client.get<DailyLogComponent>(`/component/dailylog/GetDailyLog/${projectId}/${year}/${month}/${day}`);
  }

  public GetLastDailyLog(projectId: string): Observable<DailyLogComponent> {
    return this.client.get<DailyLogComponent>(`/component/dailylog/GetLastDailyLog/${projectId}`);
  }

  public GetNotSubmitted(filter: any): Observable<DailyLogComponent[]> {
    return this.client.post<DailyLogComponent[]>('/component/dailylog/GetNotSubmitted', filter);
  }

  public GetAllDailyLogNoData(projects: Array<any>, startDate: NgbDateStruct, endDate: NgbDateStruct): Observable<any[]> {
    const dateObj = {
      Projects: projects,
      StartDate: new Date(startDate.year, startDate.month + 1, startDate.day),
      EndDate: new Date(endDate.year, endDate.month + 1, endDate.day)
    };
    return this.client.post<any[]>('/component/dailylog/GetAllDailyLogNoData', dateObj);
  }

  public GetDailyLogById(projectId: string, dailyLogId: string): Observable<DailyLogComponent> {
    return this.client.get<DailyLogComponent>(`/component/dailylog/GetDailyLogById/${projectId}/${dailyLogId}`);
  }
}
