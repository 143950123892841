import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';

@Injectable()
export class NavService {
  showNavIcon: boolean = false;
  showDelegation: boolean = false;
  isNavOpen: boolean = true;
  isNavOpenTest = new Subject<boolean>();
  isNavOpenTest$ = this.isNavOpenTest.asObservable();
  navOpenState: string = 'active';
}
