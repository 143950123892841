import { Component, OnInit, Input } from '@angular/core';
import * as UserApi from '../../../user/models';
import { TeamService } from './team.service';
import { TeamMember } from './models/TeamMember';
import { Router, ActivatedRoute } from '@angular/router';
import { TeamComponent } from './models/TeamComponent';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IComponent } from '../../models/IComponent';
import { Observable } from 'rxjs/Observable';
import { ValidationResult } from '../../models/validationResult';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { UserService } from '../../../user/services/user.service';

@Component({
  selector: 'project-team',
  templateUrl: './project.team.component.html',
  styleUrls: ['../project.component.css', './project.team.component.css']
})
export class ProjectTeamComponent implements OnInit, IComponent {
  aUser: UserApi.User;
  user: UserApi.CMUser;
  teamComponent: TeamComponent;
  projectId: string;
  @Input() permissionType: AccessLevel;

  public loaded = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private teamService: TeamService,
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) {
    this.userService.getUser().subscribe(aUser => {
      this.aUser = aUser;
    });
  }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.route.data.subscribe(params => {
      this.permissionType = params.permissionType;
      this.route.parent.params.subscribe(params => {
        this.projectId = params['id'];

        this.teamService.GetTeamComponent(this.projectId).subscribe(
          teamComponent => {
            this.teamComponent = teamComponent;
            this.loaded = true;
          },
          err => {
            this.loaded = true;
          }
        );
      });
    });
  }

  addAll() {
    for (let teamMember of this.teamComponent.TeamMembers) {
      this.teamService.SetTeamMemberDeleted(this.projectId, teamMember.Id, false).subscribe(member => {
        teamMember.IsDeleted = false;
      });
    }
  }

  removeAll() {
    for (let teamMember of this.teamComponent.TeamMembers) {
      this.teamService.SetTeamMemberDeleted(this.projectId, teamMember.Id, true).subscribe(member => {
        teamMember.IsDeleted = true;
      });
    }
  }

  addTeamMember(teamMember: TeamMember) {
    this.teamService.SetTeamMemberDeleted(this.projectId, teamMember.Id, false).subscribe(member => {
      teamMember.IsDeleted = false;
    });
  }

  editTeamMember(teamMember: TeamMember) {
    this.router.navigate(['/user', 'project', this.projectId, 'permission', teamMember.DelegateUser.Id]);
    //const modalRef = this.modalService.open(TeamMemberEditModal, { size: 'lg' });
    //modalRef.componentInstance.TeamMember = Object.assign({}, teamMember);
    //modalRef.componentInstance.projectId = this.projectId;
    //modalRef.componentInstance.modalType = 'select';

    //modalRef.result.then(member => {
    //    this.teamComponent.TeamMembers.splice(idx, 1, member);
    //});
  }

  removeTeamMember(idx: number, teamMember: TeamMember) {
    this.teamService.SetTeamMemberDeleted(this.projectId, teamMember.Id, true).subscribe(member => {
      teamMember.IsDeleted = true;
    });
  }

  Save(): Observable<boolean> {
    return new Observable<boolean>(obs => {
      throw new Error('Method not implemented.');
    });
  }

  Validate(): Observable<ValidationResult> {
    return new Observable<ValidationResult>(obs => {
      var vResult = new ValidationResult();
      vResult.IsValid = true;
      obs.next(vResult);
      obs.complete();
    });
  }

  Clear(): Observable<boolean> {
    return Observable.of(true);
  }

  Remove(): Observable<boolean> {
    return Observable.of(true);
  }

  Submit(): Observable<boolean> {
    return Observable.of(true);
  }

  UnSubmit(): Observable<boolean> {
    return Observable.of(true);
  }
}
