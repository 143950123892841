import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserSharedService } from '../../services/user.shared.sevice';
import { UserActivity } from '../../models';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'project-settings-activityhistory',
  templateUrl: './activity.history.component.html'
})
export class ActivityHistoryComponent implements OnInit {
  activity: UserActivity[];
  page: number = 1;
  totalItems: number;
  search = new FormControl();
  orderBy: string;
  projectId: string;
  isReversed: boolean = true;

  constructor(private userSharedService: UserSharedService) {}

  ngOnInit() {
    this.getPagedActivity();

    this.search.valueChanges.debounceTime(800).subscribe(value => {
      this.getPagedActivity();
    });

    //this.userSharedService.GetUserActivity().subscribe(activity => {
    //    this.activity = activity;
    //});
  }

  getPagedActivity() {
    this.userSharedService.GetUserActivityPaged(this.projectId, this.page - 1, this.orderBy, this.isReversed, this.search.value).subscribe(result => {
      this.activity = result.Items;
      this.totalItems = result.Info.TotalItems;
    });
  }

  changePage() {
    this.getPagedActivity();
  }
}
