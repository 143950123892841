import { Component, Input, OnInit, Injectable } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as UserApi from '../../../user/models';
import { EmployeeService } from '../../services/employee.service';

@Component({
  selector: 'modal-foreman-add',
  templateUrl: './foreman.add.modal.html'
})
export class ForemanAddModal implements OnInit {
  @Input() foreman: UserApi.Foreman;
  @Input() delegates: UserApi.Delegate[] = [];
  @Input() formType: string;

  constructor(private activeModal: NgbActiveModal, private employeeService: EmployeeService) {}

  ngOnInit(): void {}

  saveForeman() {
    this.foreman._t = ['BaseEntity', 'Foreman'];
    this.employeeService.SaveForeman(this.foreman).subscribe(emp => {
      this.activeModal.close(emp);
    });
  }

  getDelegateInfo() {
    for (var x = 0; x < this.delegates.length; x++) {
      var del = this.delegates[x];

      if (del.Id === this.foreman.DelegateUserId) {
        this.foreman.FirstName = del.FirstName;
        this.foreman.LastName = del.LastName;
        break;
      }
    }
  }

  close() {
    this.activeModal.dismiss();
  }
}
