import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { NotesComponent } from './models/notescomponent';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable()
export class NotesService {
  constructor(private client: HttpClient) {}

  public GetNotesComponent(projectId: string, year: number, month: number, day: number): Observable<NotesComponent> {
    return this.client.get<NotesComponent>(`/component/notes/GetNotesComponent/${projectId}/${year}/${month}/${day}`);
  }

  public GetNotesComponentByUser(projectId: string, userId: string, year: number, month: number, day: number): Observable<NotesComponent> {
    return this.client.get<NotesComponent>(`/component/notes/GetNotesComponentByUser/${projectId}/${userId}/${year}/${month}/${day}`);
  }

  public SaveNotesComponent(projectId: string, nc: NotesComponent): Observable<NotesComponent> {
    return this.client.post<NotesComponent>(`/component/notes/SaveNotesComponent/${projectId}`, nc);
  }

  public DeleteNoteComponent(projectId: string, year: number, month: number, day: number): Observable<boolean> {
    return this.client.delete<boolean>(`/component/notes/DeleteNotesComponent/${projectId}/${year}/${month}/${day}`);
  }
}
