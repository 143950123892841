import { Component, OnInit, Input, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { UACPolicyService } from '../../services/uac.policy.service';
import { UserSharedService } from '../../../user/services/user.shared.sevice';
import { ActivatedRoute } from '@angular/router';
import { Policy } from '../../models/policy';
import { Permission } from '../../models/permission';
import { GrowlService } from '../../../custom.controls/growl/growl.service';
import { AlertType } from '../../../custom.controls/growl/alert-type.enum';
import { GlobalService } from '../../../../services/global.service';
import { UserPermissionServiceSerializer } from '../../models/userPermissionSerializer';

@Component({
  selector: 'manage-policy',
  templateUrl: './manage.policy.component.html',
  styleUrls: []
})
export class ManagePolicyComponent implements OnInit {
  @Input() policy: Policy;
  permissions: Permission[] = [];
  cPermissions: Permission[] = [];
  permissionGroup: string;
  policyId: string;
  serializer: UserPermissionServiceSerializer = new UserPermissionServiceSerializer();

  @ViewChild('userPermissions', { static: false }) target: ViewContainerRef;

  constructor(
    private route: ActivatedRoute,
    private uacPolicyService: UACPolicyService,
    private userSharedService: UserSharedService,
    private globalService: GlobalService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private growlService: GrowlService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.policyId = params.policyId;

      this.initializePage();
    });
  }

  initializePage() {
    this.globalService.GetPermissions().subscribe(perms => {
      this.permissions = perms.filter(x => x.Access.AccessLevelId.toLowerCase() !== 'deny');
      this.cPermissions = Object.assign([], this.permissions);

      this.userSharedService.GetPolicy(this.policyId).subscribe(p => {
        this.policy = p;
        this.initiateActivePermission();
        //this.createUserPermissionView(p.Permissions);
      });
    });
  }

  initiateActivePermission() {
    for (let sPermission of this.permissions) {
      var permission = this.policy.Permissions.filter(
        x =>
          x.PermissionId === sPermission.PermissionId && x.Group === sPermission.Group && x.Access.AccessLevelId === sPermission.Access.AccessLevelId
      );

      if (permission.length > 0) {
        sPermission['Active'] = true;
      } else {
        sPermission['Active'] = false;
      }
    }
  }

  addPermission(perm: Permission) {
    this.serializer.SerializePermission(perm);

    try {
      delete perm['Active'];
    } catch (e) {}

    var hasPerm = this.policy.Permissions.filter(x => x.PermissionId == perm.PermissionId);
    if (hasPerm.length <= 0) {
      this.userSharedService.AddPermissionToPolicy(this.policy.PolicyId, perm).subscribe(result => {
        this.uacPolicyService.AddPermissionToPolicy(this.policy.PolicyId, perm).subscribe(results => {
          this.policy.Permissions.push(perm);
          this.initiateActivePermission();
        });
      });
    } else {
      this.growlService.addAlert(
        'Permission <b>' +
          hasPerm[0].Name +
          ' - ' +
          hasPerm[0].Access.Name +
          '</b> exists on the policy.  You can only have a single type of permission per policy. You must remove that permission first before adding the new permission',
        AlertType.DANGER
      );
    }
  }

  removePermission(perm: Permission) {
    try {
      delete perm['Active'];
    } catch (e) {}

    this.userSharedService.RemovePermissionFromPolicy(this.policy.PolicyId, perm.PermissionId).subscribe(result => {
      this.uacPolicyService.RemovePermissionFromPolicy(this.policy.PolicyId, perm.PermissionId).subscribe(result => {
        var rIdx = null;
        for (var x = 0; x < this.policy.Permissions.length; x++) {
          var uPerm = this.policy.Permissions[x];
          if (uPerm.PermissionId === perm.PermissionId && uPerm.Group === perm.Group && uPerm.Access.AccessLevelId === perm.Access.AccessLevelId) {
            rIdx = x;
            break;
          }
        }

        if (rIdx != null) {
          this.policy.Permissions.splice(rIdx, 1);
          this.initiateActivePermission();
        }
      });
    });
  }
}
