import { Temperature } from './Temperature';
import { Sky } from './Sky';
import { SelectedData } from '../../../../core/SelectedData';
import { DailyProjectComponent } from '../../daily.project.component';

export class WeatherComponent extends DailyProjectComponent {
  constructor() {
    super();
  }

  _t: ['BaseEntity', 'Component', 'ProjectComponent', 'WeatherComponent'];

  TempId: number;
  SkyId: number;
  SelectedSkys: Array<SelectedData> = new Array();

  Skys: Sky[] = [];
  Temperatures: Temperature[] = [];
}
