import { Component, OnInit, Input, Inject } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import * as UserApi from '../../models';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl } from '@angular/forms';

import 'rxjs/Rx';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import { EmployeeService } from '../../services/employee.service';
import { EmployeeImportModal } from './employee.import.modal';
import { EmployeeAddModal } from './employee.add.modal';
import { UserSharedService } from '../../services/user.shared.sevice';
import { AccessLevel } from '../../../user.permissions/models/accessLevel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'employee-component',
  templateUrl: './employee.component.html'
})
export class EmployeeComponent implements OnInit {
  employees: UserApi.Employee[] = [];
  private nEmployees: UserApi.Employee[] = [];
  status = 'active';
  user: UserApi.CMUser;
  search = new FormControl();
  templateUrl: string;
  view = 'list';
  inputPlaceholder = 'filter by name, number, or foreman';
  @Input() permissionType: AccessLevel;

  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private employeeService: EmployeeService,
    private userSharedService: UserSharedService
  ) {
    this.userSharedService.GetDelegates().subscribe(user => {
      this.user = user;
    });
  }

  ngOnInit() {
    this.route.data.subscribe(params => {
      this.permissionType = params.permissionType;

      this.templateUrl = environment.api + '/user/employee/GetEmployeeTemplate';

      this.gatherAll(this.status);

      this.search.valueChanges.debounceTime(400).subscribe(value => {
        const v = value.toLowerCase().trim();
        this.employees = this.nEmployees.filter(
          item =>
            item.FirstName.toLowerCase().indexOf(v) > -1 ||
            item.LastName.toLowerCase().indexOf(v) > -1 ||
            (item.FirstName + ' ' + item.LastName).toLowerCase().indexOf(v) > -1 ||
            // || item.ForemanId.toLowerCase().indexOf(v) > -1
            item.CustomId.toLowerCase().indexOf(v) > -1
        );

        // this.foremen = this.nForemen.filter(item => item.ForemanId.toLowerCase().indexOf(v) > -1
        //    || item.FirstName.toLowerCase().indexOf(v) > -1
        //    || (item.FirstName + ' ' + item.LastName).toLowerCase().indexOf(v) > -1
        //    || item.LastName.toLowerCase().indexOf(v) > -1);
      });
    });
  }

  gatherAll(status) {
    if (this.view === 'list') {
      // this.getForeman(status);
      this.searchEmployee(status);
      // this.inputPlaceholder = "filter by name, number, or foreman";
      this.inputPlaceholder = 'filter by name, number';
    } else if (this.view === 'crew') {
      // this.getForemanWithEmployees(status);
      this.inputPlaceholder = 'filter foreman by name, number';
    }
  }

  searchEmployee(status) {
    this.status = status;
    this.employeeService.GetEmployees(status).subscribe(employees => {
      this.employees = Object.assign([], employees);
      this.nEmployees = Object.assign([], employees);
      this.search.setValue('');
    });
  }

  setView(view: string) {
    this.view = view;
    this.gatherAll(this.status);
  }

  // getForemanWithEmployees(status) {
  //    this.status = status;
  //    this.employeeService.GetForemenWithEmployees(status).subscribe(foremen => {
  //        this.foremen = foremen;
  //        this.nForemen = Object.assign([], foremen.map(x => {
  //            (x.FirstName === null) ? x.FirstName = "" : x.FirstName;
  //            (x.LastName === null) ? x.LastName = "" : x.FirstName;
  //            return x;
  //        }));
  //    });
  // }

  // getForeman(status) {
  //    this.status = status;
  //    this.employeeService.GetForemen(status).subscribe(foremen => {
  //        this.foremen = foremen;
  //        this.nForemen = Object.assign([], foremen.map(x => {
  //            (x.FirstName === null) ? x.FirstName = "" : x.FirstName;
  //            (x.LastName === null) ? x.LastName = "" : x.FirstName;
  //            return x;
  //        }));
  //    });

  //    this.employeeService.GetForemen("active").subscribe(foremen => {
  //        this.foremanSelection = Object.assign([], foremen);
  //    });
  // }

  importEmployees() {
    const modalRef = this.modalService.open(EmployeeImportModal, { size: 'lg' });
    modalRef.componentInstance.Delegates = this.user.DelegatedUsers;
    modalRef.result.then(result => {
      this.view = 'list';
      this.gatherAll('active');
    });
  }

  saveEmployee(emp) {
    this.employeeService.SaveEmployee(emp).subscribe(value => {});
  }

  addEmployee() {
    const modalRef = this.modalService.open(EmployeeAddModal, { size: 'lg' });
    const emp = new UserApi.Employee();
    emp.IsActive = true;
    modalRef.componentInstance.employee = emp;
    // modalRef.componentInstance.foremen = this.foremanSelection;
    modalRef.componentInstance.formType = 'Add';

    modalRef.result.then(employee => {
      this.employees.push(employee);
      this.nEmployees.push(employee);
    });
  }

  editEmployee(idx: number, emp: UserApi.Employee) {
    const modalRef = this.modalService.open(EmployeeAddModal, { size: 'lg' });
    modalRef.componentInstance.employee = Object.assign({}, emp);
    // modalRef.componentInstance.foremen = this.foremanSelection;
    modalRef.componentInstance.formType = 'Edit';

    modalRef.result.then(employee => {
      this.employees.splice(idx, 1, employee);
      this.nEmployees.splice(idx, 1, employee);
    });
  }

  // editForeman(idx: number, emp: UserApi.Foreman) {
  //    const modalRef = this.modalService.open(ForemanAddModal, { size: 'lg' });
  //    modalRef.componentInstance.foreman = Object.assign({}, emp);;
  //    modalRef.componentInstance.formType = "Edit";
  //    modalRef.componentInstance.delegates = this.user.DelegatedUsers;

  //    modalRef.result.then(foreman => {
  //        this.employees.splice(idx, 1, foreman);
  //        var sIdx = null;
  //        for (var x = 0; x < this.foremen.length; x++) {
  //            var fMan = this.foremen[x] as UserApi.Foreman;

  //            if (fMan.ForemanId === foreman.ForemanId) {
  //                sIdx = x;
  //                break;
  //            }
  //        }

  //        if (sIdx !== null) {
  //            this.foremen.splice(sIdx, 1, foreman);
  //        }
  //    });
  // }

  // addForeman() {
  //    const modalRef = this.modalService.open(ForemanAddModal, { size: 'lg' });
  //    var emp = new UserApi.Foreman();
  //    emp.IsActive = true;
  //    modalRef.componentInstance.foreman = emp;
  //    modalRef.componentInstance.delegates = this.user.DelegatedUsers;
  //    modalRef.componentInstance.formType = "Add";

  //    modalRef.result.then(fman => {
  //        this.foremen.push(fman);
  //        this.foremanSelection.push(fman);
  //        this.nForemen.push(fman);
  //    });
  // }

  deactivate(idx: number, emp: UserApi.Employee) {
    this.employeeService.EmployeeActivation(emp.Id, false).subscribe(e => {
      emp.IsActive = false;
      if (this.status === 'active') {
        this.employees.splice(idx, 1);
        this.nEmployees.splice(idx, 1);
      } else if (this.status === 'nonactive') {
        this.employees.push(emp);
        this.nEmployees.push(emp);
      }
    });
  }

  // deactivateForeman(idx: number, emp: UserApi.Foreman) {
  //    const modalRef = this.modalService.open(ForemanDeactivateModal, { size: 'lg' });
  //    modalRef.componentInstance.foremen = Object.assign([], this.foremen);
  //    modalRef.componentInstance.foreman = emp;

  //    modalRef.result.then(result => {
  //        this.gatherAll(this.status);
  //    });
  // }

  activate(idx: number, emp: UserApi.Employee) {
    this.employeeService.EmployeeActivation(emp.Id, true).subscribe(e => {
      emp.IsActive = false;
      if (this.status === 'nonactive') {
        this.employees.splice(idx, 1);
        this.nEmployees.slice(idx, 1);
      } else if (this.status === 'active' || this.status === 'all') {
        this.employees.push(emp);
        this.nEmployees.push(emp);
      }
    });
  }

  // activateForeman(idx: number, emp: UserApi.Foreman) {
  //    this.employeeService.ForemanActivation(emp.Id, true).subscribe(e => {
  //        emp.IsActive = false;
  //        if (this.status === "nonactive") {
  //            this.foremen.splice(idx, 1);
  //            this.nForemen.splice(idx, 1);
  //            this.foremanSelection.splice(idx, 1);
  //        } else if (this.status === "active" || this.status === "all") {
  //            this.foremen.push(emp);
  //            this.nForemen.push(emp);
  //            this.foremanSelection.push(emp);
  //        }
  //    });
  // }
}
