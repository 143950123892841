import * as ProjectApi from './';
import { BaseEntity } from '../../core/BaseEntity';

export class Project extends BaseEntity {
  UserId: string;
  Title: string;
  InternalId: string;
  IsHighlighted: boolean;

  Components: ProjectApi.Component[] = [];
}
