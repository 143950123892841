import { Injectable } from '@angular/core';
import 'rxjs/add/operator/toPromise';
import { Observable } from 'rxjs/Rx';
import { TeamMember } from '../team/models/TeamMember';
import { saveAs } from 'file-saver';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { SelectedData } from '../../../core/SelectedData';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ReportsService {
  constructor(private client: HttpClient, private authService: AuthService) {}

  public RunDailyReport(
    projectId: string,
    startDate: NgbDateStruct,
    endDate: NgbDateStruct,
    users: Array<SelectedData>,
    options: any
  ): Observable<boolean> {
    return new Observable<boolean>(obs => {
      const info = {
        StartDate: startDate,
        EndDate: endDate,
        Users: users,
        Options: options
      };
      this.authService.getAccessToken().subscribe(result => {
        const url = `/Component/Reports/GetDailyLogReport/${projectId}`;
        const res = this.client
          .post(url, info, {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${result}` },
            responseType: 'blob'
          })
          .subscribe(result => {
            saveAs(result, 'DailyReport.pdf');
            obs.next(true);
          });
      });
    });
  }

  public GetReportUsers(projectId): Observable<TeamMember[]> {
    return this.client.get<TeamMember[]>(`/Component/Reports/GetReportUsers/${projectId}`);
  }
}
