import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApprovalHistory } from './models/approvalHistory';

@Component({
  selector: 'approval-history',
  templateUrl: './approvalhistory.modal.component.html'
})
export class ApprovalHistoryModalComponent {
  @Input() history: Array<ApprovalHistory>;

  constructor(private activeModal: NgbActiveModal) {}

  close() {
    this.activeModal.dismiss();
  }
}
