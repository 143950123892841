import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import * as UserApi from '../models';
import { HttpClient } from '@angular/common/http';
import { Auth } from 'aws-amplify';
import { environment } from 'src/environments/environment';

@Injectable()
export class EquipmentService {
  constructor(private client: HttpClient) {}

  public GetEquipment(): Observable<UserApi.EquipmentItem[]> {
    return this.client.get<UserApi.EquipmentItem[]>('/user/equipment/GetEquipment');
  }

  public SaveEquipmentItem(item: UserApi.EquipmentItem): Observable<UserApi.EquipmentItem> {
    return this.client.post<UserApi.EquipmentItem>('/user/equipment/SaveEquipmentItem', item);
  }

  public RemoveEquipmentItem(item: UserApi.EquipmentItem): Observable<boolean> {
    return this.client.delete<boolean>(`/user/equipment/RemoveEquipmentItem/${item.Id}`);
  }
}
