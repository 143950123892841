import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { WeatherComponent } from './models/weather.component';
import { Auth } from 'aws-amplify';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class WeatherService {
  constructor(private client: HttpClient) {}

  public GetWeatherComponent(projectId: string, year: number, month: number, day: number): Observable<WeatherComponent> {
    return this.client.get<WeatherComponent>(`/component/weather/GetWeatherComponent/${projectId}/${year}/${month}/${day}`);
  }

  public GetWeatherComponentByUser(
    projectId: string,
    userId: string,
    year: number,
    month: number,
    day: number
  ): Observable<WeatherComponent> {
    return this.client.get<WeatherComponent>(`/component/weather/GetWeatherComponentByUser/${projectId}/${userId}/${year}/${month}/${day}`);
  }

  public SaveWeatherComponent(projectId: string, tc: WeatherComponent): Observable<WeatherComponent> {
    return this.client.post<WeatherComponent>(`/component/weather/SaveWeatherComponent/${projectId}`, tc);
  }

  public DeleteWeatherComponent(projectId: string, year: number, month: number, day: number): Observable<boolean> {
    return this.client.delete<boolean>(`/component/weather/DeleteWeatherComponent/${projectId}/${year}/${month}/${day}`);
  }
}
